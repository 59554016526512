import { useEffect, useState } from "react";
import API from "../../../../services/api";
import Loader from "../../../../components/loader";
import Cashout from "./cashout";
import Payments from "./payments";

export default ({ project }) => {
  const [banks, setBanks] = useState([]);
  const [cashout, setCashout] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getPayments();
    getCashouts();
  }, []);

  const getPayments = async () => {
    setLoading(true);
    try {
      const { data } = await API.post(`/bank/search`, { per_page: 100, projectId: project._id, category: "INCOME" });
      setBanks(data.banks);
    } catch (e) {
      console.log(e);
    }
  };

  const getCashouts = async () => {
    try {
      const { data } = await API.get(`/cashout/project/${project._id}`);
      setCashout(data);
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  if (loading) return <Loader />;

  return (
    <div className="space-y-6">
      <Cashout cashout={cashout} setCashout={setCashout} />
      <Payments banks={banks} />
    </div>
  );
};
