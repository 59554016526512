import React, { useEffect, useState } from "react";
import Loader from "../../../components/loader";
import api from "../../../services/api";
import moment from "moment";
import toast from "react-hot-toast";
import SelectJobChannel from "../../../components/selectJobChannel";
import Modal from "../../../components/modal";
import { IoIosLink } from "react-icons/io";
import { slugify } from "../../../utils";

export default ({ job }) => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    get();
  }, []);

  async function get() {
    const { data } = await api.post("/jobpost/search", { job_id: job._id });
    setItems(data);
  }

  if (!job) return <Loader />;

  return (
    <div className="p-2 mt-2">
      <Create job={job} callback={get} />
      <div className="overflow-x-auto">
        <table className="w-full">
          <thead className="border-b border-e5eaef">
            <tr className="text-sm text-212325 font-semibold">
              <th className="py-3 px-3 w-1/6">Name</th>
              <th className="w-1/6">Project</th>
              <th className="w-1/6">Channel</th>
              <th className="w-1/6">Views</th>
              <th className="w-1/6">Created At</th>
              <th className="w-1/6"></th>
            </tr>
          </thead>
          <tbody>
            {items.map((hit) => (
              <tr className="hover:bg-f6f6f6 border-b border-dee2e6 text-676d7c cursor-pointer text-sm font-medium" key={hit._id}>
                <td className="px-3 w-1/6">
                  <a href={`/jobpost/${hit._id}`} target="_blank" rel="noreferrer">
                    {hit.job_title}
                  </a>
                </td>
                <td className="w-1/6">{hit.project_name}</td>
                <td className="w-1/6">{hit.channel_name}</td>
                <td className="w-1/6">{hit.views}</td>
                <td className="py-2.5 px-4 flex items-center space-x-3 w-1/6">
                  <div>{moment(hit.created_at).format("DD/MM/YYYY, HH:MM")}</div>
                </td>
                <td className="text-right w-1/6">
                  <div className="flex flex-col items-end gap-2">
                    <a href={`/auth/apply?jobpost=${hit._id}`} target="_blank" rel="noreferrer" className="text-blue-500 hover:underline">
                      apply
                    </a>
                    {hit.channel_name === "Accounting" && (
                      <div className="flex items-center gap-2">
                        <a href={job.webflow_url} target="_blank" rel="noreferrer" className="text-blue-500 hover:underline">
                          view offer
                        </a>
                        <button onClick={() => navigator.clipboard.writeText(job.webflow_url)} className="text-blue-500 hover:bg-gray-100 p-3 rounded-md flex items-center gap-1">
                          <IoIosLink size={14} />
                        </button>
                      </div>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const Create = ({ job, callback }) => {
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({});

  async function onCreate() {
    const obj = {};
    if (!values.channel) return toast.error("Missing channel !");

    obj.job_id = job._id;
    obj.job_title = job.title;
    obj.project_id = job.project_id;
    obj.project_name = job.project_name;
    obj.channel_id = values.channel._id;
    obj.channel_name = values.channel.name;

    const res = await api.post("/jobpost", obj);
    if (!res.ok) return toast.error("Error !", res.code);
    toast.success("Created!");
    callback();
    setOpen(false);
  }
  return (
    <div style={{ marginBottom: 10 }}>
      <div className="text-right">
        <button className="btn btn-primary" onClick={() => setOpen(true)}>
          Create a job post
        </button>
      </div>
      <Modal isOpen={open} className="max-w-xl w-full p-6" onClose={() => setOpen(false)}>
        <div className="w-full mb-6">
          <div className="text-sm font-medium mb-2">Channel (*)</div>
          <SelectJobChannel className="projectsInput" value={values.channel} onChange={(e) => setValues({ ...values, channel: e })} />
        </div>

        <button className="btn btn-primary mt-4" onClick={onCreate}>
          Create
        </button>
      </Modal>
    </div>
  );
};
