import React, { useEffect, useState } from "react";
import { FaLocationDot } from "react-icons/fa6";
import FileInput from "../../../../components/FileInput";

const CvHeader = ({ values, editing, onChange, lang }) => {
  if (editing) return <CvHeaderForm values={values} onChange={onChange} lang={lang} />;

  return (
    <div className="flex flex-row gap-4 items-center p-4 bg-white">
      <div className="w-24 h-24">{values.image_url && <img src={values.image_url} alt="Profile" className="w-full h-full object-cover rounded-full" />}</div>
      <div className="flex-1">
        <h2 className="text-xl font-semibold">{values.employee}</h2>
        <h3 className="text-sm text-gray-600">{values.description[lang]}</h3>
        <div className="flex flex-wrap mt-2 border-t border-gray-200 pt-2">
          <InfoBlock label={lang === "fr" ? "Tarif indicatif" : "Indicative Price"} value={values.tjm} />
          <InfoBlock label={lang === "fr" ? "Expérience" : "Experience"} value={values.experience[lang]} />
          <InfoBlock label={lang === "fr" ? "Disponibilité" : "Availability"} value={values.availability[lang]} />
          <InfoBlock label={lang === "fr" ? "Localisation" : "Location"} value={values.location[lang]} icon={<FaLocationDot />} />
        </div>
      </div>
    </div>
  );
};

const InfoBlock = ({ label, value, icon }) => (
  <div className="flex items-center gap-1 p-2 flex-grow">
    {icon && <span className="text-gray-500">{icon}</span>}
    <div>
      <div className="text-xs text-gray-500">{label}</div>
      <div className="text-sm font-medium">{value}</div>
    </div>
  </div>
);

const CvHeaderForm = ({ values, onChange, lang }) => {
  const handleChange = (e) => {
    const field = e.target.name;
    if (field === "description" || field === "experience" || field === "availability" || field === "location") {
      onChange({ ...values, [field]: { ...values[field], [lang]: e.target.value } });
    } else {
      onChange({ ...values, [field]: e.target.value });
    }
  };

  return (
    <div className="flex flex-col gap-2 p-4 bg-white border border-gray-200 rounded-md shadow-md">
      <div className="flex">
        <div className="w-1/3">
          <div className="font-medium">Image</div>
          <FileInput folder="quotes/cv_header" value={values.image_url} name="image_url" onChange={handleChange} />
        </div>
        <div className="w-2/3">
          <div className="font-medium mb-2">Employee name</div>
          <input className="input mb-2" type="text" name="employee" value={values.employee} onChange={handleChange} />
          <div className="font-medium mb-2">Sub Title</div>
          <input className="input mb-2" type="text" name="description" value={values.description[lang]} onChange={handleChange} />
          <div className="flex flex-wrap gap-4">
            <div>
              <div className="font-medium mb-2">Tarif indicatif</div>
              <input className="input" type="text" name="tjm" value={values.tjm} onChange={handleChange} />
            </div>
            <div>
              <div className="font-medium mb-2">Experience</div>
              <input className="input" type="text" name="experience" value={values.experience[lang]} onChange={handleChange} />
            </div>
            <div>
              <div className="font-medium mb-2">Disponibilité</div>
              <input className="input" type="text" name="availability" value={values.availability[lang]} onChange={handleChange} />
            </div>
            <div>
              <div className="font-medium mb-2">Localisation</div>
              <input className="input" type="text" name="location" value={values.location[lang]} onChange={handleChange} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CvHeader;
