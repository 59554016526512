import React, { useEffect, useState } from "react";
import { Routes, Route, useParams, Link } from "react-router-dom";
import { IoPeople, IoInformationCircle, IoCard, IoPencil, IoNewspaper, IoDesktop } from "react-icons/io5";

import Loader from "../../../components/loader";

import api from "../../../services/api";

import Info from "./info";
import EditorBis from "./editorbis";
import ComptaBis from "./compta-bis";
import Logs from "./logs";
import Bank from "./bank";
import Raw from "../../../components/Raw";

const TABS = [
  { title: "Info", href: "", icon: <IoInformationCircle /> },
  { title: "Compta", href: "compta-new", icon: <IoDesktop /> },
  { title: "Editor", href: "editorbis", icon: <IoPencil /> },
  { title: "Logs", href: "logs", icon: <IoNewspaper /> },
  { title: "Banks", href: "bank", icon: <IoPeople /> },
  { title: "Raw", href: "raw", icon: <IoNewspaper /> },
];

const Invoice = () => {
  const [invoice, setInvoice] = useState(null);
  const [project, setProject] = useState();
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: invoiceObject } = await api.get(`/invoice/${id}`);
        setInvoice(invoiceObject);
        api.get(`/project/${invoiceObject.projectId}`).then((res) => {
          if (res.data) setProject(res.data);
        });
      } catch (e) {
        console.log("a", e);
      }
    };
    fetchData();
  }, [id]);

  if (!invoice || !project) return <Loader />;

  return (
    <div className="px-8 py-12 flex flex-col gap-4">
      <div className="flex justify-between">
        <div className="flex items-center justify-between gap-2 w-full">
          <h1 className="text-3xl text-gray-900">
            Invoice - {invoice.name} {invoice.index ? `#${invoice.index}` : ""}
          </h1>
          <Link to={`/project/${invoice.projectId}`} className="empty-btn">
            See the project
          </Link>
        </div>
      </div>

      <TabBar tabs={TABS} />

      <div className="w-full">
        <Routes>
          <Route path="/" element={<Info invoice={invoice} setInvoice={setInvoice} project={project} setProject={setProject} />} />
          <Route path="/compta-new" element={<ComptaBis invoice={invoice} setInvoice={setInvoice} project={project} setProject={setProject} />} />
          <Route path="/editorbis" element={<EditorBis invoice={invoice} setInvoice={setInvoice} project={project} setProject={setProject} />} />
          <Route path="/logs" element={<Logs invoice={invoice} setInvoice={setInvoice} project={project} setProject={setProject} />} />
          <Route path="/bank" element={<Bank invoice={invoice} setInvoice={setInvoice} project={project} setProject={setProject} />} />
          <Route path="/raw" element={<Raw data={invoice} />} />
        </Routes>
      </div>
    </div>
  );
};

const TabBar = ({ tabs }) => (
  <div className="overflow-x-scroll">
    <nav className="bg-white rounded-md flex item-center gap-1 w-full border border-neutral-300 p-1">
      {tabs.map((tab, i) => (
        <TabItem key={i} href={tab.href} title={tab.title} icon={tab.icon} />
      ))}
    </nav>
  </div>
);

const TabItem = ({ href, title, icon }) => {
  const { id } = useParams();
  const active = href === "" ? window.location.pathname === `/project/${id}` : window.location.pathname === `/project/${id}/${href}`;

  return (
    <Link to={`/invoice/${id}/${href}`} className={`${active ? "blue-btn" : "transparent-btn"} relative rounded py-2 px-4 flex items-center gap-2`}>
      {icon}
      <div className="flex items-center gap-2 truncate">{title}</div>
    </Link>
  );
};

export default Invoice;
