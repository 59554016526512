import { useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../../services/api";
import LoadingButton from "../../../components/loadingButton";
import UserSelector from "../../../components/selectUser";
import toast from "react-hot-toast";

const Edit = ({ techSkill, setTechSkill }) => {
  const navigate = useNavigate();
  const [values, setValues] = useState(techSkill);
  const [btnLoading, setBtnLoading] = useState(false);

  const onUpdate = async () => {
    setBtnLoading(true);
    const { data, ok, code } = await api.put(`/user_tech_skill/${techSkill._id}`, values);
    if (!ok) {
      toast.error(code || "Error updating!");
      setBtnLoading(false);
    } else {
      toast.success("Updated!");
      setBtnLoading(false);
      setTechSkill(data);
    }
  };

  const onDelete = async () => {
    const { ok } = await api.remove(`/user_tech_skill/${techSkill._id}`);
    if (!ok) return toast.error("Error deleting tech skill assessment!");
    toast.success("Deleted!");
    navigate("/tech_skills");
  };

  const skillLevels = ["BAD", "FAIR", "GOOD", "MASTER", "IDK"];

  return (
    <div className="">
      <div className="grid grid-cols-2 gap-6">
        <div className="w-full">
          <div className="font-medium mb-2 text-sm">Select User</div>
          <UserSelector
            placeholder="Select User"
            className="w-full"
            value={{
              _id: values.user_id,
              name: values.user_name,
              avatar: values.user_avatar,
            }}
            onChange={(e) => {
              setValues({ ...values, user_avatar: e.avatar, user_name: e.name, user_id: e._id });
            }}
          />
        </div>

        <div className="w-full">
          <div className="font-medium mb-2 text-sm">Data Modeling</div>
          <select className="input" value={values["Data Modeling"] || ""} onChange={(e) => setValues({ ...values, "Data Modeling": e.target.value })}>
            <option value="" disabled>Select Level</option>
            {skillLevels.map((level) => (
              <option key={level} value={level}>{level}</option>
            ))}
          </select>
        </div>

        <div className="w-full">
          <div className="font-medium mb-2 text-sm">API Principles</div>
          <select className="input" value={values["API Principles"] || ""} onChange={(e) => setValues({ ...values, "API Principles": e.target.value })}>
            <option value="" disabled>Select Level</option>
            {skillLevels.map((level) => (
              <option key={level} value={level}>{level}</option>
            ))}
          </select>
        </div>

        <div className="w-full">
          <div className="font-medium mb-2 text-sm">Conventions</div>
          <select className="input" value={values["Conventions"] || ""} onChange={(e) => setValues({ ...values, "Conventions": e.target.value })}>
            <option value="" disabled>Select Level</option>
            {skillLevels.map((level) => (
              <option key={level} value={level}>{level}</option>
            ))}
          </select>
        </div>

        <div className="w-full">
          <div className="font-medium mb-2 text-sm">Separation of Concerns</div>
          <select className="input" value={values["Separation of Concerns"] || ""} onChange={(e) => setValues({ ...values, "Separation of Concerns": e.target.value })}>
            <option value="" disabled>Select Level</option>
            {skillLevels.map((level) => (
              <option key={level} value={level}>{level}</option>
            ))}
          </select>
        </div>

        <div className="w-full">
          <div className="font-medium mb-2 text-sm">Componentisation</div>
          <select className="input" value={values["Componentisation"] || ""} onChange={(e) => setValues({ ...values, "Componentisation": e.target.value })}>
            <option value="" disabled>Select Level</option>
            {skillLevels.map((level) => (
              <option key={level} value={level}>{level}</option>
            ))}
          </select>
        </div>

        <div className="w-full">
          <div className="font-medium mb-2 text-sm">React Native</div>
          <select className="input" value={values["React Native"] || ""} onChange={(e) => setValues({ ...values, "React Native": e.target.value })}>
            <option value="" disabled>Select Level</option>
            {skillLevels.map((level) => (
              <option key={level} value={level}>{level}</option>
            ))}
          </select>
        </div>

        <div className="w-full">
          <div className="font-medium mb-2 text-sm">Multiple Project Experience</div>
          <select className="input" value={values["Multiple Project Experience"] || ""} onChange={(e) => setValues({ ...values, "Multiple Project Experience": e.target.value })}>
            <option value="" disabled>Select Level</option>
            {skillLevels.map((level) => (
              <option key={level} value={level}>{level}</option>
            ))}
          </select>
        </div>

        <div className="w-full">
          <div className="font-medium mb-2 text-sm">Devops</div>
          <select className="input" value={values["Devops"] || ""} onChange={(e) => setValues({ ...values, "Devops": e.target.value })}>
            <option value="" disabled>Select Level</option>
            {skillLevels.map((level) => (
              <option key={level} value={level}>{level}</option>
            ))}
          </select>
        </div>

        <div className="w-full">
          <div className="font-medium mb-2 text-sm">AI</div>
          <select className="input" value={values["AI"] || ""} onChange={(e) => setValues({ ...values, "AI": e.target.value })}>
            <option value="" disabled>Select Level</option>
            {skillLevels.map((level) => (
              <option key={level} value={level}>{level}</option>
            ))}
          </select>
        </div>

        <div className="w-full">
          <div className="font-medium mb-2 text-sm">DevOps Selego PMF Kit</div>
          <select className="input" value={values["DevOps Selego PMF Kit"] || ""} onChange={(e) => setValues({ ...values, "DevOps Selego PMF Kit": e.target.value })}>
            <option value="" disabled>Select Level</option>
            {skillLevels.map((level) => (
              <option key={level} value={level}>{level}</option>
            ))}
          </select>
        </div>

        <div className="w-full">
          <div className="font-medium mb-2 text-sm">Communication</div>
          <select className="input" value={values["Communication"] || ""} onChange={(e) => setValues({ ...values, "Communication": e.target.value })}>
            <option value="" disabled>Select Level</option>
            {skillLevels.map((level) => (
              <option key={level} value={level}>{level}</option>
            ))}
          </select>
        </div>

        <div className="w-full">
          <div className="font-medium mb-2 text-sm">Ownership</div>
          <select className="input" value={values["Ownership"] || ""} onChange={(e) => setValues({ ...values, "Ownership": e.target.value })}>
            <option value="" disabled>Select Level</option>
            {skillLevels.map((level) => (
              <option key={level} value={level}>{level}</option>
            ))}
          </select>
        </div>

        <div className="w-full">
          <div className="font-medium mb-2 text-sm">GetShitDone</div>
          <select className="input" value={values["GetShitDone"] || ""} onChange={(e) => setValues({ ...values, "GetShitDone": e.target.value })}>
            <option value="" disabled>Select Level</option>
            {skillLevels.map((level) => (
              <option key={level} value={level}>{level}</option>
            ))}
          </select>
        </div>

        <div className="w-full">
          <div className="font-medium mb-2 text-sm">Quote Making</div>
          <select className="input" value={values["Quote Making"] || ""} onChange={(e) => setValues({ ...values, "Quote Making": e.target.value })}>
            <option value="" disabled>Select Level</option>
            {skillLevels.map((level) => (
              <option key={level} value={level}>{level}</option>
            ))}
          </select>
        </div>

        <div className="w-full">
          <div className="font-medium mb-2 text-sm">Hiring</div>
          <select className="input" value={values["Hiring"] || ""} onChange={(e) => setValues({ ...values, "Hiring": e.target.value })}>
            <option value="" disabled>Select Level</option>
            {skillLevels.map((level) => (
              <option key={level} value={level}>{level}</option>
            ))}
          </select>
        </div>

        <div className="w-full">
          <div className="font-medium mb-2 text-sm">Becoming a Lead Mini CTO</div>
          <select className="input" value={values["lead_mini_cto"] || ""} onChange={(e) => setValues({ ...values, "lead_mini_cto": e.target.value })}>
            <option value="" disabled>Select Level</option>
            <option value="true">Yes</option>
            <option value="false">No</option>
          </select>
        </div>

        <div className="w-full col-span-2">
          <div className="font-medium mb-2 text-sm">Add Comment</div>
          <textarea
            placeholder="Enter comment..."
            className="input"
            onBlur={(e) => {
              const newLogs = [...(values.logs || [])];
              newLogs.push({
                date: new Date(),
                comment: e.target.value
              });
              setValues({ ...values, logs: newLogs });
            }}
          />
        </div>

      </div>

      <div className="flex items-center justify-between space-x-2 mt-10 border-t pt-8">
        <button className="btn bg-red-500 text-white" onClick={onDelete}>
          Delete
        </button>

        <LoadingButton className="btn bg-primary text-white" loading={btnLoading} onClick={onUpdate}>
          Update
        </LoadingButton>
      </div>
    </div>
  );
};

export default Edit;
