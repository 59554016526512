import React, { useEffect, useState } from "react";
import { FaLinkedin, FaComment, FaEnvelope } from "react-icons/fa";
import toast from "react-hot-toast";

import api from "../../../../services/api";

export default ({ user, company }) => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState("");
  const [intent, setIntent] = useState("");
  const [sendMethod, setSendMethod] = useState("linkedin");
  const [activities, setActivities] = useState([]);

  useEffect(() => {
    fetchActivities();
  }, [user]);

  if (!user) return <div />;

  async function fetchActivities() {
    try {
      if (!user) return;
      setLoading(true);
      const { data } = await api.post(`/s_activity/search`, { contact_id: user._id });
      console.log("data", data);
      setActivities(data.reverse());
      setLoading(false);
    } catch (e) {
      console.error(e);
      toast.error("Error fetching activities");
      setLoading(false);
    }
  }

  async function onSendLinkedin() {
    try {
      setLoading(true);
      const res = await api.post(`/s_contact/send_linkedin`, { message, intent, contact_id: user._id });
      if (!res.ok) throw res;
      toast.success("Message sent");
      setMessage("");
      setIntent("");
      await fetchActivities();
      setLoading(false);
    } catch (e) {
      console.error(e);
      toast.error("Some Error!", e.code);
    }
  }

  async function onSendEmail() {
    try {
      setLoading(true);
      const res = await api.post(`/s_contact/send_email`, { message, intent, subject, contact_id: user._id });
      if (!res.ok) throw res;
      toast.success("Message sent");
      setMessage("");
      setIntent("");
      await fetchActivities();
      setLoading(false);
    } catch (e) {
      console.error(e);
      toast.error("Some Error!", e.code);
    }
  }

  async function onSaveDraft() {
    try {
      setLoading(true);
      const res = await api.post(`/s_activity`, { message, intent, method: sendMethod, subject, contact_id: user._id, status: "draft" });
      if (!res.ok) throw res;
      toast.success("Draft saved");
      setMessage("");
      setIntent("");
      await fetchActivities();
      setLoading(false);
    } catch (e) {
      console.error(e);
      toast.error("Some Error!", e.code);
    }
  }

  const getActivityIcon = (type) => {
    if (type.includes("linkedin")) return <FaLinkedin className="text-blue-600" />;
    if (type.includes("email")) return <FaEnvelope className="text-red-600" />;
    return <FaComment className="text-gray-600" />;
  };

  return (
    <div className="bg-white shadow-md p-4 flex flex-col h-full">
      <div className="flex-grow overflow-y-auto flex flex-col-reverse mb-4" style={{ maxHeight: "400px" }}>
        {loading ? (
          <div className="text-center py-4">Loading messages...</div>
        ) : activities.length === 0 ? (
          <div className="text-center text-gray-500 py-4">No messages yet</div>
        ) : (
          <div className="space-y-4">
            {activities.map((activity) => (
              <div key={activity._id} className="p-3 bg-gray-50 rounded-lg">
                <div className="flex items-center justify-between mb-2">
                  <div className="flex items-center gap-2">
                    {getActivityIcon(activity.type)}
                    <span className="font-medium capitalize text-sm">{activity.type.replace("linkedin_", "").replace("_", " ")}</span>
                  </div>
                  <div className="text-xs text-gray-400">{new Date(activity.created_at).toLocaleString()}</div>
                </div>
                {activity.message && <div className="text-sm text-gray-600">{activity.message}</div>}
              </div>
            ))}
          </div>
        )}
      </div>

      <div className="sticky bottom-0 bg-white">
        <Recommendation user={user} company={company} activities={activities} setMessage={setMessage} />
        {sendMethod === "email" && <input className="input w-full mb-2" placeholder="Subject line..." value={subject} onChange={(e) => setSubject(e.target.value)} />}
        <textarea className="input w-full mb-2" value={message} onChange={(e) => setMessage(e.target.value)} placeholder="Type your message..." rows={4} />
        <div className="flex gap-2">
          <select className="input w-full mb-4" value={sendMethod} onChange={(e) => setSendMethod(e.target.value)}>
            <option value="linkedin">LinkedIn</option>
            <option value="email">Email</option>
          </select>
          <button
            className="btn blue-btn flex-1"
            onClick={(e) => {
              if (sendMethod === "linkedin") return onSendLinkedin();
              return onSendEmail();
            }}
            disabled={loading || !message}>
            {loading ? "Sending..." : `Send ${sendMethod}`}
          </button>
          <button className="btn border border-gray-300 flex-1" onClick={onSaveDraft} disabled={loading}>
            Save as Draft
          </button>
        </div>
      </div>
    </div>
  );
};

const Recommendation = ({ user, company, setMessage, activities }) => {
  const [loading, setLoading] = useState(false);
  const [intent, setIntent] = useState("");

  console.log(activities);

  useEffect(() => {
    const lastActivity = activities[activities.length - 1];
    if (!lastActivity) return;
    if (lastActivity.type === "linkedin_message_received") return setIntent(`Answer to his last message`);
  }, [activities]);

  async function generateRecommendation() {
    try {
      setLoading(true);
      if (!user) return;
      const res = await api.put(`/s_contact/${user._id}/bertrand`, { intent });
      if (!res.ok) return;
      setMessage(res.data.message);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="bg-white mb-2 mt-2">
      <div className=" flex gap-2">
        <input className="input flex-1" value={intent} onChange={(e) => setIntent(e.target.value)} placeholder="Enter your intent..." />
        <button className="btn blue-btn px-3" onClick={generateRecommendation} disabled={loading}>
          {loading ? <span className="animate-spin">⟳</span> : <span>✨</span>}
        </button>
      </div>
    </div>
  );
};
