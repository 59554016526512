import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { getDaysInMonth } from "../../utils";

import api from "../../../../services/api";

const Activities = ({ project, filters }) => {
  const [activities, setActivities] = useState([]);
  const [date, setDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
  const [days, setDays] = useState([]);
  const { search } = useLocation();
  const query = new URLSearchParams(search);

  useEffect(() => {
    if (!project || !date) return;

    (async () => {
      const from = new Date(date);
      const to = new Date(date);
      from.setDate(1);
      to.setMonth(to.getMonth() + 1);
      to.setDate(0);
      setDays(getDaysInMonth(from.getMonth(), from.getFullYear()));
      const { data } = await api.get(`/activity?dateFrom=${from.getTime()}&dateTo=${to.getTime()}&projectName=${encodeURIComponent(project.name)}`);
      setActivities(data);
    })();
  }, [date, project]);

  useEffect(() => {
    if (!filters.date) return;
    setDate(new Date(filters.date));
  }, [filters.date]);

  const getTotalDays = () => {
    return (activities.reduce((acc, a) => acc + a.total, 0) / 8).toFixed(2);
  };

  const getTotalBudget = () => {
    return activities.reduce((acc, a) => acc + a.value, 0).toFixed(2);
  };

  return (
    <div className="space-y-2">
      <h2 className="text-gray-800 text-sm">Activities calendar</h2>
      <div className="border border-gray-300 rounded">
        <table className="table-fixed">
          <thead>
            <tr>
              <th colSpan={5} className="p-2 text-sm font-semibold">
                <div className="flex gap-2 items-center">
                  <button className="transparent-btn w-fit p-1" onClick={() => setDate(new Date(date.setMonth(date.getMonth() - 1)))}>
                    {"<"}
                  </button>
                  <p className="text-xs flex-1">{date.toLocaleDateString("en-GB", { month: "long", year: "numeric" })}</p>
                  <button className="transparent-btn w-fit p-1" onClick={() => setDate(new Date(date.setMonth(date.getMonth() + 1)))}>
                    {">"}
                  </button>
                </div>
              </th>
              <th colSpan={3} className="py-2 text-xs font-semibold text-center">
                Sum
              </th>
              {days.map((e) => {
                const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
                const _date = new Date(e);
                const day = _date.getDay();
                const weekday = days[day];
                const date = _date.getDate();
                return (
                  <th className={`py-2 text-xs text-center ${day == 0 || day == 6 ? "bg-gray-200" : "bg-[white]"}`} key={e} day={day}>
                    <p>{weekday[0]}</p>
                    <p className="font-semibold">{date}</p>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {activities
              .sort((a, b) => b.total - a.total)
              .map((e, i) => {
                return (
                  <tr key={i} className="border-y border-gray-200">
                    <td colSpan={5} className="text-sm text-left p-2">
                      <span className="group-hover:underline">{e.userName}</span>
                    </td>
                    <td colSpan={3} className="text-sm text-center">
                      <p>{(e.total / 8).toFixed(2)} d.</p>
                      <p>{(e.value || 0).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</p>
                    </td>
                    {e.detail.map((f, j) => {
                      return <Field key={`${e.user} ${j}`} value={f.value || 0} />;
                    })}
                  </tr>
                );
              })}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={5} className="text-sm text-left font-semibold p-2">
                Total
              </td>
              <td colSpan={3} className="text-sm text-center font-semibold">
                <p>{getTotalDays()} d.</p>
                <p>{getTotalBudget().toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</p>
              </td>

              {days.map((e, i) => {
                const v = activities.reduce((acc, a) => {
                  if (!a.detail[i]) return acc;
                  return acc + a.detail[i].value;
                }, 0);
                return <td className="text-sm text-center font-semibold bg-gray-100">{v}</td>;
              })}
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};

const Field = ({ value = "-" }) => {
  let bgColor = "white";
  let textColor = "#000";
  if (value >= 7) {
    bgColor = "#216E39";
    textColor = "#fff";
  } else if (value >= 5) {
    bgColor = "#30A14E";
  } else if (value >= 3) {
    bgColor = "#40C463";
  } else if (value > 0) {
    bgColor = "#9BE9A8";
  } else {
    textColor = "#aaa";
  }

  return (
    <td style={{ backgroundColor: bgColor, color: textColor }} className="text-sm text-center">
      {value}
    </td>
  );
};

export default Activities;
