import React, { useEffect, useState, useRef } from "react";
import toast from "react-hot-toast";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import { ImSpinner2 } from "react-icons/im";
import { HiChevronDown, HiChevronUp, HiMagnifyingGlass, HiXMark } from "react-icons/hi2";

import Comments from "./Comments";
import SelectBudget from "../../../../../../components/SelectBudget";
import MultiSelect from "../../../../../../components/MultiSelect";
import SelectUser2 from "../../../../../../components/SelectUser2";
import { classNames } from "../../../../../../utils";
import Gerard from "./gerard";
import api from "../../../../../../services/api";

export default ({ notion, setNotion, project, onUpdate }) => {
  return (
    <div className="flex gap-1">
      <div className="w-1/6 bg-white">
        <div className="p-3">
          <div className="">
            <label className="text-xs font-semibold text-gray-700">Category</label>
            <SelectCategory
              value={notion.category}
              onChange={async (e) => {
                const { data } = await api.put(`/notion/${notion._id}`, { category: e.target.value });
                setNotion(data);
                toast.success("Category updated!");
                onUpdate(data);
              }}
            />
            <label className="text-xs font-semibold text-gray-700">
              Priority
              <a
                href={`https://www.selego.co/ressources/hierarchiser-les-priorites-des-bugs-dans-le-developpement-agile/`}
                target="_blank"
                className="color-indigo-500 ml-1 underline">
                what does that mean?
              </a>
            </label>
            <SelectPriority
              value={notion.priority}
              onChange={async (e) => {
                const { data } = await api.put(`/notion/${notion._id}`, { priority: e.target.value });
                setNotion(data);
                toast.success("Priority updated!");
                onUpdate(data);
              }}
            />
            <label className="text-xs font-semibold text-gray-700">Budget</label>
            <SelectBudget
              value={{ name: notion.budget_name, _id: notion.budget_id }}
              onChange={async (e) => {
                const { data } = await api.put(`/notion/${notion._id}`, { budget_name: e.name, budget_id: e._id });
                setNotion(data);
                toast.success("Budget updated!");
                onUpdate(data);
              }}
              projectId={notion.project_id}
            />

            <label className="text-xs font-semibold text-gray-700">Tags</label>
            <SelectTags
              projectId={notion.project_id}
              value={notion.tags}
              onChange={async (e) => {
                try {
                  await api.put(`/notion/${notion._id}`, { tags: e });
                  toast.success("Tags updated!");
                  onUpdate(data);
                } catch (e) {
                  console.log(e);
                  toast.error("Something went wrong!");
                }
              }}
            />
            <label className="text-xs font-semibold text-gray-700">Estimated hours</label>
            <Hours
              value={notion.estimated_hours}
              onChange={async (estimated_hours) => {
                const estimated_price = ((estimated_hours * 500) / 8).toFixed(2);
                const { data } = await api.put(`/notion/${notion._id}`, { estimated_hours, estimated_price });
                setNotion(data);
                toast.success("Hours updated!");
                onUpdate(data);
              }}
            />
            <label className="text-xs font-semibold text-gray-700" data-tip data-for={`estimated-price`}>
              Estimated price
            </label>
            <ReactTooltip id={`estimated-price`}>
              <span>Based on a 500€ daily rate</span>
            </ReactTooltip>
            <input
              type="number"
              className="w-full rounded-md border-[1px] border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-sm placeholder:text-gray-300 py-2 px-2 disabled:bg-gray-100"
              value={notion.estimated_price}
              disabled
              onChange={async (e) => {
                const { data } = await api.put(`/notion/${notion._id}`, { estimated_price: e });
                setNotion(data);
                toast.success("Price updated!");
                onUpdate(data);
              }}
            />
            <label className="text-xs font-semibold text-gray-700">Status</label>
            <select
              className="input"
              value={notion.status}
              onChange={async (e) => {
                const { data } = await api.put(`/notion/${notion._id}`, { status: e.target.value });
                setNotion(data);
                toast.success("Status updated!");
                onUpdate(data);
              }}>
              <option value="">No status</option>
              <option value="TODO">TODO</option>
              <option value="READY_TO_DEV">READY_TO_DEV</option>
              <option value="IN_PROGRESS">IN_PROGRESS</option>
              <option value="TESTING">TESTING</option>
              <option value="DONE">DONE</option>
              <option value="ARCHIVED">ARCHIVED</option>
            </select>

            <label className="text-xs font-semibold text-gray-700">Assignees</label>
            <SelectUser2
              value={notion.users}
              onChange={async (e) => {
                const { data } = await api.put(`/notion/${notion._id}`, { users: e });
                setNotion(data);
                toast.success("Assignees updated!");
                onUpdate(data);
              }}
            />
            <label className="text-xs font-semibold text-gray-700">Quick assign</label>
            <div className="flex flex-wrap gap-2">
              {project?.team?.map((member) => (
                <div
                  key={member.userId}
                  className="group relative cursor-pointer"
                  onClick={async () => {
                    const newUsers = notion.users || [];
                    if (!newUsers.find((u) => u.user_id === member.userId)) {
                      const updatedUsers = [
                        ...newUsers,
                        {
                          _id: member._id,
                          user_id: member.userId,
                          user_name: member.userName,
                          user_avatar: member.userAvatar,
                        },
                      ];
                      const { data } = await api.put(`/notion/${notion._id}`, { users: updatedUsers });
                      setNotion(data);
                      toast.success("Team member added!");
                      onUpdate(data);
                    }
                  }}>
                  <img
                    className="h-8 w-8 rounded-full hover:ring-2 hover:ring-indigo-500"
                    src={member.userAvatar}
                    alt={member.userName}
                    title={`Quick assign ${member.userName}`}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="w-3/6 bg-white">
        <div className="p-4">
          <Comments value={notion} setValue={setNotion} />
        </div>
        <div className="mb-2">
          <span className="flex items-center text-xs text-gray-600 italic">
            Created by&nbsp;{notion.user_name} on {moment(notion.created_at).format("DD/MM/YYYY")}
          </span>
        </div>
      </div>
      <div className="w-2/6 bg-white p-4">
        <Epic notion={notion} setNotion={setNotion} />

        <div className="mt-4 border-t border-gray-200 pt-4"></div>
        <Gerard notion={notion} setNotion={(e) => setNotion(e)} />
      </div>
    </div>
  );
};

const Epic = ({ notion, setNotion }) => {
  const [epic, setEpic] = useState();
  const [loading, setLoading] = useState(false);

  async function fetchEpic() {
    if (!notion.epic_id) return;
    setLoading(true);
    const { data } = await api.get(`/epic/${notion.epic_id}`);
    setEpic(data);
    setLoading(false);
  }

  useEffect(() => {
    fetchEpic();
  }, [notion.epic_id]);

  if (loading) {
    return (
      <div className="flex justify-center items-center p-4">
        <ImSpinner2 className="animate-spin h-6 w-6 text-blue-500" />
      </div>
    );
  }
  if (epic) {
    return (
      <div className="flex flex-col gap-2">
        <h2 className="text-lg font-semibold">Epic</h2>
        <div className="relative flex items-center justify-between bg-white px-3 py-3 border border-gray-200 rounded-lg shadow-sm">
          <div className="flex flex-col gap-2">
            <div className="flex flex-col  items-center gap-2">
              <span className="text-sm font-medium text-gray-900">{epic.title}</span>
              {epic.budget_name && <span className="px-3 py-1 text-xs font-medium rounded-full bg-purple-100 text-purple-700 w-fit">{epic.budget_name}</span>}
            </div>
          </div>
          {epic.user_name && (
            <div className="flex items-center gap-2">
              {epic.user_avatar && <img src={epic.user_avatar} alt={epic.user_name} className="w-8 h-8 rounded-full border border-gray-200" />}
            </div>
          )}
          <div className="flex items-center gap-2">
            <span className="px-3 py-1 text-xs font-medium rounded-full bg-gray-100 text-gray-700">{epic.status}</span>
          </div>
          <button
            className="absolute -top-2 -right-2 text-gray-400 hover:text-gray-600 text-xl leading-none"
            onClick={async () => {
              const { data } = await api.put(`/notion/${notion._id}`, { epic_id: null, epic_name: null, epic_module: null });
              setNotion(data);
              setEpic(null);
              toast.success("Epic unmatch!");
            }}>
            <HiXMark className="h-6 w-6" />
          </button>
        </div>
      </div>
    );
  }

  return (
    <div>
      <h2 className="text-lg font-semibold mb-2">Epic</h2>
      <SelectEpic
        value={{ name: notion.epic_name, _id: notion.epic_id }}
        onChange={async (e) => {
          const { data } = await api.put(`/notion/${notion._id}`, { epic_name: e.title, epic_id: e._id, epic_module: e.module });
          setNotion(data);
          toast.success("Epic updated!");
        }}
        projectId={notion.project_id}
        budgetId={notion.budget_id}
      />
    </div>
  );
};

const Hours = ({ value, onChange }) => {
  const [hours, setHours] = useState(0);

  useEffect(() => {
    setHours(value);
  }, [value]);

  return (
    <input
      type="number"
      className="w-full rounded-md border-[1px] border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-sm placeholder:text-gray-300 py-2 px-2"
      value={hours}
      onChange={(e) => setHours(e.target.value)}
      onBlur={(e) => onChange(hours)}
    />
  );
};

const SelectPriority = ({ value, onChange }) => {
  return (
    <select
      className="w-full rounded-md border-[1px] border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-sm placeholder:text-gray-300 py-2 px-2"
      value={value}
      onChange={onChange}>
      <option value="">No priority</option>
      <option value="P0">P0 (Critical)</option>
      <option value="P1">P1 (Important)</option>
      <option value="P2">P2 (Medium)</option>
      <option value="P3">P3 (Low)</option>
    </select>
  );
};

const SelectCategory = ({ value, onChange }) => {
  return (
    <select
      className="w-full rounded-md border-[1px] border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-sm placeholder:text-gray-300 py-2 px-2"
      value={value}
      onChange={onChange}>
      <option value="">No category</option>
      <option value="BUG">BUG</option>
      <option value="FEATURE">FEATURE</option>
    </select>
  );
};

const SelectTags = ({ value, onChange, projectId }) => {
  const [tags, setTags] = useState(value);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    get();
  }, [value]);

  async function get() {
    const { data } = await api.post("/notion/aggregate", { project_id: projectId, key: "tags" });
    const arr = data.filter((e) => e._id !== null).map((e) => ({ label: e._id, value: e._id, count: e.count }));
    setOptions(arr);
  }

  return (
    <div className="flex flex-col gap-2">
      <MultiSelect
        values={tags.map((tag) => ({ value: tag, label: tag }))}
        id="select-tags"
        options={options}
        onSelectedChange={(e) => {
          setTags(e.map((e) => e.value));
          onChange(e.map((e) => e.value));
        }}
        placeholder="Tags"
        onCreateNew={(e) => {
          setTags([...tags, e.value]);
          get();
        }}
      />

      <div className="flex flex-wrap gap-1">
        {tags.map((tag) => (
          <span
            key={tag}
            className="text-xs w-fit  text-gray-600 bg-gray-100 px-2 py-1 rounded border border-gray-200 hover:bg-gray-200 cursor-pointer"
            onClick={() => {
              const newTags = tags.filter((t) => t !== tag);
              setTags(newTags);
              onChange(newTags);
            }}>
            {tag}
          </span>
        ))}
      </div>
    </div>
  );
};

const SelectEpic = ({ id, value, projectId, budgetId, onChange }) => {
  const [selectedOption, setSelectedOption] = useState(value);
  const [options, setOptions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);
  const refInput = useRef(null);
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (isOpen) refInput.current.focus();
    if (!isOpen) setSearch("");
    if (projectId) fetch();
  }, [, projectId, isOpen, budgetId]);

  useEffect(() => {
    setSelectedOption(value);
  }, [value]);

  async function fetch() {
    if (!isOpen) return;
    const query = { limit: 20, status: "active" };
    if (projectId) query.project_id = projectId;
    if (budgetId) query.budget_id = budgetId;
    const res = await api.post("/epic/search", query);
    setOptions(res.data);
  }

  function handleOptionClick(option) {
    setSelectedOption(option);
    onChange(option);
    setIsOpen(false);
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      const idRegex = new RegExp(`epic-text`);
      if (ref.current && !ref.current.contains(event.target) && !idRegex.test(event.target.id)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <div className="relative">
      <button
        id={id}
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        className="min-w-[90px] w-full inline-flex justify-between items-center gap-4 px-2 pr-1 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 z-50">
        <span id={`epic-text`} className="flex-1 text-left">
          {value?.name || "Select an Epic"}
        </span>
        {isOpen ? <HiChevronUp /> : <HiChevronDown />}
      </button>
      {isOpen && (
        <div ref={ref} className="absolute z-20 mt-1 max-h-96 w-[400px] bg-white shadow-lg rounded-md overflow-y-scroll">
          <ul className="border border-gray-200 divide-y divide-gray-200 list-none w-full">
            <li className={classNames(`flex items-center gap-1 text-sm cursor-pointer w-full`)}>
              <div className="relative w-full py-2">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <HiMagnifyingGlass className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </div>
                <input
                  ref={refInput}
                  className="block w-full rounded-md border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 md:text-sm"
                  placeholder={`Search`}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            </li>
            {options
              .filter((o) => {
                if (!search) return true;
                return o.title.toLowerCase().includes(search.toLowerCase());
              })
              .map((option) => {
                const isSelected = selectedOption?._id === option._id;
                return (
                  <li
                    key={option._id}
                    onClick={() => handleOptionClick(option)}
                    className={classNames(
                      `flex items-center gap-2 px-3 py-2 cursor-pointer w-full`,
                      isSelected ? "bg-indigo-50 text-indigo-700" : "hover:bg-gray-50 text-gray-700",
                    )}>
                    <div className="flex flex-col">
                      <div className="flex items-center gap-2">
                        <span className="text-xs text-gray-500">#{option.index}</span>
                        <span className={classNames("text-sm", isSelected ? "font-semibold" : "font-normal")}>{option.title}</span>
                      </div>
                    </div>
                  </li>
                );
              })}
          </ul>
        </div>
      )}
    </div>
  );
};
