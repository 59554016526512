import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import moment from "moment";
import { HiTrash } from "react-icons/hi";

import api from "../../../services/api";
import Modal from "../../../components/modal";
import Pagination from "../../../components/Paginator";
import DebounceInput from "../../../components/DebounceInput";

const ListAnswers = () => {
  const [total, setTotal] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [filter, setFilter] = useState({ search: "", page: 1, pageSize: 100 });
  const [openModal, setOpenModal] = useState(false);
  const [newAnswer, setNewAnswer] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    loadAnswers();
  }, [filter]);

  async function loadAnswers() {
    try {
      const { ok, data, total } = await api.post("/mission_ao_answer/search", filter);
      if (!ok) return console.error("Error fetching answers");
      setAnswers(data);
      setTotal(total);
    } catch (error) {
      console.error(error);
    }
  }

  async function addAnswer() {
    try {
      const { ok, data } = await api.post("/mission_ao_answer", { title: newAnswer });
      if (!ok) throw new Error("Failed to add answer");
      toast.success("Answer added successfully!");
      setNewAnswer("");
      setOpenModal(false);
      navigate(`/missions_ao/answers/${data._id}`); // Redirect to the new answer
    } catch (error) {
      console.error(error);
      toast.error("Error adding answer");
    }
  }

  async function deleteAnswer(id) {
    try {
      const { ok } = await api.remove(`/mission_ao_answer/${id}`);
      if (!ok) throw new Error("Failed to delete answer");
      toast.success("Answer deleted successfully!");
      loadAnswers();
    } catch (error) {
      console.error(error);
      toast.error("Error deleting answer");
    }
  }

  if (!answers) return <div className="p-3 text-sm">Loading...</div>;

  return (
    <div className="p-3">
      <div className="flex flex-1 items-center gap-4 justify-between mb-3">
        <DebounceInput debounce={300} value={filter.search} onChange={(e) => setFilter({ ...filter, search: e.target.value })} className="input" placeholder="Search answers" />
        <button className="btn btn-primary" onClick={() => setOpenModal(true)}>
          Add Answer
        </button>
      </div>

      <div className="overflow-x-auto">
        <table className="w-full bg-white rounded-lg shadow-md overflow-hidden">
          <thead className="bg-gray-100 text-left text-sm font-semibold text-gray-600">
            <tr>
              <th className="px-6 py-3">Title</th>
              <th className="px-6 py-3">Created At</th>
              <th className="px-6 py-3">Montant</th>
              <th className="px-6 py-3 text-center">Status</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {(answers || []).map((answer) => (
              <tr key={answer._id} className="hover:bg-gray-50 cursor-pointer" onClick={() => navigate(`/missions_ao/answers/${answer._id}`)}>
                <td className="px-6 py-4 text-sm text-gray-800">{answer.title || "Untitled"}</td>
                <td className="px-6 py-4 text-sm text-gray-800">{moment(answer.created_at).format("DD/MM/YYYY")}</td>
                <td className="px-6 py-4 text-sm text-gray-800">{answer.montant}</td>
                <td className="px-6 py-4 text-center">
                  <span
                    className={`px-2 py-1 rounded-full text-xs font-semibold ${
                      answer.status === "approved" ? "bg-green-100 text-green-800" : answer.status === "pending" ? "bg-yellow-100 text-yellow-800" : "bg-red-100 text-red-800"
                    }`}>
                    {answer.status}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="flex w-full mt-5">
        <Pagination page={filter.page} setPage={(page) => setFilter({ ...filter, page })} last={Math.ceil(total / filter.pageSize)} />
      </div>

      {openModal && (
        <Modal isOpen={openModal} className="max-w-md w-full p-6" onClose={() => setOpenModal(false)}>
          <div className="w-full mb-4">
            <div className="text-sm font-medium mb-2">Nouvelle AO</div>
            <textarea className="input block w-full" value={newAnswer} onChange={(e) => setNewAnswer(e.target.value)} placeholder="Merci de rentrer le nom de l'AO..." />
          </div>
          <button className="btn btn-primary mt-4 justify-center" onClick={addAnswer}>
            Créer
          </button>
        </Modal>
      )}
    </div>
  );
};

export default ListAnswers;
