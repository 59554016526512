import React, { useEffect, useState } from "react";

import api from "../../../services/api";

export default ({ article, setArticle }) => {
  const [question, setQuestion] = useState("can you recommand me SEO actions ? ");
  const [isLoading, setIsLoading] = useState(false);
  const [answer, setAnswer] = useState("");
  const [customUrl, setCustomUrl] = useState("");
  const [includeContent, setIncludeContent] = useState(false);

  useEffect(() => {
    if (!article.url) return;
    if (article.status !== "live") return;
    setCustomUrl(article.url);
  }, [article]);

  async function handleAsk() {
    try {
      setIsLoading(true);
      setAnswer("");
      const obj = {};
      obj.question = question;
      if (customUrl) obj.page = customUrl;
      if (includeContent) obj.article_id = article._id;
      const { ok, data, code } = await api.post("/article/ask", obj);
      if (!ok) return toast.error(`Error - \n${code}`);
      setAnswer(data);
    } catch (error) {
      console.error("Failed to get answer:", error);
    } finally {
      setIsLoading(false);
    }
  }
  return (
    <div className="space-y-4 p-4 border rounded-md mt-4 h-[calc(100vh-160px)] overflow-auto">
      <div className="space-y-2">
        <h1 className="text-xl font-bold mb-4">{article.title}</h1>
        <div className="bg-blue-50 border border-blue-200 p-4 rounded-lg mb-4">
          <p className="text-sm text-blue-800">
            Claude has been trained with our SEO data, target audience profiles (ICP), and content strategy from our{" "}
            <a href="https://www.selego.co/sitemap.xml" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">
              Sitemap
            </a>
            ,{" "}
            <a href="https://bank.cellar-c2.services.clever-cloud.com/seo/pages.json" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">
              Website Architecture
            </a>
            ,{" "}
            <a href="https://bank.cellar-c2.services.clever-cloud.com/seo/performance.json" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">
              Google console search queries
            </a>
            , and{" "}
            <a
              href="https://bank.cellar-c2.services.clever-cloud.com/file/project/3936c922358ca53fb7bb0dfe958ba934/Brief%20SEO.pdf"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 hover:underline">
              SEO Brief
            </a>
            . You can ask questions about SEO optimization, content recommendations, or how to better reach our target audience. Claude will provide personalized suggestions based
            on our specific context and goals.
          </p>
        </div>

        <div className="flex flex-col gap-2">
          <label className="font-semibold">Ask Claude a question about this article...</label>
          <textarea className="input flex-1" placeholder="Ask Claude a question about this article..." value={question} onChange={(e) => setQuestion(e.target.value)} rows={10} />
          <div className="flex items-center gap-2">
            <input type="checkbox" id="includeContent" checked={includeContent} onChange={(e) => setIncludeContent(e.target.checked)} className="h-4 w-4 text-blue-600" />
            <label htmlFor="includeContent" className="text-sm text-gray-700">
              Include article content in analysis
            </label>
          </div>
          <div className="flex flex-col gap-2">
            <label className="font-semibold">Custom URL to analyse with your question</label>
            <input type="text" className="input w-full" placeholder="" value={customUrl} onChange={(e) => setCustomUrl(e.target.value)} />
          </div>
          <button className="blue-btn px-4 py-2 flex items-center gap-2" onClick={handleAsk} disabled={isLoading || !question.trim()}>
            {isLoading ? "Asking..." : "Ask Claude"}
          </button>
        </div>
      </div>
      {isLoading && (
        <div className="flex justify-center">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
        </div>
      )}

      {answer && (
        <div className="bg-gray-50 p-4 rounded-lg">
          <p className="whitespace-pre-wrap">{answer}</p>
        </div>
      )}
    </div>
  );
};



// const searchQueries = [
//   "no code vs traditional development",
//   "bubble.io vs coding",
//   "when to use no code",
//   "no code for startups",
//   "limitations of no code platforms",
//   "bubble.io pros and cons",
//   "should startups use no code"
// ];

// async function analyzeContentOpportunity(queries) {
//   const baseUrl = 'https://www.googleapis.com/customsearch/v1';
//   const insights = {};
  
//   for (const query of queries) {
//       const params = new URLSearchParams({
//           key: 'YOUR_API_KEY',
//           cx: 'YOUR_SEARCH_ENGINE_ID',
//           q: query,
//           num: 10
//       });

//       try {
//           const response = await fetch(`${baseUrl}?${params}`);
//           const data = await response.json();
          
//           insights[query] = {
//               topSites: data.items.map(item => item.link),
//               headlines: data.items.map(item => item.title),
//               snippets: data.items.map(item => item.snippet),
//               relatedQueries: data.queries?.related || []
//           };
//       } catch (error) {
//           console.error(`Error analyzing query "${query}":`, error);
//       }
//   }
  
//   return insights;
// }
