import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/loader";
import api from "../../services/api";
import { MdDeleteForever } from "react-icons/md";
import { IoInformationCircleSharp } from "react-icons/io5";
import DebounceInput from "react-debounce-input";

import SelectMonth from "../../components/selectMonth";
import SelectUser from "../../components/selectUser";
// import SelectInvoice from "../../components/SelectInvoice";
import Modal from "../../components/modal";

// Where to put my time if I am not working directly on a project ?
// - If I am working on strategy, coaching someone from the team or if I am learning something/doing a training, then hours should be add on Le Stud
// - If I am prospecting : if it's for startups, it should go to Prospection_Startup, and if it's for Le Collectif, Prospection Established Company
// - Last, If I am spending time on admin tasks, it should go to Selego or Le Collectif, depending on the company

import { getDaysInMonth } from "../../utils";

const Activity = () => {
  const u = useSelector((state) => state.Auth.user);

  const [date, setDate] = useState(null);
  const [user, setUser] = useState(u);

  if (user === null) return <Loader />;

  // Container

  return (
    <div className="bg-white border-[1px] border-gray-200 rounded-md overflow-hidden w-screen md:w-full">
      <div className="flex flex-wrap gap-5 p-2 md:!px-8">
        <div className="flex-1">
          <SelectUser disabled={u.role !== "admin"} value={user} onChange={setUser} />
        </div>
        <SelectMonth start={-3} indexDefaultValue={3} value={date} onChange={(e) => setDate(e.target.value)} showArrows />
      </div>
      {date && user && <Activities date={new Date(date)} user={user} />}
      <div className="border-l-4 border-yellow-400 bg-yellow-50 p-4 pb-20">
        <div className="flex">
          <div className="flex-shrink-0">
            <IoInformationCircleSharp className="h-5 w-5 text-yellow-400" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <p className="text-sm text-yellow-700">
              We need to give visibility to clients about what we do and what value we create. We dont need to be exact. It&apos;s not a time tracking tool but more an activity and
              reporting tool.
              <br />1 day = 8 hours
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const AddBudget = ({ onAdd, user }) => {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [budgets, setBudgets] = useState([]);
  const [lastMonthBudgets, setLastMonthBudgets] = useState([]);

  useEffect(() => {
    onSearch(search);
  }, [search]);

  useEffect(() => {
    getLastMonthBudgets();
    return () => setLastMonthBudgets([]);
  }, [user]);

  async function getLastMonthBudgets() {
    const lastMonth = new Date();
    lastMonth.setUTCMonth(lastMonth.getUTCMonth() - 1);
    lastMonth.setUTCDate(1);
    lastMonth.setUTCHours(0, 0, 0, 0);

    // First get activities from last month
    const { data: activities, ok } = await api.post(`/activity/search`, {
      date: lastMonth.getTime(),
      userId: user._id,
    });
    if (!ok) return;

    // Get unique budget IDs
    const budgetIds = [...new Set(activities.map((item) => item.budgetId))];

    // Fetch the actual budgets using these IDs
    const { data: budgets } = await api.post(`/budget/search`, {
      ids: budgetIds,
      status: "active",
    });

    setLastMonthBudgets(budgets);
  }

  async function onSearch(str) {
    const { data } = await api.post(`/budget/search`, { search: str, limit: 10, status: "active" });
    setBudgets(data);
  }

  return (
    <div>
      <div>
        <button className="gray-btn" onClick={() => setOpen(true)}>
          Add a new entry
        </button>
      </div>
      <Modal isOpen={open} onClose={() => setOpen(false)} className="w-10/12 mx-auto">
        <div className="border-b pb-4 p-6">
          <h2 className="text-xl font-bold">Add a new activity entry</h2>
          <p className="text-gray-600 text-sm">Select from common budgets or search for specific ones</p>
        </div>

        <div className="flex flex-col md:flex-row gap-6 p-6">
          <div className="md:w-1/2">
            <h3 className="text-lg font-semibold mb-3">Search Budgets</h3>
            <div className="relative mb-4">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <svg className="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                </svg>
              </div>
              <DebounceInput
                debounceTimeout={300}
                id="search"
                className="form-input block w-full pl-10 p-3 border border-gray-300 rounded-lg focus:border-blue-500 focus:ring-blue-500 transition-all"
                placeholder="Search budgets..."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
            <div className="sticky top-0 max-h-[70vh] overflow-y-auto">
              <div className="space-y-3  ">
                {budgets.map((e) => (
                  <div
                    key={e._id}
                    className="group bg-white rounded-lg border hover:border-blue-500 shadow-sm hover:shadow-md p-4 cursor-pointer transition-all duration-200"
                    onClick={() => {
                      setOpen(false);
                      onAdd(e);
                    }}>
                    <div className="flex items-center gap-3">
                      <img src={e.projectLogo} className="w-12 h-12 rounded-lg object-cover" alt={e.projectName} />
                      <div className="flex-1 min-w-0">
                        <div className="font-bold group-hover:text-blue-600">
                          {e.projectName} {e.year}
                        </div>
                        <div className="font-medium text-gray-700">{e.name}</div>
                        <div className="text-sm text-gray-500 line-clamp-2 hover:line-clamp-none">{e.description}</div>
                      </div>
                    </div>
                  </div>
                ))}
                {search && budgets.length === 0 && (
                  <div className="text-center py-8 bg-gray-50 rounded-lg">
                    <div className="text-gray-400 mb-2">No results found</div>
                    <p className="text-sm text-gray-500">Try adjusting your search terms</p>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="md:w-1/2">
            <h3 className="text-lg font-semibold mb-3">Last Month's Budgets</h3>
            <div className="grid grid-cols-2 gap-4  max-h-[75vh] overflow-y-auto">
              {lastMonthBudgets.map((e) => (
                <div
                  key={e._id}
                  className="group bg-white rounded-lg border hover:border-blue-500 shadow-sm hover:shadow-md p-4 cursor-pointer transition-all duration-200"
                  onClick={() => {
                    setOpen(false);
                    onAdd(e);
                  }}>
                  <div className="flex items-center gap-3">
                    <img src={e.projectLogo} className="w-12 h-12 rounded-lg object-contain" alt={e.projectName} />
                    <div className="flex-1 min-w-0">
                      <div className="font-bold group-hover:text-blue-600">
                        {e.projectName} {e.year}
                      </div>
                      <div className="font-medium text-gray-700">{e.name}</div>
                      <div className="text-sm text-gray-500 line-clamp-2 hover:line-clamp-none">{e.description}</div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

const Activities = ({ date, user }) => {
  const [activities, setActivities] = useState([]);

  useEffect(() => {
    get();
  }, [date, user]);

  const navigate = useNavigate();

  async function get() {
    try {
      const { data } = await api.post(`/activity/search`, { date: date.getTime(), userId: user._id });
      setActivities(data);
    } catch (e) {
      console.error(e);
    }
  }

  const days = getDaysInMonth(date.getMonth(), date.getFullYear());

  const onAddBudget = async (budget) => {
    const found = activities.find((a) => budget._id === a.budgetId);
    if (found) return toast.error(`Budget ${budget.name} already added !`);
    const detail = days.map((e) => ({ date: e.date, value: 0 }));
    const { data, code } = await api.post(`/activity`, { budgetId: budget._id, userId: user._id, date: date.getTime(), detail });
    if (code) return toast.error(code);
    toast.success(`Budget ${budget.name} added`);
    setActivities([...activities, data]);
  };

  async function onSave() {
    for (let i = 0; i < activities.length; i++) {
      console.log(`activities[i]`, activities[i]);
      const { code } = await api.post(`/activity`, activities[i]);
      if (code) {
        toast.error(`${activities[i].projectName} not saved : ${code}`);
      } else {
        toast.success(`Saved ${activities[i].projectName}`);
      }
    }
  }

  async function onDelete(i) {
    if (!window.confirm("Are you sure ?")) return;
    await api.remove(`/activity/${activities[i]._id}`);
    const newActivities = [...activities];
    newActivities.splice(i, 1);
    setActivities(newActivities);
    toast.success(`Deleted `);
  }

  function onUpdateValue(i, j, value) {
    if (!user.tjm) return toast.error("Please contact an admin to set your TJM");
    if (!user.tjms) return toast.error("Please contact an admin to set your TJMS");

    const n = [...activities];

    n[i].detail[j].value = value;
    n[i].total = n[i].detail.reduce((acc, b) => acc + b.value, 0);

    /// 0 if this project
    if (n[i].project === "Unavailable") {
      n[i].cost = 0;
      n[i].value = 0;
    } else {
      n[i].cost = (n[i].total / 8) * user.tjm;
      n[i].value = (n[i].total / 8) * (n[i].userTjms || 0);
    }

    setActivities(n);
  }

  const getWorkingDays = () => {
    return days.reduce((acc, a) => {
      const day = a.getDay();
      if (day === 0 || day == 6) return acc;
      return acc + 1;
    }, 0);
  };

  const getTotal = () => (activities.reduce((acc, a) => acc + a.total, 0) / 8).toFixed(2);

  return (
    <div className="flex flex-wrap py-3 gap-4 text-black">
      <div className="w-screen md:w-full p-2 md:!px-8">
        {true && (
          <div className="mt-2 rounded-xl bg-[#fff] overflow-auto">
            <div className="overflow-x-auto">
              <table className="w-full">
                <thead>
                  <tr>
                    <th className="py-[10px] text-[14px] font-bold text-[#212325] text-left pl-[10px]">Invoices</th>
                    {days.map((e) => {
                      const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
                      const _date = new Date(e);
                      const day = _date.getDay();
                      const weekday = days[day];
                      const date = _date.getDate();
                      return (
                        <th
                          className={`w-[20px] border border-[#E5EAEF] text-[12px] font-semibold text-center ${day == 0 || day == 6 ? "bg-[#FFD5F1]" : "bg-[white]"}`}
                          key={e}
                          day={day}>
                          <div>{weekday}</div>
                          <div>{date}</div>
                        </th>
                      );
                    })}
                    <th className={`w-[20px] border border-[#E5EAEF] text-[12px] font-semibold text-center bg-[white]`} />
                  </tr>
                </thead>
                <tbody>
                  <tr className="border-t border-b border-r border-[#E5EAEF]">
                    <th className="px-2">
                      <div className="flex justify-end w-full text-[12px] font-bold text-[#212325] italic">
                        <div>{`${getTotal()} / ${getWorkingDays()} days`}</div>
                      </div>
                    </th>
                    {days.map((e, i) => {
                      const v = activities.reduce((acc, a) => {
                        if (!a.detail[i]) return acc;
                        return acc + a.detail[i].value;
                      }, 0);
                      return <Field key={`day-${i}`} value={v} disabled />;
                    })}
                  </tr>
                  {activities.map((e, i) => {
                    return (
                      <React.Fragment key={`1-${e._id}`}>
                        <tr className="border-t border-b border-r border-[#E5EAEF]" key={`1-${e._id}`}>
                          <th className="w-[100px] border-t border-b border-r text-[12px] font-bold text-[#212325] text-left">
                            <div className="flex flex-1 items-center justify-between gap-1 px-2">
                              <div className="flex flex-col flex-1 items-center justify-start" onClick={() => navigate(`/project/${e.projectId}`)}>
                                <div> {e.projectName}</div>
                                <div className="text-xs italic font-normal">{e.budgetName}</div>
                              </div>
                              <div className="flex flex-col items-end">
                                <div className="text-xs italic font-normal">{(e.total / 8).toFixed(2)} days</div>
                                <div className="text-[10px] italic font-normal">{(((e.total / 8).toFixed(2) / getTotal()) * 100).toFixed(2)}%</div>
                              </div>
                            </div>
                          </th>
                          {e.detail.map((f, j) => {
                            return (
                              <Field
                                key={`${e.projectName} ${j}`}
                                freezed={e.freezed}
                                value={f.value || 0}
                                onChange={(a) => onUpdateValue(i, j, parseFloat(a.target.value || 0))}
                              />
                            );
                          })}
                          <th className={`border border-[#E5EAEF] py-[6px]`}>
                            <div className={`flex justify-center cursor-pointer text-xl hover:text-red-500`}>
                              <MdDeleteForever onClick={() => onDelete(i)} />
                            </div>
                          </th>
                        </tr>
                      </React.Fragment>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 p-4 flex justify-between items-center gap-4 z-50 md:left-64">
              <AddBudget
                onAdd={(e) => {
                  onAddBudget(e);
                }}
                user={user}
              />
              <button className="btn-primary" onClick={onSave}>
                Save
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const Field = ({ value = 0, onChange, freezed, ...rest }) => {
  let bgColor = freezed === "yes" ? "bg-[#F0F0F0]" : "bg-[white]";
  let textColor = "text-[#000]";
  if (value >= 7) {
    bgColor = "bg-[#216E39]";
    textColor = "text-[#fff]";
  } else if (value >= 5) {
    bgColor = "bg-[#30A14E]";
  } else if (value >= 3) {
    bgColor = "bg-[#40C463]";
  } else if (value > 0) {
    bgColor = "bg-[#9BE9A8]";
  } else {
    textColor = "text-[#aaa]";
  }

  return (
    <th className={`border border-[#E5EAEF] py-[6px] px-0 ${bgColor} m-0`}>
      <input
        className={`border-none min-w-[30px] w-full text-center ${bgColor} ${textColor} p-0`}
        disabled={freezed === "yes"}
        value={parseFloat(value)}
        min={0}
        {...rest}
        type="number"
        step="0.1"
        onChange={onChange}
        onFocus={(e) => {
          if (Number(e.target.value) === 0) {
            e.target.value = "";
          }
        }}
        onBlur={(e) => {
          if (e.target.value === "") {
            e.target.value = 0;
          }
        }}
      />
    </th>
  );
};

export default Activity;
