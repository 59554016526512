import api from "../services/api";

export async function tryExtractOcrText(applicant) {
  try {
    console.log(`Traitement du CV pour l'URL : ${applicant.resume}`);
    const response = await api.post("/user/applicant/ocr", {
      pdfUrl: applicant.resume,
      applicantId: applicant._id,
    });

    const { ok, text } = response;
    if (!ok || !text) {
      console.log(`Aucun texte extrait pour le candidat ${applicant._id}. Scoring annulé.`);
      return { ok: false, extractedText: null };
    }
    console.log(`Texte extrait pour ${applicant._id} :`, text);
    return { ok: true, extractedText: text };
  } catch (error) {
    console.error(`Erreur lors du traitement pour le candidat ${applicant._id} :`, error);
    return { ok: false, extractedText: null };
  }
}

export async function tryScoreApplicant(applicant, text, job) {
  try {
    const { ok } = await api.post(`/job/${job._id}/michelle`, {
      applicant_id: applicant._id,
      text,
    });

    if (!ok) {
      throw new Error(`Erreur lors du scoring du candidat ${applicant._id}`);
    }

    console.log(`Scoring réussi pour ${applicant._id}`);
  } catch (error) {
    console.error(`Erreur lors du traitement pour le candidat ${applicant._id} :`, error);
  }
}
