import React, { useEffect, useState, useCallback } from "react";
import { FaUser, FaLinkedin, FaGlobe, FaRegBuilding, FaEnvelope, FaCheck, FaTimes } from "react-icons/fa";
import toast from "react-hot-toast";

import { RiMoneyEuroCircleFill } from "react-icons/ri";
import { useSelector } from "react-redux";
import SelectUser from "../../../../components/selectUser";
import Campaigns from "./campaigns";
import SelectSource from "../SelectSource";
import api from "../../../../services/api";
import Status from "./status";

const TABS = {
  view: "view",
  edit: "edit",
  raw: "raw",
};

export default ({ company, setCompany, onClick }) => {
  const [tab, setTab] = useState(TABS.view);
  const [selected, setSelected] = useState(false);
  const [values, setValues] = useState();

  useEffect(() => {
    if (!company) return;
    setValues(company);
  }, [company]);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (!selected) return;
      if (e.target.closest(".company-container") !== null) return;
      setSelected(false);
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [selected]);

  if (!company) return <div />;

  if (!selected) {
    return (
      <div>
        <Preview company={company} onClick={() => setSelected(true)} />
        <Comments company={company} setCompany={setCompany} />
      </div>
    );
  }

  return (
    <div className="bg-white shadow-md cursor-pointer company-container" onClick={onClick}>
      <div className="flex space-x-2 text-sm border-b">
        <button className={`px-3 py-1 ${tab === TABS.view ? "border-b-2 border-blue-500 font-medium" : "text-gray-500 hover:text-gray-700"}`} onClick={() => setTab(TABS.view)}>
          View
        </button>
        <button className={`px-3 py-1 ${tab === TABS.edit ? "border-b-2 border-blue-500 font-medium" : "text-gray-500 hover:text-gray-700"}`} onClick={() => setTab(TABS.edit)}>
          Edit
        </button>
        <button className={`px-3 py-1 ${tab === TABS.raw ? "border-b-2 border-blue-500 font-medium" : "text-gray-500 hover:text-gray-700"}`} onClick={() => setTab(TABS.raw)}>
          Raw
        </button>
      </div>
      <div className="p-4">
        {tab === TABS.view && <ViewTab values={values} company={company} setValues={setValues} setTab={setTab} />}
        {tab === TABS.edit && <EditTab values={values} setValues={setValues} company={company} setCompany={setCompany} setTab={setTab} />}
        {tab === TABS.raw && <RawTab values={values} />}
      </div>
    </div>
  );
};

const Preview = ({ company, onClick }) => {
  return (
    <div className="space-y-2 cursor-pointer  p-4" onClick={onClick}>
      <div className="flex items-center">
        <FaUser className="mr-3" />
        <span>{company.name}</span>
      </div>
      <div className="flex items-center">
        {company.linkedin ? (
          <a href={company.linkedin} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline flex items-center">
            <FaLinkedin className="mr-3" /> {company.linkedin_pseudo}
          </a>
        ) : (
          <span className="text-gray-500">No LinkedIn profile</span>
        )}
      </div>
      <div className="flex items-center">
        <FaGlobe className="mr-3" />
        {company.website ? (
          <a
            href={company.website.startsWith("http") ? company.website : `http://${company.website}`}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 hover:underline">
            {company.website}
          </a>
        ) : (
          <span className="text-gray-500">No website</span>
        )}
      </div>
      {company.personnae && (
        <div className="flex items-center">
          <FaRegBuilding className="mr-3" />
          <span>{company.personnae}</span>
        </div>
      )}
      {company.stage && (
        <div className="flex items-center">
          <FaRegBuilding className="mr-3" />
          <span>{company.stage}</span>
        </div>
      )}
      {company.source_name && (
        <div className="flex items-center">
          <FaRegBuilding className="mr-3" />
          <span>{company.source_name}</span>
        </div>
      )}
      {company.deal_amount && (
        <div className="flex items-center">
          <RiMoneyEuroCircleFill className="mr-3" />
          <div>{company.deal_amount} €</div>
        </div>
      )}
      {company?.linkedin_raw?.employees && (
        <div className="flex items-center">
          <FaRegBuilding className="mr-3" />
          <div>{company.linkedin_raw.employees} employees</div>
        </div>
      )}
      <div>
        <p className="text-xs text-gray-600">{company.description || "No description"}</p>
      </div>
    </div>
  );
};

const ViewTab = ({ values }) => {
  console.log("values", values);
  return (
    <div className="space-y-2">
      <div className="flex items-center">
        <FaUser className="mr-3" />
        <span>{values.name}</span>
      </div>
      <div className="flex items-center">
        {values.linkedin ? (
          <a href={values.linkedin} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline flex items-center">
            <FaLinkedin className="mr-3" /> {values.linkedin_pseudo}
          </a>
        ) : (
          <span className="text-gray-500">No LinkedIn profile</span>
        )}
      </div>
      <div className="flex items-center">
        <FaGlobe className="mr-3" />
        {values.website ? (
          <a
            href={values.website.startsWith("http") ? values.website : `http://${values.website}`}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 hover:underline">
            {values.website}
          </a>
        ) : (
          <span className="text-gray-500">No website</span>
        )}
      </div>
      {values.personnae && (
        <div className="flex items-center">
          <FaRegBuilding className="mr-3" />
          <span>{values.personnae}</span>
        </div>
      )}
      {values.stage && (
        <div className="flex items-center">
          <FaRegBuilding className="mr-3" />
          <span>{values.stage}</span>
        </div>
      )}
      {values.source_name && (
        <div className="flex items-center">
          <FaRegBuilding className="mr-3" />
          <span>{values.source_name}</span>
        </div>
      )}
      {values?.linkedin_raw?.employees && (
        <div className="flex items-center">
          <FaRegBuilding className="mr-3" />
          <div>{values.linkedin_raw.employees} employees</div>
        </div>
      )}
      <div>
        <p className="text-xs text-gray-600">{values.description || "No description"}</p>
      </div>
    </div>
  );
};

const EditTab = ({ values, setValues, company, setCompany, setTab }) => {
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await api.put(`/s_company/${company._id}`, values);
      setCompany(data);
      toast.success("Updated!");
      setTab(TABS.view);
    } catch (error) {
      console.error(error);
      toast.error("Some Error!");
    }
  };

  return (
    <form onSubmit={handleSubmit} className="col-span-2">
      <div className="flex items-center">
        <FaUser className="mr-3" />
        <input className="input w-full" type="text" name="name" value={values.name} placeholder="Enter name" onChange={(e) => setValues({ ...values, name: e.target.value })} />
      </div>
      <div className="flex items-center">
        {!values.linkedin ? (
          <FaLinkedin className="mr-3" />
        ) : (
          <a href={`${values.linkedin}`} target="_blank" className="mr-3">
            <FaLinkedin />
          </a>
        )}
        <input
          className="input w-full"
          type="text"
          name="linkedin"
          value={values.linkedin}
          placeholder="LinkedIn profile URL"
          onChange={(e) => setValues({ ...values, linkedin: e.target.value })}
        />
      </div>
      <div className="flex flex-col gap-2">
        <div className="flex items-center">
          {!values.website ? (
            <FaGlobe className="mr-3" />
          ) : (
            <a href={values.website.startsWith("http") ? values.website : `http://${values.website}`} target="_blank" className="mr-3">
              <FaGlobe />
            </a>
          )}
          <input
            className="input w-full"
            type="text"
            name="website"
            value={values.website}
            placeholder="Website URL"
            onChange={(e) => setValues({ ...values, website: e.target.value })}
          />
        </div>
        <div className="flex items-center gap-2">
          <FaRegBuilding className="mr-3" />
          <input type="checkbox" id="pipeline" checked={values.pipeline} onChange={(e) => setValues({ ...values, pipeline: e.target.checked })} />
          <label htmlFor="pipeline">Add to pipeline</label>
        </div>
      </div>
      <div className="flex items-center gap-2">
        <input
          type="checkbox"
          id="incubator"
          checked={values.incubator}
          onChange={(e) => setValues({ ...values, incubator: e.target.checked })}
          className="rounded border-gray-300"
        />
        <label htmlFor="incubator" className="text-sm text-gray-700">
          Incubator
        </label>
      </div>

      <div className="flex items-center gap-2">
        <input
          type="checkbox"
          id="corporate"
          checked={values.corporate}
          onChange={(e) => setValues({ ...values, corporate: e.target.checked })}
          className="rounded border-gray-300"
        />
        <label htmlFor="corporate" className="text-sm text-gray-700">
          Corporate
        </label>
      </div>

      <div className="flex items-center gap-2">
        <input
          type="checkbox"
          id="startups_advanced"
          checked={values.startups_advanced}
          onChange={(e) => setValues({ ...values, startups_advanced: e.target.checked })}
          className="rounded border-gray-300"
        />
        <label htmlFor="startups_advanced" className="text-sm text-gray-700">
          Startups Advanced
        </label>
      </div>

      <div className="flex items-center gap-2">
        <input
          type="checkbox"
          id="startups_early"
          checked={values.startups_early}
          onChange={(e) => setValues({ ...values, startups_early: e.target.checked })}
          className="rounded border-gray-300"
        />
        <label htmlFor="startups_early" className="text-sm text-gray-700">
          Startups early
        </label>
      </div>

      <div className="flex items-center gap-2">
        <input
          type="checkbox"
          id="intrapreneurship"
          checked={values.intrapreneurship}
          onChange={(e) => setValues({ ...values, intrapreneurship: e.target.checked })}
          className="rounded border-gray-300"
        />
        <label htmlFor="intrapreneurship" className="text-sm text-gray-700">
          Intrapreneurship
        </label>
      </div>
      <div className="flex items-center">
        <FaRegBuilding className="mr-3" />
        <select className="input w-full" value={values.personnae} onChange={(e) => setValues({ ...values, personnae: e.target.value })}>
          <option value="">Select personae</option>
          <option value="STARTUPS_EARLYSTAGE">STARTUPS_EARLYSTAGE</option>
          <option value="STARTUPS_ADVANCED">STARTUPS_ADVANCED</option>
          <option value="BUSINESS_REFERRERS">BUSINESS_REFERRERS</option>
          <option value="INCUBATORS">INCUBATORS</option>
          <option value="CORPORATES">CORPORATES</option>
          <option value="GOVERNMENT">GOVERNMENT</option>
        </select>
      </div>
      <hr className="my-4 border-gray-200" />
      <div className="flex items-start">
        <FaRegBuilding className="mr-3 mt-2" />
        <textarea className="input w-full h-24 resize-none" placeholder="Enter need" value={values.need || ""} onChange={(e) => setValues({ ...values, need: e.target.value })} />
      </div>
      <div className="flex items-center">
        <FaRegBuilding className="mr-3" />
        <select className="input w-full" value={values.duration || ""} onChange={(e) => setValues({ ...values, duration: e.target.value ? Number(e.target.value) : null })}>
          <option value="">Select duration (months)</option>
          <option value="1">1 month</option>
          <option value="2">2 months</option>
          <option value="3">3 months</option>
          <option value="6">6 months</option>
          <option value="12">12 months</option>
        </select>
      </div>
      <div className="flex items-center">
        <FaRegBuilding className="mr-3" />
        <select className="input w-full" value={values.priority || ""} onChange={(e) => setValues({ ...values, priority: e.target.value })}>
          <option value="">Select priority</option>
          <option value="low">Low</option>
          <option value="medium">Medium</option>
          <option value="urgent">Urgent</option>
        </select>
      </div>
      <Status values={values} setValues={setValues} />
      <hr className="my-4 border-gray-200" />
      <div className="flex items-center">
        <FaRegBuilding className="mr-3" />
        <input
          type="number"
          className="input w-full"
          placeholder="Confidence (0-100)"
          min="0"
          max="100"
          value={values.confidence || ""}
          onChange={(e) => setValues({ ...values, confidence: e.target.value ? Number(e.target.value) : null })}
        />
      </div>
      <div className="flex items-center">
        <FaRegBuilding className="mr-3" />
        <select className="input w-full" value={values.status || ""} onChange={(e) => setValues({ ...values, status: e.target.value })}>
          <option value="">Select status</option>
          <option value="PROSPECTION">PROSPECTION</option>
          <option value="QUALIFIED">QUALIFIED</option>
          <option value="DISCOVERY">DISCOVERY</option>
          <option value="PROPOSAL">PROPOSAL</option>
          <option value="NEGOTIATION">NEGOTIATION</option>
          <option value="CLOSING">CLOSING</option>
          <option value="WON">WON</option>
        </select>
      </div>
      <hr className="my-4 border-gray-200" />
      <Stage values={values} setValues={setValues} />
      <div className="flex flex-col gap-2">
        {/* <div className="flex items-center">
          <FaRegBuilding className="mr-3" />
          <select className="input w-full" value={values.situation || ""} onChange={(e) => setValues({ ...values, situation: e.target.value })}>
            <option value="">Select situation</option>
            <option value="todo">Todo</option>
          </select>
        </div> */}
        <div className="flex items-center">
          <RiMoneyEuroCircleFill className="mr-3" />
          <input
            type="number"
            className="input w-full"
            placeholder="Deal amount"
            value={values.deal_amount || ""}
            onChange={(e) => setValues({ ...values, deal_amount: e.target.value ? Number(e.target.value) : null })}
          />
        </div>
      </div>
      <div className="flex items-center">
        <FaRegBuilding className="mr-3" />
        <SelectSource
          value={{ _id: values.source_id, name: values.source_name }}
          onChange={(e) => {
            console.log(e);
            setValues({ ...values, source_id: e._id, source_name: e.name });
          }}
        />
      </div>
      {values?.linkedin_raw?.employees && (
        <div className="flex items-center">
          <FaRegBuilding className="mr-3" />
          <div>{values?.linkedin_raw?.employees} employees</div>
        </div>
      )}
      <div className="w-full md:w-[260px] mt-2">
        <div className="text-sm text-[#212325] font-medium">Lead dev</div>
        <SelectUser
          value={{ _id: values.lead_user_id, name: values.lead_user_name, avatar: values.lead_user_avatar }}
          userName={values.lead_user_name}
          name="lead"
          onChange={(e) => {
            if (!e) return setValues({ ...values, lead_user_id: "", lead_user_name: "", lead_user_avatar: "", lead_user_email: "" });
            setValues({ ...values, lead_user_id: e._id, lead_user_name: e.name, lead_user_avatar: e.avatar, lead_user_email: e.email });
          }}
        />
      </div>
      <div className="col-span-2">
        <label className="block text-xs font-medium text-gray-700" htmlFor="description">
          Description
        </label>
        <textarea rows={4} className="input w-full" name="description" value={values.description} onChange={(e) => setValues({ ...values, description: e.target.value })} />
      </div>

      <Campaigns company={company} setCompany={setCompany} />

      <div className="flex gap-2">
        <button className="btn blue-btn" type="submit">
          Save
        </button>

        <button
          className="btn red-btn"
          type="button"
          onClick={async () => {
            if (!window.confirm("Are you sure you want to delete this company?")) return;
            const { ok } = await api.remove(`/s_company/${company._id}`);
            if (!ok) return toast.error("Error deleting company");
            setCompany(null);
          }}>
          Delete
        </button>
      </div>
    </form>
  );
};

const Stage = ({ values, setValues }) => {
  if (values.personnae === "STARTUPS_EARLYSTAGE") {
    return (
      <div className="flex items-center">
        <FaRegBuilding className="mr-3" />
        <select className="input w-full" value={values.stage} onChange={(e) => setValues({ ...values, stage: e.target.value })}>
          <option value="">Select stage</option>
          <option value="idea">Idea</option>
          <option value="businessPlan">Business Plan</option>
          <option value="activeUsers">Active Users</option>
        </select>
      </div>
    );
  }

  if (values.personnae === "GOVERNMENT") {
    return (
      <div className="flex items-center">
        <FaRegBuilding className="mr-3" />
        <select className="input w-full" value={values.stage} onChange={(e) => setValues({ ...values, stage: e.target.value })}>
          <option value="">Select stage</option>
          <option value="investigation">Investigation</option>
          <option value="construction">Construction</option>
          <option value="perenisation">Perenisation</option>
          <option value="acceleration">Acceleration</option>
        </select>
      </div>
    );
  }

  if (values.personnae === "STARTUPS_EARLYSTAGE") {
    return (
      <div className="flex items-center">
        <FaRegBuilding className="mr-3" />
        <select className="input w-full" value={values.stage} onChange={(e) => setValues({ ...values, stage: e.target.value })}>
          <option value="">Select stage</option>
          <option value="idea">Idea</option>
          <option value="businessPlan">Business Plan</option>
          <option value="activeUsers">Active Users</option>
        </select>
      </div>
    );
  }

  if (values.personnae === "INCUBATORS") {
    return (
      <div className="flex items-center">
        <FaRegBuilding className="mr-3" />
        <select className="input w-full" value={values.stage} onChange={(e) => setValues({ ...values, stage: e.target.value })}>
          <option value="">Select stage</option>
          <option value="prospective">Prospective</option>
          <option value="active">Active</option>
          <option value="followUpNeeded">Follow-Up Needed</option>
          <option value="lost">Lost</option>
        </select>
      </div>
    );
  }
  return <div></div>;
};

const RawTab = ({ values }) => (
  <div className="space-y-4">
    <pre className="whitespace-pre-wrap text-xs font-mono bg-gray-50 p-4 rounded">{JSON.stringify(values, null, 2)}</pre>
  </div>
);

const Comments = ({ company, setCompany }) => {
  const [comment, setComment] = useState("");

  const comments = company.comments || [];

  const user = useSelector((state) => state.Auth.user);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!comment) return;

    const { data } = await api.put(`/s_company/${company._id}`, { comments: [...comments, { text: comment, user_name: user.name, user_id: user._id, user_avatar: user.avatar }] });
    setComment("");
    setCompany(data);
    toast.success("Comment added!");
  };

  return (
    <div className="md:flex hidden flex-col self-start w-full" style={{ maxWidth: "300px" }}>
      <div className="flex flex-col">
        {(comments || []).map((comment, index) => (
          <div className="border-b-2 pb-2 mb-2">
            <div className="flex flex-row">
              <img src={comment.user_avatar} className="rounded-full w-10 h-10 mr-4" />
              <div key={index} className="flex items-center mb-2">
                <div>
                  <div className="text-sm">{comment.user_name}</div>
                  <div className="text-sm text-gray-500">{new Date(comment.created_at).toLocaleDateString()}</div>
                </div>
              </div>
            </div>
            <div>{comment.text}</div>
          </div>
        ))}
      </div>

      <div>
        <div className="flex items-center">
          <img src={user.avatar} alt="userlogo" className="rounded-full w-9 h-9 mr-2" />
          <textarea
            className="rounded-[5px]  border-[transparent] shadow-sm block w-full"
            placeholder="Add a comment"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                handleSubmit(e);
              }
            }}
          />
        </div>
        <div className="flex justify-end mt-2">
          <button className="btn btn-primary text-xs font-medium" onClick={handleSubmit}>
            Add
          </button>
        </div>
      </div>
    </div>
  );
};
