import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { HiMiniRocketLaunch, HiMinusCircle } from "react-icons/hi2";
import { FaStar } from "react-icons/fa";

import SelectUser from "../../../components/selectUser";
import FileInput from "../../../components/FileInput";
import api from "../../../services/api";
import LoadingButton from "../../../components/loadingButton";

export default ({ article, setArticle }) => {
  const navigate = useNavigate();
  const [isPublishingAction, setIsPublishingAction] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  async function onDelete() {
    const confirm = window.confirm("Are you sure ?");
    if (!confirm) return;
    await api.remove(`/article/${article._id}`);
    toast.success("successfully removed!");
    navigate("/articles");
  }

  async function handleSubmit() {
    try {
      setIsUpdating(true);
      const { ok, data, code } = await api.put(`/article/${article._id}`, article);
      if (!ok) return toast.error(`Error - \n${code}`);
      setArticle(data);
      toast.success("Updated !");
    } catch (e) {
      console.log(e);
      toast.error("Some Error!", e.code);
    } finally {
      setIsUpdating(false);
    }
  }

  async function handlePublish() {
    try {
      setIsPublishingAction(true);
      const { ok, data, code, message } = await api.post(`/article/${article._id}/publish`);
      if (!ok) return toast.error(`Error - ${message}`);
      setArticle(data);
      toast.success("Published to Webflow!");
    } catch (e) {
      console.log(e);
      toast.error(`${e?.error}`);
    } finally {
      setIsPublishingAction(false);
    }
  }

  async function handleUnpublish() {
    try {
      setIsPublishingAction(true);
      const { ok, data, code } = await api.post(`/article/${article._id}/unpublish`);
      if (!ok) return toast.error(`Error !\n${code}`, { icon: "❌" });
      setArticle(data);
      toast.success("Unpublished from Webflow!");
    } catch (e) {
      console.log(e);
      toast.error(`${e?.error}`);
    } finally {
      setIsPublishingAction(false);
    }
  }

  if (!article) return <div />;
  return (
    <div className="space-y-4">
      <div className="grid grid-cols-2 gap-2 p-2">
        {article.collection_name && (
          <div className="w-full space-y-2">
            <label className="font-semibold">Titre</label>
            <input type="text" className="input" value={article.title} onChange={(e) => setArticle({ ...article, title: e.target.value })} />
          </div>
        )}
        {article.collection_name && (
          <div className="w-full space-y-2">
            <label className="font-semibold flex items-center gap-2">Slug</label>
            <input
              type="text"
              disabled={article.status === "live"}
              className="input"
              value={article.slug}
              onChange={(e) => {
                const slug = e.target.value.toLowerCase().replace(/ /g, "-");
                setArticle({ ...article, slug });
              }}
            />
          </div>
        )}
        {article.collection_name && (
          <div className="col-span-2 w-full space-y-2">
            <label className="font-semibold">Meta Description</label>
            <textarea type="text" className="input" value={article.meta_description} rows={4} onChange={(e) => setArticle({ ...article, meta_description: e.target.value })} />
          </div>
        )}
        <CollectionSelect article={article} setArticle={setArticle} />
        <div className="space-y-2">
          <label className="font-semibold">Category</label>
          <select className="input" value={article.category || ""} onChange={(e) => setArticle({ ...article, category: e.target.value })}>
            <option value="">Select a category</option>
            <option value="general">General</option>
            <option value="tech">Tech</option>
            <option value="produit">Produit</option>
            <option value="distribution">Distribution</option>
            <option value="financement">Financement</option>
          </select>
        </div>

        <div className="col-span-2 w-full space-y-2">
          <label className="font-semibold">Instruction</label>
          <textarea type="text" className="input" value={article.instruction} rows={4} onChange={(e) => setArticle({ ...article, instruction: e.target.value })} />
        </div>
        <div className=" w-full space-y-2">
          <label className="font-semibold">Author</label>
          <SelectUser
            value={{ _id: article.user_id, name: article.user_name, avatar: article.user_avatar }}
            onChange={(user) => setArticle({ ...article, user_id: user._id, user_name: user.name, user_avatar: user.avatar })}
          />
        </div>
        <div className="grid grid-cols-2 gap-2">
          <div className="space-y-2">
            <label className="font-semibold">Image</label>
            <FileInput
              value={article.image}
              onChange={async (e) => {
                const { data } = await api.put(`/article/${article._id}`, { image: e.target.value[0] });
                setArticle(data);
                toast.success("Updated!");
              }}
              folder={`/article`}
            />
          </div>
          <div className="w-full space-y-2">
            <label className="font-semibold">Logo</label>
            <FileInput
              value={article.logo}
              onChange={async (e) => {
                const { data } = await api.put(`/article/${article._id}`, { logo: e.target.value[0] });
                setArticle(data);
                toast.success("Updated!");
              }}
              folder={`/article`}
            />
          </div>
        </div>
        <StarRating article={article} setArticle={setArticle} />
        <div className="space-y-2">
          <label className="font-semibold">Status</label>
          <select className="input" value={article.status || ""} onChange={(e) => setArticle({ ...article, status: e.target.value })}>
            <option value="">Select a status</option>
            <option value="live">Live</option>
            <option value="draft">Draft</option>
            <option value="archived">Archived</option>
          </select>
        </div>
      </div>
      <div className="flex gap-4 justify-between">
        <div className="flex gap-2 px-2">
          <button className="red-btn" onClick={onDelete}>
            Delete
          </button>
          <LoadingButton loading={isUpdating} className="blue-btn" onClick={handleSubmit}>
            Update
          </LoadingButton>
        </div>
      </div>
    </div>
  );
};
const CollectionSelect = ({ article, setArticle }) => {
  const options = [];
  options.push("perks");
  options.push("use-cases");
  options.push("glossaire");
  options.push("blog");
  options.push("companies");

  return (
    <div className="w-full">
      <div className="space-y-3">
        <label className="block text-sm font-semibold text-gray-700">Collection</label>
        <select
          className="form-select w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          value={article.collection_name || ""}
          onChange={(e) => setArticle({ ...article, collection_name: e.target.value })}>
          <option value="">Select a collection</option>
          {options.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};
const StarRating = ({ article, setArticle }) => {
  const [hover, setHover] = useState(null);
  return (
    <div className=" py-2 mb-2">
      <div className="space-y-2 flex items-center justify-center">
        <label
          className="group text-lg font-semibold cursor-pointer flex text-center"
          htmlFor="score"
          onClick={() => setArticle({ ...article, score: 0 })}
          onMouseEnter={(e) => (e.target.textContent = "Reset")}
          onMouseLeave={(e) => (e.target.textContent = "Score")}>
          Score
        </label>
      </div>
      <div id="score" className="flex space-x-1 justify-center py-1">
        {[1, 2, 3, 4, 5].map((star) => (
          <button
            key={star}
            type="button"
            className="focus:outline-none"
            onClick={() => setArticle({ ...article, score: star })}
            onMouseEnter={() => setHover(star)}
            onMouseLeave={() => setHover(null)}>
            <FaStar size={24} color={star <= (hover || article.score) ? "#ffc107" : "#e4e5e9"} />
          </button>
        ))}
      </div>
    </div>
  );
};
