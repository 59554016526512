import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { HiMagnifyingGlass, HiOutlineGlobeAlt } from "react-icons/hi2";

import Loader from "../../components/loader";
import Pagination from "../../components/pagination";
import api from "../../services/api";
import SelectProject from "../../components/selectProject";

export default () => {
  const [sentries, setSentries] = useState();
  const [filter, setFilter] = useState({
    search: "",
    per_page: 50,
    page: 1,
    project_id: "",
    project_number: "",
  });
  const [total, setTotal] = useState();

  const handleNextPage = () => setFilter((prev) => ({ ...prev, page: prev.page + 1 }));
  const handlePreviousPage = () => setFilter((prev) => ({ ...prev, page: prev.page - 1 }));

  useEffect(() => {
    get();
  }, [filter]);

  const get = async () => {
    try {
      const { data, ok, total } = await api.post("/sentry/search", filter);
      if (!ok) return toast.error("Error!");
      setSentries(data);
      setTotal(total);
    } catch (error) {
      console.log(error);
      return toast.error("Error fetching sentry events!");
    }
  };

  if (!sentries) return <Loader />;

  return (
    <div className="p-4 space-y-4">
      <div className="flex items-center justify-between mb-2">
        <h2 className="text-xl font-bold text-gray-900">Sentry Events</h2>
        <div className="flex items-center gap-4">
          <SelectProject
            indexDefaultValue={0}
            value={filter.project_id || ""}
            onChange={(e) => {
              setFilter({ ...filter, project_id: e._id, project_number: e.sentry_project_id});
            }}
          />
          <div className="relative w-80">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <HiMagnifyingGlass className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </div>
            <input
              type="text"
              value={filter.search}
              onChange={(e) => setFilter({ ...filter, search: e.target.value })}
              className="block w-full rounded-lg border border-gray-300 bg-white py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
              placeholder="Search events..."
            />
          </div>
        </div>
      </div>

      <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-lg">
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-gray-50">
            <tr className="text-left text-xs font-semibold text-gray-900">
              <th className="py-3 px-2">Event</th>
              <th className="py-3 px-2 w-24">Level</th>
              <th className="py-3 px-2 w-32">Project Sentry Id</th>
              <th className="py-3 px-2 w-32">Project Name</th>
              <th className="py-3 px-2 w-32">Platform</th>
              <th className="py-3 px-2 w-32">Environment</th>
              <th className="py-3 px-2">Message</th>
              <th className="py-3 px-2 w-32">Date</th>
              <th className="py-3 px-2 w-32">Release</th>
              <th className="py-3 px-2">Culprit</th>
              <th className="py-3 px-2 w-32">Actor</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {sentries.map((sentry) => (
              <tr key={sentry.event_id} className="hover:bg-gray-100">
                <td className="py-0.5 px-2 text-xs text-gray-500">
                  <div className="flex items-center gap-1">
                    <span className="font-mono">{sentry.event_id.substring(0, 8)}</span>
                    {sentry.web_url && (
                      <a
                        href={sentry.web_url}
                        title="View in Sentry"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-flex items-center text-xs text-blue-600 hover:text-blue-800">
                        <HiOutlineGlobeAlt className="h-3 w-3" />
                      </a>
                    )}
                  </div>
                </td>
                <td className="py-0.5 px-2">
                  <span
                    className={`inline-flex rounded-full px-2 text-xs font-semibold ${
                      sentry.level === "error" ? "bg-red-100 text-red-800" : sentry.level === "warning" ? "bg-yellow-100 text-yellow-800" : "bg-gray-100 text-gray-800"
                    }`}>
                    {sentry.level}
                  </span>
                </td>
                <td className="py-0.5 px-2 text-xs text-gray-500">{sentry.project}</td>
                <td className="py-0.5 px-2 text-xs text-gray-500">{sentry.project_name}</td>
                <td className="py-0.5 px-2 text-xs text-gray-500">{sentry.platform}</td>
                <td className="py-0.5 px-2 text-xs text-gray-500">{sentry.environment}</td>
                <td className="py-0.5 px-2 text-xs text-gray-500 truncate max-w-md">{sentry.message || sentry.title}</td>
                <td className="py-0.5 px-2 text-xs text-gray-500 whitespace-nowrap">
                  {new Date(sentry.datetime).toLocaleString("fr", {
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit"
                  })}
                </td>
                <td className="py-0.5 px-2 text-xs text-gray-500">{sentry.release}</td>
                <td className="py-0.5 px-2 text-xs text-gray-500 truncate max-w-md">{sentry.culprit}</td>
                <td className="py-0.5 px-2 text-xs text-gray-500">
                  {sentry.actor_name && (
                    <span title={`${sentry.actor_type || 'User'} - ${sentry.actor_id}`}>
                      {sentry.actor_name}
                    </span>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination per_page={filter.per_page} total={total} currentPage={filter.page} onNext={handleNextPage} onPrevious={handlePreviousPage} />
    </div>
  );
};
