import React from "react";
import { Routes, Route, useNavigate, Navigate, useLocation } from "react-router-dom";

import SearchList from "./Search";
import SearchView from "./Search/view";

import ListAnswers from "./Answer";
import ViewAnswer from "./Answer/view";

export default function Index() {
  const navigate = useNavigate();
  const location = useLocation();

  const is_active = (path) => location.pathname === path;

  return (
    <div>
      <div className="m-2">
        <nav className="bg-white rounded-md flex item-center gap-1 w-full border border-neutral-300 p-1">
          <TabItem onClick={() => navigate(`/missions_ao`)} active={is_active("/missions_ao")} title="Recherche" />
          <TabItem onClick={() => navigate(`/missions_ao/answers`)} active={is_active("/missions_ao/answers")} title="Nos AO" />
        </nav>

        <Routes>
          <Route path="/" element={<Navigate to="/missions_ao/search" replace />} />
          <Route path="/search" element={<SearchList />} />
          <Route path="/search/:id" element={<SearchView />} />
          <Route path="/answers" element={<ListAnswers />} />
          <Route path="/answers/:id" element={<ViewAnswer />} />
        </Routes>
      </div>
    </div>
  );
}

const TabItem = ({ onClick, active, title }) => {
  return (
    <div onClick={onClick} className={`${active ? "blue-btn" : "transparent-btn"} relative rounded px-4 flex items-center gap-2 cursor-pointer`}>
      <div className="flex items-center gap-2 truncate text-xs">{title}</div>
    </div>
  );
};
