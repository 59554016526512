import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { format, isAfter, subMonths } from "date-fns";
import API from "../../../../services/api";
import { BiSolidTrash } from "react-icons/bi";
import toast from "react-hot-toast";

const Cashout = ({ cashout, setCashout }) => {
  if (!cashout) return null;
  return (
    <div className="bg-white rounded-md border border-neutral-300 overflow-hidden">
      <div className="p-4 border-b border-gray-200">
        <div className="flex justify-between items-center">
          <h2 className="text-xl font-semibold">Payment Status</h2>
          <StatusBadge cashout={cashout} setCashout={setCashout} />
        </div>
      </div>

      <div className="p-4 space-y-4">
        {cashout.date && (
          <div className="flex justify-between items-center text-sm">
            <span className="text-gray-600">Next Action Date:</span>
            <input
              type="date"
              value={format(new Date(cashout.date), "yyyy-MM-dd")}
              onChange={async (e) => {
                const newDate = e.target.value;
                try {
                  const { data } = await API.put(`/cashout/${cashout._id}`, { date: newDate });
                  setCashout(data);
                  toast.success("Date updated successfully!");
                } catch (error) {
                  console.error("Error updating date:", error);
                  toast.error("An error occurred while updating the date");
                }
              }}
              className="font-medium border border-gray-300 rounded-md px-2 py-1"
            />
          </div>
        )}

        <CommentsSection cashout={cashout} setCashout={setCashout} />
      </div>
    </div>
  );
};

const CommentsSection = ({ cashout, setCashout }) => {
  const [comments, setComments] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [recentComments, setRecentComments] = useState([]);
  const user = useSelector((state) => state.Auth.user);
  const [newComment, setNewComment] = useState("");

  const displayComments = () => {
    if (!cashout?.comments) return;

    const lastMonth = subMonths(new Date(), 1);
    const recent = cashout.comments.filter((comment) => isAfter(new Date(comment.created_at), lastMonth));
    setRecentComments(recent);
    setComments(showAll ? cashout.comments : recent);
  };

  useEffect(() => {
    displayComments();
  }, [cashout, showAll]);

  const handleAddComment = async () => {
    try {
      const comment = {
        text: newComment,
        user_id: user._id,
        user_name: user.name,
        user_avatar: user.avatar,
        created_at: new Date().toISOString(),
      };

      const updatedComments = [...(cashout.comments || []), comment];
      const { data, ok } = await API.put(`/cashout/${cashout._id}`, { comments: updatedComments });

      if (!ok) return toast.error("Failed to add comment");

      setCashout(data);
      setNewComment("");
      toast.success("Comment added successfully!");
    } catch (error) {
      console.error("Error adding comment:", error);
      toast.error("An error occurred while adding the comment");
    }
  };

  const handleDeleteComment = async (comment_id) => {
    try {
      if (!window.confirm("Are you sure you want to delete this comment?")) return;
      const updatedComments = cashout.comments.filter((c) => c._id !== comment_id);
      const { data, ok } = await API.put(`/cashout/${cashout._id}`, { comments: updatedComments });
      if (!ok) return toast.error("Failed to delete comment");
      setCashout(data);
      toast.success("Comment deleted successfully!");
    } catch (error) {
      console.error("Error deleting comment:", error);
      toast.error("An error occurred while deleting the comment");
    }
  };

  return (
    <div className="space-y-3">
      <h3 className="font-medium text-gray-700">Comments History</h3>

      <div className="flex gap-2">
        <input
          type="text"
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
          placeholder="Add a comment..."
          className="flex-1 rounded-md border border-gray-300 px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <button
          onClick={handleAddComment}
          className="rounded-md bg-blue-600 px-4 py-2 text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500">
          Add
        </button>
      </div>

      <div className="space-y-4">
        {comments.length === 0 ? (
          <div className="text-center text-gray-500">No comments in the last month</div>
        ) : (
          comments.map((comment) => (
            <div key={comment._id} className="flex items-start space-x-3 p-3 bg-gray-50 rounded-lg">
              <img src={comment.user_avatar} alt={comment.user_name} className="w-8 h-8 rounded-full" />
              <div className="flex-1">
                <div className="flex items-center justify-between">
                  <span className="font-medium text-sm">{comment.user_name}</span>
                  <div className="flex items-center gap-2">
                    <span className="text-xs text-gray-500">{format(new Date(comment.created_at), "dd MMM yyyy")}</span>
                    <button onClick={() => handleDeleteComment(comment._id)} className="text-gray-400 hover:text-red-500 transition-colors">
                      <BiSolidTrash className="h-4 w-4" />
                    </button>
                  </div>
                </div>
                <p className="text-sm text-gray-600 mt-1">{comment.text}</p>
              </div>
            </div>
          ))
        )}
        {cashout?.comments?.length > recentComments.length && (
          <button onClick={() => setShowAll(!showAll)} className="w-full mt-2 text-sm text-blue-600 hover:text-blue-800 font-medium">
            {showAll ? "Show less" : `Show all comments (${cashout.comments.length - recentComments.length} more)`}
          </button>
        )}
      </div>
    </div>
  );
};

const StatusBadge = ({ cashout, setCashout }) => {
  const getColorClasses = (status) => {
    if (status === "Timetable sent") return "bg-green-100 text-green-800";
    if (status === "Signed timetable") return "bg-yellow-100 text-yellow-800";
    if (status === "Payment in progress") return "bg-blue-100 text-blue-800";
    if (status === "Late payment") return "bg-red-100 text-red-800";
    if (status === "Late payment in progress") return "bg-orange-100 text-orange-800";
    if (status === "In recovery") return "bg-purple-100 text-purple-800";
    return "bg-gray-100 text-gray-800";
  };

  const handleStatusChange = async (e) => {
    try {
      const { data, ok } = await API.put(`/cashout/${cashout._id}`, { status: e.target.value });
      if (!ok) return toast.error("Failed to update collection status");
      setCashout(data);
      toast.success("Collection status updated!");
    } catch (error) {
      console.error(error);
      toast.error("Error updating collection status");
    }
  };

  return (
    <select
      value={cashout.status || "Late payment"}
      onChange={handleStatusChange}
      className={`px-3 py-1 rounded-full w-1/5 text-sm font-medium ${getColorClasses(cashout.status)} border-none focus:ring-0 cursor-pointer`}>
      <option value="Timetable sent">Timetable sent</option>
      <option value="Signed timetable">Signed timetable</option>
      <option value="Payment in progress">Payment in progress</option>
      <option value="Late payment">Late payment</option>
      <option value="Late payment in progress">Late payment in progress</option>
      <option value="In recovery">In recovery</option>
    </select>
  );
};

export default Cashout;
