import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import DebounceInput from "react-debounce-input";

import api from "../services/api";

export default function UserSelector({ value, onChange, disabled = false, placeholder = "", userRoles = ["admin", "user"] }) {
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState("");
  const [showSuggestions, setShowSuggestions] = useState(false);
  const wrapperRef = useRef(null);

  const currentUser = useSelector((state) => state.Auth.user);

  // Fetch users from the API
  const searchUser = async (str) => {
    try {
      if (!str) {
        setUsers([currentUser]);
        return;
      }
      const res = await api.post("/user/search", { search: str, role: userRoles, per_page: 30, sort: "name" });
      setUsers(res.data.users);
      setShowSuggestions(true);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    searchUser(search);
  }, [search]);

  // Handle clicks outside the component to close suggestions
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowSuggestions(false);
        // handleClearUser();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div ref={wrapperRef} className="relative w-full">
      <div className="flex items-center">
        {value && value._id && (
          <div key={value?._id} className="group relative flex items-center mr-2">
            <span
              onClick={() => onChange(null)}
              className="cursor-pointer h-6 w-6 p-2 rounded-full bg-black text-white flex items-center justify-center opacity-0 text-xs group-hover:opacity-100 absolute -top-1 -right-1">
              ×
            </span>
            <div style={{ minHeight: "2.5rem", minWidth: "2.5rem" }}>
              <img className="h-10 w-10 rounded-full flex-shrink-0 object-cover" src={value.avatar} alt={value.name} />
            </div>
          </div>
        )}
        <DebounceInput
          value={search}
          disabled={disabled}
          debounceTimeout={300}
          className="w-full border-gray-300 rounded text-sm text-gray-700 "
          placeholder={placeholder}
          onChange={(e) => {
            e.persist();
            setSearch(e.target.value);
          }}
          onFocus={() => {
            if (!search) {
              // setUsers([currentUser]);
              setShowSuggestions(true);
            } else if (users.length > 0) {
              setShowSuggestions(true);
            }
          }}
        />
      </div>
      {showSuggestions && users.length > 0 && (
        <ul className="absolute z-10 bg-white border border-gray-300 w-full max-h-60 overflow-y-auto shadow-lg rounded-md">
          {users.map((user) => (
            <li
              key={user._id}
              className={`flex items-center p-2 hover:bg-gray-100 cursor-pointer ${value && value._id === user._id ? "bg-blue-500 text-white" : ""}`}
              onClick={() => {
                setSearch("");
                onChange(user);
                setShowSuggestions(false);
              }}>
              <img src={user.avatar || "/default-avatar.png"} alt="Avatar" className="w-8 h-8 rounded-full mr-3 object-cover" />
              <div className="flex flex-col">
                <span className="font-semibold">{user.name}</span>
                <span className="text-gray-500 text-sm">{user.contract}</span>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
