import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import moment from "moment";
import { RiMoneyEuroCircleFill } from "react-icons/ri";
import { useSelector } from "react-redux";
import Board from "react-trello";

import CompanyModal from "../component/companyModal";
import CreateCompany from "../component/createCompany";

import api from "../../../services/api";

export default () => {
  const user = useSelector((state) => state.Auth.user);

  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [filters, setFilters] = useState({ showAll: false, user, personnae: "ALL" });

  useEffect(() => {
    fetch();
  }, [filters]);

  async function fetch() {
    setLoading(true);
    const newFilters = {};
    newFilters.pipeline = true;
    if (filters.personnae !== "ALL") {
      newFilters.personnae = filters.personnae;
    }
    const { data } = await api.post("/s_company/search", newFilters);
    console.log("data", data);
    setCompanies(data);
    setLoading(false);
  }

  if (loading) return <div>Loading...</div>;

  return (
    <div className="bg-white h-full">
      <div className="flex justify-between items-center">
        <div className="">
          <select className="border rounded p-2" value={filters.personnae} onChange={(e) => setFilters({ ...filters, personnae: e.target.value })}>
            <option value="ALL">All</option>
            <option value="BUSINESS_REFERRERS">Business Referrers</option>
            <option value="INCUBATORS">Incubators</option>
            <option value="STARTUPS_ADVANCED">Advanced Startups</option>
            <option value="STARTUPS_EARLYSTAGE">Early Stage Startups</option>
            <option value="CORPORATES">Corporates</option>
            <option value="GOVERNMENT">Government</option>
          </select>
        </div>
        <CreateCompany onDone={fetch} defaultValues={{ pipeline: true }} />
      </div>

      <CompanyModal
        company={selectedCompany}
        setCompany={(e) => {
          setSelectedCompany(e);
          if (!e) return;
          const index = companies.findIndex((c) => c._id === e._id);
          if (index !== -1) companies[index] = e;
          setCompanies(companies);
          toast.success("Company updated");
        }}
        onClose={() => setSelectedCompany(null)}
      />
      <div>
        <Board
          components={{ Card }}
          style={{ backgroundColor: "#F5F6FC", overflow: "scroll", height: "100%" }}
          laneStyle={{ backgroundColor: "#e5e6eC", overflow: "scroll" }}
          hideCardDeleteIcon
          data={{
            lanes: ["DISCOVERY", "PROPOSAL", "NEGOTIATION", "CLOSING", "WON", "LOST"].map((category) => ({
              id: category,
              title: (
                <div>
                  <h2 className="text-base">
                    {category}&nbsp;{percentageSebHappy[category]}
                  </h2>
                  <div className="text-gray-600 text-sm font-normal flex gap-1 items-center">
                    <RiMoneyEuroCircleFill />
                    {companies
                      .filter((s_company) => s_company.status === category)
                      .reduce((prev, curr) => prev + (curr.deal_amount || 0), 0)
                      .toLocaleString()}
                    &nbsp;-&nbsp;
                    {companies.filter((s_company) => s_company.status === category).length}&nbsp;companies
                  </div>
                  <div className="max-w-[250px] overflow-hidden overflow-wrap text-sm font-normal text-gray-600 whitespace-pre-line">{categoryExplanations[category]}</div>
                </div>
              ),
              cards: companies
                .filter((s_company) => {
                  if (!s_company.status && category === "DISCOVERY") return true;
                  return category === s_company.status;
                })
                .sort((a, b) => {
                  const dateA = new Date(a.last_updated_at);
                  const dateB = new Date(b.last_updated_at);
                  return dateB - dateA;
                })
                .map((s_company) => ({ s_company, id: s_company._id })),
            })),
          }}
          onCardClick={(id) => {
            const s_company = companies.find((f) => f._id === id);
            setSelectedCompany(s_company);
          }}
          onCardMoveAcrossLanes={async (fromLaneId, toLaneId, cardId) => {
            if (fromLaneId === toLaneId) return;
            const s_company = companies.find((f) => f._id === cardId);
            s_company.status = toLaneId;
            await api.put(`/s_company/${cardId}`, s_company);
            toast.success("s_company moved successfully");
          }}
        />
      </div>
    </div>
  );
};

const Card = ({ s_company, onClick }) => {
  return (
    <div className="w-[250px]">
      <div className="p-2 my-2 flex flex-col bg-gray-50 rounded shadow-md cursor-pointer" onClick={onClick}>
        <div className="flex justify-between space-x-2"></div>
        <div className="font-medium whitespace-normal">{s_company.name}</div>
        <div className="text-xs text-gray-500 line-clamp-3 overflow-hidden whitespace-normal">
          {s_company.personnae && <span className="font-semibold">{s_company.personnae}</span>}
          {s_company.source_name && (
            <span className="font-semibold">
              {s_company.personnae ? " - " : ""}
              {s_company.source_name}
            </span>
          )}
          {(s_company.personnae || s_company.source_name) && " - "}
          {s_company.description}
        </div>
        <div className="text-sm font-medium text-gray-700 mt-1">{s_company.deal_amount ? `${s_company.deal_amount.toLocaleString()}€` : ""}</div>
        <div className="flex flex-wrap gap-1 mt-1">
          {s_company.need && <div className="text-xs text-gray-600 bg-gray-100 px-2 py-1 rounded whitespace-pre-wrap">{s_company.need}</div>}
          {s_company.priority && (
            <div
              className={`text-xs px-2 py-1 rounded ${
                s_company.priority === "urgent" ? "bg-red-100 text-red-700" : s_company.priority === "medium" ? "bg-yellow-100 text-yellow-700" : "bg-green-100 text-green-700"
              }`}>
              {s_company.priority}
            </div>
          )}
          {s_company.confidence && (
            <div
              className={`text-xs px-2 py-1 rounded ${
                s_company.confidence >= 75 ? "bg-green-100 text-green-700" : s_company.confidence >= 50 ? "bg-yellow-100 text-yellow-700" : "bg-red-100 text-red-700"
              }`}>
              {s_company.confidence}% confidence
            </div>
          )}
          {s_company.duration && (
            <div className="text-xs text-gray-600 bg-gray-100 px-2 py-1 rounded">
              {s_company.duration} month{s_company.duration > 1 ? "s" : ""}
            </div>
          )}
        </div>
        <hr className="mt-2 mb-2" />
        <div className="flex justify-between">
          <div className="flex items-center">
            <img src={s_company.lead_user_avatar} className="w-8 h-8 rounded-full" alt={s_company.lead_user_name} />
          </div>
          <div>
            <div
              className={`text-xs ${
                moment().diff(moment(s_company.updated_at), "days") > 15
                  ? "bg-red-100 text-red-700"
                  : moment().diff(moment(s_company.updated_at), "days") > 7
                  ? "bg-orange-100 text-orange-700" 
                  : "bg-green-100 text-green-700"
              }`}>
              {`Updated ${moment(s_company.updated_at).fromNow()}`}
            </div>
            {/* <div className="text-xs text-gray-500">{`Created : ${moment(s_company.created_at).format("DD/MM/YYYY")}`}</div> */}
          </div>
        </div>
      </div>  
    </div>
  );
};

const categoryExplanations = {
  PROSPECTION: "Various contact methods (mail, Linkedin, phone/Whatsapp) and establishing a connection.",
  QUALIFIED: "Understanding the prospect's problem, knowing what to sell, and being in contact with the right people.",
  DISCOVERY: "The prospect describes their problem, budget, and timing, and a clear identification of their needs.",
  PROPOSAL: "Sending a proposal.",
  NEGOTIATION: "Modifying price/timing.",
  CLOSING: "Getting the go-ahead but lacking the signature or project not started.",
  WON: "Contract signed, and the project has started.",
  LOST: "Client rejection or no communication for three weeks.",
};

const percentageSebHappy = {
  PROSPECTION: "10,00%",
  QUALIFIED: "20,00%",
  DISCOVERY: "50,00%",
  PROPOSAL: "75,00%",
  NEGOTIATION: "80,00%",
  CLOSING: "90,00%",
  WON: "100,00%",
  LOST: "0,00%",
};
