import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import DebounceInput from "../../components/DebounceInput";
import { useNavigate, useSearchParams } from "react-router-dom";

import UserCard from "./UserCard";
import Loader from "../../components/loader";
import LoadingButton from "../../components/loadingButton";
import api from "../../services/api";
import Pagination from "../../components/pagination";

const NewList = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [filter, setFilter] = useState({
    search: searchParams.get("search") || "",
    per_page: 50,
    applicants: false,
    page: parseInt(searchParams.get("page")) || 1,
    roles: [],
    divisions: [],

    status: "active",
  });

  const [tab, setTab] = useState("all");
  const [users, setUsers] = useState(null);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (filter.applicants) setUsers(null);
    const newSearchParams = new URLSearchParams();
    if (filter.search) newSearchParams.set("search", filter.search);
    if (filter.page) newSearchParams.set("page", filter.page);
    if (filter.roles) newSearchParams.set("roles", filter.roles);
    if (filter.availability) newSearchParams.set("availability", filter.availability);
    if (filter.divisions) newSearchParams.set("divisions", filter.divisions);
    if (filter.contract) newSearchParams.set("contract", filter.contract);
    setSearchParams(newSearchParams);
    getUsers();
  }, [filter]);

  async function getUsers() {
    const { data } = await api.post(`/user/search`, filter);
    setUsers(data.users);
    setTotal(data.total);
  }

  return (
    <div className="p-4">
      <nav className="bg-white rounded-md flex item-center gap-2 w-full border border-neutral-300 overflow-hidden p-1">
        <TabItem
          title="All people"
          onClick={() => {
            setTab("all");
            setFilter((p) => ({ ...p, roles: [], contracts: [], divisions: [] }));
          }}
          active={tab === "all"}
        />
        <TabItem
          title="Employee"
          onClick={() => {
            setTab("employee");
            setFilter((p) => ({ ...p, divisions: ["LE_COLLECTIF", "SELEGO_FR", "SELEGO_NL", "SELESPAGNE"], contract: null, roles: [] }));
          }}
          active={tab === "employee"}
        />
        <TabItem
          title="Freelance"
          onClick={() => {
            setTab("freelance");
            setFilter((p) => ({ ...p, divisions: [], contract: "FREELANCE", roles: [] }));
          }}
          active={tab === "freelance"}
        />
        <TabItem
          title="Client"
          onClick={() => {
            setTab("client");
            setFilter((p) => ({ ...p, roles: ["client"], contract: null, divisions: [] }));
          }}
          active={tab === "client"}
        />
        <TabItem
          title="Lead"
          onClick={() => {
            setTab("lead");
            setFilter((p) => ({ ...p, roles: ["lead"], contract: null, divisions: [] }));
          }}
          active={tab === "lead"}
        />
      </nav>
      <div className="flex gap-2 flex-wrap flex-col">
        <div className="flex items-end space-x-3">
          <div className="flex-1 space-y-1">
            <label htmlFor="search" className="text-xs text-gray-500">
              Search
            </label>
            <DebounceInput
              debounce={300}
              className="input"
              placeholder="Search (name, email, skills, etc...)"
              value={filter.search}
              onChange={(e) => {
                console.log("search");
                setFilter((prev) => ({ ...prev, search: e.target.value, page: 1 }));
              }}
            />
          </div>
          <div className="block">
            <div>
              <label htmlFor="availability" className="text-xs text-gray-500">
                Availability
              </label>
            </div>
            <select
              className="input"
              name="availability"
              value={filter.availability}
              onChange={(e) => {
                e.persist();
                if (e.target.value === "all")
                  setFilter((prev) => {
                    return { ...prev, availability: "" };
                  });
                else setFilter((prev) => ({ ...prev, availability: e.target.value }));
              }}>
              <option value="all">All</option>
              <option value="available">Available</option>
              <option value="not available">Not available</option>
            </select>
          </div>
          <div className="block">
            <div>
              <label htmlFor="availability" className="text-xs text-gray-500">
                Status
              </label>
            </div>
            <select
              className="input"
              name="status"
              value={filter.status}
              onChange={(e) => {
                e.persist();
                if (e.target.value === "all")
                  setFilter((prev) => {
                    return { ...prev, status: "" };
                  });
                else setFilter((prev) => ({ ...prev, status: e.target.value }));
              }}>
              <option value="all">All</option>
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
            </select>
          </div>

          <Create />
        </div>
      </div>
      {users?.length ? <Users users={users} setUsers={setUsers} total={total} /> : <Loader />}
      <Pagination
        per_page={filter.per_page}
        total={total}
        onNext={() => setFilter((f) => ({ ...f, page: +f.page + 1 }))}
        onPrevious={() => setFilter((f) => ({ ...f, page: +f.page - 1 }))}
        currentPage={filter.page}
      />
    </div>
  );
};

const Users = ({ users, setUsers, total }) => {

  return (
    <>
      <div>
        <span className="text-sm font-normal text-gray-500">
          <span className="text-base font-medium text-gray-700">{users?.length}</span> of {total}
        </span>
      </div>
      <div className="overflow-x-auto">
        <div className="grid grid-cols-2 lg:grid-cols-3 py-1 gap-5 ">
          {users?.map((hit) => {
            return <UserCard key={hit._id} user={hit} setUser={(user) => setUsers((prev) => prev.map((u) => (u._id === user._id ? user : u)))} />;
          })}
        </div>
      </div>
    </>
  );
};

const Create = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  return (
    <div>
      <button className="btn blue-btn" onClick={() => setOpen(true)}>
        Create new user
      </button>
      {open ? (
        <div className=" absolute top-0 bottom-0 left-0 right-0  bg-[#00000066] flex justify-center p-[1rem] z-50 " onClick={() => setOpen(false)}>
          <div className="w-full md:w-[60%] h-fit  bg-[white] p-[25px] rounded-md" onClick={(e) => e.stopPropagation()}>
            <Formik
              initialValues={{}}
              onSubmit={async (values, { setSubmitting }) => {
                try {
                  values.status = "active";
                  values.availability = "not available";
                  const res = await api.post("/user", values);
                  if (!res.ok) throw res;
                  toast.success("Created!");
                  setOpen(false);
                  navigate(`/user/${res.data._id}`);
                } catch (e) {
                  console.log(e);
                  toast.error("Some Error!", e.code);
                }
                setSubmitting(false);
              }}>
              {({ values, handleChange, handleSubmit, isSubmitting }) => (
                <React.Fragment>
                  <div>
                    <div className="flex justify-between flex-wrap">
                      <div className="w-full md:w-[48%] mt-2">
                        <div className="text-[14px] text-[#212325] font-medium	">Name</div>
                        <input className="projectsInput text-[14px] font-normal text-[#212325] rounded-[10px]" name="name" value={values.name} onChange={handleChange} />
                      </div>
                      <div className="w-full md:w-[48%] mt-2">
                        <div className="text-[14px] text-[#212325] font-medium	">Email</div>
                        <input className="projectsInput text-[14px] font-normal text-[#212325] rounded-[10px]" name="email" value={values.email} onChange={handleChange} />
                      </div>
                    </div>
                    <div className="flex justify-between flex-wrap mt-3">
                      <div className="w-full md:w-[48%] mt-2">
                        <div className="text-[14px] text-[#212325] font-medium	">Role</div>
                        <select
                          className="projectsInput text-[14px] font-normal  bg-white text-[#212325] rounded-[10px]"
                          type="select"
                          name="role"
                          value={values.role}
                          onChange={handleChange}>
                          <option value="user">User</option>
                          <option value="admin">Admin</option>
                          <option value="applicant">Applicant</option>
                          <option value="client">Client</option>
                          <option value="lead">Lead</option>
                        </select>
                      </div>
                    </div>
                    <div className="flex justify-between flex-wrap mt-3">
                      <div className="w-full  mt-2">
                        <div className="text-[14px] text-[#212325] font-medium	">Description</div>
                        <textarea
                          name="description"
                          value={values.description}
                          onChange={handleChange}
                          className="w-full border border-gray-300 rounded px-2 py-1 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                      </div>
                    </div>
                  </div>

                  <br />
                  <LoadingButton
                    className="mt-[1rem]  bg-[#0560FD] text-[16px] font-medium text-[#FFFFFF] py-[12px] px-[22px] rounded-[10px]"
                    loading={isSubmitting}
                    onClick={handleSubmit}>
                    Save
                  </LoadingButton>
                </React.Fragment>
              )}
            </Formik>
          </div>
        </div>
      ) : null}
    </div>
  );
};

const TabItem = ({ title, onClick, active }) => (
  <button onClick={onClick} className={`${active ? "blue-btn" : "transparent-btn"} relative rounded py-2 px-4 flex items-center gap-2`}>
    <div className="flex items-center gap-2">{title}</div>
  </button>
);

export default NewList;
