import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";

import api from "../../../services/api";

import Table from "../../../components/Table";
import Paginator from "../../../components/Paginator";

import CreateCompany from "../component/createCompany";

import CompanyModal from "../component/companyModal";

export default () => {
  const [companies, setCompanies] = useState([]);
  const [filters, setFilters] = useState({ page: 1 });
  const [loading, setLoading] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    fetch();
    load();
  }, [filters]);

  async function load() {
    const params = new URLSearchParams(window.location.search);
    const id = params.get("id");
    if (!id) return;
    const { ok, data } = await api.get(`/s_company/${id}`);
    if (!ok) return toast.error("Error fetching company");
    setSelectedCompany(data);
    window.history.pushState({}, "", "/acquiz/companies");
  }

  async function fetch() {
    setLoading(true);
    const newFilters = {};
    newFilters.skip = (filters.page - 1) * 100;
    newFilters.incubator = true;
    if (filters.search) newFilters.search = filters.search;
    const { data, total, ok } = await api.post("/s_company/search", newFilters);
    if (!ok) return toast.error("Error fetching companies");
    setCompanies(data);
    setTotal(total);
    setLoading(false);
  }

  return (
    <div className="bg-white h-full">
      <div className="flex justify-between items-center">
        <Search setFilters={setFilters} filters={filters} />
        <CreateCompany
          defaultValues={{ personnae: "INCUBATORS" }}
          onDone={(e) => {
            fetch();
            setSelectedCompany(e);
          }}
        />
      </div>
      <div className="text-sm text-gray-500">{total} items</div>
      <CompanyModal
        company={selectedCompany}
        setCompany={(e) => {
          setSelectedCompany(e);
          if (!e) return;
          const index = companies.findIndex((c) => c._id === e._id);
          if (index !== -1) companies[index] = e;
          setCompanies(companies);
          toast.success("Company updated");
        }}
        onClose={() => setSelectedCompany(null)}
      />
      <Table
        total={total}
        header={[
          { title: "Name", key: "name" },
          { title: "Created At", key: "created_at" },
        ]}
        loading={loading}
        height="h-[32rem]"
        sticky={true}
        noResultRender={<div className="w-full bg-white text-center p-2 my-5 rounded shadow-sm">Aucune résultat n'a été trouvé</div>}>
        {companies.map((item, index) => {
          return (
            <tr
              className={`${index % 2 === 0 ? "bg-gray-100" : "bg-gray-50"} cursor-pointer`}
              key={item._id}
              onClick={(e) => {
                e.stopPropagation();
                setSelectedCompany(item);
              }}>
              <td className="border hover:bg-gray-200 px-4 py-2 max-w-xs truncate">{item.name}</td>
              <td className="border hover:bg-gray-200 px-4 py-2 max-w-xs truncate">
                {new Date(item.created_at).toLocaleDateString("fr-FR", { day: "2-digit", month: "2-digit", year: "numeric" })}
              </td>
            </tr>
          );
        })}
      </Table>
      <div className="mt-1">
        <Paginator page={filters.page} setPage={(page) => setFilters({ ...filters, page })} last={Math.ceil(total / 100)} />
      </div>
    </div>
  );
};

const Search = ({ filters, setFilters }) => {
  const [str, setStr] = useState("");
  useEffect(() => {
    setStr(filters.search || "");
  }, [filters.search]);

  return (
    <div className="pr-2 pl-2 pb-2 flex items-start">
      <input type="text" className="form-control" placeholder="Search" value={str} onChange={(e) => setStr(e.target.value)} />
      <button type="submit" className="ml-2 btn btn-primary" onClick={() => setFilters({ ...filters, search: str })}>
        Search
      </button>
    </div>
  );
};
