import { Node } from "@tiptap/core";

const HANDLE_POSITIONS = ["se", "sw", "ne", "nw"];

const HANDLE_STYLES = {
  se: { bottom: "-4px", right: "-4px" },
  sw: { bottom: "-4px", left: "-4px" },
  ne: { top: "-4px", right: "-4px" },
  nw: { top: "-4px", left: "-4px" },
};

const RESIZE_STYLES = `
  .resizing {
    user-select: none;
  }
  .resizing * {
    cursor: grabbing !important;
  }
  .selected-image {
    @apply ring-2 ring-blue-500;
  }
  .selected-image-container .resize-handle {
    opacity: 1 !important;
  }
`;

const createImageContainer = (node, dom) => {
  const wrapper = document.createElement("div");
  wrapper.className = "w-full";

  const container = document.createElement("div");
  container.className = "relative inline-block group";

  const alignmentClasses = {
    left: "flex justify-start",
    center: "flex justify-center",
    right: "flex justify-end",
  };

  wrapper.className = `w-full ${alignmentClasses[node.attrs.alignment] || alignmentClasses.center}`;

  container.appendChild(dom);
  wrapper.appendChild(container);
  return { wrapper, container };
};

const createImage = (node) => {
  const dom = document.createElement("img");
  Object.entries(node.attrs).forEach(([key, value]) => {
    if (value) {
      if (key === "width" || key === "height") {
        dom.style[key] = value;
      } else if (key !== "alignment") {
        dom.setAttribute(key, value);
      }
    }
  });

  dom.className = "cursor-pointer max-w-full hover:ring-2 hover:ring-blue-500 transition-all duration-200";
  return dom;
};

const createResizeHandle = (position, dom, container, editor, node, getPos) => {
  const handle = document.createElement("div");
  handle.className = `resize-handle absolute w-3 h-3 bg-white border-2 border-blue-500 rounded-full opacity-0 transition-opacity duration-200`;
  handle.style.cursor = `${position}-resize`;

  Object.assign(handle.style, HANDLE_STYLES[position]);

  handle.addEventListener("mousedown", (startEvent) => {
    handleResizeStart(startEvent, { handle, dom, container, editor, node, getPos, position });
  });

  return handle;
};

const handleResizeStart = (startEvent, { handle, dom, container, editor, node, getPos, position }) => {
  startEvent.preventDefault();
  startEvent.stopPropagation();

  const startWidth = dom.offsetWidth;
  const startHeight = dom.offsetHeight;
  const startX = startEvent.clientX;
  const aspectRatio = startWidth / startHeight;

  container.classList.add("resizing");
  handle.style.opacity = "1";

  const handleMouseMove = (moveEvent) => {
    moveEvent.preventDefault();
    const deltaX = moveEvent.clientX - startX;
    const newWidth = position.includes("e") ? startWidth + deltaX : startWidth - deltaX;
    const finalWidth = Math.max(50, newWidth);
    const newHeight = finalWidth / aspectRatio;

    dom.style.width = `${finalWidth}px`;
    dom.style.height = `${newHeight}px`;
  };

  const handleMouseUp = () => {
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
    container.classList.remove("resizing");
    handle.style.opacity = "";

    // Mise à jour immédiate du contenu de l'éditeur avec les nouvelles dimensions
    if (editor && typeof getPos === "function") {
      const transaction = editor.state.tr.setNodeMarkup(getPos(), undefined, {
        ...node.attrs,
        width: dom.style.width,
        height: dom.style.height,
      });
      editor.view.dispatch(transaction);
    }
  };

  document.addEventListener("mousemove", handleMouseMove);
  document.addEventListener("mouseup", handleMouseUp);
};

const handleImageClick = (e, { container, editor, handles, node, dom, options }) => {
  e.stopPropagation();
  e.preventDefault();

  editor.options.element.querySelectorAll("img").forEach((img) => {
    if (img !== dom) {
      img.classList.remove("selected-image");
      img.closest(".relative").classList.remove("selected-image-container");
    }
  });

  dom.classList.add("selected-image");
  container.classList.add("selected-image-container");

  if (options.onImageClick) {
    options.onImageClick(node);
  }
};

const handleEditorClick = (e, { container, editor, handles, dom }) => {
  const isClickInMenu = e.target.closest(".image-attributes-menu");
  const isClickOnImage = e.target === dom || dom.contains(e.target);
  const isClickOnHandle = e.target.classList.contains("resize-handle");

  if (isClickInMenu || isClickOnImage || isClickOnHandle) {
    return;
  }

  dom.classList.remove("selected-image");
  container.classList.remove("selected-image-container");
};

export default Node.create({
  name: "image",
  group: "block",
  inline: false,
  draggable: true,
  selectable: true,

  addAttributes() {
    return {
      src: { default: null },
      alt: { default: null },
      title: { default: null },
      width: { default: null },
      height: { default: null },
      alignment: { default: "center" },
    };
  },

  parseHTML() {
    return [{ tag: "img[src]" }];
  },

  renderHTML({ HTMLAttributes }) {
    const { width, height, alignment } = HTMLAttributes;
    const style = width ? `width: ${width}; height: ${height};` : "";

    const alignmentClasses = {
      left: "flex justify-start",
      center: "flex justify-center",
      right: "flex justify-end",
    };

    return [
      "div",
      {
        class: `w-full ${alignmentClasses[alignment] || alignmentClasses.center}`,
      },
      [
        "div",
        {
          class: "relative inline-block",
        },
        [
          "img",
          {
            ...HTMLAttributes,
            style,
            class: "cursor-pointer max-w-full hover:ring-2 hover:ring-blue-500 transition-all duration-200",
          },
        ],
      ],
    ];
  },

  addNodeView() {
    return ({ node, getPos, editor }) => {
      const dom = createImage(node);
      const { wrapper, container } = createImageContainer(node, dom);

      const handles = HANDLE_POSITIONS.map((position) => createResizeHandle(position, dom, container, editor, node, getPos));
      handles.forEach((handle) => container.appendChild(handle));

      dom.addEventListener("click", (e) => handleImageClick(e, { container, editor, handles, node, dom, options: this.options }));

      const editorClickHandler = (e) => handleEditorClick(e, { container, editor, handles, dom, options: this.options });

      editor.options.element.addEventListener("click", editorClickHandler);

      const style = document.createElement("style");
      style.textContent = RESIZE_STYLES;
      document.head.appendChild(style);

      return {
        dom: wrapper,
        update: (updatedNode) => {
          // Mise à jour synchrone des attributs
          Object.entries(updatedNode.attrs).forEach(([key, value]) => {
            if (value) {
              if (key === "width" || key === "height") {
                dom.style[key] = value;
              } else if (key === "alignment") {
                const alignmentClasses = {
                  left: "flex justify-start",
                  center: "flex justify-center",
                  right: "flex justify-end",
                };
                wrapper.className = `w-full ${alignmentClasses[value] || alignmentClasses.center}`;
              } else {
                dom.setAttribute(key, value);
              }
            }
          });
          return true;
        },
        destroy: () => {
          editor.options.element.removeEventListener("click", editorClickHandler);
          style.remove();
        },
      };
    };
  },
});
