import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import api from "../../services/api";

export default () => {
  const navigate = useNavigate();
  const [workspaces, setWorkspaces] = useState(null);

  useEffect(() => {
    get();
  }, []);

  const get = async () => {
    try {
      const { data } = await api.post("/rfp_workspace/search", {});
      console.log(data);
      setWorkspaces(data);
    } catch (e) {
      console.log(e);
    }
  };

  if (!workspaces) return <div>loading</div>;

  return (
    <div className="px-4 pt-2 ">
      <div className="w-full overflow-x-auto bg-[#fff] rounded-[10px] mt-3">
        <Create />
        <table className="w-full min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            <tr>
              <th className="px-6 py-3">Name</th>
              <th className="px-6 py-3">Description</th>
              <th className="px-6 py-3">Deadline</th>
              <th className="px-6 py-3">Creator</th>
              <th className="px-6 py-3 whitespace-nowrap">Created At</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 whitespace-nowrap text-sm text-gray-500">
            {workspaces.map((workspace) => (
              <tr key={workspace._id} className="cursor-pointer hover:bg-gray-50" onClick={() => navigate(`/rfp/${workspace._id}`)}>
                <td className="px-6 py-2">{workspace.name}</td>
                <td className="px-6 py-2">{workspace.description}</td>
                <td className="px-6 py-2">{workspace.deadline ? new Date(workspace.deadline).toLocaleDateString() : '-'}</td>
                <td className="px-6 py-2">
                  <div className="flex items-center space-x-2">
                    <span>{workspace.user_name || "Sebastien Le Goff"}</span>
                  </div>
                </td>
                <td className="px-6 py-2">{new Date(workspace.created_at).toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const Create = () => {
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({});
  const navigate = useNavigate();

  async function onCreate() {
    const res = await api.post("/rfp_workspace", { 
      name: values.name,
      description: values.description,
      deadline: values.deadline
    });
    navigate(`/rfp/${res.data._id}`);
  }

  return (
    <>
      <div className="text-right">
        <button className="btn btn-primary" onClick={() => setOpen(true)}>
          Create new RFP workspace
        </button>
      </div>
      {open ? (
        <div>
          <div className=" absolute top-0 bottom-0 left-0 right-0  bg-[#00000066] flex justify-center p-[1rem] z-50 " onClick={() => setOpen(false)}>
            <div
              className="w-full md:w-[60%] h-fit  bg-[white] p-[25px] rounded-md"
              onClick={(e) => {
                e.stopPropagation();
              }}>
              <React.Fragment>
                <div>
                  <div className="flex flex-wrap justify-between mt-3">
                    <div className="w-full md:w-[48%] mt-2">
                      <input
                        type="text"
                        className="w-full border border-[#E5E5E5] rounded-[10px] px-[10px] py-[12px] text-[16px] font-medium text-[#000000] placeholder-[#BDBDBD] focus:outline-none"
                        placeholder="Name"
                        value={values.name}
                        onChange={(e) => setValues({ ...values, name: e.target.value })}
                      />
                    </div>
                    <div className="w-full md:w-[48%] mt-2">
                      <input
                        type="text"
                        className="w-full border border-[#E5E5E5] rounded-[10px] px-[10px] py-[12px] text-[16px] font-medium text-[#000000] placeholder-[#BDBDBD] focus:outline-none"
                        placeholder="Description"
                        value={values.description}
                        onChange={(e) => setValues({ ...values, description: e.target.value })}
                      />
                    </div>
                    <div className="w-full md:w-[48%] mt-2">
                      <input
                        type="date"
                        className="w-full border border-[#E5E5E5] rounded-[10px] px-[10px] py-[12px] text-[16px] font-medium text-[#000000] placeholder-[#BDBDBD] focus:outline-none"
                        placeholder="Deadline"
                        value={values.deadline}
                        onChange={(e) => setValues({ ...values, deadline: e.target.value })}
                      />
                    </div>
                  </div>
                </div>
                <br />
                <button className="mt-[1rem] bg-[#0560FD] text-[16px] font-medium text-[#FFFFFF] py-[12px] px-[22px] rounded-[10px]" onClick={onCreate}>
                  Create
                </button>
              </React.Fragment>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
