import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import moment from "moment";
import { IoClose } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

import Comments from "./Comments";
import DebounceInput from "../../../../../components/DebounceInput";
import MultiSelect from "../../../../../components/MultiSelect";
import SelectUser from "../../../../../components/selectUser";
import api from "../../../../../services/api";

import Gerard from "./gerard";
import SingleSelect from "../../../../../components/SingleSelect";

export default ({ epic, setEpic }) => {
  return (
    <div className="flex gap-1">
      <div className="w-1/6 bg-white">
        <div className="p-4">
          <div className="flex flex-col space-y-4">
            <div className="">
              <DebounceInput
                debounceTimeout={600}
                className="input h-24 focus:ring-2 focus:ring-blue-500"
                isTextArea={true}
                placeholder="Business Objective"
                type="text"
                id="business_objective"
                value={epic.business_objective || ""}
                onChange={async (e) => {
                  const { data } = await api.put(`/epic/${epic._id}`, { business_objective: e.target.value });
                  setEpic(data);
                  toast.success("Business Objective updated!");
                }}
              />
              <label className="text-xs font-semibold text-gray-700">Personae</label>
              <DebounceInput
                className="input focus:ring-2 focus:ring-blue-500"
                value={epic.persona}
                onChange={async (e) => {
                  const { data } = await api.put(`/epic/${epic._id}`, { persona: e.target.value });
                  setEpic(data);
                  toast.success("Persona updated!");
                }}
              />

              <label className="text-xs font-semibold text-gray-700">Module</label>
              <SelectModule
                value={epic.module}
                onChange={async (e) => {
                  const { data } = await api.put(`/epic/${epic._id}`, { module: e });
                  setEpic(data);
                  toast.success("Module updated!");
                }}
                projectId={epic.project_id}
              />
            </div>
            <div className=" pt-2 border-t border-gray-100">
              <label className="text-xs font-semibold text-gray-700">Resource Profile</label>
              <select
                className="input focus:ring-2 focus:ring-blue-500"
                value={epic.resource_profile}
                onChange={async (e) => {
                  let tjm = 500;
                  if (e.target.value === "CTO") tjm = 650;
                  else if (e.target.value === "EXPERIMENTED DEVELOPER") tjm = 500;
                  else if (e.target.value === "DEVELOPER") tjm = 350;
                  else if (e.target.value === "PRODUCT") tjm = 500;
                  const estimation = epic.days_required * tjm;
                  const { data } = await api.put(`/epic/${epic._id}`, { resource_profile: e.target.value, tjm, estimation });
                  setEpic(data);
                  toast.success("Resource profile updated!");
                }}>
                <option value="DEVELOPER">Developer</option>
                <option value="EXPERIMENTED DEVELOPER">Experimented Developer</option>
                <option value="CTO">CTO</option>
                <option value="PRODUCT">Product</option>
              </select>

              <label className="text-xs font-semibold text-gray-700">Assigned To</label>
              <SelectAssigned
                value={epic.assigned}
                onChange={async (e) => {
                  const { data } = await api.put(`/epic/${epic._id}`, { assigned: e });
                  setEpic(data);
                  toast.success("Assigned updated!");
                }}
                project={epic.project_id}
              />

              <label className="text-xs font-semibold text-gray-700">Days Required</label>
              <EstimationDays
                value={epic.days_required}
                onChange={async (e) => {
                  const tjm = epic.tjm || 500;
                  const estimation = e * tjm;
                  const { data } = await api.put(`/epic/${epic._id}`, { days_required: e, estimation });
                  setEpic(data);
                  toast.success("Estimation updated!");
                }}
              />

              <label className="text-xs font-semibold text-gray-700">Daily Rate (€)</label>
              <input
                type="number"
                className="input focus:ring-2 focus:ring-blue-500"
                value={epic.tjm}
                onWheel={(e) => e.target.blur()}
                onChange={async (e) => {
                  if (e.target.value === epic.tjm) return;
                  const estimation = epic.days_required * e.target.value;
                  const { data } = await api.put(`/epic/${epic._id}`, { tjm: e.target.value, estimation });
                  setEpic(data);
                }}
              />
              <label className="text-xs font-semibold text-gray-700">Estimation (€)</label>
              <EstimationPrice
                value={epic.estimation}
                onChange={async (e) => {
                  const { data } = await api.put(`/epic/${epic._id}`, { estimation: e });
                  setEpic(data);
                  toast.success("Estimation updated!");
                }}
              />
            </div>
            <div className="bg-purple-50 p-4 rounded-xl border border-purple-200 hover:shadow-md transition-shadow">
              <h3 className="text-sm font-bold text-purple-700">T-Shirt Size Evaluation</h3>
              <div className="space-y-2">
                <label className="text-xs font-semibold text-purple-600">Charge</label>
                <select
                  className="input bg-white focus:ring-2 focus:ring-purple-500"
                  value={epic.charge}
                  onChange={async (e) => {
                    const { data } = await api.put(`/epic/${epic._id}`, { charge: e.target.value });
                    setEpic(data);
                    toast.success("Charge updated!");
                  }}>
                  {["XS", "S", "M", "L", "XL"].map((size) => (
                    <option key={size} value={size}>
                      {size}
                    </option>
                  ))}
                </select>
              </div>

              <div className="space-y-2">
                <label className="text-xs font-semibold text-purple-600">Business Contribution</label>
                <select
                  className="input bg-white focus:ring-2 focus:ring-purple-500"
                  value={epic.business_contribution}
                  onChange={async (e) => {
                    const { data } = await api.put(`/epic/${epic._id}`, { business_contribution: e.target.value });
                    setEpic(data);
                    toast.success("Business Contribution updated!");
                  }}>
                  {["XS", "S", "M", "L", "XL"].map((size) => (
                    <option key={size} value={size}>
                      {size}
                    </option>
                  ))}
                </select>
              </div>

              <div className="space-y-2">
                <label className="text-xs font-semibold text-purple-600">Status</label>
                <select
                  className="input bg-white focus:ring-2 focus:ring-purple-500"
                  value={epic.status}
                  onChange={async (e) => {
                    const { data } = await api.put(`/epic/${epic._id}`, { status: e.target.value });
                    setEpic(data);
                    toast.success("Status updated!");
                  }}>
                  <option value="TODO">To Do</option>
                  <option value="IN_PROGRESS">In Progress</option>
                  <option value="NEED_INFO">Need Info</option>
                  <option value="DONE">Done</option>
                </select>
              </div>
            </div>

            <div className="bg-blue-50 p-4 rounded-xl border border-blue-200 space-y-3 hover:shadow-md transition-shadow">
              <h3 className="text-sm font-bold text-blue-700">Now, Next, Later</h3>
              <div className="space-y-2">
                <label className="text-xs font-semibold text-blue-600">Priority</label>
                <select
                  className="input bg-white focus:ring-2 focus:ring-blue-500"
                  value={epic.priority}
                  onChange={async (e) => {
                    const { data } = await api.put(`/epic/${epic._id}`, { priority: e.target.value });
                    setEpic(data);
                    toast.success("Priority updated!");
                  }}>
                  <option value="NOW">Now</option>
                  <option value="NEXT">Next</option>
                  <option value="LATER">Later</option>
                </select>
              </div>
            </div>

            {/* Tags & User Section */}
            <div className="space-y-3 pt-2 border-t border-gray-100">
              <div className="space-y-2">
                <label className="text-xs font-semibold text-gray-700">Tags</label>
                <SelectTags
                  value={epic.tags}
                  onChange={async (e) => {
                    const { data } = await api.put(`/epic/${epic._id}`, { tags: e });
                    setEpic(data);
                    toast.success("Tags updated!");
                  }}
                  projectId={epic.project_id}
                />
              </div>

              <div className="space-y-2">
                <label className="text-xs font-semibold text-gray-700">Owner</label>
                <SelectUser
                  value={{ _id: epic.user_id, name: epic.user_name, avatar: epic.user_avatar }}
                  onChange={async (e) => {
                    const { data } = await api.put(`/epic/${epic._id}`, { user_id: e._id, user_name: e.name, user_avatar: e.avatar });
                    setEpic(data);
                    toast.success("User updated!");
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-3/6 bg-white">
        <div className="p-4">
          <Comments epic={epic} setEpic={setEpic} />
        </div>
        <span className="flex items-center text-xs pl-4 pb-1 text-gray-600 italic">
          Created by&nbsp;{epic.user_name} on {moment(epic.created_at).format("DD/MM/YYYY")}
        </span>
      </div>

      <div className="w-2/6 bg-white p-4">
        <Backlog epic={epic} />
        <div className="mt-4"></div>
        <Gerard epic={epic} setEpic={setEpic} />
      </div>
    </div>
  );
};

const EstimationPrice = ({ value, onChange }) => {
  const [v, setV] = useState(value);

  useEffect(() => {
    setV(value);
  }, [value]);

  return (
    <input
      className="input"
      disabled
      type="number"
      value={v}
      onChange={(e) => setV(e.target.value)}
      onWheel={(e) => e.target.blur()}
      onBlur={() => {
        if (v === value) return;
        onChange(v);
      }}
    />
  );
};

const EstimationDays = ({ value, onChange }) => {
  const [v, setV] = useState(value);

  useEffect(() => {
    setV(value);
  }, [value]);

  return (
    <input
      className="input"
      type="number"
      value={v}
      onChange={(e) => setV(e.target.value)}
      onWheel={(e) => e.target.blur()}
      onBlur={() => {
        if (v === value) return;
        onChange(v);
      }}
    />
  );
};

const SelectTags = ({ value, onChange, projectId }) => {
  const [tags, setTags] = useState(value);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    get();
  }, [value]);

  async function get() {
    const { data } = await api.post("/epic/aggregate", { project_id: projectId, key: "tags" });
    const arr = data.filter((e) => e._id !== null).map((e) => ({ label: e._id, value: e._id, count: e.count }));
    setOptions(arr);
  }

  return (
    <div className="flex flex-col gap-2 mb-2">
      <MultiSelect
        values={tags.map((tag) => ({ value: tag, label: tag }))}
        id="select-tags"
        options={options}
        onSelectedChange={(e) => {
          setTags(e.map((e) => e.value));
          onChange(e.map((e) => e.value));
        }}
        placeholder="Tags"
        onCreateNew={(e) => {
          setTags([...tags, e.value]);
          get();
        }}
      />

      <div className="flex flex-wrap gap-1">
        {tags.map((tag) => (
          <span
            key={tag}
            className="text-xs w-fit  text-gray-600 bg-gray-100 px-2 py-1 rounded border border-gray-200 hover:bg-gray-200 cursor-pointer"
            onClick={() => {
              const newTags = tags.filter((t) => t !== tag);
              setTags(newTags);
              onChange(newTags);
            }}>
            {tag}
          </span>
        ))}
      </div>
    </div>
  );
};

const SelectAssigned = ({ value, onChange, projectId }) => {
  const [assigned, setAssigned] = useState(value);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    get();
  }, [value]);

  async function get() {
    const { data } = await api.post("/epic/aggregate", { project_id: projectId, key: "assigned" });
    const arr = data.filter((e) => e._id !== null).map((e) => ({ label: e._id, value: e._id, count: e.count }));
    setOptions(arr);
  }

  return (
    <div className="flex flex-col gap-2 mb-2">
      <SingleSelect
        value={assigned ? { value: assigned, label: assigned } : null}
        id="select-assigned"
        options={options}
        onChange={(option) => {
          setAssigned(option.value);
          onChange(option.value);
        }}
        placeholder="Select a assigned"
        onCreateNew={(e) => {
          setAssigned(e.value);
          get();
        }}
      />
    </div>
  );
};

const Backlog = ({ epic }) => {
  const [tickets, setTickets] = useState([]);
  const navigate = useNavigate();

  async function fetch() {
    const { data } = await api.post("/notion/search", { epic_id: epic._id, project_id: epic.project_id });
    setTickets(data);
  }

  useEffect(() => {
    fetch();
  }, [epic]);

  return (
    <div className="flex flex-col gap-4">
      <h2 className="text-lg font-semibold">Backlog</h2>
      {tickets.map((ticket) => (
        <button
          onClick={() => navigate(`/project/${epic.project_id}/backlog?task_modal_id=${ticket._id}`)}
          key={ticket._id}
          className="flex flex-col bg-white px-6 py-3 border border-gray-200 rounded-lg shadow-sm hover:shadow-md transition-shadow duration-200">
          <div className="flex justify-between w-full">
            <span className="text-sm font-medium text-gray-900">{ticket.name}</span>
            <span className="px-3 py-1 text-xs font-medium rounded-full bg-gray-100 text-gray-700">{ticket.status}</span>
          </div>
          {ticket.user_name && (
            <div className="flex gap-2 ">
              {ticket.user_avatar && <img src={ticket.user_avatar} alt={ticket.user_name} className="w-6 h-6 rounded-full border border-gray-200" />}
              <span className="text-sm text-gray-600">{ticket.user_name}</span>
            </div>
          )}
        </button>
      ))}
    </div>
  );
};

const SelectModule = ({ value, onChange, projectId }) => {
  const [module, setModule] = useState(value);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    get();
  }, [value]);

  async function get() {
    const { data } = await api.post("/epic/aggregate", { project_id: projectId, key: "module" });
    const arr = data.filter((e) => e._id !== null).map((e) => ({ label: e._id, value: e._id, count: e.count }));
    setOptions(arr);
  }

  return (
    <div className="flex flex-row gap-2">
      <div className="flex-1">
        <SingleSelect
          value={module ? { value: module, label: module } : null}
          id="select-module"
          options={options}
          onChange={(option) => {
            setModule(option.value);
            onChange(option.value);
          }}
          placeholder="Select a module"
          onCreateNew={(e) => {
            setModule(e.value);
            get();
          }}
        />
      </div>
      {module && (
        <button
          onClick={() => {
            setModule(null);
            onChange(null);
          }}
          className="p-1 hover:bg-gray-100 rounded-full">
          <IoClose className="w-4 h-4 text-gray-500" />
        </button>
      )}
    </div>
  );
};
