import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import Table from "./Table";
import { useLocation, useNavigate } from "react-router-dom";

export default ({ notions }) => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const navigate = useNavigate();

  const [sortBy, setSortBy] = useState({ key: "points", value: 1 });

  return (
    <div className="space-y-4">
      <Table
        total={notions.length}
        header={[
          { title: "#", key: "index" },
          { title: "Priority", key: "priority" },
          { title: "Title", key: "title" },
          { title: "Status", key: "status" },
          { title: "Assignees", key: "users" },
          { title: "Created", key: "created_at" },
          { title: "Updated", key: "updated_at" },
        ]}
        height="h-[32rem]"
        sort={{ [sortBy.key]: sortBy.value }}
        onSort={(e) => setSortBy(sortBy.key === e ? { key: e, value: sortBy.value * -1 } : { key: e, value: 1 })}>
        <AnimatePresence>
          {notions
            .sort((a, b) => {
              if (sortBy.key === "created_at") return sortBy.value * (new Date(b.created_at) - new Date(a.created_at));
              if (sortBy.key === "points") return sortBy.value * (b.points - a.points);
              return sortBy.value * (a[sortBy.key] || "").localeCompare(b[sortBy.key] || "");
            })
            .map((item, index) => {
              return (
                <motion.tr
                  onClick={() => {
                    query.set("task_modal_id", item._id);
                    navigate({ search: query.toString() });
                  }}
                  key={item._id}
                  layout
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.3 }}
                  className={`${index % 2 === 0 ? "bg-gray-100" : "bg-gray-50"} cursor-pointer hover:bg-gray-200`}>
                  <td className="border px-1 py-2 max-w-xs truncate font-medium text-sm">#{item.index}</td>
                  <td className="border px-4 py-2">
                    <span className={getPriorityColor(item.priority)}>{item.priority || "-"}</span>
                  </td>
                  <td className="border px-4 py-2">
                    <div className="flex flex-col gap-1 max-w-[300px]">
                      <span className="font-medium">{item.name}</span>
                      {item.tags && (
                        <div className="flex flex-wrap gap-1 ">
                          {item.tags.map((tag) => (
                            <span key={tag} className="text-xs text-gray-600 bg-gray-100 px-2 py-1 rounded border border-gray-200">
                              {tag}
                            </span>
                          ))}
                        </div>
                      )}
                    </div>
                  </td>
                  <td>
                    <StatusTag item={item} />
                  </td>
                  <td className="border px-4 py-2">
                    <div className="flex flex-wrap gap-1">
                      {item.users?.map((user) => (
                        <img key={user.user_avatar} className="h-6 w-6 rounded-full" src={user.user_avatar} alt={user.user_name} title={user.user_name} />
                      ))}
                    </div>
                  </td>
                  <td className="border px-4 py-2 text-sm text-gray-500">{`${daysAgo(item.created_at)} days ago`}</td>
                  <td className="border px-4 py-2 text-sm text-gray-500">{`${daysAgo(item.updated_at)} days ago`}</td>
                </motion.tr>
              );
            })}
        </AnimatePresence>
      </Table>
    </div>
  );
};

const StatusTag = ({ item }) => {
  if (!item?.status) return null;
  const colors = {
    TODO: "bg-blue-600 text-xs text-white border-blue-700",
    READY_TO_DEV: "bg-pink-100 text-xs text-pink-800 border-pink-200",
    IN_PROGRESS: "bg-blue-100 text-xs text-blue-800 border-blue-200",
    TESTING: "bg-purple-100 text-xs text-purple-800 border-purple-200",
    NEED_INFO: "bg-yellow-100 text-xs text-yellow-800 border-yellow-200",
    DONE: "bg-green-100 text-xs text-green-800 border-green-200",
  };
  return <div className={`px-2 py-1 rounded w-fit ${colors[item?.status]}`}>{item?.status}</div>;
};

const daysAgo = (date) => {
  const today = new Date();
  const diff = today - new Date(date);
  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  return days;
};

const getPriorityColor = (priority) => {
  if (priority === "P0") return "px-2 py-1 rounded w-fit bg-green-100 text-xs text-green-800";
  if (priority === "P1") return "px-2 py-1 rounded w-fit bg-yellow-100 text-xs text-yellow-800";
  if (priority === "P2") return "px-2 py-1 rounded w-fit bg-orange-100 text-xs text-orange-800";
  if (priority === "P3") return "px-2 py-1 rounded w-fit bg-red-100 text-xs text-red-800";
  return "px-2 py-1 rounded w-fit text-gray-600";
};
