import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import validator from "validator";
import { useLocation } from "react-router-dom";
import { FaGithub, FaLinkedin } from "react-icons/fa";
import { BsCheckCircle, BsCheckCircleFill } from "react-icons/bs";
import { MdDelete, MdUpload } from "react-icons/md";

import { readFileAsync } from "../../utils";

import Loader from "../../components/loader";
import selego from "../../assets/selego_logo3.jpg";

import api from "../../services/api";

const STEPS = {
  APPLY: "APPLY",
  QUESTIONS: "QUESTIONS",
  THANKYOU: "THANKYOU",
};

export default () => {
  const [step, setStep] = useState(STEPS.APPLY);
  const [loading, setLoading] = useState(true);
  const [jobPost, setJobPost] = useState(null);
  const [job, setJob] = useState(null);
  const [user, setUser] = useState(null);

  const { search } = useLocation();
  const query = new URLSearchParams(search);

  useEffect(() => {
    fetch();
  }, []);

  async function fetch() {
    setLoading(true);
    const jobPostId = query.get("jobpost");
    const { data: datajobPost } = await api.get(`/jobpost/${jobPostId}?incrementViews=true`);
    const { data: job } = await api.get(`/job/${datajobPost.job_id}`);
    setJobPost(datajobPost);
    setJob(job);
    setLoading(false);
  }

  if (loading) return <Loader />;

  return (
    <div className="w-full h-full px-4 py-8 bg-blue-50">
      {step === STEPS.APPLY && (
        <Apply
          jobPost={jobPost}
          job={job}
          callback={(e) => {
            setUser(e);
            if (job?.questions?.length > 0) setStep(STEPS.QUESTIONS);
            else setStep(STEPS.THANKYOU);
          }}
        />
      )}
      {step === STEPS.QUESTIONS && <Questions job={job} onClose={() => setStep(STEPS.THANKYOU)} user={user} />}
      {step === STEPS.THANKYOU && <ThankYou job={job} jobPost={jobPost} />}
    </div>
  );
};

const Questions = ({ job, user, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [answers, setAnswers] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  async function handleSubmit(e) {
    try {
      e.preventDefault();
      setIsSubmitting(true);

      const job_application_qna = job?.questions.map((question, index) => ({
        question: question.text,
        answer: answers[index] || "",
      }));

      const { data, ok } = await api.put(`/user/${user._id}`, { job_application_qna });
      setLoading(false);

      if (!ok) return toast.error("Wrong signup");

      onClose();
    } catch (e) {
      setLoading(false);
      if (e.code === "USER_ALREADY_REGISTERED") {
        return toast.error(
          <span>
            This email is already registered.
            <br />
            We&apos;ll contact you soon.
          </span>,
        );
      }
      toast.error(e.code);
    } finally {
      setIsSubmitting(false);
    }
  }

  if (loading) return <Loader />;

  return (
    <div className="max-w-3xl mx-auto p-8 bg-white rounded-xl shadow-lg">
      <form className="flex flex-col space-y-8" onSubmit={handleSubmit}>
        <div className="space-y-2">
          <div className="flex items-center justify-between">
            <h2 className="text-xl font-semibold text-gray-800">Additional questions</h2>
            <p className="text-sm text-gray-500">Please, answer the following questions to proceed with your application.</p>
          </div>
        </div>

        <div className="space-y-8">
          {job?.questions.map((question, index) => (
            <div key={index} className={`transition-all duration-300`}>
              <label className="block text-gray-700 font-medium mb-3">
                {question.text}
                <span className="text-red-500">*</span>
              </label>
              <textarea
                required
                className="w-full px-4 py-3 rounded-lg border border-gray-300 
                          focus:border-blue-500 focus:ring-2 focus:ring-blue-200 
                          transition-all min-h-[120px] resize-y"
                placeholder="Type your answer here..."
                value={answers[index] || ""}
                onChange={(e) => {
                  const newAnswers = [...answers];
                  newAnswers[index] = e.target.value;
                  setAnswers(newAnswers);
                }}
              />
              <div className="text-right text-sm text-gray-500 mt-2">{answers[index]?.length || 0} characters</div>
            </div>
          ))}
        </div>

        <div className="flex justify-between pt-4 border-t">
          <button type="submit" disabled={answers.length < job?.questions.length || isSubmitting} className="btn-primary">
            {isSubmitting ? (
              <div className="flex items-center gap-2">
                <div className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin" />
                Submitting...
              </div>
            ) : (
              "Submit application"
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

const ThankYou = ({ job }) => {
  return (
    <div className="max-w-3xl mx-auto p-8 bg-white rounded-xl shadow-lg text-center">
      <div className="mb-8">
        <div className="flex justify-center mb-6">
          <img src={selego} alt="Selego Logo" className="h-16" />
        </div>
        <h4 className="text-3xl font-semibold text-gray-800 mb-4">Thank you for your application!</h4>
        <p className="text-gray-600 mb-2">
          We appreciate your interest in the <span className="font-medium">{job?.name}</span> position at Selego for
        </p>
        <p className="text-gray-800 mb-8 font-medium text-2xl">{job?.title}</p>
        <div className="bg-blue-50 p-6 rounded-lg">
          <h5 className="font-medium text-gray-800 mb-3">What happens next?</h5>
          <div className="space-y-2 text-gray-600">
            <p>1. Our team will review your application carefully</p>
            <p>2. If your profile matches our requirements, we'll contact you for next steps</p>
            <p>3. The process typically takes 5-7 business days</p>
          </div>
        </div>
      </div>
      <div className="text-sm text-gray-500">
        <p className="mt-2">
          If you have any questions, please contact us at{" "}
          <a href="mailto:hire@selego.co" className="text-blue-600 hover:text-blue-700">
            hire@selego.co
          </a>
        </p>
      </div>
    </div>
  );
};

const Apply = ({ jobPost, job, callback }) => {
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({ linkedin: "" });

  async function handleSubmit(e) {
    try {
      e.preventDefault();
      if (!validator.isEmail(values?.email)) return toast.error("Invalid email");
      setLoading(true);

      const { data, token, code, ok } = await api.post(`/user/applicant`, { ...values, jobpost_id: jobPost?._id, job_id: job?._id });
      setLoading(false);

      if (!ok) return toast.error("Wrong signup", code);
      api.setToken(token);

      callback(data);
      setValues({});
    } catch (e) {
      setLoading(false);
      if (e.code === "USER_ALREADY_REGISTERED") {
        return toast.error(
          <span>
            This email is already registered.
            <br />
            We&apos;ll contact you soon.
          </span>,
        );
      }
      toast.error(e.code);
    }
  }

  return (
    <div className="max-w-3xl mx-auto p-8 bg-white rounded-xl shadow-lg">
      <form className="flex flex-col space-y-8" onSubmit={handleSubmit}>
        {job && (
          <div className="text-center">
            <div className="flex items-center justify-center gap-6 mb-6">
              <img src={selego} alt="Selego Logo" className="h-16" />
              <h1 className="text-3xl font-bold text-gray-800">Join our team</h1>
            </div>
            <p className="text-gray-600">Please complete the required information to proceed with your application</p>
          </div>
        )}

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="space-y-2">
            <label className="block text-gray-700 font-medium">
              Full Name <span className="text-red-500">*</span>
            </label>
            <input
              required
              type="name"
              value={values?.name}
              onChange={(e) => setValues({ ...values, name: e.target.value })}
              className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition-all"
              placeholder="John Doe"
            />
          </div>

          <div className="space-y-2">
            <label className="block text-gray-700 font-medium">
              Email <span className="text-red-500">*</span>
            </label>
            <input
              required
              type="email"
              value={values?.email}
              onChange={(e) => setValues({ ...values, email: e.target.value })}
              className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition-all"
              placeholder="example@mail.com"
            />
          </div>
        </div>

        <div className="space-y-6">
          {job?.linkedin_active && (
            <div className="space-y-2">
              <label className="flex items-center gap-2 text-gray-700 font-medium">
                LinkedIn Profile <FaLinkedin className="text-blue-600" />
                {job?.linkedin_required && <span className="text-red-500">*</span>}
              </label>
              <input
                required={job?.linkedin_required}
                type="text"
                value={values?.linkedin}
                onChange={(e) => setValues({ ...values, linkedin: withHttp(e.target.value) })}
                className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition-all"
                placeholder="Enter your LinkedIn URL"
              />
            </div>
          )}

          {job?.github_active && (
            <div className="space-y-2">
              <label className="flex items-center gap-2 text-gray-700 font-medium">
                GitHub Profile <FaGithub className="text-gray-800" />
                {job?.github_required && <span className="text-red-500">*</span>}
              </label>
              <input
                required={job?.github_required}
                type="text"
                value={values?.github}
                onChange={(e) => setValues({ ...values, github: withHttp(e.target.value) })}
                className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition-all"
                placeholder="Enter your GitHub URL"
              />
            </div>
          )}

          {job?.resume_active && (
            <div className="space-y-3">
              <label className="block text-gray-700 font-medium">
                Resume
                {job?.resume_required && <span className="text-red-500">*</span>}
              </label>
              <div className="flex items-center gap-4">
                <label className="flex-1 flex items-center justify-between px-4 py-3 border border-gray-300 rounded-lg hover:border-blue-500 cursor-pointer transition-all group">
                  <div className="flex items-center gap-3">
                    <MdUpload className="text-gray-500 group-hover:text-blue-500 transition-colors" />
                    <span className="text-gray-600 group-hover:text-gray-800 transition-colors">Upload resume</span>
                  </div>
                  {values.resume ? <BsCheckCircleFill className="text-green-500" /> : <BsCheckCircle className="text-gray-400" />}
                  <input
                    type="file"
                    hidden
                    required={job?.resume_required}
                    onChange={async (e) => {
                      const f = e.target.files[0];
                      const rawBody = await readFileAsync(f);
                      const { data } = await api.post(`/file`, { file: { rawBody, name: f.name }, folder: "/documents" });
                      setValues({ ...values, resume: data });
                    }}
                  />
                </label>
                {values.resume && (
                  <div className="flex items-center gap-3 px-4 py-2 bg-gray-50 rounded-lg border border-gray-200">
                    <div className="flex-1 flex items-center gap-2">
                      <div className="flex flex-col">
                        <span className="text-sm font-medium text-gray-900">Resume uploaded</span>
                        <a href={values.resume} target="_blank" rel="noopener noreferrer" className="text-xs text-blue-600 hover:text-blue-700">
                          Click to view
                        </a>
                      </div>
                    </div>
                    <button
                      type="button"
                      onClick={() => setValues({ ...values, resume: "" })}
                      className="p-1.5 text-gray-400 hover:text-red-500 rounded-full hover:bg-red-50 transition-colors">
                      <MdDelete size={18} />
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        <label className="flex items-start gap-3 cursor-pointer group">
          <input
            type="checkbox"
            className="mt-1 h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
            onChange={(e) => setValues({ ...values, profile_retention_consent: e.target.checked })}
          />
          <span className="text-gray-700 group-hover:text-gray-900 transition-colors">Would you like us to keep your profile for upcoming job openings or potential projects?</span>
        </label>

        <div className="flex justify-end">
          <button
            type="submit"
            disabled={loading}
            className={`px-8 py-3 rounded-lg font-medium text-white transition-all
              ${loading ? "bg-gray-400 cursor-not-allowed" : "bg-blue-600 hover:bg-blue-700 active:bg-blue-800"}`}>
            {loading ? (
              <div className="flex items-center gap-2">
                <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin" />
                Submitting...
              </div>
            ) : (
              "Submit Application"
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

const withHttp = (url) => (!/^https?:\/\//i.test(url) ? `http://${url}` : url);
