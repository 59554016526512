import React, { useEffect, useState } from "react";
import { FaUser, FaLinkedin, FaEnvelope, FaCheck, FaTimes, FaRegClock } from "react-icons/fa";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

import api from "../../../../services/api";

export default ({ selectedUser, setSelectedUser }) => {
  console.log("selectedUser", selectedUser);
  if (!selectedUser) return <View user={selectedUser} />;
  return <View user={selectedUser} />;
};

const TABS = {
  view: "view",
  edit: "edit",
  raw: "raw",
};

const View = ({ user }) => {
  const [tab, setTab] = useState(TABS.view);

  return (
    <div className={`user-form bg-white shadow-md cursor-pointer ring-2 ring-blue-500`}>
      <div className="flex space-x-2 text-sm border-b">
        <button className={`px-3 py-1 ${tab === TABS.view ? "border-b-2 border-blue-500 font-medium" : "text-gray-500 hover:text-gray-700"}`} onClick={() => setTab(TABS.view)}>
          View
        </button>
        <button className={`px-3 py-1 ${tab === TABS.edit ? "border-b-2 border-blue-500 font-medium" : "text-gray-500 hover:text-gray-700"}`} onClick={() => setTab(TABS.edit)}>
          Edit
        </button>
        <button className={`px-3 py-1 ${tab === TABS.raw ? "border-b-2 border-blue-500 font-medium" : "text-gray-500 hover:text-gray-700"}`} onClick={() => setTab(TABS.raw)}>
          Raw
        </button>
      </div>

      <div className="p-4">
        {tab === TABS.view && <ViewTab user={user} />}
        {tab === TABS.edit && <EditTab user={user} />}
        {tab === TABS.raw && <RawTab user={user} />}
      </div>
    </div>
  );
};

const ViewTab = ({ user }) => (
  <>
    <div className="flex items-center justify-between ">
      <div className="flex items-center">
        <div className="w-12 h-12 bg-gray-200 rounded-full flex items-center justify-center mr-4 overflow-hidden">
          {user?.picture ? (
            <img src={user.picture} alt="avatar" className="w-full h-full object-cover rounded-full" style={{ aspectRatio: "1/1" }} />
          ) : (
            user.first_name?.[0]?.toUpperCase() || "U"
          )}
        </div>
        <div>
          <h3 className="font-medium text-sm">{`${user.first_name || ""} ${user.last_name || ""}`}</h3>
          <p className="text-xs text-gray-500">{user.job_title}</p>
          <div className="flex space-x-3">
            <span className="text-base flex gap-1">
              {user.linkedin && (
                <a href={user.linkedin} target="_blank" rel="noopener noreferrer">
                  <FaLinkedin className="text-gray-600 text-base" />
                </a>
              )}
              {user.status_linkedin_visit_profile ? <FaCheck className="text-green-500" /> : <FaTimes className="text-red-500" />}
              {user.status_linkedin_invite_sent ? <FaCheck className="text-green-500" /> : <FaTimes className="text-red-500" />}
              {user.status_linkedin_invite_accepted ? <FaCheck className="text-green-500" /> : <FaTimes className="text-red-500" />}
              {user.status_linkedin_message_sent ? <FaCheck className="text-green-500" /> : <FaTimes className="text-red-500" />}
              {user.email && <FaEnvelope className="text-gray-600 text-base" />}
            </span>
          </div>
        </div>
      </div>
    </div>
    <p className="text-sm text-gray-600 line-clamp-2">{user.description || user?.linkedin_raw?.description || ""}</p>
    {user.location && <div className="text-sm text-gray-600">{user.location}</div>}
    <Experiences user={user} />
    <LastPosts user={user} />
    <Comments contact={user} setContact={(e) => setSelectedUser(e)} />
  </>
);

const EditTab = ({ user }) => {
  const [values, setValues] = useState({ ...user, description: user.description || user?.linkedin_raw?.description || "" });

  const handleSave = async () => {
    try {
      const { ok } = await api.put(`/s_contact/${user._id}`, values);
      if (!ok) return toast.error("Error saving contact");
      toast.success("Contact saved successfully");
    } catch (error) {
      console.error(error);
      toast.error("Error saving contact");
    }
  };

  const handleDelete = async () => {
    if (window.confirm("Are you sure you want to delete this contact?")) {
      try {
        const { ok } = await api.remove(`/s_contact/${user._id}`);
        if (!ok) return toast.error("Error deleting contact");
        toast.success("Contact deleted successfully");
      } catch (error) {
        console.error(error);
        toast.error("Error deleting contact");
      }
    }
  };

  return (
    <div className="space-y-4">
      <div className="flex items-center">
        <FaLinkedin className="mr-3" />
        <input
          className="flex-1 p-2 border border-gray-300 rounded"
          type="text"
          value={values.linkedin}
          onChange={(e) => setValues({ ...values, linkedin: e.target.value })}
          placeholder="LinkedIn URL"
        />
      </div>
      <div className="flex items-center gap-2">
        <FaUser className="mr-3" />
        <input
          className="flex-1 p-2 border border-gray-300 rounded"
          type="text"
          value={values.first_name}
          onChange={(e) => setValues({ ...values, first_name: e.target.value })}
          placeholder="First Name"
        />
        <input
          className="flex-1 p-2 border border-gray-300 rounded"
          type="text"
          value={values.last_name}
          onChange={(e) => setValues({ ...values, last_name: e.target.value })}
          placeholder="Last Name"
        />
      </div>
      <div className="flex items-center">
        <FaEnvelope className="mr-3" />
        <input
          className="flex-1 p-2 border border-gray-300 rounded"
          type="email"
          value={values.email}
          onChange={(e) => setValues({ ...values, email: e.target.value })}
          placeholder="Email"
        />
      </div>
      <textarea
        className="w-full p-2 border border-gray-300 rounded"
        rows={4}
        value={values.description}
        onChange={(e) => setValues({ ...values, description: e.target.value })}
        placeholder="Description"
      />
      <div className="flex items-center">
        <FaRegClock className="mr-3" />
        <select
          className="flex-1 p-2 border border-gray-300 rounded"
          value={values.status}
          onChange={(e) => setValues({ ...values, status: e.target.value, updated_at: new Date() })}>
          <option value="">Select Status</option>
          <option value="not_ready">Not Ready</option>
          <option value="ready">Ready</option>
          <option value="in_progress">In Progress</option>
          <option value="todo">Todo</option>
          <option value="follow_up_1">Follow Up 1</option>
          <option value="standby">Standby</option>
          <option value="lead">Lead</option>
          <option value="lost">Lost</option>
          <option value="unsuitable">Unsuitable</option>
        </select>
      </div>
      <div className="flex flex-col gap-2">
        <div className="flex items-center gap-2">
          <input
            type="checkbox"
            id="business_introducer"
            checked={values.business_introducer}
            onChange={(e) => setValues({ ...values, business_introducer: e.target.checked })}
            className="rounded border-gray-300"
          />
          <label htmlFor="business_introducer" className="text-sm text-gray-700">
            Business Introducer
          </label>
        </div>
        <div className="flex items-center gap-2">
          <input
            type="checkbox"
            id="intrapreneurship"
            checked={values.intrapreneurship}
            onChange={(e) => setValues({ ...values, intrapreneurship: e.target.checked })}
            className="rounded border-gray-300"
          />
          <label htmlFor="intrapreneurship" className="text-sm text-gray-700">
            Intrapreneurship
          </label>
        </div>
        <div className="flex items-center gap-2">
          <input
            type="checkbox"
            id="incubator"
            checked={values.incubator}
            onChange={(e) => setValues({ ...values, incubator: e.target.checked })}
            className="rounded border-gray-300"
          />
          <label htmlFor="incubator" className="text-sm text-gray-700">
            Incubator
          </label>
        </div>
        <div className="flex items-center gap-2">
          <input
            type="checkbox"
            id="metabase"
            checked={values.metabase}
            onChange={(e) => setValues({ ...values, metabase: e.target.checked })}
            className="rounded border-gray-300"
          />
          <label htmlFor="metabase" className="text-sm text-gray-700">
            Metabase
          </label>
        </div>

        <div className="flex items-center gap-2">
          <input
            type="checkbox"
            id="corporate"
            checked={values.corporate}
            onChange={(e) => setValues({ ...values, corporate: e.target.checked })}
            className="rounded border-gray-300"
          />
          <label htmlFor="corporate" className="text-sm text-gray-700">
            Corporate
          </label>
        </div>

        <div className="flex items-center gap-2">
          <input
            type="checkbox"
            id="startups_advanced"
            checked={values.startups_advanced}
            onChange={(e) => setValues({ ...values, startups_advanced: e.target.checked })}
            className="rounded border-gray-300"
          />
          <label htmlFor="startups_advanced" className="text-sm text-gray-700">
            Startups Advanced
          </label>
        </div>

        <div className="flex items-center gap-2">
          <input
            type="checkbox"
            id="startups_early"
            checked={values.startups_early}
            onChange={(e) => setValues({ ...values, startups_early: e.target.checked })}
            className="rounded border-gray-300"
          />
          <label htmlFor="startups_early" className="text-sm text-gray-700">
            Startups early
          </label>
        </div>

        {values.business_introducer && (
          <>
            <div className="flex items-center gap-2">
              <select value={values.level} onChange={(e) => setValues({ ...values, level: e.target.value })} className="flex-1 p-2 border border-gray-300 rounded">
                <option value="NOPASS">No Pass</option>
                <option value="ONEPASS">One Pass</option>
                <option value="FREQUENTPASS">Frequent Pass</option>
              </select>
            </div>
            <div className="flex items-center gap-2">
              <input
                type="text"
                value={values.category}
                onChange={(e) => setValues({ ...values, category: e.target.value })}
                className="flex-1 p-2 border border-gray-300 rounded"
                placeholder="Category"
              />
            </div>
          </>
        )}
      </div>
      <div className="flex justify-between">
        <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded" onClick={handleDelete}>
          Delete
        </button>
        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={handleSave}>
          Save
        </button>
      </div>
    </div>
  );
};

const RawTab = ({ user }) => (
  <div className="space-y-4">
    <pre className="whitespace-pre-wrap text-xs font-mono bg-gray-50 p-4 rounded">{JSON.stringify(user, null, 2)}</pre>
  </div>
);

const LastPosts = ({ user }) => {
  if (!user?.unipile_last_posts?.length) return null;

  return (
    <div className="space-y-4">
      <h3 className="text-sm mt-4">Recent LinkedIn Posts</h3>
      {user.unipile_last_posts.slice(0, 3).map((post, index) => {
        return (
          <div key={index} className="border p-4 rounded cursor-pointer hover:bg-gray-50" onClick={() => post.share_url && window.open(post.share_url, "_blank")}>
            <div className="text-xs line-clamp-6">{post.text}</div>
            <div className="flex items-center gap-4 mt-2 text-xs text-gray-600">
              <div>{post.reaction_counter || 0} reactions</div>
              <div>{post.comment_counter || 0} comments</div>
              <div className="text-xs text-gray-500">
                {(() => {
                  const postDate = new Date(post.parsed_datetime);
                  const now = new Date();
                  const diffTime = Math.abs(now - postDate);
                  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                  return `${diffDays} days ago (${postDate.toLocaleDateString()})`;
                })()}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
const Experiences = ({ user }) => {
  const [showAll, setShowAll] = useState(false);
  if (!user?.linkedin_raw?.experiences?.length) return <div>No experiences</div>;

  // Calculate total years of experience from earliest start date to today
  const earliestStartDate = user.linkedin_raw.experiences.reduce((earliest, exp) => {
    if (!exp.duration_startDate) return earliest;
    const startDate = new Date(exp.duration_startDate);
    return earliest ? (startDate < earliest ? startDate : earliest) : startDate;
  }, null);

  const totalYears = earliestStartDate ? (new Date() - earliestStartDate) / (1000 * 60 * 60 * 24 * 365) : 0;

  // Filter for current and recent experiences
  const filteredExperiences = user.linkedin_raw.experiences.filter((exp) => {
    const isPresent = !exp.duration_endDate;
    if (isPresent) return true;

    const endDate = new Date(exp.duration_endDate);
    const twoYearsAgo = new Date();
    twoYearsAgo.setFullYear(twoYearsAgo.getFullYear() - 2);
    return endDate > twoYearsAgo;
  });

  const experiencesToShow = showAll ? user.linkedin_raw.experiences : filteredExperiences;
  const hasHiddenExperiences = user.linkedin_raw.experiences.length > filteredExperiences.length;

  return (
    <div className="flex flex-col space-y-1">
      <h3 className="text-sm mt-4">Experiences ({Math.round(totalYears)} years total)</h3>
      {experiencesToShow.map((exp, index) => (
        <CompanyItem key={index} exp={exp} />
      ))}
      {hasHiddenExperiences && (
        <button onClick={() => setShowAll(!showAll)} className="text-sm text-blue-600 hover:text-blue-800 mt-2">
          {showAll ? "Show Recent Only" : `Show All (${user.linkedin_raw.experiences.length} experiences)`}
        </button>
      )}
    </div>
  );
};

const Comments = ({ contact, setContact }) => {
  const [comment, setComment] = useState("");

  const user = useSelector((state) => state.Auth.user);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!comment) return;
    setComment("");
    const comments = contact.comments || [];
    comments.push({ text: comment, user_name: user.name, user_id: user._id, user_avatar: user.avatar });
    const { data } = await api.put(`/s_contact/${contact._id}`, { comments });
    setContact(data);
    toast.success("Comment added!");
  };

  async function onDelete(id) {
    if (confirm("Are you sure you want to delete this comment?") === false) return;
    const comments = contact.comments.filter((c) => c._id !== id);
    const { data } = await api.put(`/s_contact/${contact._id}`, { comments });
    setContact(data);
    toast.success("Comment deleted!");
  }

  return (
    <div className=" w-full">
      <div className="w-full flex flex-col ">
        <h3 className="text-sm mt-4 mb-2">Comments</h3>
        <div className="flex flex-col justify-end">
          {(contact.comments || []).map((comment, index) => (
            <div className="border-b-2 pb-2 mb-2">
              <div className="flex flex-row">
                <img src={comment.user_avatar} className="rounded-full w-10 h-10 mr-4" />
                <div key={index} className="flex items-center mb-2">
                  <div>
                    <div className="bg-white  p-2 break-words">{comment.text}</div>
                    <div className="text-xs text-gray-500">
                      {comment.user_name} - {!!comment?.created_at && new Date(comment.created_at).toLocaleDateString()}
                    </div>
                    {onDelete && (
                      <div className="text-xs text-gray-500 text-right w-full cursor-pointer hover:text-red-500" onClick={() => onDelete(comment._id)}>
                        DELETE
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div>
          <div className="flex items-center">
            <textarea
              className="rounded-md border border-gray-100 shadow-sm block w-full"
              placeholder="Add a comment"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
          </div>
          <div className="flex justify-end mt-2">
            <button className="btn btn-primary text-xs font-medium" onClick={handleSubmit}>
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const CompanyItem = ({ exp }) => {
  const [company, setCompany] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getCompany();
  }, [exp]);

  async function getCompany() {
    setLoading(true);
    const name = exp.company;
    if (!name) return;
    const { data } = await api.post(`/s_company/search`, { linkedin_id: exp.linkedin_id });
    if (data.length === 0) return setLoading(false);
    setCompany(data[0]);
    setLoading(false);
  }

  if (loading) return <div>Loading...</div>;

  return (
    <div className="p-2 border border-gray-400 rounded-md">
      <a href={exp.link} target="_blank" rel="noopener noreferrer" className="flex items-center space-x-3">
        {exp.logo && <img src={exp.logo} alt={`${exp.company} logo`} className="w-10 h-10 rounded-full" />}
        <div>
          <h2 className="text-sm m-0 p-0">{exp.title}</h2>
          <p className="text-sm">
            {exp.company} {exp.company_contract ? `(${exp.company_contract})` : ""}
          </p>
          <p className="text-xs">{exp.isActive ? `Since ${exp.duration_startDate}` : `${exp.duration_startDate} - ${exp.duration_endDate}`}</p>
        </div>
      </a>
      <div>
        {company?.linkedin_raw?.about && <div className="text-xs text-gray-500">{company.linkedin_raw.about}</div>}
        {company?.linkedin_raw?.headcount && <div className="text-xs text-gray-500">{company.linkedin_raw.headcount}</div>}
        {company?.linkedin_raw?.mediumTenure && <div className="text-xs text-gray-500">{company.linkedin_raw.mediumTenure}</div>}
        {company?.linkedin_raw?.followers && <div className="text-xs text-gray-500">{company.linkedin_raw.followers}</div>}
        {company?.linkedin_raw?.employees && <div className="text-xs text-gray-500">{company.linkedin_raw.employees}</div>}
        {company?.linkedin_raw?.industry && <div className="text-xs text-gray-500">{company.linkedin_raw.industry}</div>}
      </div>
    </div>
  );
};
