import React, { useState } from "react";
import { useSelector } from "react-redux";

import Send from "./send";
import moment from "moment";
import { MdOutlineKeyboardArrowRight, MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { IoMailOutline, IoOpenOutline, IoCopyOutline } from "react-icons/io5";
import { copyToClipboard } from "../../utils";
import Comments from "../Comments";
import GithubIcon from "../../assets/github.svg";
import { MdUpload } from "react-icons/md";
import { readFileAsync } from "../../utils";

import api from "../../services/api";
import toast from "react-hot-toast";

const APPLICANT_STATUS_COLOR = {
  NEW: "#8B5CF6",
  REJECT: "#EF4444",
  SHORTLIST: "#3B82F6",
  CONTACTED: "#10B981",
  INTERVIEW: "#F59E0B",
  TECHNICAL_TEST: "#2563EB",
  FINAL_LIST: "#F59E0B",
  HIRE: "#10B981",
};

export default ({ user, setUser }) => {
  const [values, setValues] = useState(user);
  const loggedInUser = useSelector((state) => state.Auth.user);
  const [loading, setLoading] = useState(false);

  const onSave = async (body) => {
    const { data, ok } = await api.put(`/user/${user._id}`, body);
    if (!ok) return toast.error("Some Error!");
    toast.success("Updated !");
    setUser(data);
  };

  const handleFileUpload = async (e) => {
    try {
      setLoading(true);
      const file = e.target.files[0];
      const rawBody = await readFileAsync(file);
      const { data } = await api.post(`/file`, { file: { rawBody, name: file.name }, folder: "documents" });
      await onSave({ ...values, resume: data });
      setLoading(false);
    } catch (e) {
      console.log(e);
      toast.error("Some Error!");
    }
  };

  return (
    <div className="grid grid-cols-12 gap-4">
      <div className="col-span-12 bg-white border-[1px] rounded-lg border-gray-200 p-4">
        <div className="flex flex-col gap-4">
          <div className="flex items-center justify-between border-b border-gray-200 pb-2">
            <div className="flex flex-col">
              <a href={`/job/${user.job_id}`} target="_blank" rel="noreferrer" className="text-lg font-medium hover:text-blue-600">
                {user.job_title}
              </a>
              <div className="flex items-center gap-2 text-sm text-gray-500 mt-1">
                {user.jobCategory && <span className="bg-gray-100 px-2 py-0.5 rounded-full">{user.jobCategory}</span>}
                {user.channel && (
                  <span className="bg-blue-50 text-blue-700 px-2 py-0.5 rounded-full flex items-center gap-1">
                    <span className="w-2 h-2 rounded-full bg-blue-700"></span>
                    {user.channel}
                  </span>
                )}
              </div>
            </div>
            <div className="flex items-center gap-4">
              <SelectStatus
                value={user.applicant_status}
                onChange={async (e) => {
                  const { ok, data } = await api.put(`/user/${user._id}`, { applicant_status: e.target.value });
                  toast.success("Status updated");
                  setUser(data);
                }}
              />
              {user.applicant_status === "NEW" && (
                <button
                  onClick={async () => {
                    const { ok, data } = await api.put(`/user/${values._id}`, {
                      applicant_status: "SHORTLIST",
                      user_id: loggedInUser._id,
                      user_avatar: loggedInUser.avatar,
                      user_name: loggedInUser.name,
                    });
                    if (!ok) return toast.error("Some Error!");
                    toast.success("User shortlisted");
                    setUser(data);
                  }}
                  className="hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 text-sm border-[1px] border-blue-500 hover:border-transparent rounded">
                  Shortlist
                </button>
              )}
              {user.applicant_status === "HIRE" ? (
                <div className="border border-red-400 p-2 rounded-xl">
                  <div className="px-1 text-sm text-red-600 font-medium mb-2">Please fill-in the following infos!!</div>
                  <div>
                    <div className="px-1 text-sm text-gray-600 font-medium">Office</div>
                    <SelectCountry value={values.office} onChange={(e) => setValues({ ...values, office: e.target.value })} />
                  </div>
                  <div className="mt-4">
                    <div className="px-1 text-sm text-gray-600 font-medium">Starting date</div>
                    <input
                      type="date"
                      label="Starting date"
                      name="starting_date"
                      defaultValue={values.starting_date ? new Date(values.starting_date).toISOString().split("T")[0] : null}
                      onChange={(e) => setValues({ ...values, starting_date: e.target.value })}
                      className="block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    />
                  </div>
                </div>
              ) : (
                <Reject user={user} setUser={setUser} />
              )}
              <Send user={user} callback={setUser} />
              <button className="btn btn-primary" onClick={() => onSave(values)}>
                Save
              </button>
            </div>
          </div>

          <div className="flex items-center gap-8 text-sm border-b border-gray-200 pb-2">
            <div className="flex items-center gap-2">
              <span className="text-gray-500">Applied:</span>
              <span className="font-medium">{moment(user.created_at).format("MMM D, YYYY")}</span>
            </div>
            <div className="flex items-center gap-2">
              <span className="text-gray-500">Source:</span>
              <span className="font-medium flex items-center gap-1">
                <span className="w-2 h-2 rounded-full" style={{ backgroundColor: user.channel === "Direct" ? "#10B981" : "#3B82F6" }}></span>
                {user.channel || "Unknown"}
              </span>
            </div>
            <div className="flex items-center gap-2">
              <span className="text-gray-500">Location:</span>
              <span className="font-medium flex items-center gap-1">{user.location || "Unknown"}</span>
            </div>
            <div className="flex items-center gap-2">
              <span className="text-gray-500">Country:</span>
              <span className="font-medium flex items-center gap-1">{user.country || "Unknown"}</span>
            </div>
            {user.contracts && user.contracts.length > 0 && (
              <div className="flex items-center gap-2">
                <span className="text-gray-500">Contracts:</span>
                <div className="flex items-center gap-1">
                  {user.contracts.map((contract) => (
                    <span key={contract} className="bg-blue-100 text-blue-800 text-xs px-2 py-0.5 rounded">
                      {contract}
                    </span>
                  ))}
                </div>
              </div>
            )}
            {user.experience && (
              <div className="flex items-center gap-2">
                <span className="text-gray-500">Experience:</span>
                <span className="font-medium">{user.experience}</span>
              </div>
            )}
          </div>
          <div className="flex items-center gap-8 text-sm">
            <div className="flex space-x-6">
              {user.linkedin && (
                <a href={user.linkedin} target="_blank" rel="noopener noreferrer">
                  <img src="https://upload.wikimedia.org/wikipedia/commons/c/ca/LinkedIn_logo_initials.png" alt="LinkedIn Logo" className="w-6 h-6" />
                </a>
              )}
              {user.email && (
                <a href={`mailto:${user.email}`}>
                  <img src="https://upload.wikimedia.org/wikipedia/commons/4/4e/Gmail_Icon.png" alt="Email Logo" className="w-6 h-6" />
                </a>
              )}
              {user.resume && (
                <a href={user.resume} target="_blank" rel="noopener noreferrer">
                  <img src="https://upload.wikimedia.org/wikipedia/commons/6/6c/PDF_icon.svg" alt="Resume Logo" className="w-6 h-6" />
                </a>
              )}
              {user.github && (
                <a href={user.github} target="_blank" rel="noreferrer">
                  <img alt="github" src={GithubIcon} className="w-6 h-6" />
                </a>
              )}
              {user.other && (
                <a href={user.other} target="_blank" rel="noreferrer" className="flex items-center gap-1 hover:underline">
                  <span className="flex-1">{user.other}</span> <IoOpenOutline />
                </a>
              )}
              <div className="flex items-center gap-1">
                {user.email}
                <div
                  className="hover:bg-gray-100 cursor-pointer rounded-lg p-1"
                  onClick={() => {
                    copyToClipboard(user.email);
                    toast.success("Email copied");
                  }}>
                  <IoCopyOutline />
                </div>
              </div>
              <div>
                <label className="btn btn-primary p-2 h-auto cursor-pointer flex items-center mb-2" style={{ opacity: loading ? 0.5 : 1 }}>
                  <input name="file-upload" type="file" disabled={loading} onChange={handleFileUpload} />
                  <MdUpload className="text-lg mr-2" />
                  {user.resume ? "Edit CV" : "Upload CV"}
                  <a href={user?.resume} target="_blank" rel="noopener noreferrer" className="ml-2">
                    <img src="https://upload.wikimedia.org/wikipedia/commons/6/6c/PDF_icon.svg" alt="Resume Logo" className="w-6 h-6" />
                  </a>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-span-8 bg-white border-[1px] rounded-lg border-gray-200 p-4">
        {user.resume ? (
          <object data={user.resume} type="application/pdf" width="100%" height="700px">
            <p>
              Unable to display PDF.{" "}
              <a href={user.resume} target="_blank" rel="noopener noreferrer">
                Open PDF
              </a>
            </p>
          </object>
        ) : (
          <div className="flex items-center justify-center h-full text-gray-500">No resume available</div>
        )}
      </div>

      <div className="col-span-4">
        <div className="bg-white border-[1px] rounded-lg border-gray-200 p-4">
          <div className="mb-4">
            <div className="flex items-center justify-between mb-4">
              <p className="font-medium">Comments</p>
            </div>

            <Comments
              value={user.comments}
              onAdd={async (text) => {
                const { data } = await api.post(`/user/${user._id}/comment`, { text });
                setUser(data);
                toast.success("Comment added!");
              }}
              onDelete={async (comment_id) => {
                const { data } = await api.put(`/user/${user._id}/delete-comment/${comment_id}`, {});
                setUser(data);
                toast.success("Comment deleted!");
              }}
            />
          </div>

          <div className="border-t border-gray-200 pt-4">
            <div className="mt-2 space-y-2 max-h-[300px] overflow-y-auto">
              {(user.logs || [])
                .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
                .map((log, index) => {
                  const renderValue = () => {
                    if (log?.type === "update_field") {
                      if (log?.field === "applicant_status")
                        return (
                          <div className="flex items-center gap-1 text-xs">
                            <span className="flex items-center">
                              <div className="h-2 w-2 rounded-full mr-1" style={{ backgroundColor: APPLICANT_STATUS_COLOR[log.old_value] }} />
                              {log.old_value}
                            </span>
                            <MdOutlineKeyboardArrowRight />
                            <span className="flex items-center">
                              <div className="h-2 w-2 rounded-full mr-1" style={{ backgroundColor: APPLICANT_STATUS_COLOR[log.new_value] }} />
                              {log.new_value}
                            </span>
                          </div>
                        );
                      return (
                        <p className="text-xs">
                          {log.old_value} <MdOutlineKeyboardArrowRight className="inline" /> {log.new_value}
                        </p>
                      );
                    }
                    if (log?.type === "mail_sent")
                      return (
                        <div className="flex items-center gap-1 text-xs">
                          <IoMailOutline /> <span>{log.subject}</span>
                        </div>
                      );
                  };

                  return (
                    <div key={index} className="flex items-start gap-2 py-1">
                      <div className="flex-shrink-0 w-20">
                        <p className="text-[10px] text-gray-500">{moment(log.created_at).format("DD MMM, HH:mm")}</p>
                      </div>
                      <div className="flex-1 min-w-0">
                        {renderValue()}
                        <div className="flex items-center gap-1 mt-0.5">
                          <img src={log.from_user_avatar} className="w-3 h-3 rounded-full" />
                          <span className="text-[10px] text-gray-500">{log.from_username}</span>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>

          {user?.job_application_qna?.length > 0 && (
            <div className="border-t border-gray-200 pt-4">
              <div className="flex items-center justify-between mb-4">
                <p className="font-medium">Additional questions</p>
              </div>

              <div className="mt-2 space-y-2 max-h-[300px] overflow-y-auto">
                <dl className="divide-y divide-gray-200">
                  {user.job_application_qna.map((qna, index) => (
                    <div key={index} className="py-2">
                      <dt className="text-sm font-medium text-gray-500 mb-1">{qna.question}</dt>
                      <dd className="text-sm text-gray-700">{qna.answer}</dd>
                    </div>
                  ))}
                </dl>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export const SelectStatus = ({ value, onChange }) => {
  return (
    <div>
      <select
        className={`w-full text-[12px] text-black border-2 font-semibold py-[4px] px-[4px] rounded-[5px] border-r-[16px] border-[transparent] cursor-pointer shadow-sm`}
        style={{ borderColor: APPLICANT_STATUS_COLOR[value] }}
        value={value || ""}
        name="status"
        onChange={onChange}>
        <option value="" selected>
          Select a status
        </option>
        <option key="NEW" value="NEW">
          NEW
        </option>
        <option key="REJECT" value="REJECT">
          REJECT
        </option>
        <option key="SHORTLIST" value="SHORTLIST">
          SHORTLIST
        </option>
        <option key="CONTACTED" value="CONTACTED">
          CONTACTED
        </option>
        <option key="INTERVIEW" value="INTERVIEW">
          INTERVIEW
        </option>
        <option key="FINAL_LIST" value="FINAL_LIST">
          FINAL_LIST
        </option>
        <option key="HIRE" value="HIRE">
          HIRE
        </option>
        <option key="TECHNICAL_TEST" value="TECHNICAL_TEST">
          TECHNICAL_TEST
        </option>
      </select>
    </div>
  );
};

const SelectCountry = ({ value, onChange }) => {
  return (
    <div>
      <select
        className="w-full rounded-md border-[1px] bg-[#ffffff] disabled:bg-gray-100 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-sm placeholder:text-gray-300 py-2 px-2"
        value={value || ""}
        name="country"
        onChange={(e) => {
          e.preventDefault();
          onChange(e);
        }}>
        <option value="" selected>
          Select a country
        </option>
        <option key="France" value="France">
          France
        </option>
        <option key="Spain" value="Spain">
          Spain
        </option>
        <option key="Netherland" value="Netherland">
          Netherland
        </option>
        <option key="Other" value="Other">
          Other
        </option>
      </select>
    </div>
  );
};

const Reject = ({ user, setUser }) => {
  const [open, setOpen] = useState(false);

  async function onSubmit() {
    const rejected_reason = Array.from(document.querySelectorAll('input[name="reason"]:checked')).map((input) => input.value);
    await api.put(`/user/${user._id}`, { applicant_status: "REJECT", rejected_reason });
    const { data } = await api.post(`/user/${user._id}/comment`, { text: `Rejected: ${rejected_reason.join(", ")}` });
    setUser(data);
    toast.success("Applicant rejected");
    setOpen(false);
  }

  return (
    <div className="">
      <button
        className="hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 text-sm border-[1px] border-blue-500 hover:border-transparent rounded block"
        onClick={() => setOpen(!open)}>
        Toggle reject
      </button>
      {open && (
        <div className="border border-gray-400 p-2 rounded-xl">
          <React.Fragment>
            <div className="w-full text-left">
              <form>
                <label className="flex items-center">
                  <input type="checkbox" name="reason" value="not-right-mindset" />
                  <div className="ml-2">Not the right mindset</div>
                </label>
                <label className="flex items-center">
                  <input type="checkbox" name="reason" value="not-right-skills" />
                  <div className="ml-2">Not the right skills</div>
                </label>
                <label className="flex items-center">
                  <input type="checkbox" name="reason" value="not-right-experience" />
                  <div className="ml-2">Not the right experience</div>
                </label>
                <label className="flex items-center">
                  <input type="checkbox" name="reason" value="not-right-localisation-language" />
                  <div className="ml-2">Not the right localisation/language</div>
                </label>
                <label className="flex items-center">
                  <input type="checkbox" name="reason" value="salary-expectations" />
                  <div className="ml-2">Salary expectations</div>
                </label>
                <label className="flex items-center">
                  <input type="checkbox" name="reason" value="poor-performance" />
                  <div className="ml-2">Poor performance in the interview process</div>
                </label>
                <label className="flex items-center">
                  <input type="checkbox" name="reason" value="position-already-filled" />
                  <div className="ml-2"> Position already filled</div>
                </label>
                <br />
              </form>
              <button className=" bg-[#0560FD] text-[16px] font-medium text-[#FFFFFF] py-[12px] px-[22px] rounded-[10px]" onClick={onSubmit}>
                Reject
              </button>
            </div>
          </React.Fragment>
        </div>
      )}
    </div>
  );
};
