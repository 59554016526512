import { useEffect, useRef, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";
import Table from "../../../../components/Table";
import EpicEditModal from "./EpicModal";
import EpicCreationModal from "./EpicCreationModal";
import api from "../../../../services/api";
import DebounceInput from "../../../../components/DebounceInput";
import MultiSelect from "../../../../components/MultiSelect";
import { MultiAction } from "./Composant/MultiAction";

export default ({ project }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [epics, setEpics] = useState([]);
  const [filters, setFilters] = useState({
    page: 1,
    statuses: searchParams.get("statuses")?.split(",") || ["TODO", "IN_PROGRESS", "NEED_INFO"],
    urgent: searchParams.get("urgent")?.split(",") || [],
    modules: searchParams.get("modules")?.split(",") || [],
  });
  const [sortBy, setSortBy] = useState({ key: "points", value: 1 });
  const [selectedEpics, setSelectedEpics] = useState([]);
  const [selectedEpic, setSelectedEpic] = useState(null);
  const [isEpicEditModalOpen, setIsEpicEditModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchEpics();
  }, [filters]);

  const fetchEpics = async () => {
    setLoading(true);
    try {
      const { data, ok } = await api.post("/epic/search", { project_id: project._id, ...filters });
      if (!ok) return toast.error("Failed to fetch roadmaps");

      setEpics(addPoints(data));

      const newSearchParams = new URLSearchParams(searchParams);
      if (filters.statuses.length > 0) newSearchParams.set("statuses", filters.statuses.join(","));
      if (filters.urgent.length > 0) newSearchParams.set("urgent", filters.urgent.join(","));
      if (filters.modules.length > 0) newSearchParams.set("modules", filters.modules.join(","));
      setSearchParams(newSearchParams);
    } catch (error) {
      console.error(error);
      toast.error("Failed to fetch roadmaps");
    } finally {
      setLoading(false);
    }
  };

  const handleMultipleDelete = async () => {
    try {
      if (!window.confirm("Are you sure you want to delete selected epics?")) return;

      for (const epicId of selectedEpics) {
        console.log(epicId);
        const { ok } = await api.remove(`/epic/${epicId}`);
        if (!ok) {
          toast.error(`Failed to delete epic ${epicId}`);
          continue;
        }
      }
      toast.success("Epics deleted successfully");
      fetchEpics();
      setSelectedEpics([]);
    } catch (error) {
      console.error(error);
      toast.error("Failed to delete epics");
    }
  };

  const handleSave = async (itemId, field, value) => {
    try {
      const { ok, data } = await api.put(`/epic/${itemId}`, { [field]: value });
      if (!ok) return toast.error("Failed to update");

      const index = epics.findIndex((item) => item._id === itemId);
      const newEpics = [...epics];
      newEpics[index] = { ...newEpics[index], [field]: value };
      setEpics(addPoints(newEpics));
      toast.success(`${field} updated successfully`);
    } catch (error) {
      console.error(error);
      toast.error("Failed to update");
    }
  };

  const addPoints = (arr1) => {
    const arr = [...arr1];
    for (let i = 0; i < arr.length; i++) {
      //= (IF(B2="XL",1,0) + IF(B2="L",2,0) + IF(B2="M",3,0) + IF(B2="S",4,0)  + IF(B2="XS",5,0))*(IF(C2="XL",5,0) + IF(C2="L",4,0) + IF(C2="M",3,0) + IF(C2="S",2,0)  + IF(C2="XS",1,0))  + (IF(D2="YES",100,0))

      let chargePoints = 0;
      if (arr[i].charge === "XL") chargePoints += 1;
      if (arr[i].charge === "L") chargePoints += 2;
      if (arr[i].charge === "M") chargePoints += 3;
      if (arr[i].charge === "S") chargePoints += 4;
      if (arr[i].charge === "XS") chargePoints += 5;

      let businessPoints = 0;
      if (arr[i].business_contribution === "XL") businessPoints += 5;
      if (arr[i].business_contribution === "L") businessPoints += 4;
      if (arr[i].business_contribution === "M") businessPoints += 3;
      if (arr[i].business_contribution === "S") businessPoints += 2;
      if (arr[i].business_contribution === "XS") businessPoints += 1;

      let basePoints = chargePoints * businessPoints;
      let urgentPoints = arr[i].urgent ? 100 : 0;
      arr[i].points = basePoints + urgentPoints;

      let days_required = 0;
      if (arr[i].charge === "XL") days_required += 20;
      if (arr[i].charge === "L") days_required += 15;
      if (arr[i].charge === "M") days_required += 10;
      if (arr[i].charge === "S") days_required += 5;
      if (arr[i].charge === "XS") days_required += 1;
      arr[i].days_required = days_required;
    }
    return arr;
  };

  return (
    <div className="space-y-4">
      <div className="bg-white p-4 rounded border border-gray-200">
        <div className="flex items-start gap-4">
          <div className="w-full flex justify-between gap-4">
            <div className="grid grid-cols-4 gap-2 w-4/5">
              <DebounceInput
                debounce={300}
                className="inline-flex justify-between items-center h-[38px] gap-4 px-2 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                placeholder="Search by title..."
                value={filters.search}
                onChange={(e) => setFilters((prev) => ({ ...prev, search: e.target.value, page: 1 }))}
              />

              <SelectModule value={filters.modules} onChange={(e) => setFilters((f) => ({ ...f, modules: e }))} project={project} />

              <MultiSelect
                id="select-status"
                options={["TODO", "IN_PROGRESS", "DONE", "NEED_INFO"].map((status) => ({ value: status, label: status }))}
                values={filters.statuses?.map((status) => ({ value: status, label: status })) || []}
                onSelectedChange={(e) => setFilters((f) => ({ ...f, statuses: e.map((e) => e.value) }))}
                placeholder="Status"
              />

              <MultiSelect
                id="select-urgent"
                options={[
                  { value: "yes", label: "Yes" },
                  { value: "no", label: "No" },
                ]}
                values={filters.urgent?.map((urgent) => ({ value: urgent, label: urgent })) || []}
                onSelectedChange={(e) => setFilters((f) => ({ ...f, urgent: e.map((e) => e.value) }))}
                placeholder="Urgence"
              />
            </div>
            <div className="flex gap-4 mb-2 w-1/5">
              <button
                onClick={async () => {
                  const { ok, data } = await api.post("/epic", { project_id: project._id });
                  if (!ok) return toast.error("Failed to create epic");
                  toast.success("Epic created successfully");
                  fetchEpics();
                  setSelectedEpic(data);
                  setIsEpicEditModalOpen(true);
                }}
                className="blue-btn w-full h-[38px]">
                New Epic
              </button>
              <MultiAction selectedEpics={selectedEpics} epics={epics} project={project} handleMultipleDelete={handleMultipleDelete} />
            </div>
          </div>
        </div>
      </div>
      {isEpicEditModalOpen && (
        <EpicEditModal
          epic={selectedEpic}
          onClose={(e) => {
            if (!selectedEpic) return;
            const index = epics.findIndex((item) => item._id === selectedEpic._id);
            const newEpics = [...epics];
            newEpics[index] = { ...newEpics[index], ...e };
            setEpics(addPoints(newEpics));
            setSelectedEpic(null);
            setIsEpicEditModalOpen(false);
          }}
          onDelete={() => {
            const newEpics = epics.filter((item) => item._id !== selectedEpic._id);
            setEpics(newEpics);
            setSelectedEpic(null);
            setIsEpicEditModalOpen(false);
          }}
        />
      )}
      <Table
        total={epics.length}
        header={[
          {
            title: (
              <input
                type="checkbox"
                className="w-4 h-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedEpics(epics.map((epic) => epic._id));
                  } else {
                    setSelectedEpics([]);
                  }
                }}
              />
            ),
            position: "center",
          },
          { title: "#", key: "index" },
          { title: "Personae", key: "persona" },
          { title: "Module", key: "module" },
          { title: "Title", key: "title" },
          { title: "Charge", key: "charge" },
          { title: "Apport Business", key: "business_contribution" },
          { title: "Urgent", key: "urgent" },
          { title: "Points", key: "points" },
          { title: "Status", key: "status" },
        ]}
        loading={loading}
        height="h-[32rem]"
        sort={{ [sortBy.key]: sortBy.value }}
        onSort={(e) => setSortBy(sortBy.key === e ? { key: e, value: sortBy.value * -1 } : { key: e, value: 1 })}>
        <AnimatePresence>
          {epics
            .sort((a, b) => {
              if (sortBy.key === "created_at") return sortBy.value * (new Date(b.created_at) - new Date(a.created_at));
              if (sortBy.key === "points") return sortBy.value * (b.points - a.points);
              return sortBy.value * (a[sortBy.key] || "").localeCompare(b[sortBy.key] || "");
            })
            .map((item, index) => {
              return (
                <motion.tr
                  key={item._id}
                  layout
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.3 }}
                  className={`${index % 2 === 0 ? "bg-gray-100" : "bg-gray-50"} cursor-pointer`}>
                  <td
                    className="border px-4 py-2 cursor-pointer"
                    onClick={(e) => {
                      if (selectedEpics.includes(item._id)) {
                        setSelectedEpics(selectedEpics.filter((id) => id !== item._id));
                      } else {
                        setSelectedEpics([...selectedEpics, item._id]);
                      }
                    }}>
                    <input
                      type="checkbox"
                      className="w-4 h-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                      checked={selectedEpics.includes(item._id)}
                      onChange={() => {}}
                    />
                  </td>
                  <td
                    onClick={() => {
                      setSelectedEpic(item);
                      setIsEpicEditModalOpen(true);
                    }}
                    className="border px-4 py-2 max-w-xs truncate">
                    {item.index}
                  </td>
                  <td
                    onClick={() => {
                      setSelectedEpic(item);
                      setIsEpicEditModalOpen(true);
                    }}
                    className="border px-4 py-2 max-w-xs truncate">
                    {item.persona}
                  </td>
                  <td
                    onClick={() => {
                      setSelectedEpic(item);
                      setIsEpicEditModalOpen(true);
                    }}
                    className="border px-4 py-2 max-w-xs truncate">
                    {item.module}
                  </td>
                  <td
                    onClick={() => {
                      setSelectedEpic(item);
                      setIsEpicEditModalOpen(true);
                    }}
                    className="border px-4 py-2 text-sm hover:bg-gray-200">
                    {item.title}
                  </td>
                  <EditableSelectCell itemId={item._id} field="charge" value={item.charge} options={["XS", "S", "M", "L", "XL"]} onSave={handleSave} />
                  <EditableSelectCell
                    itemId={item._id}
                    field="business_contribution"
                    value={item.business_contribution}
                    options={["XS", "S", "M", "L", "XL"]}
                    onSave={handleSave}
                  />
                  <EditableSelectCell itemId={item._id} field="urgent" value={item.urgent ? "yes" : "no"} options={["yes", "no"]} onSave={handleSave}>
                    <UrgentTag item={item} />
                  </EditableSelectCell>
                  <td className="border px-4 py-2 max-w-xs truncate">{item.points}</td>
                  <EditableSelectCell itemId={item._id} field="status" value={item.status} options={["TODO", "IN_PROGRESS", "NEED_INFO", "DONE"]} onSave={handleSave}>
                    <StatusTag item={item} />
                  </EditableSelectCell>
                </motion.tr>
              );
            })}
        </AnimatePresence>
      </Table>
    </div>
  );
};

const EditableSelectCell = ({ itemId, field, value, options, onSave, children }) => {
  const [editing, setEditing] = useState(false);
  const [currentValue, setCurrentValue] = useState(value);
  const cellRef = useRef(null);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  const handleSave = async (e) => {
    const newValue = e.target.value;
    setCurrentValue(newValue);
    setEditing(false);
    await onSave(itemId, field, newValue);
  };

  const handleClickOutside = (event) => {
    if (cellRef.current && !cellRef.current.contains(event.target)) {
      setEditing(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <td ref={cellRef} onClick={() => setEditing(true)} onBlur={() => setEditing(false)} className="border px-4 py-2 max-w-xs truncate text-sm hover:bg-gray-200">
      {editing ? (
        <select className="input p-1" value={currentValue} onChange={handleSave}>
          {options.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      ) : (
        children || currentValue
      )}
    </td>
  );
};

const StatusTag = ({ item }) => {
  if (!item?.status) return null;
  const colors = {
    TODO: "bg-blue-600 text-xs text-white border-gray-700",
    IN_PROGRESS: "bg-blue-100 text-xs text-blue-800",
    NEED_INFO: "bg-yellow-100 text-xs text-yellow-800",
    DONE: "bg-green-100 text-xs text-green-800",
  };
  return <div className={`px-2 py-1 rounded w-fit ${colors[item?.status]}`}>{item?.status}</div>;
};

const UrgentTag = ({ item }) => {
  const colors = {
    true: "bg-red-600 text-xs text-white border border-red-700",
    false: "bg-green-100 text-xs text-green-800 border border-green-200",
  };
  return <div className={`px-2 py-1 rounded w-fit ${colors[item?.urgent]}`}>{item?.urgent ? "Yes" : "No"}</div>;
};

const SelectModule = ({ value, onChange, project }) => {
  const [options, setOptions] = useState([]);
  const [lastFetch, setLastFetch] = useState(0);

  async function fetchModules() {
    const { data } = await api.post("/epic/aggregate", { project_id: project._id, key: "module" });
    const arr = data.filter((e) => e._id !== null).map((e) => ({ label: e._id, value: e._id, count: e.count }));
    setOptions(arr);
    setLastFetch(Date.now());
  }

  useEffect(() => {
    // Initial fetch when component mounts
    fetchModules();
  }, [project._id]);

  // Refetch when component gains focus if more than 5 seconds have passed
  useEffect(() => {
    function handleFocus() {
      if (Date.now() - lastFetch > 5000) {
        fetchModules();
      }
    }
    window.addEventListener("focus", handleFocus);
    return () => window.removeEventListener("focus", handleFocus);
  }, [lastFetch]);

  return (
    <MultiSelect
      id="select-modules"
      options={options}
      values={value?.map((tag) => ({ value: tag, label: tag }))}
      onSelectedChange={(e) => onChange(e.map((e) => e.value))}
      placeholder="Module"
    />
  );
};
