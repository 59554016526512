import { format, isWithinInterval, subDays } from "date-fns";

const Payments = ({ banks }) => {
  const isWithinLastWeek = (date) => {
    return isWithinInterval(new Date(date), {
      start: subDays(new Date(), 7),
      end: new Date(),
    });
  };

  if (banks.length === 0) return <div className="text-center text-gray-500">No payments found</div>;

  return (
    <div className="bg-white rounded-md border border-neutral-300 overflow-hidden">
      <div className="p-4 border-b border-gray-200">
        <h2 className="text-xl font-semibold">Payment History</h2>
      </div>

      <div className="overflow-x-auto">
        <table className="w-full">
          <thead>
            <tr className="bg-gray-50 text-gray-600 text-sm">
              <th className="text-left p-4 font-medium">Name</th>
              <th className="text-left p-4 font-medium">Date</th>
              <th className="text-left p-4 font-medium">Amount</th>
            </tr>
          </thead>
          <tbody>
            {banks.map((e) => {
              const isRecent = isWithinLastWeek(e.date);
              return (
                <tr key={e._id} className={`border-t border-gray-100 hover:bg-gray-50 transition-colors ${isRecent ? "bg-blue-50" : ""}`}>
                  <td className="p-4">
                    <div className={`font-medium ${isRecent ? "text-blue-700" : ""}`}>
                      {e.name}
                      {isRecent && <span className="ml-2 text-xs bg-blue-100 text-blue-800 px-2 py-1 rounded-full">Recent</span>}
                    </div>
                  </td>
                  <td className="p-4">
                    <div
                      className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${isRecent ? "bg-blue-200 text-blue-800" : "bg-blue-100 text-blue-800"}`}>
                      {format(new Date(e.date), "dd MMM yyyy")}
                    </div>
                  </td>
                  <td className="p-4">
                    <div className={`font-medium ${isRecent ? "text-green-700" : "text-green-600"}`}>{e.amount.toFixed(2)} EUR</div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Payments;
