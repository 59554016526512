import React, { useEffect, useState } from "react";
import { FaLinkedin, FaEnvelope, FaTimes, FaCheck } from "react-icons/fa";
import toast from "react-hot-toast";

import api from "../../../services/api";

import Table from "../../../components/Table";
import Paginator from "../../../components/Paginator";
import CompanyModal from "../component/companyModal";
import CreateContact from "../component/createContact";

export default () => {
  const [contacts, setContacts] = useState([]);
  const [filters, setFilters] = useState({ page: 1, search: "" });
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [selectedContact, setSelectedContact] = useState(null);

  useEffect(() => {
    fetch();
  }, [filters]);

  async function fetch() {
    setLoading(true);
    const newFilters = {};
    newFilters.skip = (filters.page - 1) * 100;
    if (filters.search) newFilters.search = filters.search;

    newFilters.personnae = "GOVERNMENT";

    console.log("newFilters", newFilters);

    const { data, total, ok } = await api.post("/s_contact/search", newFilters);
    if (!ok) return toast.error("Error fetching contacts");
    setContacts(data);
    setTotal(total);
    setLoading(false);
  }

  if (loading) return <div>Loading...</div>;

  return (
    <div className="">
      <div className="flex justify-between items-center">
        <Search setFilters={setFilters} filters={filters} />
        <CreateContact defaultValues={{ personnae: "GOVERNMENT" }} onDone={fetch} />
      </div>
      <CompanyModal contact={selectedContact} setContact={setSelectedContact} onClose={() => setSelectedContact(null)} />
      <Table
        total={total}
        header={[
          { title: "Name", key: "name" },
          { title: "Job title", key: "title" },
          { title: "Status ", key: "status" },
          { title: "Channels ", key: "channel" },
          { title: "Updated at ", key: "updated_at" },
          { title: "Created at ", key: "created_at" },
        ]}
        loading={loading}
        height="h-[32rem]"
        sticky={true}
        noResultRender={<div className="w-full bg-white text-center p-2 my-5 rounded shadow-sm">Aucune résultat n'a été trouvé</div>}>
        {contacts.map((item, index) => {
          return (
            <tr
              className={`${index % 2 === 0 ? "bg-gray-100" : "bg-gray-50"} cursor-pointer`}
              key={item._id}
              onClick={async (e) => {
                try {
                  e.stopPropagation();
                  // if (!item.companies.length) return toast.error("No company found");
                  // console.log(item);
                  // const res = await api.get(`/s_company/${item.companies[0]?.company_id}`);
                  // if (!res.ok) return toast.error("Error fetching company");
                  setSelectedContact(item);
                } catch (error) {
                  toast.error("Error fetching company");
                }
              }}>
              <td className="border hover:bg-gray-200 px-4 py-2 max-w-xs truncate">
                <div className="flex items-center gap-2">
                  <img
                    src={item.picture || "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y"}
                    className="h-8 w-8 rounded-full object-cover"
                    alt={`${item.first_name} ${item.last_name}`}
                  />
                  <span>
                    {item.first_name} {item.last_name}
                  </span>
                </div>
              </td>
              <td className="border hover:bg-gray-200 px-4 py-2 ">
                <div className="text-base ">{item.job_title}</div>
              </td>
              <td className="border hover:bg-gray-200 px-4 py-2 max-w-xs truncate">
                {item.status && (
                  <span
                    className={`px-2 py-1 text-xs font-medium rounded-full ${
                      {
                        not_ready: "bg-gray-200 text-gray-800",
                        ready: "bg-blue-200 text-blue-800",
                        in_progress: "bg-yellow-200 text-yellow-800",
                        todo: "bg-purple-200 text-purple-800",
                        follow_up_1: "bg-indigo-200 text-indigo-800",
                        standby: "bg-orange-200 text-orange-800",
                        lead: "bg-green-200 text-green-800",
                        lost: "bg-red-200 text-red-800",
                      }[item.status] || "bg-gray-200 text-gray-800"
                    }`}>
                    {item.status}
                  </span>
                )}
              </td>
              <td className="border hover:bg-gray-200 px-4 py-2 max-w-xs truncate">
                <div className="flex flex-col gap-2">
                  <div className="flex items-center gap-1">
                    <span className="text-base flex gap-1">
                      {item.linkedin && <FaLinkedin className="text-gray-600 text-base" />}
                      {item.status_linkedin_visit_profile ? <FaCheck className="text-green-500" /> : <FaTimes className="text-red-500" />}
                      {item.status_linkedin_invite_sent ? <FaCheck className="text-green-500" /> : <FaTimes className="text-red-500" />}
                      {item.status_linkedin_invite_accepted ? <FaCheck className="text-green-500" /> : <FaTimes className="text-red-500" />}
                      {item.status_linkedin_message_sent ? <FaCheck className="text-green-500" /> : <FaTimes className="text-red-500" />}
                      {item.email && <FaEnvelope className="text-gray-600 text-base" />}
                    </span>
                  </div>
                </div>
              </td>
              <td className="border hover:bg-gray-200 px-4 py-2 max-w-xs truncate">
                {(() => {
                  const updated = new Date(item.updated_at);
                  const now = new Date();
                  const diff = now - updated;
                  const minutes = Math.floor(diff / 60000);
                  const hours = Math.floor(minutes / 60);
                  const days = Math.floor(hours / 24);

                  if (days > 0) return `${days} days ago`;
                  if (hours > 0) return `${hours} hours ago`;
                  if (minutes > 0) return `${minutes} minutes ago`;
                  return "Just now";
                })()}
              </td>
              <td className="border hover:bg-gray-200 px-4 py-2 max-w-xs truncate">{new Date(item.created_at).toLocaleDateString()}</td>
            </tr>
          );
        })}
      </Table>
      <div className="mt-1">
        <Paginator page={filters.page} setPage={(page) => setFilters({ ...filters, page })} last={Math.ceil(total / 100)} />
      </div>
    </div>
  );
};

const Search = ({ filters, setFilters }) => {
  const [str, setStr] = useState("");
  useEffect(() => {
    setStr(filters.search || "");
  }, [filters.search]);
  return (
    <div className="flex items-center gap-2 p-2">
      <input type="text" className="flex-1 px-2 py-1 border rounded" placeholder="Search..." value={str} onChange={(e) => setStr(e.target.value)} />
      <button className="px-3 py-1 bg-blue-600 text-white rounded" onClick={() => setFilters({ ...filters, search: str })}>
        Search
      </button>
    </div>
  );
};
