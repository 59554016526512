import React, { useState, useEffect } from "react";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import RichTextEditorSun from "../../../../../components/RichTextEditor";
import Loader from "../../../../../components/loader";
import api from "../../../../../services/api";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { RiSendPlaneFill } from "react-icons/ri"; // Add this import at the top

export default ({ epic, setEpic }) => {
  const [comment, setComment] = useState("");
  const [comments, setComments] = useState(epic.comments || []);
  const [loading, setLoading] = useState(true);
  const user = useSelector((state) => state.Auth.user);

  const handleSubmit = async (e) => {
    e.preventDefault();
    onAdd(comment);
    setComment("");
  };

  const onAdd = async (e) => {
    const newComments = [...comments, { text: e, user_name: user.name, user_id: user._id, user_avatar: user.avatar }];
    const { data } = await api.put(`/epic/${epic._id}`, { comments: newComments });
    setEpic(data);
    toast.success("Comment added!");
  };

  const onDelete = async (id) => {
    const newComments = comments.filter((c) => c._id !== id);
    const { data } = await api.put(`/epic/${epic._id}`, { comments: newComments });
    setEpic(data);
    toast.success("Comment deleted!");
  };

  const getEpic = async () => {
    try {
      setLoading(true);
      const { data } = await api.get(`/epic/${epic._id}`);
      console.log("data", data);
      setComments(data.comments || []);
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    getEpic();
  }, []);

  if (loading) return <Loader />;

  return (
    <div className="flex-grow-0 ">
      <form onSubmit={handleSubmit} className="sticky bottom-0">
        <div className="flex flex-col gap-4">
          <div className="rounded-xl p-1">
            <RichTextEditorSun
              buttonList={[["bold", "italic", "link", "image"]]}
              values={comment}
              onChange={(e) => setComment(e)}
              placeholder="Type your message..."
              height="100px"
            />
          </div>
          <div className="flex justify-end">
            <button type="submit" disabled={!comment.trim()} className="btn btn-primary rounded-full px-6 py-3 flex items-center gap-2">
              <span>Send</span>
              <RiSendPlaneFill className="h-5 w-5" />
            </button>
          </div>
        </div>
      </form>
      <hr className="mx-8 my-4 border-gray-200" />
      <div className="flex flex-col gap-y-2 mb-4 overflow-y-auto">
        {(comments || [])
          .sort((a, b) => {
            return new Date(b.created_at) - new Date(a.created_at);
          })
          .map((comment, index) => (
            <div key={index} className="flex flex-col bg-gray-50 p-4 rounded-lg shadow-sm">
              <div className="flex items-start justify-between">
                <div className="flex items-center gap-3">
                  <img src={comment.user_avatar} className="rounded-full w-8 h-8 border-2 border-white shadow-sm" alt={comment.user_name} />
                  <div>
                    <div className="font-medium text-gray-900">{comment.user_name}</div>
                    <div className="text-xs text-gray-500" data-tip data-for={`comment-${comment._id}`}>
                      {moment(new Date(comment.created_at)).fromNow()}
                    </div>
                  </div>
                </div>

                {onDelete && (
                  <button
                    className="text-xs text-gray-400 hover:text-red-500 transition-colors"
                    onClick={() => {
                      if (!window.confirm("Are you sure you want to delete this comment?")) return;
                      onDelete(comment._id);
                    }}>
                    Delete
                  </button>
                )}
              </div>

              <div className="mt-3 text-gray-700 prose prose-sm max-w-none" dangerouslySetInnerHTML={{ __html: comment.text }} />

              <ReactTooltip id={`comment-${comment._id}`}>
                <span>{new Date(comment.created_at).toLocaleString()}</span>
              </ReactTooltip>
            </div>
          ))}
      </div>
    </div>
  );
};
