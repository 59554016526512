import React, { useState, useEffect } from "react";
import { format } from "date-fns";
import api from "../../services/api";

export default ({}) => {
  const [stats, setStats] = useState([]);
  const [activities, setActivities] = useState([]);
  const [budgets, setBudgets] = useState([]);
  const [occupationData, setOccupationData] = useState([]);
  const [userData, setUserData] = useState([]);

  const months = [...Array(6)].map((_, i) => {
    const date = new Date();
    date.setDate(1);
    date.setMonth(date.getMonth() + i);
    return date;
  });

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    const [budgetsData, occupationsData, usersData, activitiesData] = await Promise.all([
      loadBudgets(),
      loadOccupations(),
      loadUsers(),
      loadActivities()
    ]);

    if (budgetsData && occupationsData && usersData && activitiesData) {
      const newStats = months.map(month => {
        const theoreticalCosts = calculateTheoreticalCosts(usersData, occupationsData, month);
        const realCosts = calculateRealCosts(usersData, activitiesData, month);
        return calculateMonthStats(month, budgetsData, occupationsData, theoreticalCosts, realCosts);
      });
      setStats(newStats);
    }
  };

  const loadBudgets = async () => {
    const { data, ok } = await api.post("/budget/monthly-aggregated", {});
    if (!ok) return null;
    setBudgets(data);
    return data;
  };

  const loadOccupations = async () => {
    const { data, ok } = await api.post("/b_occupation/search", {});
    if (!ok) return null;
    setOccupationData(data);
    return data;
  };

  const loadUsers = async () => {
    const { data, ok } = await api.post("/user/search", {
      contract: [CONTRACTS.CDD, CONTRACTS.CDI, CONTRACTS.VIE, CONTRACTS.INTERNSHIP, CONTRACTS.APPRENTICESHIP, CONTRACTS.FREELANCE], 
      roles: ["user", "admin"],
      status: ["active"],
    });
    if (!ok) return null;
    setUserData(data.users);
    return data.users;
  };

  const loadActivities = async () => {
    const { data, ok } = await api.get("/activity/stats/by-user-and-budget-type", {});
    if (!ok) return null;
    setActivities(data);
    return data;
  };

  const calculateMonthStats = (month, budgetData, occupationData, theoreticalCosts, realCosts) => {
    const projectBudgets = budgetData.reduce((acc, item) => {
      if (item.projectName === "Unavailable") return acc;
      
      acc[item._id] = {
        projectName: item.projectName,
        budgets: item.budgets
      };
      return acc;
    }, {});

    let totalBudget = 0;
    let totalSell = 0;
    let projectCount = 0;

    Object.entries(projectBudgets).forEach(([projectId, { budgets }]) => {
      const monthlyBudget = calculateMonthlyAmount(budgets, month);
      if (monthlyBudget > 0) projectCount++;
      
      const people = getPeopleDetails(projectId, budgets, month, occupationData);
      totalBudget += monthlyBudget;
      totalSell += calculatePeopleSell(people);
    });

    return {
      month,
      projects: projectCount,
      budget: totalBudget,
      sell: totalSell,
      theoreticalCosts,
      realCosts,
    };
  };

  const calculateMonthlyAmount = (budgets, month) => {
    return budgets.reduce((total, budget) => {
      const startDate = new Date(budget.startAt);
      const endDate = new Date(budget.endAt);
      
      const monthsDiff = (endDate.getFullYear() - startDate.getFullYear()) * 12 + 
                        (endDate.getMonth() - startDate.getMonth()) + 1;
      const totalMonths = Math.max(1, monthsDiff);
      const monthlyAmount = budget.amount / totalMonths;

      const currentDate = new Date(month);
      currentDate.setDate(1);
      const startDateCompare = new Date(startDate);
      startDateCompare.setDate(1);
      const endDateCompare = new Date(endDate);
      endDateCompare.setDate(1);

      if (currentDate.getFullYear() === startDateCompare.getFullYear() && 
          currentDate.getMonth() === startDateCompare.getMonth() ||
          currentDate.getFullYear() === endDateCompare.getFullYear() && 
          currentDate.getMonth() === endDateCompare.getMonth() ||
          (currentDate > startDateCompare && currentDate < endDateCompare)) {
        return total + monthlyAmount;
      }
      return total;
    }, 0);
  };

  const getPeopleDetails = (projectId, budgets, month, occupations) => {
    const people = occupations.filter(o => 
      o.project_id === projectId && 
      new Date(o.month).getMonth() === month.getMonth() &&
      new Date(o.month).getFullYear() === month.getFullYear()
    );

    return people;
  };

  const calculatePeopleSell = (people) => {
    return people.reduce((total, person) => {
      if (!person.user_tjms) return total;
      const daysPerMonth = (person.percent / 100) * 23; 
      return total + (person.user_tjms * daysPerMonth);
    }, 0);
  };

  const calculateTheoreticalCosts = (userData, occupationData, month) => {
    return userData.reduce((total, user) => {
      if (user.contract === "FREELANCE" && user.payment_type === "DAILY") {

        const allocations = occupationData.filter(o => 
          o.user_id === user._id && 
          new Date(o.month).getMonth() === month.getMonth() &&
          new Date(o.month).getFullYear() === month.getFullYear()
        );
        
        const allocatedDays = allocations.reduce((days, allocation) => {
          return days + ((allocation.percent / 100) * 23);
        }, 0);

        return total + (user.tjm || 0) * allocatedDays;
      } else {
        return total + (user.total_monthly_cost || 0);
      }
    }, 0);
  };

  const calculateRealCosts = (userData, activityData, month) => {
    if (!activityData || !Array.isArray(userData) || !month) return 0;

    const monthKey = `${month.getFullYear()}-${String(month.getMonth() + 1).padStart(2, '0')}`;
    
    const userCosts = [];
    let total = 0;

    userData.forEach(user => {
      if (user.contract !== CONTRACTS.FREELANCE || user.payment_type !== "DAILY") {
        total += user.total_monthly_cost || 0;
        userCosts.push({
          name: user.name,
          cost: user.total_monthly_cost || 0,
          type: user.contract
        });
      }
    });

    // Then handle freelancers based on their activities
    Object.entries(activityData).forEach(([userName, userActivities]) => {
      if (userActivities[monthKey]) {
        const user = userData.find(u => u.name === userName);
        if (!user || user.contract !== CONTRACTS.FREELANCE || user.payment_type !== "DAILY") return;

        const totalHours = Object.values(userActivities[monthKey]).reduce((sum, hours) => sum + hours, 0);
        const days = totalHours / 8;
        const cost = days * (user.tjm || 0);
        
        userCosts.push({
          name: user.name,
          cost,
          hours: totalHours,
          days,
          type: user.contract
        });
        
        total += cost;
      }
    });

    return total;
  };

  return (
    <table className="w-full mb-8">
      <thead>
        <tr className="border-b">
          <th className="text-left p-2">Month</th>
          <th className="text-right p-2">Projects</th>
          <th className="text-right p-2">Project Budget</th>
          <th className="text-right p-2">Allocated Revenue</th>
          <th className="text-right p-2">Theoretical Costs</th>
          <th className="text-right p-2">Real Costs Current</th>
          <th className="text-right p-2">Gross Profit Min</th>
          <th className="text-right p-2">Gross Profit Max</th>
          <th className="text-right p-2">Expenses not related to people</th>
          <th className="text-right p-2">Net Profit Min</th>
          <th className="text-right p-2">Net Profit Max</th>
        </tr>
      </thead>
      <tbody>
        {stats.map(({ month, projects, budget, sell, theoreticalCosts, realCosts }) => {          
          const grossProfitMin = budget - Math.max(theoreticalCosts, realCosts);
          const grossProfitMax = sell - Math.min(theoreticalCosts, realCosts);
          
          return (
            <tr key={month.toString()} className="border-b hover:bg-gray-50">
              <td className="p-2 font-medium">
                {format(month, "MMM yyyy")}
              </td>
              <td className="p-2 text-right">
                {projects}
              </td>
              <td className="p-2 text-right font-medium">
                €{Math.round(budget).toLocaleString()}
              </td>
              <td className="p-2 text-right font-medium text-blue-600">
                €{Math.round(sell).toLocaleString()}
              </td>
              <td className="p-2 text-right font-medium text-gray-400">
                €{Math.round(theoreticalCosts).toLocaleString()}
              </td>
              <td className="p-2 text-right font-medium text-gray-600">
                €{Math.round(realCosts).toLocaleString()}
              </td>
              <td className="p-2 text-right font-medium text-red-600">
                €{Math.round(grossProfitMin).toLocaleString()}
              </td>
              <td className="p-2 text-right font-medium text-green-600">
                €{Math.round(grossProfitMax).toLocaleString()}
              </td>
              <td className="p-2 text-right font-medium text-gray-600">
                ?
              </td>
              <td className="p-2 text-right font-medium text-gray-600">
                ?
              </td>
              <td className="p-2 text-right font-medium text-gray-600">
                ?
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

const CONTRACTS = {
    CDD: "CDD",
    CDI: "CDI",
    VIE: "VIE",
    INTERNSHIP: "INTERNSHIP",
    APPRENTICESHIP: "APPRENTICESHIP",
    FREELANCE: "FREELANCE",
  };
  