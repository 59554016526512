import { useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../../services/api";
import LoadingButton from "../../../components/loadingButton";
import SelectProject from "../../../components/selectProject";
import UserSelector from "../../../components/selectUser";
import toast from "react-hot-toast";
import { IoMdCloseCircle } from "react-icons/io";

const Edit = ({ audit, setAudit }) => {
    const navigate = useNavigate();
    const [values, setValues] = useState(audit);
    const [btnLoading, setBtnLoading] = useState(false);
  
    const onUpdate = async () => {
      setBtnLoading(true);
      const { data, ok, code } = await api.put(`/audit/${audit._id}`, values);
      if (!ok) {
        toast.error(code || "Error updating!");
        setBtnLoading(false);
      } else {
        toast.success("Updated!");
        setBtnLoading(false);
        setAudit(data);
      }
    };
  
    const onDelete = async () => {
      const { ok } = await api.remove(`/audit/${audit._id}`);
      if (!ok) return toast.error("Error deleting audit!");
      toast.success("Deleted!");
      navigate("/audit");
    };
  
    return (
      <div className="">
        <div className="grid grid-cols-2 gap-6">
          <div className="w-ful">
            <div className="font-medium mb-2 text-sm">Select Project</div>
            <SelectProject className="w-full" value={values.project_id} onChange={(e) => setValues({ ...values, project_name: e.name, project_id: e._id })} />
          </div>
  
          <div className="w-full">
            <div className="font-medium mb-2 text-sm">Select Auditor</div>
            <UserSelector
              placeholder="Select Auditor"
              className="w-full"
              value={{
                _id: values.author_id,
                name: values.author_name,
                avatar: values.author_avatar,
              }}
              onChange={(e) => {
                setValues({ ...values, author_avatar: e.avatar, author_name: e.name, author_id: e._id });
                console.log(e);
              }}
            />
          </div>
  
          <div className="grid grid-cols-3 gap-3">
            <div className="w-full mb-3">
              <div className="font-medium mb-2 text-sm">Last Audit Date</div>
              <input
                className="input block"
                placeholder="Select Date"
                type="date"
                value={values?.last_audit_date ? values?.last_audit_date?.slice(0, 10) : ""}
                onChange={(e) => setValues({ ...values, last_audit_date: e.target.value })}
              />
            </div>
  
            <div className="w-full">
              <div className="text-sm font-medium mb-2">Audit Status</div>
              <select className="input" value={values.status || ""} onChange={(e) => setValues({ ...values, status: e.target.value })}>
                <option value="" disabled>
                  Select
                </option>
                {["todo", "audited", "addressed"].map((status) => (
                  <option key={status} value={status}>
                    {status}
                  </option>
                ))}
              </select>
            </div>
  
            <div className="w-full">
              <div className="text-sm font-medium mb-2">Effort to Audit</div>
              <select className="input" value={values.effort_to_audit || ""} onChange={(e) => setValues({ ...values, effort_to_audit: e.target.value })}>
                <option value="" disabled>
                  Select
                </option>
                {["S", "M", "L", "XL"].map((effort) => (
                  <option key={effort} value={effort}>
                    {effort}
                  </option>
                ))}
              </select>
            </div>
          </div>
  
          Criticality
          <div className="grid grid-cols-3 gap-3">
            <div className="w-full">
              <div className="text-sm font-medium mb-2">High Issues</div>
              <input type="number" className="input" value={values.high_issues || 0} onChange={(e) => setValues({ ...values, high_issues: e.target.value })} />
            </div>
  
            <div className="w-full">
              <div className="text-sm font-medium mb-2">Medium Issues</div>
              <input type="number" className="input" value={values.medium_issues || 0} onChange={(e) => setValues({ ...values, medium_issues: e.target.value })} />
            </div>
  
            <div className="w-full">
              <div className="text-sm font-medium mb-2">Low Issues</div>
              <input type="number" className="input" value={values.low_issues || 0} onChange={(e) => setValues({ ...values, low_issues: e.target.value })} />
            </div>
          </div>
          
  
            Type of Issues
            <div className="grid grid-cols-2 gap-3">
              <div className="w-full">
                <div className="text-sm font-medium mb-2">Code Complexity Issues</div>
                <input 
                  type="number" 
                  className="input" 
                  value={values.complexity_issues || 0} 
                  onChange={(e) => setValues({ ...values, complexity_issues: e.target.value })} 
                />
              </div>
  
              <div className="w-full">
                <div className="text-sm font-medium mb-2">SELEGO Convention Issues</div>
                <input 
                  type="number" 
                  className="input" 
                  value={values.convention_issues || 0} 
                  onChange={(e) => setValues({ ...values, convention_issues: e.target.value })} 
                />
              </div>
  
              <div className="w-full">
                <div className="text-sm font-medium mb-2">Code Inconsistency Issues</div>
                <input 
                  type="number" 
                  className="input" 
                  value={values.inconsistency_issues || 0} 
                  onChange={(e) => setValues({ ...values, inconsistency_issues: e.target.value })} 
                />
              </div>
  
              <div className="w-full">
                <div className="text-sm font-medium mb-2">Red Flags</div>
                <input 
                  type="number" 
                  className="input" 
                  value={values.red_flags || 0} 
                  onChange={(e) => setValues({ ...values, red_flags: e.target.value })} 
                />
              </div>
            </div>
  
          <div className="w-full">
            <div className="text-sm font-medium mb-2">Audit Document</div>
            <input
              type="text"
              placeholder="Enter document link..."
              className="input"
              value={values.audit_document || ""}
              onChange={(e) => setValues({ ...values, audit_document: e.target.value })}
            />
          </div>
  
          <div className="w-full">
            <div className="text-sm font-medium mb-2">Tracker</div>
            <input
              type="text"
              placeholder="Enter sheets link..."
              className="input"
              value={values.tracker || ""}
              onChange={(e) => setValues({ ...values, tracker: e.target.value })}
            />
          </div>
          <div className="w-full">
            <div className="text-sm font-medium mb-2">Github Link</div>
            <input
              type="text"
              placeholder="Enter github link..."
              className="input"
              value={values.github_link || ""}
              onChange={(e) => setValues({ ...values, github_link: e.target.value })}
            />
          </div>
  
          <div>
            <div className="text-sm font-medium mb-2">Team on time of audit</div>
            <UserSelector
              placeholder="Select team member"
              onChange={(user) => {
                if (!user?._id) return;
                const newUsers = values?.team.filter((u) => u._id !== user._id);
                newUsers.push({
                  _id: user._id,
                  name: user.name,
                  avatar: user.avatar,
                });
                setValues({ ...values, team: newUsers });
              }}
            />
          </div>
  
          <div className="flex flex-row gap-3 p-2 flex-wrap mt-3">
            {(values.team || []).map((user, index) => {
              return (
                <div key={index} className="flex justify-center items-center flex-col">
                  <div className="relative">
                    <img src={user.avatar} alt="User Avatar" className="w-12 h-12 rounded-full object-cover" />
                    <button
                      className="absolute -top-1 -right-1"
                      onClick={() => {
                        const newUsers = values?.team.filter((u) => u._id !== user._id);
                        setValues({ ...values, team: newUsers });
                      }}>
                      <IoMdCloseCircle className="text-gray-500 text-lg" />
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
  
        <div className="w-full mt-8 border-t pt-5 grid grid-cols-3 gap-3">
          <div className="w-full">
            <div className="text-sm font-medium mb-1">Sentry </div>
            <div className="flex gap-3 items-center">
              <label htmlFor="sentry_set_up" className="text-xs text-gray-600">
                Does this project have the Sentry setup ready?
              </label>
              <input
                type="checkbox"
                name="sentry_set_up"
                id="sentry_set_up"
                checked={values?.sentry}
                value={values?.sentry}
                onChange={(e) => setValues({ ...values, sentry: e.target.checked })}
              />
            </div>
          </div>
          <div className="w-full">
            <div className="text-sm font-medium mb-1">Uptime Robot </div>
            <div className="flex gap-3 items-center">
              <label htmlFor="timerobot" className="text-xs text-gray-600">
                Does this project have Uptime Robot setup ready?
              </label>
              <input
                type="checkbox"
                name="timerobot"
                id="timerobot"
                checked={values?.timerobot}
                value={values?.timerobot}
                onChange={(e) => setValues({ ...values, timerobot: e.target.checked })}
              />
            </div>
          </div>
  
          <div className="w-full">
            <div className="text-sm font-medium mb-1">Selego Stack </div>
            <div className="flex gap-3 items-center">
              <label htmlFor="selego_stack" className="text-xs text-gray-600">
                Is this project built with the Selego stack?
              </label>
              <input
                type="checkbox"
                name="selego_stack"
                id="selego_stack"
                checked={values?.selego_stack}
                value={values?.selego_stack}
                onChange={(e) => setValues({ ...values, selego_stack: e.target.checked })}
              />
            </div>
          </div>
        </div>
  
        <div className="mt-5 w-1/3">
          <div className="text-sm font-medium mb-2">Hosted On</div>
          <select className="input" value={values?.hosted_on || ""} onChange={(e) => setValues({ ...values, hosted_on: e.target.value })}>
            <option value="" disabled>
              Select
            </option>
            {["scaleway", "clevercloud"].map((hosted_on) => (
              <option key={hosted_on} value={hosted_on}>
                {hosted_on}
              </option>
            ))}
          </select>
        </div>
  
        <div className="w-full mt-8 border-t pt-5">
          <div className="text-sm font-medium mb-2">Summary</div>
          <textarea
            placeholder="Enter summary..."
            className="input"
            name="summary"
            id="summary"
            onChange={(e) => setValues({ ...values, summary: e.target.value })}
            value={values?.summary}></textarea>
        </div>
  
  
        <div className="w-full mt-8 border-t pt-5">
          <div className="text-sm font-medium mb-2">InternalNotes</div>
          <textarea
            placeholder="Enter notes..."
            className="input"
            name="notes"
            id="notes"
            onChange={(e) => setValues({ ...values, note: e.target.value })}
            value={values?.note}></textarea>
        </div>
  
        <div className="flex items-center justify-between space-x-2 mt-10 border-t pt-8">
          <button className="btn bg-red-500 text-white" onClick={onDelete}>
            Delete
          </button>
  
          <LoadingButton className="btn bg-primary text-white" loading={btnLoading} onClick={() => onUpdate()}>
            Update
          </LoadingButton>
        </div>
      </div>
    );
  };





export default Edit;
