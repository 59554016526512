import React, { useState, useRef, useCallback, useEffect } from "react";
import { toast } from "react-hot-toast";
import { debounce } from "lodash";
import { CgSpinner } from "react-icons/cg";
import { FaCircleCheck } from "react-icons/fa6";
import { useSelector } from "react-redux";
import { HiOutlineArrowLongLeft, HiOutlineArrowLongRight } from "react-icons/hi2";
import { TbAlignCenter } from "react-icons/tb";

import { useEditor, EditorContent, BubbleMenu } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Image from "@tiptap/extension-image";
import Underline from "@tiptap/extension-underline";
import TextAlign from "@tiptap/extension-text-align";
import Link from "@tiptap/extension-link";
import Strike from "@tiptap/extension-strike";
import CodeBlock from "@tiptap/extension-code-block";
import Code from "@tiptap/extension-code";
import HorizontalRule from "@tiptap/extension-horizontal-rule";
import Color from "@tiptap/extension-color";
import TextStyle from "@tiptap/extension-text-style";
import Highlight from "@tiptap/extension-highlight";
import HardBreak from "@tiptap/extension-hard-break";

import api from "../../../../services/api";
import { readFileAsync } from "../../../../utils";

import plugin_comment from "./plugin_comment";
// import plugin_linter from "./plugin_linter";
import plugin_image from "./plugin_image";

import Menu from "./menu";

const commentStyles = `
 .comment-mark {
    background-color: rgba(255, 255, 0, 0.2);
    cursor: pointer;
  }
  .ProseMirror h1 {
    border-left: 4px solid #3b82f6;
    padding-left: 1rem;
  }

  .ProseMirror h2 {
    border-left: 3px solid #60a5fa;
    padding-left: 1rem;
  }
  .ProseMirror h3 {
    border-left: 2px solid #93c5fd;
    padding-left: 1rem;
  }
`;

export default ({ article, setArticle }) => {
  const user = useSelector((state) => state.Auth.user);
  const imageButtonRef = useRef();
  const [content, setContent] = useState(article?.content || "");
  const [comments, setComments] = useState([]);
  const [saveStatus, setSaveStatus] = useState("saved"); // "saved", "saving"
  const [selectedCommentId, setSelectedCommentId] = useState("open");
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    loadComments();
  }, [article?._id]);

  async function loadComments() {
    try {
      if (!article?._id) return;
      const { data } = await api.post("/article_comment/search", { article_id: article._id });
      setComments(data);
    } catch (error) {
      console.error("Failed to load comments:", error);
      toast.error("Failed to load comments");
    }
  }

  const debouncedUpdateArticle = useRef(
    debounce(async (articleData) => {
      try {
        setSaveStatus("saving");
        const { data } = await api.put(`/article/${articleData._id}`, articleData);
        setArticle(data);
        setSaveStatus("saved");
        console.log("💐 Content Updated", article?.content);
      } catch (error) {
        console.error("Failed to update article:", error);
        toast.error("Failed to save changes");
      }
    }, 1000),
  ).current;

  const editor = useEditor({
    extensions: [
      StarterKit.configure({
        history: true,
        image: false,
      }),
      plugin_comment,
      plugin_image.configure({
        onImageClick: (node) => {
          setSelectedImage(node);
          setSelectedCommentId(null); // Masquer les commentaires
        },
      }),
      Underline,
      // plugin_linter,
      TextStyle,
      Color,
      Highlight.configure({ multicolor: true }),
      TextAlign.configure({ types: ["heading", "paragraph"] }),
      Link.configure({ openOnClick: false }),
    ],
    content,
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      setContent(html);
      if (setArticle) {
        const updatedArticle = { ...article, content: html };
        setArticle(updatedArticle);
        debouncedUpdateArticle(updatedArticle);
      }
    },
  });

  if (!editor) return null;

  return (
    <div className="flex flex-col h-[calc(100vh-130px)]">
      {/* Title Bar */}
      <div className="bg-gray-50 border-b p-3">
        <h1 className="text-base font-semibold text-gray-800">{article?.title || "Untitled Article"}</h1>
      </div>

      {/* Main Content */}
      <div className="flex flex-1 overflow-hidden">
        {/* Left Column - Editor */}
        <div className="flex flex-col flex-1 border-r">
          <style>{commentStyles}</style>
          <Menu editor={editor} />
          <div className="prose max-w-none no-tailwind flex-1 overflow-auto bg-white">
            <EditorContent editor={editor} />
            <CustomBubbleMenu
              editor={editor}
              onAddComment={async (comment) => {
                const { data } = await api.post("/article_comment", {
                  article_id: article._id,
                  text: comment,
                  user_id: user._id,
                  user_name: user.name,
                  user_avatar: user.avatar,
                  status: "open",
                });
                setComments([data, ...comments]);
                editor.commands.setComment(data._id);
                toast.success("Comment added");
              }}
            />
          </div>
        </div>

        {/* Right Column - Comments & Status */}
        <div className="w-80 flex flex-col bg-gray-50">
          <div className="border-b p-3">
            <Loading saveStatus={saveStatus} />
          </div>
          <div className="flex-1 overflow-auto">
            {selectedImage ? (
              <ImageAttributesMenu
                node={selectedImage}
                updateAttributes={(attrs) => {
                  editor.chain().focus().updateAttributes("image", attrs).run();
                }}
                onClose={() => setSelectedImage(null)}
              />
            ) : (
              <>
                <div className="sticky top-0 bg-gray-50 py-4 p-2">
                  <div className="flex bg-gray-200 rounded-xl p-1 w-fit">
                    <button
                      className={`px-2 text-sm py-1 rounded-lg transition-all duration-200 ${selectedCommentId === "open" ? "bg-white text-black" : ""}`}
                      onClick={() => setSelectedCommentId("open")}>
                      Open
                    </button>
                    <button
                      className={`px-2 text-sm py-1 rounded-lg transition-all duration-200 ${selectedCommentId === "resolved" ? "bg-white text-black" : ""}`}
                      onClick={() => setSelectedCommentId("resolved")}>
                      Resolved
                    </button>
                  </div>
                </div>
                <div className="px-2 pb-5">
                  {comments.map((comment) => {
                    if (selectedCommentId === "resolved" && comment.status === "open") return null;
                    if (selectedCommentId === "open" && comment.status === "resolved") return null;
                    return (
                      <div
                        key={comment._id}
                        className="p-3 border rounded-lg mb-2 bg-white hover:border-gray-400"
                        onClick={() => {
                          const element = document.querySelector(`[data-comment-id="${comment._id}"]`);
                          if (element) {
                            element.style.backgroundColor = "rgba(147, 51, 234, 0.2)"; // Light purple background
                            element.scrollIntoView({ behavior: "smooth" });
                            // Reset highlight after a delay
                            setTimeout(() => {
                              element.style.backgroundColor = "rgba(255, 255, 0, 0.2)"; // Reset to original yellow
                            }, 3000);
                          }
                        }}>
                        <div className="flex justify-between items-start mb-2">
                          <div>
                            <div className="font-medium text-sm">{comment.user_name}</div>
                            <div className="text-xs text-gray-500">{new Date(comment.created_at).toLocaleString()}</div>
                          </div>
                          {comment.status === "open" && (
                            <button
                              onClick={async () => {
                                const { data } = await api.put(`/article_comment/${comment._id}`, { status: "resolved" });
                                toast.success("Comment resolved");
                                editor.commands.removeCommentById(comment._id);
                                setComments(comments.map((c) => (c._id === comment._id ? data : c)));
                              }}
                              className="text-xs border text-black border-gray-300 hover:bg-gray-100 px-2 py-1 rounded">
                              Resolve
                            </button>
                          )}
                        </div>
                        <div className="text-sm">{comment.text}</div>
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const CustomBubbleMenu = ({ editor, onAddComment }) => {
  const [showModal, setShowModal] = useState(false);
  const [comment, setComment] = useState("");

  return (
    <>
      <BubbleMenu className="flex items-center gap-2 bg-gray-100 text-black shadow-lg border border-gray-200" tippyOptions={{ duration: 100 }} editor={editor}>
        <button onClick={() => setShowModal(true)} className="px-3 py-1 hover:text-gray-800">
          <span className="text-sm">Add a comment</span>
        </button>
      </BubbleMenu>

      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 w-96">
            <h3 className="text-lg font-medium mb-4">Add Comment</h3>
            <textarea value={comment} onChange={(e) => setComment(e.target.value)} className="w-full border rounded-lg p-2 mb-4 h-32" placeholder="Enter your comment..." />
            <div className="flex justify-end gap-2">
              <button onClick={() => setShowModal(false)} className="px-4 py-2 text-sm text-gray-600 hover:bg-gray-100 rounded">
                Cancel
              </button>
              <button
                onClick={() => {
                  onAddComment(comment);
                  setShowModal(false);
                }}
                className="px-4 py-2 text-sm bg-blue-500 text-white rounded hover:bg-blue-600">
                Add Comment
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const Loading = ({ saveStatus }) => {
  return (
    <div className="flex items-center justify-end gap-2">
      {saveStatus === "saving" && (
        <div className="flex items-center gap-2">
          <span className="text-xs text-gray-500">Saving</span>
          <CgSpinner className="animate-spin h-4 w-4 text-gray-500" />
        </div>
      )}
      {saveStatus === "saved" && (
        <div className="flex items-center gap-2">
          <span className="text-xs text-green-500">Saved</span>
          <FaCircleCheck className="h-3 w-3 text-green-500" />
        </div>
      )}
    </div>
  );
};

const ImageAttributesMenu = ({ node, updateAttributes, onClose }) => {
  const [alt, setAlt] = useState(node.attrs.alt || "");
  const [title, setTitle] = useState(node.attrs.title || "");
  const [alignment, setAlignment] = useState(node.attrs.alignment || "center");
  const [dimensions, setDimensions] = useState({
    width: node.attrs.width || "auto",
    height: node.attrs.height || "auto",
  });

  const [editingDimensions, setEditingDimensions] = useState({
    width: dimensions.width.replace("px", ""),
    height: dimensions.height.replace("px", ""),
  });

  const updateCurrentDimensions = useCallback(() => {
    const imgElement = document.querySelector(`img[src="${node.attrs.src}"]`);
    if (imgElement) {
      setDimensions({
        width: imgElement.style.width || "auto",
        height: imgElement.style.height || "auto",
      });
    }
  }, [node.attrs.src]);

  useEffect(() => {
    updateCurrentDimensions();
    const resizeObserver = new ResizeObserver(() => {
      updateCurrentDimensions();
    });

    const imgElement = document.querySelector(`img[src="${node.attrs.src}"]`);
    if (imgElement) {
      resizeObserver.observe(imgElement);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [node, updateCurrentDimensions]);

  useEffect(() => {
    setAlt(node.attrs.alt || "");
    setTitle(node.attrs.title || "");
    setAlignment(node.attrs.alignment || "center");
  }, [node.attrs]);

  const handleSubmit = (e) => {
    e.preventDefault();
    updateAttributes({ alt, title, alignment, ...dimensions });
    handleClose();
  };

  const handleAlignmentChange = (newAlignment) => {
    setAlignment(newAlignment);
    const newAttrs = {
      ...node.attrs,
      alignment: newAlignment,
      width: dimensions.width,
      height: dimensions.height,
    };
    updateAttributes(newAttrs);
  };

  const handleDimensionChange = (dimension, value) => {
    setEditingDimensions((prev) => ({
      ...prev,
      [dimension]: value,
    }));
  };

  const handleDimensionBlur = (dimension) => {
    const value = editingDimensions[dimension];
    const newValue = value ? `${value}px` : "auto";
    const newDimensions = {
      ...dimensions,
      [dimension]: newValue,
    };
    setDimensions(newDimensions);
    updateAttributes(
      {
        ...node.attrs,
        ...newDimensions,
        alignment,
      },
      { preventClose: true },
    );
  };

  const handleClose = () => {
    const imgElement = document.querySelector(`img[src="${node.attrs.src}"]`);
    if (imgElement) {
      imgElement.classList.remove("selected-image");
    }
    onClose();
  };

  return (
    <div className="w-80 flex flex-col bg-gray-50 h-full image-attributes-menu" onClick={(e) => e.stopPropagation()}>
      <div className="border-b p-3">
        <h3 className="text-base font-semibold text-gray-800">Image Properties</h3>
      </div>
      <div className="p-4">
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Dimensions</label>
            <div className="flex gap-2">
              <div>
                <label className="block text-xs text-gray-500 mb-1">Width</label>
                <input
                  type="text"
                  value={editingDimensions.width}
                  onChange={(e) => handleDimensionChange("width", e.target.value)}
                  onBlur={() => handleDimensionBlur("width")}
                  className="w-20 text-sm rounded-md border border-gray-300 px-2 py-1 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
                  placeholder="auto"
                />
              </div>
              <div>
                <label className="block text-xs text-gray-500 mb-1">Height</label>
                <input
                  type="text"
                  value={editingDimensions.height}
                  onChange={(e) => handleDimensionChange("height", e.target.value)}
                  onBlur={() => handleDimensionBlur("height")}
                  className="w-20 text-sm rounded-md border border-gray-300 px-2 py-1 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
                  placeholder="auto"
                />
              </div>
              <div className="flex items-end">
                <span className="text-xs text-gray-500 mb-1">px</span>
              </div>
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Alignment</label>
            <div className="flex gap-2">
              <button
                type="button"
                onClick={() => handleAlignmentChange("left")}
                className={`p-2 rounded-md flex items-center justify-center ${alignment === "left" ? "bg-blue-100 text-blue-600" : "bg-gray-100 text-gray-600 hover:bg-gray-200"}`}>
                <HiOutlineArrowLongLeft className="w-5 h-5" />
              </button>
              <button
                type="button"
                onClick={() => handleAlignmentChange("center")}
                className={`p-2 rounded-md flex items-center justify-center ${
                  alignment === "center" ? "bg-blue-100 text-blue-600" : "bg-gray-100 text-gray-600 hover:bg-gray-200"
                }`}>
                <TbAlignCenter className="w-5 h-5" />
              </button>
              <button
                type="button"
                onClick={() => handleAlignmentChange("right")}
                className={`p-2 rounded-md flex items-center justify-center ${
                  alignment === "right" ? "bg-blue-100 text-blue-600" : "bg-gray-100 text-gray-600 hover:bg-gray-200"
                }`}>
                <HiOutlineArrowLongRight className="w-5 h-5" />
              </button>
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Alt Text</label>
            <input
              type="text"
              value={alt}
              onChange={(e) => {
                setAlt(e.target.value);
              }}
              className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
              placeholder="Describe the image"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Title</label>
            <input
              type="text"
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
              placeholder="Image title"
            />
          </div>
          <div className="flex justify-end space-x-2">
            <button type="button" onClick={handleClose} className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200">
              Cancel
            </button>
            <button type="submit" className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700">
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
