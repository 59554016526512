import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import Board from "react-trello";
import { useLocation, useNavigate } from "react-router-dom";
import Card from "./kanban_card";
import api from "../../../../../services/api";
import { IoArrowUpOutline, IoArrowDownOutline, IoEllipsisVertical } from "react-icons/io5";

export default ({ notions, setNotions, projectId, getNotions }) => {
  const KANBAN_LANES = ["TODO", "READY_TO_DEV", "IN_PROGRESS", "TESTING", "DONE", "ARCHIVED"];

  const { search } = useLocation();
  const navigate = useNavigate();

  const [KanbanLanesSort, setKanbanLanesSort] = useState({
    TODO: "priority",
    READY_TO_DEV: "priority",
    IN_PROGRESS: "priority",
    TESTING: "priority",
    DONE: "priority",
    ARCHIVED: "priority",
  });

  const KANBAN_LANES_COLORS = {
    TODO: "#e0f2fe",
    READY_TO_DEV: "#fff0f6",
    IN_PROGRESS: "#e0f2fe",
    TESTING: "#f3e8ff",
    DONE: "#dcfce7",
    ARCHIVED: "#f3f4f6",
  };

  const StatusTag = ({ status }) => {
    if (!status) return null;
    const colors = {
      TODO: "bg-blue-600 text-xs text-blue-100 border-gray-300",
      READY_TO_DEV: "bg-pink-100 text-xs text-pink-800 border-pink-200",
      IN_PROGRESS: "bg-blue-100 text-xs text-blue-800 border-blue-200",
      TESTING: "bg-purple-100 text-xs text-purple-800 border-purple-200",
      DONE: "bg-green-100 text-xs text-green-800 border-green-200",
      ARCHIVED: "bg-gray-200 text-xs text-gray-800 border-gray-300",
    };
    return <div className={`px-2 py-1 rounded w-fit border ${colors[status]}`}>{status}</div>;
  };

  return (
    <div className="w-full border border-gray-200 rounded overflow-hidden">
      {notions && (
        <Board
          components={{ Card }}
          style={{ backgroundColor: "white", overflow: "scroll", height: "100%", width: "100%" }}
          laneStyle={{ overflow: "scroll", width: "100%" }}
          hideCardDeleteIcon
          data={{
            lanes: KANBAN_LANES.map((status) => ({
              id: status,
              label: (
                <div className="flex items-center justify-end gap-2">
                  <button
                    className="text-xs text-blue-600 hover:text-blue-800"
                    onClick={async () => {
                      const nf = { project_id: projectId, status };
                      const { data } = await api.post("/notion", nf);
                      const query = new URLSearchParams(search);
                      query.set("task_modal_id", data[0]._id);
                      navigate({ search: query.toString() });
                      setNotions([data[0], ...notions]);
                    }}>
                    + New ticket
                  </button>
                  <MultiAction
                    actions={[
                      {
                        name: (
                          <span className="flex items-center gap-2">
                            <IoArrowUpOutline className="w-4 h-4" />
                            Priority ASC
                          </span>
                        ),
                        cb: () => {
                          setKanbanLanesSort((prev) => ({ ...prev, [status]: "priority" }));
                        },
                      },
                      {
                        name: (
                          <span className="flex items-center gap-2">
                            <IoArrowDownOutline className="w-4 h-4" />
                            Priority DESC
                          </span>
                        ),
                        cb: () => {
                          setKanbanLanesSort((prev) => ({ ...prev, [status]: "-priority" }));
                        },
                      },
                      {
                        name: (
                          <span className="flex items-center gap-2">
                            <IoArrowUpOutline className="w-4 h-4" />
                            Oldest first (created at)
                          </span>
                        ),
                        cb: () => {
                          setKanbanLanesSort((prev) => ({ ...prev, [status]: "created_at" }));
                        },
                      },
                      {
                        name: (
                          <span className="flex items-center gap-2">
                            <IoArrowDownOutline className="w-4 h-4" />
                            Newest first (created at)
                          </span>
                        ),
                        cb: () => {
                          setKanbanLanesSort((prev) => ({ ...prev, [status]: "-created_at" }));
                        },
                      },
                      {
                        name: (
                          <span className="flex items-center gap-2">
                            <IoArrowUpOutline className="w-4 h-4" />
                            Oldest first (updated at)
                          </span>
                        ),
                        cb: () => {
                          setKanbanLanesSort((prev) => ({ ...prev, [status]: "updated_at" }));
                        },
                      },
                      {
                        name: (
                          <span className="flex items-center gap-2">
                            <IoArrowDownOutline className="w-4 h-4" />
                            Newest first (updated at)
                          </span>
                        ),
                        cb: () => {
                          setKanbanLanesSort((prev) => ({ ...prev, [status]: "-updated_at" }));
                        },
                      },
                    ]}
                  />
                </div>
              ),
              title: (
                <div className="flex justify-between items-center gap-2 w-full">
                  <div className="flex items-center gap-2">
                    <StatusTag status={status} />
                    <div className="text-gray-600 text-xs py-1 font-semibold">{notions.filter((t) => t.status === status).length} items</div>
                  </div>
                </div>
              ),
              cards: notions
                .filter((t) => t.status === status)
                .sort((a, b) => {
                  const sort = KanbanLanesSort[status];
                  const order = sort.startsWith("-") ? -1 : 1;
                  const sortKey = sort.replace("-", "");

                  // Put empty/null values at the end
                  if (!a[sortKey] && !b[sortKey]) return 0;
                  if (!a[sortKey]) return 1;
                  if (!b[sortKey]) return -1;

                  // Handle numeric values (like priority)
                  if (typeof a[sortKey] === "number" && typeof b[sortKey] === "number") {
                    return order * (a[sortKey] - b[sortKey]);
                  }

                  // Handle string values
                  return order * String(a[sortKey]).localeCompare(String(b[sortKey]));
                })
                .map((n) => ({ notion: n, id: n._id })),
              style: { backgroundColor: KANBAN_LANES_COLORS[status], minHeight: "28rem" },
            })),
          }}
          onCardMoveAcrossLanes={async (fromLaneId, toLaneId, cardId) => {
            const { data, ok } = await api.put(`/notion/${cardId}`, { status: toLaneId });
            if (!ok) return toast.error("Something went wrong");
            setNotions((prev) => prev.map((n) => (n._id === cardId ? data : n)));
            toast.success("Task moved successfully");
          }}
        />
      )}
    </div>
  );
};

const MultiAction = ({ actions }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="relative h-[38px]">
      <button
        onClick={(e) => {
          e.stopPropagation();
          setIsOpen(!isOpen);
        }}
        className="inline-flex justify-between items-center h-[38px] gap-4 px-2 py-2 text-sm font-medium rounded-md text-gray-700 hover:bg-gray-50">
        <IoEllipsisVertical className="w-4 h-4" />
      </button>
      {isOpen && (
        <>
          <div className="fixed inset-0" onClick={() => setIsOpen(false)} />
          <div className="absolute top-full right-0 w-56 bg-white border border-gray-200 rounded-lg shadow-xl z-10 overflow-hidden animate-fadeIn">
            <div className="py-2">
              {actions.map((action, index) => (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    action.cb(e);
                    setIsOpen(false);
                  }}
                  className="w-full px-4 py-2.5 text-left text-sm font-medium text-gray-700 hover:bg-gray-50 transition-colors duration-200 cursor-pointer flex items-center gap-2">
                  {action.name}
                </button>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
