import React from "react";
import ReactJson from "react-json-view";

export default ({ project }) => {
  return (
    <div>
      <div className="p-2">
        <ReactJson
          src={project}
          theme="monokai"
          style={{
            borderRadius: "0.25rem",
            padding: "0.5rem",
          }}
          displayDataTypes={false}
          enableClipboard={true}
        />
      </div>
    </div>
  );
};
