import SelegoJpeg from "../../assets/selego.jpeg";

export const TEMPLATE_NAV = [
  { title: "All templates", type: "ALL" },
  { title: "Propal templates", type: "PROPAL" },
  { title: "Quote templates", type: "QUOTE" },
  { title: "Contract templates", type: "CONTRACT" },
  { title: "Other templates", type: "" },
];

export const ORGANISATIONS = [
  {
    img: SelegoJpeg,
    name: "Selego B.V.",
    address: "Oudeschans 85 IV, 1011 KW Amsterdam, Netherlands",
    email: "office@selego.co",
    vat: "NL858995918B01",
    kvk: "72125845",
  },
];

// blocks

export const TEXT_BLOCK = {
  _id: "0000text",
  type: "text",
  label: "Text",
  icon: (
    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 24 24" fill="none" stroke="currentColor">
      <path d="M12 4v16m8-8H4" />
    </svg>
  ),

  description: { fr: "Bloc de texte", en: "Text Block" },
};

export const TABLE_BLOCK = {
  _id: "0000table",
  type: "table",
  label: "Pricing",
  icon: (
    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 24 24" fill="none" stroke="currentColor">
      <path d="M4 4h16v16H4zM4 10h16M10 4v16" />
    </svg>
  ),

  arr: [{ description: { fr: "Objet 0", en: "Item 0" }, price: 300, quantity: 1, total: 300 }],
};

export const TEAM_BLOCK = {
  _id: "0000team",
  type: "team",
  label: "Team",
  icon: (
    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 24 24" fill="none" stroke="currentColor">
      <path d="M16 21v-2a4 4 0 00-8 0v2M12 7a4 4 0 110-8 4 4 0 010 8zM17 11v2M7 11v2" />
    </svg>
  ),

  visible_field: {
    avatar: true,
    name: true,
    role: true,
    sell_price: true,
    cost_price: false,
    location: false,
    experience_total: false,
  },
  users: [
    {
      id: "600d74c384ba261c5890b6db",
      name: "Sebastien Le Goff",
      avatar: "https://bank.cellar-c2.services.clever-cloud.com/file/user/ad5e35c53f09f5a1f61dfc1193855e6f/1689019358260%20%281%29.jpeg.jpeg",
      sell_price: 800,
      cost_price: 400,
      role: { fr: "CTO", en: "CTO" },
      location: "Paris",
      experience_total: 8,
      description: "hey !",
    },
  ],
};

export const CV_HEADER_BLOCK = {
  _id: "0000cv_header",
  type: "cv_header",
  label: "CV - Header",
  icon: (
    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 24 24" fill="none" stroke="currentColor">
      <path d="M4 4h16v16H4zM4 10h16M10 4v16" />
    </svg>
  ),

  employee: "Jane Doe",
  description: { fr: "Ingénieur Software", en: "Software Engineer" },
  tjm: "4000 $",
  experience: { fr: "8 ans", en: "8 years" },
  location: { fr: "Paris", en: "Paris" },
  availability: { fr: "Complète", en: "Full" },
  image_url: "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y",
};

export const CV_SKILLS_BLOCK = {
  _id: "0000cv_skills",
  type: "cv_skills",
  label: "CV - Skills",
  icon: (
    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 24 24" fill="none" stroke="currentColor">
      <path d="M4 4h16v16H4zM4 10h16M10 4v16" />
    </svg>
  ),
  skills: [
    { fr: "JavaScript", en: "JavaScript" },
    { fr: "React", en: "React" },
  ],
};

export const CV_FORMATION_BLOCK = {
  _id: "0000cv_formation",
  type: "cv_formation",
  label: "CV - Formation",
  icon: (
    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 24 24" fill="none" stroke="currentColor">
      <path d="M19 7V4H5v3M3 7v13h18V7H3z" />
    </svg>
  ),

  name: { fr: "Master en Computer Science", en: "Master's in Computer Science" },
  start_and_end_date: "12/6/2020 - 11/8/2024",
  location: { fr: "Université de Columbia", en: "University of Columbia" },
  logo_url: "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y",
};

export const CV_EXPERIENCES_BLOCK = {
  _id: "0000cv_experience",
  type: "cv_experience",
  label: "CV - Experience",
  icon: (
    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 24 24" fill="none" stroke="currentColor">
      <path d="M4 4h16v2H4zM4 10h16v2H4zM4 16h16v2H4z" />
    </svg>
  ),

  name: { fr: "Développeur Full Stack chez Inspired Nova", en: "Full Stack Developer @ Inspired Nova" },
  description: {
    fr: "<div>Concentré sur le développement web et la gestion de bases de données.&nbsp;</div>",
    en: "<div>Focused on web development and database management.&nbsp;</div>",
  },
  location: { fr: "75011 Paris, France", en: "75011 Paris, France" },
  start_and_end_date: "12/6/2020 - 11/8/2024",
  logo_url: "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y",
};

export const MULTIPLE_IMAGES_BLOCK = {
  _id: "0000clients",
  type: "multiple_images",
  label: "Multiple Images",
  grid_props: "grid-cols-5",
  icon: (
    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 24 24" fill="none" stroke="currentColor">
      <path d="M5 3h14a2 2 0 012 2v14a2 2 0 01-2 2H5a2 2 0 01-2-2V5a2 2 0 012-2zM7 7h10M7 11h10M7 15h4" />
    </svg>
  ),

  images: [
    {
      label: "Animaj",
      logo_url: "https://bank.cellar-c2.services.clever-cloud.com/project/63c2ba6409b15e7f076b36c9.png",
    },
  ],
};

export const SIGNATURE_BLOCK = {
  _id: "0000signature",
  type: "signature",
  label: "Signature",
  icon: (
    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 24 24" fill="none" stroke="currentColor">
      <path d="M12 20h9" />
      <path d="M16.5 3.5a2.121 2.121 0 013 0L21 5.5a2.121 2.121 0 010 3L8.5 21H3v-5.5L16.5 3.5z" />
    </svg>
  ),

  name: "Sebastien Le Goff",
  date: new Date().toDateString(),
  signature: "https://accounting.selego.co/assets/seb-signature.png",
};

export const CONTACT_BLOCK = {
  _id: "0000contact",
  type: "contact",
  label: "Contact",
  icon: (
    <svg fill="#000000" className="h-6 w-6" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M20,1H4A1,1,0,0,0,3,2V22a1,1,0,0,0,1,1H20a1,1,0,0,0,1-1V2A1,1,0,0,0,20,1ZM19,21H5V3H19ZM9,8.5a3,3,0,1,1,3,3A3,3,0,0,1,9,8.5Zm-2,9a5,5,0,0,1,10,0,1,1,0,0,1-2,0,3,3,0,0,0-6,0,1,1,0,0,1-2,0Z" />
    </svg>
  ),

  users: [
    {
      name: "Sebastien Le Goff",
      avatar: "https://bank.cellar-c2.services.clever-cloud.com/file/user/ad5e35c53f09f5a1f61dfc1193855e6f/1689019358260%20%281%29.jpeg.jpeg",
      role: { fr: "CTO", en: "CTO" },
      email: "se.legoff@gmail.com",
      links: [{ title: "Calendly", url: undefined }],
    },
  ],
};

export const CARDS_BLOCK = {
  _id: "0000Card",
  type: "cards",
  label: "Cards",
  icon: (
    <svg fill="#000000" className="h-6 w-6" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M21,2H3A1,1,0,0,0,2,3V21a1,1,0,0,0,1,1H21a1,1,0,0,0,1-1V3A1,1,0,0,0,21,2ZM8,20H4V10H8Zm6,0H10V10h4Zm6,0H16V10h4ZM20,8H4V4H20Z" />
    </svg>
  ),

  grid_props: "grid-cols-3",
  cards: [
    {
      title: { fr: "Carte", en: "Card" },
      description: { fr: "Description de la carte", en: "Card's description" },
    },
  ],
};

export const TWO_COLS = {
  _id: "0000Two_cols",
  type: "two_cols_presentation",
  label: "Two columns",
  icon: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
      <path d="M21,2H3A1,1,0,0,0,2,3V21a1,1,0,0,0,1,1H21a1,1,0,0,0,1-1V3A1,1,0,0,0,21,2ZM4,20V4h7v16ZM13,20V4h7v16Z" />
    </svg>
  ),

  text_left: { fr: "<div>Block deux colonnes ( gauche )<br></div>", en: "<div>Two columns block ( left )<br></div>" },
  text_right: { fr: "<div>Block deux colonnes ( droite )<br></div>", en: "<div>Two columns block ( right )<br></div>" },
};

export const IMAGE = {
  _id: "0000Image",
  type: "image",
  label: "Image",
  icon: (
    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 24 24" fill="none" stroke="currentColor">
      <rect x="3" y="3" width="18" height="18" rx="2" ry="2" />
      <circle cx="8.5" cy="8.5" r="1.5" />
      <polyline points="21 15 16 10 5 21" />
    </svg>
  ),

  image_url: "https://bank.cellar-c2.services.clever-cloud.com/filequotes/66f0b75ad6a45b6c0f00f49121fcfeca/500x300.png",
  legend: { fr: "Légende", en: "Legend" },
};

// @ not in the block menu

export const PORTFOLIO_BLOCK = {
  _id: "0000portfolio",
  type: "portfolio",
  label: "Portfolio",
  icon: (
    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 24 24" fill="none" stroke="currentColor">
      <path d="M21 21H3V3h18v18zM12 10l-1.5 2L8 10l-4 5h16l-6-8z" />
    </svg>
  ),

  description: "Portfolio Block",
  image_url: "https://place-hold.it/500x300/AAA",
};

export const HEADER_BLOCK = {
  _id: "0000header",
  type: "header",
  label: "Header",
  icon: (
    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 24 24" fill="none" stroke="currentColor">
      <path d="M3 12h18M3 6h18M3 18h18" />
    </svg>
  ),

  receiver_name: "",
  receiver_email: "",
  receiver_address: "",
  receiver_vat: "",
  sender_name: ORGANISATIONS[0].name,
  sender_email: ORGANISATIONS[0].email,
  sender_address: ORGANISATIONS[0].address,
  sender_vat: ORGANISATIONS[0].vat,
  sender_kvk: ORGANISATIONS[0].kvk,
};
