import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { HiMagnifyingGlass } from "react-icons/hi2";

import Loader from "../../components/loader";
import Pagination from "../../components/pagination";
import api from "../../services/api";

export default () => {
  const navigate = useNavigate();
  const [reviews, setReviews] = useState();
  const [filter, setFilter] = useState({
    search: "",
    per_page: 50,
    page: 1
  });

  const [total, setTotal] = useState();

  const handleNextPage = () => setFilter((prev) => ({ ...prev, page: prev.page + 1 }));
  const handlePreviousPage = () => setFilter((prev) => ({ ...prev, page: prev.page - 1 }));

  useEffect(() => {
    get();
  }, [filter]);

  const get = async () => {
    try {
      const { data, ok, total, code } = await api.post("/ai-review/search", {
        search: filter.search,
        offset: (filter.page - 1) * filter.per_page,
        sort: { created_at: -1 },
      });
      if (!ok) return toast.error("Error!" || code);
      setReviews(data);
      setTotal(total);
    } catch (error) {
      console.log(error);
      return toast.error("Error fetching reviews!");
    }
  };

  if (!reviews) return <Loader />;

  return (
    <div className="p-4 space-y-4">
      <div className="flex items-center justify-between mb-2">
        <h2 className="text-xl font-bold text-gray-900">AI Code Reviews</h2>
        <div className="relative w-80">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <HiMagnifyingGlass className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
          <input
            type="text"
            name="search"
            value={filter.search}
            onChange={(e) => setFilter({ ...filter, search: e.target.value })}
            className="block w-full rounded-lg border border-gray-300 bg-white py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
            placeholder="Search reviews..."
          />
        </div>
      </div>

      <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-lg">
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-gray-50">
            <tr className="text-left text-xs font-semibold text-gray-900">
              <th className="py-3 px-2 w-52">Author</th>
              <th className="py-3 px-2">AI Review</th>
              <th className="py-3 px-2 w-24">Date</th>
              <th className="py-3 px-2 w-32">Pull Request</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {reviews.map((review) => (
              <tr 
                key={review._id} 
                className="hover:bg-gray-100"
                onClick={() => navigate(`/review/${review._id}`)}
              >
                <td className="py-0.5 px-2 text-xs text-gray-500">
                  <div className="flex flex-col">
                    <span className="font-medium text-gray-900">{review.author}</span>
                    <span className="text-xs text-gray-400">{review.repo_name}</span>
                  </div>
                </td>
                <td className="py-0.5 px-2 text-xs text-gray-500 truncate max-w-xl">
                  {review.review_content}
                </td>
                <td className="py-0.5 px-2 text-xs text-gray-500 whitespace-nowrap">
                  {new Date(review.created_at).toLocaleDateString("fr", {
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                  })}
                </td>
                <td className="py-0.5 px-2 text-xs">
                  <a 
                    href={review.comment_link} 
                    target="_blank"
                    onClick={(e) => e.stopPropagation()}
                    className="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 hover:bg-blue-100"
                  >
                    PR #{review.pr_number}
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination 
        per_page={filter.per_page} 
        total={total} 
        currentPage={filter.page} 
        onNext={handleNextPage} 
        onPrevious={handlePreviousPage} 
      />
    </div>
  );
};
