import React, { useState } from "react";
import { FaPlus, FaTrash } from "react-icons/fa6";
import toast from "react-hot-toast";

import api from "../../../services/api";

const Question = ({ job, setJob }) => {
  const [question, setQuestion] = useState("");

  const handleAddQuestion = () => {
    if (question.trim()) {
      const updatedQuestions = [...(job.questions || []), { text: question }];
      setJob({ ...job, questions: updatedQuestions });
      setQuestion("");
    }
  };

  const handleEditQuestion = (index, text) => {
    const updatedQuestions = [...job.questions];
    updatedQuestions[index].text = text;
    setJob({ ...job, questions: updatedQuestions });
  };

  const handleDeleteQuestion = (index) => {
    if (!confirm("Are you sure you want to delete this question?")) return;
    const updatedQuestions = job.questions.filter((_, i) => i !== index);
    setJob({ ...job, questions: updatedQuestions });
  };

  const handleSave = async () => {
    const { ok, data, code } = await api.put(`/job/${job._id}`, job);
    if (!ok) return toast.error(code, "Error saving questions!");

    setJob(data);
    toast.success("Questions saved successfully!");
  };

  return (
    <div className="py-5">
      <h4 className="font-semibold mb-3">Questions</h4>

      <div className="flex flex-col gap-3">
        {job.questions?.map((question, index) => (
          <div key={index} className="flex items-center gap-2">
            <input className="rounded-md text-sm flex-grow" type="text" value={question.text} onChange={(e) => handleEditQuestion(index, e.target.value)} />
            <button className="w-6 h-6 text-gray-600" onClick={() => handleDeleteQuestion(index)}>
              <FaTrash />
            </button>
          </div>
        ))}

        <div>
          <p className="font-semibold mt-5 mb-2">Add new question</p>

          <div className="flex gap-3 items-center">
            <input className="text-sm rounded-md flex-grow" type="text" value={question} onChange={(e) => setQuestion(e.target.value)} placeholder="Add new question" />
            <button onClick={handleAddQuestion} className="w-6 h-6 border-black border flex justify-center items-center rounded-md">
              <FaPlus className="text-xs" />
            </button>
          </div>
        </div>
      </div>
      <div className="mt-8">
        <p className="font-semibold mb-3">Example questions</p>
        <div className="grid grid-cols-2 gap-4">
          {[
            "Do you have any side projects ? If so, what are they ?",
            "What interests you most about this position?",
            "What relevant experience do you have for this role?",
            "What are your salary expectations?",
            "When would you be available to start?",
            "What is your preferred working style (remote, hybrid, office)?",
            "Do you have any questions about the position or company?",
          ].map((text) => (
            <button
              key={text}
              onClick={() => {
                setJob({ ...job, questions: [...job.questions, { text }] });
              }}
              className="text-left hover:bg-gray-50 p-4 rounded-lg border border-gray-200 shadow-sm transition-all duration-200 hover:shadow-md hover:border-blue-300 text-sm text-gray-700 bg-white">
              <div className="flex items-center gap-2">
                <div className="p-2 rounded-full bg-blue-50">
                  <FaPlus className="text-xs text-blue-600" />
                </div>
                {text}
              </div>
            </button>
          ))}
        </div>
      </div>

      <button className="bg-blue-700 text-white px-5 py-2 rounded-lg float-end mt-10" onClick={handleSave}>
        Save
      </button>
    </div>
  );
};

export default Question;
