import React from "react";

const RawData = ({ audit }) => {
    return (
      <div className="text-sm bg-slate-800 p-5 rounded-md text-white">
        <pre className="break-all whitespace-pre-wrap">{JSON.stringify(audit, null, 2)}</pre>
      </div>
    );
  };
  
  
export default RawData;