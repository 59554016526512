import React, { useEffect, useState } from "react";

import api from "../../../services/api";
import Loader from "../../../components/loader";

import Tooltip from "react-tooltip";

const DATES = [];
var date = new Date(Date.UTC(2023, 4, 1, 0, 0, 0));
for (let i = -10; i < 1; i++) {
  DATES.push(new Date(new Date().getFullYear(), new Date().getMonth() + i, 1, 0, 0, 0));
}

export default ({}) => {
  const [stats, setStats] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    get();
  }, []);

  async function get() {
    setLoading(true);

    const dateFrom = new Date(DATES[0]);
    dateFrom.setDate(dateFrom.getDate() - 1);

    const query2 = { type: "invoice", sent: "yes" };
    const res1 = await api.post(`/invoice/search`, query2);
    const invoices = res1.data;

    const query3 = { startDate: dateFrom, per_page: 20000 };
    const res2 = await api.post(`/bank/search`, query3);
    const banks = res2.data.banks;

    const query5 = { projectType: { $nin: ["timeoff"] } };
    const res5 = await api.post(`/activity/search`, query5);
    const activities = res5.data;

    const res8 = await api.post(`/project/search`);
    const projects = res8.data.projects;

    let transactions = [];
    const res9 = await api.post(`/wallet_transaction/search`);
    transactions = res9.data;

    const res6 = await api.post(`/budget/search`);
    const budgets = res6.data;

    for (let i = 0; i < budgets.length; i++) {
      const budget = budgets[i];
      const project = projects?.find((e) => e._id.toString() === budget.projectId.toString());

      if (budget.amountUsed > budget.amount && (budget.type == "project" || budget.type == "anticipation")) {
        budget.length = getMonthDifference(budget.startAt, budget.endAt) + 1;
        budget.depassementMensuel = (budget.amountUsed - budget.amount) / budget.length;
      }

      if (budget.type == "overbudget") {
        budget.length = getMonthDifference(budget.startAt, budget.endAt) + 1;
        budget.depassementMensuel = budget.amountUsed / budget.length;
      }

      if (project?.type == "startup-project" && (budget.type == "prospection" || budget.type == "admin" || budget.type == "invest")) {
        budget.length = getMonthDifference(budget.startAt, budget.endAt) + 1;
        budget.depassementMensuel = budget.amountUsed / budget.length;
      }
    }

    for (let i = 0; i < activities.length; i++) {
      const activity = activities[i];

      if (activity.userPosition === "FULLSTACK" && activity.userDivision === "LE_COLLECTIF" && activity.userContract != "FREELANCE") {
        activity.subventionFrench = 0.3 * activity.cost;
      }
      if (activity.userPosition === "FULLSTACK" && activity.userDivision === "SELEGO_NL" && activity.userContract != "FREELANCE") {
        activity.subventionDutch = 0.3 * activity.cost;
      }

      if (activity.projectType === "admin" || activity.projectType === "prospection") {
        activity.value = 0;
      }
    }

    const obj = {};
    for (let i = 0; i < DATES.length; i++) {
      const date = DATES[i];

      const filteredActivities = activities
        .filter((e) => e.projectType != "timeoff")
        .filter((f) => areSameMonth(f.date, date))
        .sort((a, b) => b.value - a.value);

      const filteredCostsClient = banks
        .filter((e) => e.category === "GENERAL_COST" && e.projectName != "Selego" && e.projectName != "le collectif" && e.projectName != "")
        .filter((f) => areSameMonth(f.month, date));
      const costsClient = parseInt(filteredCostsClient.reduce((acc, f) => acc + (parseInt(f.amount) || 0), 0));

      const costsClientSummedByClient = Object.values(
        filteredCostsClient.reduce((acc, curr) => {
          const { projectName, amount } = curr;
          if (!acc[projectName]) {
            acc[projectName] = { projectName, amount: 0 };
          }
          acc[projectName].amount += amount;
          return acc;
        }, {}),
      ).sort((a, b) => a.amount - b.amount);

      // Concerning revenue
      const filteredInvoice = invoices.filter((f) => areSameMonth(f.date, date));
      const revenue = parseInt(filteredInvoice.reduce((acc, f) => acc + (parseInt(f.netTotal) || 0), 0));

      const potentialRevenue = parseInt(filteredActivities.reduce((acc, f) => acc + (parseInt(f.value) || 0), 0)) + -costsClient;

      const activitiesSummedByProject = Object.values(
        filteredActivities
          .filter((f) => f.value > 0)
          .reduce((acc, curr) => {
            const { projectName, value } = curr;
            if (!acc[projectName]) {
              acc[projectName] = { projectName, value: 0 };
            }
            acc[projectName].value += value;
            return acc;
          }, {}),
      ).sort((a, b) => b.value - a.value);

      const filteredBudgets = budgets
        .filter((f) => f.depassementMensuel > 0)
        .filter((f) => isInsideDates(f.startAt, f.endAt, date))
        .sort((a, b) => b.depassementMensuel - a.depassementMensuel);

      const depassement = -parseInt(filteredBudgets.reduce((acc, f) => acc + (parseInt(f.depassementMensuel) || 0), 0));

      const doneRevenue = potentialRevenue + depassement;

      const potentialGoalRevenue = parseInt(filteredActivities.reduce((acc, f) => acc + (parseInt(f.goal) || 0), 0));

      const filteredSubventions = banks.filter((e) => e.sub_category === "SUBVENTION").filter((f) => areSameMonth(f.month, date));
      const subventions = parseInt(filteredSubventions.reduce((acc, f) => acc + (parseInt(f.amount) || 0), 0));

      //people
      const etp = Math.round(parseInt(filteredActivities.reduce((acc, f) => acc + (parseInt(f.total) || 0), 0)) / 8 / 22);
      const revenuePerPersonPerMonth = Math.round(doneRevenue / etp);

      // Concerning variable costs
      const filteredCostsCompany = banks
        .filter((e) => e.category === "GENERAL_COST" && (e.projectName == "Selego" || e.projectName == "le collectif" || e.projectName == "le stud" || e.projectName === ""))
        .filter((f) => areSameMonth(f.month, date));

      const costsSummedBySubCategory = Object.values(
        filteredCostsCompany.reduce((acc, curr) => {
          const { sub_category, amount } = curr;
          if (!acc[sub_category]) {
            acc[sub_category] = { sub_category, amount: 0 };
          }
          acc[sub_category].amount += amount;
          return acc;
        }, {}),
      ).sort((a, b) => a.amount - b.amount);

      const fixedCostsCompany = parseInt(filteredCostsCompany.reduce((acc, f) => acc + (parseInt(f.amount) || 0), 0));

      const filteredActivitiesEmployee = filteredActivities
        .filter((f) => f.userContract != "FREELANCE")
        .filter((e) => e.projectType != "admin" && e.projectType != "prospection")
        .sort((a, b) => b.cost - a.cost);

      const variableCostActivitiesEmployee = -parseInt(filteredActivitiesEmployee.reduce((acc, f) => acc + (parseInt(f.cost) || 0), 0));

      const filteredActivitiesVariableCostsEmployee = Object.values(
        filteredActivitiesEmployee.reduce((acc, curr) => {
          const { userName, cost } = curr;
          if (!acc[userName]) {
            acc[userName] = { userName, cost: 0 };
          }
          acc[userName].cost += cost;
          return acc;
        }, {}),
      ).sort((a, b) => b.cost - a.cost);

      const filteredActivitiesFreelance = filteredActivities
        .filter((f) => f.userContract === "FREELANCE")
        .filter((e) => e.projectType != "admin" && e.projectType != "prospection");

      const filteredActivitiesFreelanceByFreelance = Object.values(
        filteredActivitiesFreelance.reduce((acc, curr) => {
          const { userName, cost } = curr;
          if (!acc[userName]) {
            acc[userName] = { userName, cost: 0 };
          }
          acc[userName].cost += cost;
          return acc;
        }, {}),
      ).sort((a, b) => b.cost - a.cost);

      const costActivitiesFreelance = -parseInt(filteredActivitiesFreelance.reduce((acc, f) => acc + (parseInt(f.cost) || 0), 0));

      const filteredBanksBusinessIntroducer = banks.filter((e) => e.sub_category === "BUSINESS_INTRODUCER_COST" && !e.walletUserId).filter((f) => areSameMonth(f.month, date));
      const costBusinessIntroducerFromBanks = parseInt(filteredBanksBusinessIntroducer.reduce((acc, f) => acc + (parseInt(f.amount) || 0), 0));

      const filteredTransactionsBusinessIntroducer = transactions.filter((e) => e.role === "business introducer" && e.amount > 0).filter((f) => areSameMonth(f.date, date));
      const costBusinessIntroducer = -parseInt(filteredTransactionsBusinessIntroducer.reduce((acc, f) => acc + (parseInt(f.amount) || 0), 0)) + costBusinessIntroducerFromBanks;

      const inoccupationActivities = filteredActivities.filter((e) => e.projectName === "le stud").sort((a, b) => b.cost - a.cost);

      const inoccupationCosts = -parseInt(filteredActivities.filter((f) => f.projectName === "le stud").reduce((acc, f) => acc + (parseInt(f.cost) || 0), 0));

      const filteredTransactions = transactions.filter((e) => e.amount >= 0 && e.role == "lead" && e.status != "not valid").filter((f) => areSameMonth(f.date, date));
      const bonus = -parseInt(filteredTransactions.reduce((acc, f) => acc + (parseInt(f.amount) || 0), 0));

      const totalVariableCosts = costBusinessIntroducer + costActivitiesFreelance + costsClient + variableCostActivitiesEmployee + inoccupationCosts;

      const brutMargin = ((revenue + totalVariableCosts) / revenue).toFixed(2) * 100;
      const doneBrutMargin = ((doneRevenue + totalVariableCosts) / doneRevenue).toFixed(2) * 100;

      //Concerning general costs
      const filteredAdminCostsFromActivities = filteredActivities.filter((e) => e.projectType === "admin" && e.projectName != "Accounting" && e.projectName != "le stud");
      const fixedAdminCostsFromActivities = -parseInt(filteredAdminCostsFromActivities.reduce((acc, f) => acc + (parseInt(f.cost) || 0), 0));

      const filteredAdminCostsPerPerson = Object.values(
        filteredAdminCostsFromActivities.reduce((acc, curr) => {
          const { userName, cost } = curr;
          if (!acc[userName]) {
            acc[userName] = { userName, cost: 0 };
          }
          acc[userName].cost += cost;
          return acc;
        }, {}),
      ).sort((a, b) => b.cost - a.cost);

      const filteredAccountingCostsFromActivities = filteredActivities.filter((e) => e.projectName === "Accounting");
      const fixedAccountingCostsFromActivities = -parseInt(filteredAccountingCostsFromActivities.reduce((acc, f) => acc + (parseInt(f.cost) || 0), 0));

      const filteredAccountingCostsPerPerson = Object.values(
        filteredAccountingCostsFromActivities.reduce((acc, curr) => {
          const { userName, cost } = curr;
          if (!acc[userName]) {
            acc[userName] = { userName, cost: 0 };
          }
          acc[userName].cost += cost;
          return acc;
        }, {}),
      ).sort((a, b) => b.cost - a.cost);

      const filteredProspectionCostsFromActivities = filteredActivities.filter((e) => e.projectType === "prospection");

      const filteredProspectionCostsPerPerson = Object.values(
        filteredProspectionCostsFromActivities.reduce((acc, curr) => {
          const { userName, cost } = curr;
          if (!acc[userName]) {
            acc[userName] = { userName, cost: 0 };
          }
          acc[userName].cost += cost;
          return acc;
        }, {}),
      ).sort((a, b) => b.cost - a.cost);

      const fixedProspectionCostsFromActivities = -parseInt(filteredProspectionCostsFromActivities.reduce((acc, f) => acc + (parseInt(f.cost) || 0), 0));

      const totalGeneralCosts = fixedCostsCompany + fixedAdminCostsFromActivities + fixedProspectionCostsFromActivities + fixedAccountingCostsFromActivities;

      const ebitda = revenue + subventions + totalGeneralCosts + totalVariableCosts;

      const margin = (ebitda / (revenue + subventions)).toFixed(2) * 100;

      // what if
      const doneEbitda = doneRevenue + totalVariableCosts + totalGeneralCosts;
      const doneMargin = ((doneRevenue + totalGeneralCosts + totalVariableCosts) / doneRevenue.toFixed(2)) * 100;

      const marginWithBonuses = parseInt(((doneEbitda + bonus) / (doneRevenue + subventions)) * 100);

      //going net
      const subventionsFrench = parseInt(0.5 * filteredActivities.reduce((acc, f) => acc + (parseInt(f.subventionFrench) || 0), 0));
      const subventionsDutch = parseInt(0.3 * filteredActivities.reduce((acc, f) => acc + (parseInt(f.subventionDutch) || 0), 0));

      let impotsEstimation = 0;
      let doneImpotsEstimation = 0;
      if (ebitda > 0) impotsEstimation = -Math.round(ebitda * 0.2);
      if (doneEbitda > 0) doneImpotsEstimation = -Math.round(doneEbitda * 0.2);

      const profits = ebitda + impotsEstimation + subventionsFrench + subventionsDutch;
      const doneProfits = doneEbitda + doneImpotsEstimation + subventionsFrench + subventionsDutch;

      const netMargin = (profits / revenue).toFixed(2) * 100;
      const netDoneMargin = (doneProfits / doneRevenue).toFixed(2) * 100;

      obj[date] = {
        revenue,
        potentialRevenue,
        activitiesSummedByProject,
        potentialGoalRevenue,
        doneRevenue,
        depassement,
        filteredBudgets,
        subventions,
        etp,
        revenuePerPersonPerMonth,
        costActivitiesFreelance,
        filteredActivitiesFreelanceByFreelance,
        costBusinessIntroducer,
        variableCostActivitiesEmployee,
        filteredActivitiesVariableCostsEmployee,
        costsClient,
        costsClientSummedByClient,
        inoccupationCosts,
        inoccupationActivities,
        bonus,
        totalVariableCosts,
        brutMargin,
        doneBrutMargin,
        fixedAdminCostsFromActivities,
        filteredAdminCostsPerPerson,
        fixedProspectionCostsFromActivities,
        filteredProspectionCostsPerPerson,
        fixedAccountingCostsFromActivities,
        filteredAccountingCostsPerPerson,
        fixedCostsCompany,
        costsSummedBySubCategory,
        totalGeneralCosts,
        ebitda,
        margin,
        subventionsFrench,
        subventionsDutch,
        profits,
        netMargin,
        marginWithBonuses,
        doneEbitda,
        doneMargin,
        doneProfits,
        netDoneMargin,
      };
    }

    setStats(obj);
    setLoading(false);
  }

  if (loading) return <Loader />;

  return (
    <div className="p-4">
      <div className="flex justify-between h-12">
        <table>
          <thead className="bg-gray-200">
            <tr>
              <th></th>
              {DATES.map((e) => {
                const options = { month: "short", year: "numeric" };
                const str = e.toLocaleDateString("en-US", options) || "";
                return (
                  <th key={e} className="w-[100px]">
                    {str}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan={DATES.length + 3} className="bg-sky-200 text-center font-bold uppercase p-2">
                Revenue
              </td>
            </tr>
            {/* <tr>
              <td className="border border-gray-200 text-center">
                <div>Revenue</div>
                <div className="text-xs text-gray-500">Sum of all net total invoices</div>
              </td>
              {
                DATES.map((e, i) => {
                  const value = (stats[e]?.revenue || 0) + (stats[e]?.subventions || 0)
                  return (<td className="border border-gray-200 text-center" key={e.getTime()} >{addSpaceBetweenNumbers(value)}€</td>)
                })
              }
            </tr > */}
            <tr>
              <td className="border border-gray-200 text-center text">
                <div>Potential revenue</div>
                <div className="text-xs text-gray-500">Value of Activities and banks linked to projects</div>
              </td>
              {DATES.map((e) => {
                const value = stats[e]?.potentialRevenue || 0 + stats[e]?.subventions || 0;
                const activities = stats[e]?.activitiesSummedByProject || [];

                return (
                  <td className="border border-gray-200 text-center" data-tip data-for={`activities-${e.getTime()}`} key={e.getTime()}>
                    {addSpaceBetweenNumbers(value)}€
                    {activities.length > 0 && (
                      <Tooltip
                        id={`activities-${e.getTime()}`}
                        border
                        borderColor="#e6e6e6"
                        effect="solid"
                        className="max-w-xs text-sm keepOpacity"
                        backgroundColor="#fff"
                        textColor="#000"
                        clickable={true}
                        place="bottom"
                        delayHide={200}>
                        <div className="tooltip-content">
                          {activities.map((b, index) => (
                            <div key={index}>
                              {b.projectName} -{" "}
                              {(b.value || 0).toLocaleString("fr", {
                                style: "currency",
                                currency: "EUR",
                              })}
                            </div>
                          ))}
                        </div>
                      </Tooltip>
                    )}
                  </td>
                );
              })}
            </tr>
            <tr>
              <td className="border border-gray-200 text-center text">
                <div>Dépassement</div>
                <div className="text-xs text-gray-500">Work outside of a budget</div>
              </td>
              {DATES.map((e) => {
                const value = stats[e]?.depassement || 0;
                const budgets = stats[e]?.filteredBudgets || [];

                return (
                  <td className="border border-gray-200 text-center" data-tip data-for={`budgets-${e.getTime()}`} key={e.getTime()}>
                    {addSpaceBetweenNumbers(value)}€
                    {budgets.length > 0 && (
                      <Tooltip
                        id={`budgets-${e.getTime()}`}
                        border
                        borderColor="#e6e6e6"
                        effect="solid"
                        className="max-w-xs text-sm keepOpacity"
                        backgroundColor="#fff"
                        textColor="#000"
                        clickable={true}
                        place="bottom"
                        delayHide={200}
                        >
                        <div className="tooltip-content">
                          {budgets.map((b, index) => (
                            <div key={index}>
                              {b.projectName} -{" "}
                              {(b.depassementMensuel || 0).toLocaleString("fr", {
                                style: "currency",
                                currency: "EUR",
                              })}
                            </div>
                          ))}
                        </div>
                      </Tooltip>
                    )}
                  </td>
                );
              })}
            </tr>
            <tr className="bg-sky-50">
              <td className="border border-gray-200 text-center text font-bold">
                <div>Done revenue</div>
                <div className="text-xs text-gray-500">Sum of value of all activities + banks that could be charged to a client and inside a budget</div>
              </td>
              {DATES.map((e, i) => {
                const value = (stats[e]?.doneRevenue || 0) + (stats[e]?.subventions || 0);
                return (
                  <td className="border border-gray-200 text-center font-bold" key={e.getTime()}>
                    {addSpaceBetweenNumbers(value)}€
                  </td>
                );
              })}
            </tr>
            <tr>
              <td colSpan={DATES.length + 3} className="bg-sky-200 text-center font-bold uppercase p-2">
                Occupation and number of people
              </td>
            </tr>
            {/*             <tr>
              <td className="border border-gray-200 text-center">
                <div>Current occupation %</div>
                <div className="text-xs text-gray-500">potential charged activities / total of activities (including admin & prospection)</div>
              </td>
              {
                DATES.map((e, i) => {
                  const value = (stats[e]?.potentialRevenue || 0) / (stats[e]?.potentialMaximalRevenue || 0 - stats[e]?.fixedAdminCostsFromActivities || 0 ) * 100
                  return (<td className="border border-gray-200 text-center" key={e.getTime()} >{addSpaceBetweenNumbers(value)}%</td>)
                })
              }
            </tr > */}
            <tr>
              <td className="border border-gray-200 text-center">
                <div>ETP</div>
                <div className="text-xs text-gray-500">from activities, including support functions + freelancers</div>
              </td>
              {DATES.map((e, i) => {
                const value = stats[e]?.etp || 0;
                return (
                  <td className="border border-gray-200 text-center" key={e.getTime()}>
                    {addSpaceBetweenNumbers(value)}
                  </td>
                );
              })}
            </tr>
            <tr>
              <td className="border border-gray-200 text-center">
                <div>Revenue per person</div>
                <div className="text-xs text-gray-500">Revenue (based on what's done)/ ETP</div>
              </td>
              {DATES.map((e, i) => {
                const value = stats[e]?.revenuePerPersonPerMonth || 0;
                return (
                  <td className="border border-gray-200 text-center" key={e.getTime()}>
                    {addSpaceBetweenNumbers(value)}€
                  </td>
                );
              })}
            </tr>
            <tr>
              <td colSpan={DATES.length + 3} className="bg-sky-200 text-center font-bold uppercase p-2">
                Variable Costs
              </td>
            </tr>
            <tr>
              <td className="border border-gray-200 text-center">
                <div>Variable Employee Costs</div>
                <div className="text-xs text-gray-500 text-center">Sum of all costs linked to project activities of employees</div>
              </td>
              {DATES.map((e) => {
                const value = stats[e]?.variableCostActivitiesEmployee || 0;
                const employees = stats[e]?.filteredActivitiesVariableCostsEmployee || [];

                return (
                  <td className="border border-gray-200 text-center" data-tip data-for={`employees-${e.getTime()}`} key={e.getTime()}>
                    {addSpaceBetweenNumbers(value)}€
                    {employees.length > 0 && (
                      <Tooltip
                        id={`employees-${e.getTime()}`}
                        border
                        borderColor="#e6e6e6"
                        effect="solid"
                        className="max-w-xs text-sm keepOpacity"
                        backgroundColor="#fff"
                        textColor="#000"
                        clickable={true}
                        place="bottom"
                        delayHide={200}>
                        <div className="tooltip-content">
                          {employees.map((b, index) => (
                            <div key={index}>
                              {b.userName} -{" "}
                              {(b.cost || 0).toLocaleString("fr", {
                                style: "currency",
                                currency: "EUR",
                              })}
                            </div>
                          ))}
                        </div>
                      </Tooltip>
                    )}
                  </td>
                );
              })}
            </tr>
            <tr>
              <td className="border border-gray-200 text-center">
                <div>Inoccupation costs / internal projects</div>
                <div className="text-xs text-gray-500 text-center">le stud costs</div>
              </td>
              {DATES.map((e) => {
                const value = stats[e]?.inoccupationCosts || 0;
                const inoccupation = stats[e]?.inoccupationActivities || [];

                return (
                  <td className="border border-gray-200 text-center" data-tip data-for={`inoccupation-${e.getTime()}`} key={e.getTime()}>
                    {addSpaceBetweenNumbers(value)}€
                    {inoccupation.length > 0 && (
                      <Tooltip
                        id={`inoccupation-${e.getTime()}`}
                        border
                        borderColor="#e6e6e6"
                        effect="solid"
                        className="max-w-xs text-sm keepOpacity"
                        backgroundColor="#fff"
                        textColor="#000"
                        clickable={true}
                        place="bottom"
                        delayHide={200}>
                        <div className="tooltip-content">
                          {inoccupation.map((b, index) => (
                            <div key={index}>
                              {b.userName} -{" "}
                              {(b.cost || 0).toLocaleString("fr", {
                                style: "currency",
                                currency: "EUR",
                              })}
                            </div>
                          ))}
                        </div>
                      </Tooltip>
                    )}
                  </td>
                );
              })}
            </tr>
            <tr>
              <td className="border border-gray-200 text-center">
                <div>Variable Freelance Costs</div>
                <div className="text-xs text-gray-500">Sum of all costs linked to freelance activities, except timeoff activities</div>
              </td>
              {DATES.map((e) => {
                const value = stats[e]?.costActivitiesFreelance || 0;
                const freelance = stats[e]?.filteredActivitiesFreelanceByFreelance || [];

                return (
                  <td className="border border-gray-200 text-center" data-tip data-for={`freelance-${e.getTime()}`} key={e.getTime()}>
                    {addSpaceBetweenNumbers(value)}€
                    {freelance.length > 0 && (
                      <Tooltip
                        id={`freelance-${e.getTime()}`}
                        border
                        borderColor="#e6e6e6"
                        effect="solid"
                        className="max-w-xs text-sm keepOpacity"
                        backgroundColor="#fff"
                        textColor="#000"
                        clickable={true}
                        place="bottom"
                        delayHide={200}>
                        <div className="tooltip-content">
                          {freelance.map((b, index) => (
                            <div key={index}>
                              {b.userName} -{" "}
                              {(b.cost || 0).toLocaleString("fr", {
                                style: "currency",
                                currency: "EUR",
                              })}
                            </div>
                          ))}
                        </div>
                      </Tooltip>
                    )}
                  </td>
                );
              })}
            </tr>
            <tr>
              <td className="border border-gray-200 text-center text-sm">
                <div>Costs charged to the client</div>
                <div className="text-xs text-gray-500">Fixed costs that we charge to the client</div>
              </td>
              {DATES.map((e) => {
                const value = stats[e]?.costsClient || 0;
                const costs = stats[e]?.costsClientSummedByClient || [];

                return (
                  <td className="border border-gray-200 text-center" data-tip data-for={`costsClient-${e.getTime()}`} key={e.getTime()}>
                    {addSpaceBetweenNumbers(value)}€
                    {costs.length > 0 && (
                      <Tooltip
                        id={`costsClient-${e.getTime()}`}
                        border
                        borderColor="#e6e6e6"
                        effect="solid"
                        className="max-w-xs text-sm keepOpacity"
                        backgroundColor="#fff"
                        textColor="#000"
                        clickable={true}
                        place="bottom"
                        delayHide={200}>
                        <div className="tooltip-content">
                          {costs.map((b, index) => (
                            <div key={index}>
                              {b.projectName} -{" "}
                              {(b.amount || 0).toLocaleString("fr", {
                                style: "currency",
                                currency: "EUR",
                              })}
                            </div>
                          ))}
                        </div>
                      </Tooltip>
                    )}
                  </td>
                );
              })}
            </tr>
            <tr>
              <td className="border border-gray-200 text-center text-sm">
                <div>Cost business introducer</div>
                <div className="text-xs text-gray-500">Bonuses for business introducers</div>
              </td>
              {DATES.map((e) => {
                const value = stats[e]?.costBusinessIntroducer || 0;
                return (
                  <td className="border border-gray-200 text-center" key={e.getTime()}>
                    {addSpaceBetweenNumbers(value)}€
                  </td>
                );
              })}
            </tr>
            <tr className="bg-sky-50">
              <td className="border border-gray-200 text-center font-bold">
                <div>Total Variable Costs</div>
                <div className="text-xs text-gray-500">Free + employees + business introducer + hosting costs</div>
              </td>
              {DATES.map((e) => {
                const value = stats[e]?.totalVariableCosts || 0;
                return (
                  <td className="border border-gray-200 text-center font-bold" key={e.getTime()}>
                    {addSpaceBetweenNumbers(value)}€
                  </td>
                );
              })}
            </tr>
            <tr className="bg-sky-50">
              <td className="border border-gray-200 text-center font-bold">
                <div>Done Brut margin</div>
                <div className="text-xs text-gray-500">Brut Margin if doneRevenue - Goal : 38%</div>
              </td>
              {DATES.map((e, i) => (
                <td
                  key={i}
                  className={`border border-gray-200 text-right font-bold 
                    ${stats[e]?.doneBrutMargin < 30 ? "text-red-700" : stats[e]?.doneBrutMargin >= 30 && stats[e]?.doneBrutMargin < 35 ? "text-orange-500" : "text-green-700"}`}>
                  {(stats[e]?.doneBrutMargin / 100 || 0).toLocaleString("fr", { style: "percent" })}
                </td>
              ))}
            </tr>
            <tr>
              <td colSpan={DATES.length + 3} className="bg-sky-200 text-center font-bold uppercase p-2">
                Total General Costs
              </td>
            </tr>
            <tr>
              <td className="border border-gray-200 text-center">
                <div>General Costs Admin</div>
                <div className="text-xs text-gray-500">Salaries of employees/freelancers - on time spent on admin (hors Accounting)</div>
              </td>
              {DATES.map((e) => {
                const value = stats[e]?.fixedAdminCostsFromActivities || 0;
                const adminActivities = stats[e]?.filteredAdminCostsPerPerson || [];

                return (
                  <td className="border border-gray-200 text-center" data-tip data-for={`adminActivities-${e.getTime()}`} key={e.getTime()}>
                    {addSpaceBetweenNumbers(value)}€
                    {adminActivities.length > 0 && (
                      <Tooltip
                        id={`adminActivities-${e.getTime()}`}
                        border
                        borderColor="#e6e6e6"
                        effect="solid"
                        className="max-w-xs text-sm keepOpacity"
                        backgroundColor="#fff"
                        textColor="#000"
                        clickable={true}
                        place="bottom"
                        delayHide={200}>
                        <div className="tooltip-content">
                          {adminActivities.map((b, index) => (
                            <div key={index}>
                              {b.userName} -{" "}
                              {(b.cost || 0).toLocaleString("fr", {
                                style: "currency",
                                currency: "EUR",
                              })}
                            </div>
                          ))}
                        </div>
                      </Tooltip>
                    )}
                  </td>
                );
              })}
            </tr>
            <tr>
              <td className="border border-gray-200 text-center">
                <div>General Costs Accounting</div>
                <div className="text-xs text-gray-500">Coûts sur Accounting</div>
              </td>
              {DATES.map((e) => {
                const value = stats[e]?.fixedAccountingCostsFromActivities || 0;
                const accountingActivities = stats[e]?.filteredAccountingCostsPerPerson || [];

                return (
                  <td className="border border-gray-200 text-center" data-tip data-for={`accountingActivities-${e.getTime()}`} key={e.getTime()}>
                    {addSpaceBetweenNumbers(value)}€
                    {accountingActivities.length > 0 && (
                      <Tooltip
                        id={`accountingActivities-${e.getTime()}`}
                        border
                        borderColor="#e6e6e6"
                        effect="solid"
                        className="max-w-xs text-sm keepOpacity"
                        backgroundColor="#fff"
                        textColor="#000"
                        clickable={true}
                        place="bottom"
                        delayHide={200}>
                        <div className="tooltip-content">
                          {accountingActivities.map((b, index) => (
                            <div key={index}>
                              {b.userName} -{" "}
                              {(b.cost || 0).toLocaleString("fr", {
                                style: "currency",
                                currency: "EUR",
                              })}
                            </div>
                          ))}
                        </div>
                      </Tooltip>
                    )}
                  </td>
                );
              })}
            </tr>
            <tr>
              <td className="border border-gray-200 text-center">
                <div>General Costs Prospection</div>
                <div className="text-xs text-gray-500">Salaries of employees/freelancers - on time spent on prospection</div>
              </td>
              {DATES.map((e) => {
                const value = stats[e]?.fixedProspectionCostsFromActivities || 0;
                const prospectionActivities = stats[e]?.filteredProspectionCostsPerPerson || [];

                return (
                  <td className="border border-gray-200 text-center" data-tip data-for={`prospectionActivities-${e.getTime()}`} key={e.getTime()}>
                    {addSpaceBetweenNumbers(value)}€
                    {prospectionActivities.length > 0 && (
                      <Tooltip
                        id={`prospectionActivities-${e.getTime()}`}
                        border
                        borderColor="#e6e6e6"
                        effect="solid"
                        className="max-w-xs text-sm keepOpacity"
                        backgroundColor="#fff"
                        textColor="#000"
                        clickable={true}
                        place="bottom"
                        delayHide={200}>
                        <div className="tooltip-content">
                          {prospectionActivities.map((b, index) => (
                            <div key={index}>
                              {b.userName} -{" "}
                              {(b.cost || 0).toLocaleString("fr", {
                                style: "currency",
                                currency: "EUR",
                              })}
                            </div>
                          ))}
                        </div>
                      </Tooltip>
                    )}
                  </td>
                );
              })}
            </tr>
            <tr>
              <td className="border border-gray-200 text-center">
                <div>Fixed costs</div>
                <div className="text-xs text-gray-500">Sum of all banks with category GENERAL_COST associated to the company</div>
              </td>
              {DATES.map((e) => {
                const value = stats[e]?.fixedCostsCompany || 0;
                const costs = stats[e]?.costsSummedBySubCategory || [];

                return (
                  <td className="border border-gray-200 text-center" data-tip data-for={`costs-${e.getTime()}`} key={e.getTime()}>
                    {addSpaceBetweenNumbers(value)}€
                    {costs.length > 0 && (
                      <Tooltip
                        id={`costs-${e.getTime()}`}
                        border
                        borderColor="#e6e6e6"
                        effect="solid"
                        className="max-w-xs text-sm keepOpacity"
                        backgroundColor="#fff"
                        textColor="#000"
                        clickable={true}
                        place="bottom"
                        delayHide={200}>
                        <div className="tooltip-content">
                          {costs.map((b, index) => (
                            <div key={index}>
                              {b.sub_category} -{" "}
                              {(b.amount || 0).toLocaleString("fr", {
                                style: "currency",
                                currency: "EUR",
                              })}
                            </div>
                          ))}
                        </div>
                      </Tooltip>
                    )}
                  </td>
                );
              })}
            </tr>
            <tr className="bg-sky-50">
              <td className="border border-gray-200 text-center font-bold">
                <div>Total general costs</div>
                <div className="text-xs text-gray-500">Fixed costs + Activities Admin/prospection costs</div>
              </td>
              {DATES.map((e) => {
                const value = stats[e]?.totalGeneralCosts || 0;
                return (
                  <td className="border border-gray-200 text-center font-bold" key={e.getTime()}>
                    {addSpaceBetweenNumbers(value)}€
                  </td>
                );
              })}
            </tr>
            <tr className="bg-sky-50">
              <td className="border border-gray-200 text-center font-bold">
                <div>% of total general costs on doneRevenue - Target 20%</div>
                <div className="text-xs text-gray-500">total general costs / doneRevenue</div>
              </td>
              {DATES.map((e, i) => {
                const value = Math.abs(stats[e]?.totalGeneralCosts || 0) / ((stats[e]?.doneRevenue || 0) + (stats[e]?.subventions || 0));
                return (
                  <td className={`border border-gray-200 text-right font-bold p-2 ${value > 0.2 ? "text-red-700" : "text-green-700"}`} key={i}>
                    {value.toLocaleString("fr", { style: "percent" })}
                  </td>
                );
              })}
            </tr>
            <tr>
              <td colSpan={DATES.length + 3} className="bg-sky-200 text-center font-bold uppercase p-2">
                Results & Key numbers
              </td>
            </tr>
            <tr className="bg-sky-50">
              <td className="border border-gray-200 text-center font-bold">
                <div>Done Ebitda</div>
                <div className="text-xs text-gray-500">What if we billed all our work inside a budget</div>
              </td>
              {DATES.map((e) => {
                const value = stats[e]?.doneEbitda || 0;
                return (
                  <td className="border border-gray-200 text-center font-bold" key={e.getTime()}>
                    {addSpaceBetweenNumbers(value)}€
                  </td>
                );
              })}
            </tr>
            <tr className="bg-sky-50">
              <td className="border border-gray-200 text-center font-bold">
                <div>Done margin</div>
                <div className="text-xs text-gray-500">Based on work done inside a budget</div>
              </td>
              {DATES.map((e, i) => (
                <td
                  key={i}
                  className={`border border-gray-200 text-right font-bold
                    ${
                      stats[e]?.doneMargin <= 5
                        ? "text-red-700"
                        : stats[e]?.doneMargin > 5 && stats[e]?.doneMargin <= 8
                        ? "text-orange-500"
                        : stats[e]?.doneMargin > 8 && stats[e]?.doneMargin <= 14
                        ? "text-green-500"
                        : "text-green-700"
                    }`}>
                  {(stats[e]?.doneMargin / 100 || 0).toLocaleString("fr", { style: "percent" })}
                </td>
              ))}
            </tr>
            <tr>
              <td className="border border-gray-200 text-center">
                <div>Bonus - lead</div>
                <div className="text-xs text-gray-500">Bonuses with role = lead</div>
              </td>
              {DATES.map((e, i) => (
                <td className="border border-gray-200 text-right" key={i}>
                  {(stats[e]?.bonus || 0).toLocaleString("fr", { style: "currency", currency: "EUR", maximumFractionDigits: 0 })}
                </td>
              ))}
            </tr>
            <tr>
              <td className="border border-gray-200 text-center">
                <div>Margin after bonuses are distributed</div>
                <div className="text-xs text-gray-500">(EBITDA - bonus) / doneRevenue</div>
              </td>
              {DATES.map((e) => {
                const value = stats[e]?.marginWithBonuses || 0;
                return (
                  <td className="border border-gray-200 text-center" key={e.getTime()}>
                    {addSpaceBetweenNumbers(value)}%
                  </td>
                );
              })}
            </tr>
            <tr>
              <td colSpan={DATES.length + 3} className="bg-sky-200 text-center font-bold uppercase p-2">
                From EBITDA to Net Profits
              </td>
            </tr>
            <tr>
              <td className="border border-gray-200 text-center">
                <div>CII/CIR First Estimation</div>
                <div className="text-xs text-gray-500">30% of 50% of the dev activities from Le Collectif</div>
              </td>
              {DATES.map((e) => {
                const value = stats[e]?.subventionsFrench || 0;
                return (
                  <td className="border border-gray-200 text-center" key={e.getTime()}>
                    {addSpaceBetweenNumbers(value)}€
                  </td>
                );
              })}
            </tr>
            <tr>
              <td className="border border-gray-200 text-center">
                <div>WBSO First Estimation</div>
                <div className="text-xs text-gray-500">30% of 30% of the dev activities from Selego NL</div>
              </td>
              {DATES.map((e) => {
                const value = stats[e]?.subventionsDutch || 0;
                return (
                  <td className="border border-gray-200 text-center" key={e.getTime()}>
                    {addSpaceBetweenNumbers(value)}€
                  </td>
                );
              })}
            </tr>
            <tr className="bg-sky-50">
              <td className="border border-gray-200 text-center font-bold">
                <div>Net done profits</div>
                <div className="text-xs text-gray-500">doneRevenue - costs + subventions - estimated taxes</div>
              </td>
              {DATES.map((e) => {
                const value = stats[e]?.doneProfits || 0;
                return (
                  <td className="border border-gray-200 text-center font-bold" key={e.getTime()}>
                    {addSpaceBetweenNumbers(value)}€
                  </td>
                );
              })}
            </tr>
            <tr className="bg-sky-50">
              <td className="border border-gray-200 text-center font-bold">
                <div>Done Net Margin</div>
                <div className="text-xs text-gray-500">net done profits / doneRevenue</div>
              </td>
              {DATES.map((e) => {
                const value = stats[e]?.netDoneMargin || 0;
                return (
                  <td className="border border-gray-200 text-center font-bold" key={e.getTime()}>
                    {addSpaceBetweenNumbers(value)}%
                  </td>
                );
              })}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

function addSpaceBetweenNumbers(str) {
  return str
    .toFixed()
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}
function areSameMonth(a, b) {
  let aDate = new Date(a);
  let bDate = new Date(b);
  return aDate.getMonth() === bDate.getMonth() && aDate.getFullYear() === bDate.getFullYear();
}

function isInsideDates(start, end, date) {
  let startDate = new Date(start);
  let endDate = new Date(end);
  let targetDate = new Date(date);

  // Troncature pour ne garder que l'année et le mois
  startDate = new Date(startDate.getFullYear(), startDate.getMonth(), 1);
  endDate = new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0); // Fin du mois
  targetDate = new Date(targetDate.getFullYear(), targetDate.getMonth(), targetDate.getDate());

  // Comparer les dates
  return targetDate >= startDate && targetDate <= endDate;
}

function getMonthDifference(startDate, endDate) {
  const start = new Date(startDate);
  const end = new Date(endDate);

  // Calcul du nombre total de mois entre les deux dates
  return (end.getFullYear() - start.getFullYear()) * 12 + (end.getMonth() - start.getMonth());
}
