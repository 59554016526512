import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import api from "../../services/api";
import toast from "react-hot-toast";
import { CgUnavailable } from "react-icons/cg";
import { IoHandLeftOutline } from "react-icons/io5";
import { setUser } from "../../redux/auth/actions";
import moment from "moment";

import { classNames } from "../../utils";

const Availability = () => {
  const user = useSelector((state) => state.Auth.user);
  const [values, setValues] = useState(user);
  const [isOpened, setIsOpened] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setValues(user);
  }, [user]);

  const handleSetAvailable = async (notify) => {
    try {
      const { ok, data } = await api.put(`/user/${user._id}`, { availability: "available" });
      setValues(data);
      dispatch(setUser(data));
      if (notify) {
        await api.post("/slack/notify-available", { user_id: user._id });
      }
      toast.success("Availability updated successfully");
    } catch (e) {
      console.error(e);
      toast.error("An error occurred while updating availability");
    }
  };

  const handleSetNotAvailable = async (notify) => {
    try {
      const { ok, data } = await api.put(`/user/${user._id}`, { availability: "not available" });
      setValues(data);
      dispatch(setUser(data));
      if (notify) {
        await api.post("/slack/notify-available", { user_id: user._id });
      }
      toast.success("Availability updated successfully");
    } catch (e) {
      console.error(e);
      toast.error("An error occurred while updating availability");
    }
  };

  function SendSlackNotificationModal({ onchange, status }) {
    if (!isOpened) return null;
    // close on click outside
    window.addEventListener("click", (e) => {
      if (e.target.classList.contains("fixed")) {
        setIsOpened(false);
      }
    });
    return (
      <div className="fixed z-50 inset-0 bg-black bg-opacity-50 flex items-center justify-center">
        <div className="bg-white p-5 rounded-md w-1/2">
          <div className="flex items-center gap-2">
            <img src="https://cdn4.iconfinder.com/data/icons/logos-and-brands/512/306_Slack_logo-512.png" alt="Slack Logo" className="w-8 h-8" />
            <h1 className="text-lg text-black">Do you want to send a slack notification?</h1>
          </div>
          <h5 className="mt-2 font-normal">This will send a notification in accounting slack channel that you are now {status}</h5>
          <div className="flex justify-end gap-2 mt-5">
            <button
              className="gray-btn"
              onClick={() => {
                onchange(false);
                setIsOpened(false);
              }}>
              No, thank you
            </button>
            <button
              className="blue-btn"
              onClick={() => {
                onchange(true);
                setIsOpened(false);
              }}>
              Yes, please
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={classNames("border-[1px] border-gray-200 rounded-md overflow-hidden h-full bg-white flex flex-col justify-between")}>
      <h1 className="flex items-center text-base font-semibold text-gray-900 px-3 pt-3">
        <span>My availability</span>
      </h1>
      <div className="flex flex-col gap-2 p-3">
        <button
          className={classNames(
            "flex flex-row gap-2 items-center rounded-md p-2",
            values.availability === "not available" ? "bg-orange-600 text-white" : "bg-white text-black hover:bg-gray-100",
          )}
          onClick={() => {
            if (values.availability === "not available") return;
            setIsOpened(true);
          }}>
          <CgUnavailable className="text-xl" />I am not available
        </button>
        <button
          className={classNames(
            "flex flex-row gap-2 items-center rounded-md p-2",
            values.availability === "available" ? "bg-green-600 text-white" : "bg-white text-black hover:bg-gray-100",
          )}
          onClick={() => {
            if (values.availability === "available") return;
            setIsOpened(true);
          }}>
          <IoHandLeftOutline /> I am open to work
        </button>
        <div className="text-xs text-gray-500">
          {values.availability === "available" && <span>You are available since {moment(values.available_since).format("DD MMM, HH:mm")}</span>}
        </div>
        <SendSlackNotificationModal
          onchange={values.availability === "available" ? handleSetNotAvailable : handleSetAvailable}
          status={values.availability === "available" ? "not available" : "available"}
        />
      </div>
    </div>
  );
};

export default Availability;
