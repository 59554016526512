import { useState, useEffect } from "react";
import api from "../../../services/api";
import toast from "react-hot-toast";

const Tracking = ({ audit }) => {
  const [issues, setIssues] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchIssues();
  }, [audit]);

  const fetchIssues = async () => {
    try {
      const { data, ok } = await api.post(`/audit_issue/search`, {
        audit_id: audit._id,
      });
      if (!ok) return toast.error("Error fetching issues");
      setIssues(data);
    } catch (e) {
      toast.error("Error fetching issues");
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <div>Loading...</div>;

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-300">
        <thead>
          <tr>
            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">
              Description
            </th>
            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              Type
            </th>
            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              Priority
            </th>
            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              Status
            </th>
            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              Folder
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {issues.map((issue) => (
            <Issue key={issue._id} issue={issue} />
          ))}
          <CreateIssue audit={audit} fetchIssues={fetchIssues} />
        </tbody>
      </table>
    </div>
  );
};


const CreateIssue = ({ audit, fetchIssues }) => {
    const [value, setValue] = useState({
        description: '',
        type: 'complexity',
        priority: 'low',
        status: 'todo',
        folder: ''
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const { data, ok } = await api.post(`/audit_issue`, {
                audit_id: audit._id,
                ...value
            });
            if (!ok) return toast.error("Error creating issue");
            toast.success("Issue created");
            setValue({
                description: '',
                type: 'complexity', 
                priority: 'low',
                status: 'todo',
                folder: ''
            });
            fetchIssues();
        } catch (err) {
            toast.error("Error creating issue");
        }
    }

    return (
        <tr>
            <td>
                <input 
                    type="text"
                    placeholder="Enter description..."
                    className="border-b border-transparent hover:border-gray-300 focus:border-blue-500 px-2 py-1 w-full outline-none"
                    value={value.description}
                    onChange={(e) => setValue({...value, description: e.target.value})}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && value.description) {
                            handleSubmit(e);
                        }
                    }}
                />
            </td>
            <td>
                <select
                    className="border-b border-transparent hover:border-gray-300 focus:border-blue-500 px-2 py-1 outline-none bg-transparent"
                    value={value.type}
                    onChange={(e) => setValue({...value, type: e.target.value})}
                >
                    <option value="complexity">Complexity</option>
                    <option value="convention">Convention</option>
                    <option value="inconsistency">Inconsistency</option>
                    <option value="red_flag">Red Flag</option>
                </select>
            </td>
            <td>
                <select
                    className="border-b border-transparent hover:border-gray-300 focus:border-blue-500 px-2 py-1 outline-none bg-transparent"
                    value={value.priority}
                    onChange={(e) => setValue({...value, priority: e.target.value})}
                >
                    <option value="low">Low</option>
                    <option value="medium">Medium</option>
                    <option value="high">High</option>
                </select>
            </td>
            <td>
                <select
                    className="border-b border-transparent hover:border-gray-300 focus:border-blue-500 px-2 py-1 outline-none bg-transparent"
                    value={value.status}
                    onChange={(e) => setValue({...value, status: e.target.value})}
                >
                    <option value="todo">To do</option>
                    <option value="in_progress">In Progress</option>
                    <option value="done">Done</option>
                </select>
            </td>
            <td>
                <input
                    type="text"
                    placeholder="Enter folder..."
                    className="border-b border-transparent hover:border-gray-300 focus:border-blue-500 px-2 py-1 w-full outline-none"
                    value={value.folder}
                    onChange={(e) => setValue({...value, folder: e.target.value})}
                />
            </td>
        </tr>
    )
}

const Issue = ({ issue }) => {
  const [value, setValue] = useState();

  useEffect(() => {
    setValue(issue);
  }, [issue]);

  if (!value) return <div>Loading...</div>;
  return (
    <tr key={issue._id}>
      <td className="py-4 pl-4 pr-3">
        <textarea
          className="border-b border-transparent hover:border-gray-300 focus:border-blue-500 px-2 py-1 w-full outline-none min-h-[60px] resize-y"
          value={value.description || ""}
          onChange={(e) => setValue({ ...value, description: e.target.value })}
          onBlur={async () => {
            await api.put(`/audit_issue/${value._id}`, {
              description: value.description,
            });
            toast.success("Issue updated");
          }}
          onKeyDown={ (e) => {
            if (e.key === 'Enter') {
              handleSubmit(e);
            }
        }}
        />
      </td>
      <td className="whitespace-nowrap px-3 py-4">
        <select
          className="border-b border-transparent hover:border-gray-300 focus:border-blue-500 px-2 py-1 outline-none bg-transparent"
          value={value.type || ""}
          onChange={async (e) => {
            setValue({ ...value, type: e.target.value });

            await api.put(`/audit_issue/${value._id}`, {
              type: e.target.value,
            });
            toast.success("Issue updated");
          }}>
          <option value="complexity">Complexity</option>
          <option value="convention">Convention</option>
          <option value="inconsistency">Inconsistency</option>
          <option value="red_flag">Red Flag</option>
        </select>
      </td>
      <td className="whitespace-nowrap px-3 py-4">
        <select
          className="border-b border-transparent hover:border-gray-300 focus:border-blue-500 px-2 py-1 outline-none bg-transparent"
          value={value.priority || ""}
          onChange={async (e) => {
            setValue({ ...value, priority: e.target.value });
            try {
              await api.put(`/audit_issue/${value._id}`, {
                priority: e.target.value,
              });
              toast.success("Issue updated");
            } catch (err) {
              setValue({ ...value }); 
              toast.error("Failed to update issue");
            }
          }}>
          <option value="low">Low</option>
          <option value="medium">Medium</option>
          <option value="high">High</option>
        </select>
      </td>
      <td className="whitespace-nowrap px-3 py-4">
        <select
          className="border-b border-transparent hover:border-gray-300 focus:border-blue-500 px-2 py-1 outline-none bg-transparent"
          value={value.status || ""}
          onChange={async (e) => {
            setValue({ ...value, status: e.target.value });
            await api.put(`/audit_issue/${value._id}`, {
              status: e.target.value,
            });
            toast.success("Issue updated");
          }}>
          <option value="todo">Todo</option>
          <option value="in_progress">In Progress</option>
          <option value="done">Done</option>
        </select>
      </td>
      <td className="whitespace-nowrap px-3 py-4">
        <input
          type="text"
          className="border-b border-transparent hover:border-gray-300 focus:border-blue-500 px-2 py-1 w-full outline-none"
          value={value.folder || ""}
          onChange={(e) => setValue({ ...value, folder: e.target.value })}
          onBlur={async () => {
            await api.put(`/audit_issue/${value._id}`, {
              folder: value.folder,
            });
            toast.success("Issue updated");
          }}
        />
      </td>
    </tr>
  );
};



export default Tracking;
