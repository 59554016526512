import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";

import api from "../../../services/api";
import Modal from "../../../components/modal";
import Table from "../../../components/Table";
import Paginator from "../../../components/Paginator";

import SourceModal from "./sourceModal";

export default () => {
  const [filters, setFilters] = useState({ page: 1, status: "TODO" });
  const [loading, setLoading] = useState(false);

  const [queue, setQueue] = useState([]);
  const [selectedSource, setSelectedSource] = useState(null);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    fetch();
  }, [filters]);

  async function fetch() {
    setLoading(true);
    const newFilters = { ...filters };
    newFilters.skip = (filters.page - 1) * 100;
    if (newFilters.status === "DONE") newFilters.sort = "finished_at";

    // if(newFilters.status
    const { data, total, ok } = await api.post("/s_queue/search", newFilters);
    if (!ok) return toast.error("Error fetching queue");
    setQueue(data);
    setTotal(total);
    setLoading(false);
  }

  return (
    <div className="bg-white h-full">
      <div className="flex justify-between items-center">
        <Search setFilters={setFilters} filters={filters} />
        <SelectStatus
          value={filters.status}
          onChange={(e) => {
            if (e === "ALL") return setFilters((prev) => ({ ...prev, status: null }));
            setFilters((prev) => ({ ...prev, status: e }));
          }}
        />
        <CreateSource onDone={fetch} />
      </div>
      <div>{total} items</div>
      <SourceModal source={selectedSource} setSource={() => setSelectedSource(null)} />
      <Table
        total={total}
        header={[
          { title: "From", key: "from" },
          { title: "Name", key: "name" },
          { title: "Status", key: "status" },
          { title: "type", key: "type" },
          { title: "Created At", key: "created_at" },
          { title: "Finished At", key: "finished_at" },
        ]}
        loading={loading}
        height="h-[32rem]"
        sticky={true}
        noResultRender={<div className="w-full bg-white text-center p-2 my-5 rounded shadow-sm">Aucune résultat n'a été trouvé</div>}>
        {queue.map((item, index) => {
          return (
            <tr
              className={`${index % 2 === 0 ? "bg-gray-100" : "bg-gray-50"} cursor-pointer`}
              key={item._id}
              onClick={(e) => {
                e.stopPropagation();
                setSelectedSource(item);
              }}>
              <td className="border hover:bg-gray-200 px-4 py-2 max-w-xs truncate">
                <div className="flex items-center gap-2">
                  <img src={item.user_avatar} className="w-8 h-8 rounded-full" />
                  <div>{item.user_name}</div>
                </div>
              </td>
              <td className="border hover:bg-gray-200 px-4 py-2 max-w-xs truncate">{item.name}</td>
              <td className="border hover:bg-gray-200 px-4 py-2 max-w-xs truncate">{item.status}</td>
              <td className="border hover:bg-gray-200 px-4 py-2 max-w-xs truncate">{item.type}</td>
              <td className="border hover:bg-gray-200 px-4 py-2 max-w-xs truncate">{item.created_at}</td>
              <td className="border hover:bg-gray-200 px-4 py-2 max-w-xs truncate">{item.finished_at}</td>
            </tr>
          );
        })}
      </Table>
      <div className="mt-1">
        <Paginator page={filters.page} setPage={(page) => setFilters({ ...filters, page })} last={Math.ceil(total / 100)} />
      </div>
    </div>
  );
};

const Search = ({ filters, setFilters }) => {
  const [str, setStr] = useState("");
  useEffect(() => {
    setStr(filters.search || "");
  }, [filters.search]);
  return (
    <div className="pr-2 pl-2 pb-2 flex items-start">
      <input type="text" className="form-control" placeholder="Search" value={str} onChange={(e) => setStr(e.target.value)} />
      <button type="submit" className="ml-2 btn btn-primary" onClick={() => setFilters({ ...filters, search: str })}>
        Search
      </button>
    </div>
  );
};

const CreateSource = ({ onDone }) => {
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({});

  async function onSubmit() {
    try {
      const res = await api.post("/s_source", values);
      if (!res.ok) throw res;
      toast.success("Created");

      setOpen(false);
      onDone();
    } catch (e) {
      console.log(e);
      toast.error("Some Error!", e.code);
    }
  }
  return (
    <div>
      <button className="bg-sky-700 text-white py-2 px-4 rounded-md" onClick={() => setOpen(true)}>
        Create a source
      </button>
      <Modal isOpen={open} onClose={() => setOpen(false)} className="min-w-96 p-6">
        <div>
          <div className="flex flex-col justify-between flex-wrap">
            <div className="w-full mt-2">
              <div className="text-[14px] text-[#212325] font-medium">Name</div>
              <input
                className="projectsInput text-[14px] font-normal text-[#212325] rounded-[10px]"
                name="name"
                value={values.name}
                onChange={(e) => setValues({ ...values, name: e.target.value })}
              />
            </div>
          </div>
        </div>
        <br />
        <div className="w-full flex justify-end">
          <button className="bg-sky-700 text-white py-2 px-4 rounded-md" onClick={onSubmit}>
            Create
          </button>
        </div>
      </Modal>
    </div>
  );
};

const SelectStatus = ({ value, onChange }) => {
  const STATUS = ["TODO", "DONE"];

  return (
    <div className="w-full">
      <select
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className="block w-1/2 p-2 border border-gray-300 rounded-lg shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
        <option key={null} value={null}>
          ALL
        </option>
        {STATUS.map((status) => (
          <option key={status} value={status}>
            {status.replace("_", " ")}
          </option>
        ))}
      </select>
    </div>
  );
};
