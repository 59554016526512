import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import api from "../../services/api";
import MultiSelect from "../../components/MultiSelect";

const EmailEvents = () => {
  const [events, setEvents] = useState([]);
  const [filters, setFilters] = useState({
    email: "",
    events: [],
    messageId: "",
  });
  const [loading, setLoading] = useState(false);

  const eventOptions = [
    { value: "request", label: "Request" },
    { value: "unique_opened", label: "Unique opened" },
    { value: "delivered", label: "Delivered" },
    { value: "opened", label: "Opened" },
  ];

  useEffect(() => {
    search();
  }, []);

  const search = async () => {
    try {
      setLoading(true);
      const { ok, data } = await api.post("/email-event/search", filters);
      if (!ok) throw new Error(data.error);
      setEvents(data);
    } catch (error) {
      toast.error("Error loading events: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-6">Email Events</h1>

      {/* Search Form */}
      <form className="bg-white rounded-lg shadow p-6 mb-6" onSubmit={search}>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Email</label>
            <input
              type="text"
              className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              value={filters.email}
              onChange={(e) => setFilters({ ...filters, email: e.target.value })}
              placeholder="Search by email"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Event Type</label>
            <MultiSelect
              id="email-event-filter"
              options={eventOptions}
              values={filters.events?.map((value) => eventOptions.find((opt) => opt.value === value))}
              onSelectedChange={(selected) =>
                setFilters({
                  ...filters,
                  events: selected.map((option) => option.value),
                })
              }
              placeholder="Filter by event type"
            />
          </div>
        </div>
        <div className="mt-4">
          <button
            onClick={search}
            type="submit"
            disabled={loading}
            className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50">
            {loading ? "Searching..." : "Search"}
          </button>
        </div>
      </form>

      {/* Results Table */}
      <div className="bg-white rounded-lg shadow overflow-hidden">
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Event</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Sender</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Subject</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Message ID</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Created At</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Raw Data</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {events?.map((event, index) => (
                <tr key={index} className="hover:bg-gray-50">
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{event.event}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{event.sender}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{event.email}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{event.subject}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{event.messageId}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{new Date(event.created_at).toLocaleString()}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    <button onClick={() => alert(JSON.stringify(event.raw, null, 2))} className="text-blue-600 hover:text-blue-800">
                      View Raw
                    </button>
                  </td>
                </tr>
              ))}
              {events?.length === 0 && (
                <tr>
                  <td colSpan="5" className="px-6 py-4 text-center text-sm text-gray-500">
                    No events found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default EmailEvents;
