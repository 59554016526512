import React from "react";
import { FaRegBuilding } from "react-icons/fa";

const Status = ({ values, setValues }) => {
  return (
    <div className="flex items-center">
      <FaRegBuilding className="mr-3" />
      <select 
        className="input w-full" 
        value={values.status || ""} 
        onChange={(e) => setValues({ ...values, status: e.target.value })}
      >
        <option value="">Select status</option>
        <option value="PROSPECTION">PROSPECTION</option>
        <option value="QUALIFIED">QUALIFIED</option>
        <option value="DISCOVERY">DISCOVERY</option>
        <option value="PROPOSAL">PROPOSAL</option>
        <option value="NEGOTIATION">NEGOTIATION</option>
        <option value="CLOSING">CLOSING</option>
        <option value="WON">WON</option>
      </select>
    </div>
  );
};

export default Status; 