import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Loader from "../../../components/loader";

import api from "../../../services/api";

import Info from "./info";
import Raw from "./raw";
import Seo from "./seo";
import Stats from "./stats";
import Editor from "./editor";
import Claude from "./claude";

export default () => {
  const [tab, setTab] = useState("info");
  const [article, setArticle] = useState();
  const { id } = useParams();

  useEffect(() => {
    get();
  }, [id]);

  async function get() {
    try {
      const { data } = await api.get(`/article/${id}`);
      setArticle(data);
    } catch (e) {
      console.log("a", e);
    }
  }

  if (!article) return <Loader />;

  return (
    <div className="p-2 bg-gray-50">
      <div className="overflow-auto">
        <nav className="bg-white rounded-md flex item-center gap-2 w-full border border-neutral-300 overflow-hidden p-1">
          <TabItem key="info" tab="info" title="Info" setTab={setTab} active={tab === "info"} />
          {article.collection_name && <TabItem key="editor" tab="editor" title="Editor" setTab={setTab} active={tab === "editor"} />}
          <TabItem key="seo" tab="seo" title="SEO technique" setTab={setTab} active={tab === "seo"} />
          <TabItem key="claude" tab="claude" title="Claude" setTab={setTab} active={tab === "claude"} />
          <TabItem key="stats" tab="stats" title="Stats" setTab={setTab} active={tab === "stats"} />
          <TabItem key="raw" tab="raw" title="Raw" setTab={setTab} active={tab === "raw"} />
          <TabItem
            key="url"
            tab="url"
            title="View"
            setTab={() => {
              window.open(article.url, "_blank");
            }}
            active={tab === "url"}
          />
        </nav>
      </div>
      <div>
        {tab === "info" && <Info article={article} setArticle={setArticle} />}
        {tab === "editor" && <Editor article={article} setArticle={setArticle} />}
        {tab === "claude" && <Claude article={article} setArticle={setArticle} />}
        {tab === "seo" && <Seo article={article} setArticle={setArticle} />}
        {tab === "stats" && <Stats article={article} setArticle={setArticle} />}
        {tab === "raw" && <Raw article={article} />}
      </div>
    </div>
  );
};

const TabItem = ({ tab, title, setTab, active }) => (
  <button onClick={() => setTab(tab)} className={`${active ? "blue-btn" : "transparent-btn"} relative rounded py-2 px-6 flex items-center gap-2`}>
    <div className="flex items-center gap-2">{title}</div>
  </button>
);
