import React, { useEffect, useState } from "react";
import Table from "../../components/Table";
import Paginator from "../../components/Paginator";
import Modal from "../../components/modal";
import api from "../../services/api";

export default () => {
  const [filters, setFilters] = useState({ page: 1, limit: 100 });
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  useEffect(() => {
    fetchEvents();
  }, []);

  async function fetchEvents() {
    const response = await api.post("/event/search", filters);
    setEvents(response.data);
  }

  return (
    <div>
      Events
      <SyncronizeGoogle />
      <EventModal data={selectedEvent} onClose={() => setSelectedEvent(null)} />
      <Table
        total={events.length}
        header={[
          { title: "Name", key: "name" },
          { title: "Date", key: "date" },
        ]}
        loading={loading}
        height="h-[32rem]"
        sticky={true}
        noResultRender={<div className="w-full bg-white text-center p-2 my-5 rounded shadow-sm">Aucune résultat n'a été trouvé</div>}>
        {events.map((item, index) => {
          return (
            <tr
              className={`${index % 2 === 0 ? "bg-gray-100" : "bg-gray-50"} cursor-pointer`}
              key={item._id}
              onClick={async (e) => {
                try {
                  e.stopPropagation();
                  setSelectedEvent(item);
                } catch (error) {
                  toast.error("Error fetching company");
                }
              }}>
              <td className="border hover:bg-gray-200 px-4 py-2 max-w-xs truncate">
                <div className="flex items-center gap-2">
                  <span>{item.name}</span>
                </div>
              </td>
              <td className="border hover:bg-gray-200 px-4 py-2 max-w-xs truncate">{new Date(item.date).toLocaleDateString()}</td>
            </tr>
          );
        })}
      </Table>
      <div className="mt-1">
        <Paginator page={filters.page} setPage={(page) => setFilters({ ...filters, page })} last={Math.ceil(total / 100)} />
      </div>
    </div>
  );
};

const SyncronizeGoogle = () => {
  async function initiateGoogleAuth() {
    try {
      const response = await api.get("/google/auth");
      window.open(response.url, "_blank");
    } catch (error) {
      console.log(error);
      console.error("Error initiating auth:", error);
    }
  }

  return (
    <div className="">
      COUCOU
      <button onClick={initiateGoogleAuth}>Initiate Google Auth</button>
    </div>
  );
};

const EventModal = ({ data, onClose }) => {
  const [tab, setTab] = useState("details");

  if (!data) return <div />;
  return (
    <Modal isOpen={true} onClose={onClose} className="p-4">
      <div className="bg-white rounded-md flex flex-col w-full border-[1px] border-gray-200 overflow-hidden">
        <div className="text-sm mb-4 font-medium text-center text-gray-500 border-b border-gray-200">
          <ul className="flex flex-wrap -mb-px list-none space-x-2">
            <li
              onClick={() => setTab("details")}
              className={`inline-block p-2 rounded-t-lg border-b-2 hover:text-gray-600 cursor-pointer ${
                tab === "details" ? "border-primary" : "border-transparent"
              }`}>
              Details
            </li>
            <li
              onClick={() => setTab("attendees")} 
              className={`inline-block p-2 rounded-t-lg border-b-2 hover:text-gray-600 cursor-pointer ${
                tab === "attendees" ? "border-primary" : "border-transparent"
              }`}>
              Attendees
            </li>
            <li
              onClick={() => setTab("raw")}
              className={`inline-block p-2 rounded-t-lg border-b-2 hover:text-gray-600 cursor-pointer ${
                tab === "raw" ? "border-primary" : "border-transparent"
              }`}>
              Raw
            </li>
          </ul>
        </div>
      </div>
      <div className="bg-white rounded-md flex flex-col w-full border-[1px] border-gray-200 mt-2 p-2">
        {tab === "details" && (
          <div className="my-2">
            Details content
          </div>
        )}
        {tab === "attendees" && (
          <div className="my-2">
            Attendees content
          </div>
        )}
        {tab === "raw" && (
          <div className="my-2">
            <pre className="whitespace-pre-wrap">
              {JSON.stringify(data, null, 2)}
            </pre>
          </div>
        )}
      </div>
    </Modal>
  );
};
