import React, { useEffect, useState } from "react";

import api from "../../../services/api";

export default ({ article, setArticle }) => {
  const [stats, setStats] = useState(null);

  const from = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1);
  const to = new Date();

  useEffect(() => {
    fetchStats();
  }, [article]);

  async function fetchStats() {
    const res = await api.post(`/article/stats`, { article_id: article._id, from, to });
    setStats(res.data);
  }

  if (!stats) return <div>Loading...</div>;

  const daysDiff = Math.ceil((to - from) / (1000 * 60 * 60 * 24));

  return (
    <div className="p-4">
      <div className="text-lg font-bold mb-4">{article.url}</div>

      <div className="mb-4 text-sm text-gray-500">
        Stats on the last {daysDiff} days
      </div>
      
      <div className="grid grid-cols-4 gap-4 mb-8">
        <div className="bg-white p-4 rounded-lg shadow">
          <div className="text-sm text-gray-500">Total Clicks</div>
          <div className="text-2xl font-bold">{stats?.totalClicks || 0}</div>
        </div>
        <div className="bg-white p-4 rounded-lg shadow">
          <div className="text-sm text-gray-500">Total Impressions</div>
          <div className="text-2xl font-bold">{stats?.totalImpressions || 0}</div>
        </div>
        <div className="bg-white p-4 rounded-lg shadow">
          <div className="text-sm text-gray-500">Average Position</div>
          <div className="text-2xl font-bold">{stats?.averagePosition?.toFixed(1) || 0}</div>
        </div>
        <div className="bg-white p-4 rounded-lg shadow">
          <div className="text-sm text-gray-500">Average CTR</div>
          <div className="text-2xl font-bold">{(stats?.averageCTR * 100).toFixed(1) || 0}%</div>
        </div>
      </div>

      <div className="bg-white rounded-lg shadow">
        <div className="p-4 border-b">
          <div className="text-lg font-semibold">Top Queries</div>
        </div>
        <div className="overflow-x-auto">
          <table className="w-full">
            <thead>
              <tr className="bg-gray-50">
                <th className="p-4 text-left">Query</th>
                <th className="p-4 text-left">Clicks</th>
                <th className="p-4 text-left">Impressions</th>
                <th className="p-4 text-left">CTR</th>
                <th className="p-4 text-left">Position</th>
              </tr>
            </thead>
            <tbody>
              {stats?.topQueries
                ?.sort((a, b) => b.impressions - a.impressions)
                .map((query, i) => (
                  <tr key={i} className="border-t">
                    <td className="p-4">
                      <a 
                        href={`https://www.google.com/search?q=${encodeURIComponent(query.query)}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-600 hover:underline"
                      >
                        {query.query}
                      </a>
                    </td>
                    <td className="p-4">{query.clicks}</td>
                    <td className="p-4">{query.impressions}</td>
                    <td className="p-4">{(query.ctr * 100).toFixed(1)}%</td>
                    <td className="p-4">{query.position.toFixed(1)}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
