import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { format } from "date-fns";
import api from "../../services/api";

export default () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const query = new URLSearchParams(search);

  const [projectBudgets, setProjectBudgets] = useState({});
  const [occupations, setOccupations] = useState([]);
  const [sortBy, setSortBy] = useState(null); 

  const months = [...Array(6)].map((_, i) => {
    const date = new Date();
    date.setDate(1);
    date.setMonth(date.getMonth() + i);
    return date;
  });

  useEffect(() => {
    loadBudgets();
    loadOccupations();
  }, []);

  async function loadBudgets() {
    const { data, ok } = await api.post("/budget/monthly-aggregated", {
    });
    if (!ok) return;
    
    const budgetsByProject = data.reduce((acc, item) => {
      acc[item._id] = {
        projectName: item.projectName,
        budgets: item.budgets
      };
      return acc;
    }, {});
    
    setProjectBudgets(budgetsByProject);
  }

  async function loadOccupations() {
    const { data } = await api.post("/b_occupation/search", {});
    setOccupations(data);
  }

  const calculateMonthlyAmount = (budgets, month) => {
    return budgets.reduce((total, budget) => {
      const startDate = new Date(budget.startAt);
      const endDate = new Date(budget.endAt);
      
      // Calculate months difference
      const monthsDiff = (endDate.getFullYear() - startDate.getFullYear()) * 12 + 
                        (endDate.getMonth() - startDate.getMonth()) + 1;
      const totalMonths = Math.max(1, monthsDiff);
      const monthlyAmount = budget.amount / totalMonths;

      const currentDate = new Date(month);
      currentDate.setDate(1); // Set to first of month
      const startDateCompare = new Date(startDate);
      startDateCompare.setDate(1);
      const endDateCompare = new Date(endDate);
      endDateCompare.setDate(1);

      // Compare just year and month
      if (currentDate.getFullYear() === startDateCompare.getFullYear() && currentDate.getMonth() === startDateCompare.getMonth() ||
          currentDate.getFullYear() === endDateCompare.getFullYear() && currentDate.getMonth() === endDateCompare.getMonth() ||
          (currentDate > startDateCompare && currentDate < endDateCompare)) {
        return total + monthlyAmount;
      }
      return total;
    }, 0);
  };

  const getPeopleDetails = (projectId, month) => {
    return occupations.filter(o => 
      o.project_id === projectId && 
      new Date(o.month).getMonth() === month.getMonth() &&
      new Date(o.month).getFullYear() === month.getFullYear()
    );
  };

  const calculatePeopleSell = (people) => {
    return people.reduce((total, person) => {
      if (!person.user_tjms) return total;
      const daysPerMonth = (person.percent / 100) * 22; 
      return total + (person.user_tjms * daysPerMonth);
    }, 0);
  };

  const calculatePeopleCost = (people) => {
    return people.reduce((total, person) => {
      if (!person.user_tjm) return total;
      const daysPerMonth = (person.percent / 100) * 22; 
      return total + (person.user_tjm * daysPerMonth);
    }, 0);
  };

  const getSortedProjects = (projects) => {
    if (!sortBy) return projects;

    return [...projects].sort(([id1, proj1], [id2, proj2]) => {
      const month = new Date(); // Current month for comparison
      
      if (sortBy === 'budget') {
        const budget1 = calculateMonthlyAmount(proj1.budgets, month); 
        const budget2 = calculateMonthlyAmount(proj2.budgets, month);
        const people1 = getPeopleDetails(id1, month);
        const people2 = getPeopleDetails(id2, month);
        const sell1 = calculatePeopleSell(people1);
        const sell2 = calculatePeopleSell(people2);
        return (budget2 - sell2) - (budget1 - sell1);
      }
      
      if (sortBy === 'occupation') {
        const people1 = getPeopleDetails(id1, month);
        const people2 = getPeopleDetails(id2, month);
        const sell1 = calculatePeopleSell(people1);
        const sell2 = calculatePeopleSell(people2);
        const budget1 = calculateMonthlyAmount(proj1.budgets, month);
        const budget2 = calculateMonthlyAmount(proj2.budgets, month);
        return (budget1 - sell1) - (budget2 - sell2);
      }
      if (sortBy === 'margin') {
        const people1 = getPeopleDetails(id1, month);
        const people2 = getPeopleDetails(id2, month);
        const sell1 = calculatePeopleSell(people1);
        const sell2 = calculatePeopleSell(people2);
        const cost1 = calculatePeopleCost(people1);
        const cost2 = calculatePeopleCost(people2);
        const margin1 = sell1 ? (sell1 - cost1) / sell1 : 0;
        const margin2 = sell2 ? (sell2 - cost2) / sell2 : 0;
        return margin1 - margin2;
      }
    });
  };

  const getMonthStats = (month) => {
    let totalBudget = 0;
    let totalSell = 0;
    let totalCost = 0;
    let projectCount = 0;
    let peopleCount = new Set();

    Object.entries(projectBudgets)
      .filter(([_, { projectName }]) => projectName !== "Unavailable")
      .forEach(([projectId, { budgets }]) => {
        const monthlyBudget = calculateMonthlyAmount(budgets, month);
        if (monthlyBudget > 0) projectCount++;
        
        const people = getPeopleDetails(projectId, month);
        people.forEach(person => peopleCount.add(person.user_id));
        
        totalBudget += monthlyBudget;
        totalSell += calculatePeopleSell(people);
        totalCost += calculatePeopleCost(people);
      });

    return {
      budget: totalBudget,
      sell: totalSell,
      cost: totalCost,
      margin: totalSell - totalCost,
      marginPercent: totalSell ? Math.round(((totalSell - totalCost) / totalSell) * 100) : 0,
      projects: projectCount,
      people: peopleCount.size
    };
  };

  const getProjectTotals = (projectId, budgets) => {
    // Get first and last month from the displayed months
    const firstMonth = months[0];
    const lastMonth = months[months.length - 1];
    
    // Calculate total budget from budgets that overlap with the displayed months
    const totalBudget = budgets.reduce((total, budget) => {
      const startDate = new Date(budget.startAt);
      const endDate = new Date(budget.endAt);
      
      // Check if budget overlaps with displayed months range
      if (startDate <= lastMonth && endDate >= firstMonth) {
        return total + budget.amount;
      }
      return total;
    }, 0);

    // Calculate total sell from visible months
    let totalSell = 0;
    months.forEach(month => {
      const people = getPeopleDetails(projectId, month);
      totalSell += calculatePeopleSell(people);
    });

    return { totalBudget, totalSell };
  };

  return (
    <div className="space-y-8 mr-8">
      <div className="flex gap-2 mb-4">
        <button
          onClick={() => setSortBy(sortBy === 'budget' ? null : 'budget')}
          className={`px-3 py-1.5 text-sm rounded-md border ${
            sortBy === 'budget' 
              ? 'bg-blue-50 border-blue-200 text-blue-600' 
              : 'border-gray-200 hover:border-gray-300'
          }`}
        >
          Order by budget to fill
        </button>
        <button
          onClick={() => setSortBy(sortBy === 'occupation' ? null : 'occupation')}
          className={`px-3 py-1.5 text-sm rounded-md border ${
            sortBy === 'occupation' 
              ? 'bg-blue-50 border-blue-200 text-blue-600' 
              : 'border-gray-200 hover:border-gray-300'
          }`}
        >
          Order by over-occupation
        </button>
        <button
          onClick={() => setSortBy(sortBy === 'margin' ? null : 'margin')}
          className={`px-3 py-1.5 text-sm rounded-md border ${
            sortBy === 'margin' 
              ? 'bg-blue-50 border-blue-200 text-blue-600' 
              : 'border-gray-200 hover:border-gray-300'
          }`}
        >
          Order by low margin
        </button>
      </div>

      <div className="overflow-x-auto">
        <h2 className="text-lg font-medium mb-4">Project Budgets by Month</h2>
        <table className="w-full">
          <thead>
            <tr className="border-b">
              <th className="text-left p-2">Project</th>
              <th className="text-left p-2">Totals</th>
              {months.map((month) => (
                <th key={month.toString()} className="text-left p-2 min-w-[300px]">
                  {format(month, "MMM yyyy")}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {getSortedProjects(Object.entries(projectBudgets))
              .filter(([_, { projectName }]) => projectName !== "Unavailable")
              .filter(([projectId, { budgets }]) => {
                return months.some(month => calculateMonthlyAmount(budgets, month) > 0);
              })
              .map(([projectId, { projectName, budgets }]) => {
                const { totalBudget, totalSell } = getProjectTotals(projectId, budgets);
                return (
                  <tr key={projectId} className="border-b hover:bg-gray-50">
                    <td className="p-2">
                      <div className="font-medium">{projectName}</div>
                    </td>
                    <td className="p-2">
                      <div className="px-3 py-2 bg-gray-50 rounded-lg">
                        <div className="text-sm">
                          <div className="font-medium text-blue-700">
                            Budget: €{Math.round(totalBudget).toLocaleString()}
                          </div>
                          <div className="font-medium text-blue-600">
                            Sell so far: €{Math.round(totalSell).toLocaleString()}
                          </div>
                        </div>
                      </div>
                    </td>
                    {months.map((month) => {
                      const monthlyTotal = calculateMonthlyAmount(budgets, month);
                      if (monthlyTotal === 0) return <td key={month.toString()} className="p-1.5" />;

                      const people = getPeopleDetails(projectId, month);
                      const peopleSell = calculatePeopleSell(people);
                      const peopleCost = calculatePeopleCost(people);
                      const margin = peopleSell - peopleCost;
                      const marginPercent = peopleSell ? Math.round((margin / peopleSell) * 100) : 0;

                      return (
                        <td key={month.toString()} className="p-1.5">
                          <div className="px-3 py-2 bg-blue-50 rounded-lg">
                            <div className="flex justify-between items-start">
                              <div>
                                <div className="text-sm font-medium text-blue-700">
                                  €{Math.round(monthlyTotal).toLocaleString()}
                                </div>
                                <div className="text-xs text-gray-500 mt-1">
                                  {people.length} {people.length === 1 ? 'person' : 'people'}
                                </div>
                              </div>
                              {peopleSell > 0 && peopleCost > 0 && (
                                <div className="text-xs text-right">
                                  <div className="font-medium text-blue-600">
                                    Sell: €{Math.round(peopleSell).toLocaleString()}
                                  </div>
                                  <div className="font-medium text-gray-600">
                                    Cost: €{Math.round(peopleCost).toLocaleString()}
                                  </div>
                                  <div className={`font-medium ${margin >= 0 ? 'text-green-600' : 'text-red-600'} mt-1`}>
                                    Margin: {margin >= 0 ? '+' : ''}{Math.round(margin).toLocaleString()}€
                                    <div className="text-gray-500 font-normal">
                                      {marginPercent}%
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className="space-y-1 mt-2 border-t pt-2">
                              {people.map((person) => (
                                <div key={person._id} className="text-xs">
                                  <div className="flex items-center justify-between">
                                    <div className="flex items-center gap-1">
                                      <span 
                                        className="font-medium cursor-pointer hover:text-blue-600"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          query.set("user_modal_id", person.user_id);
                                          query.set("user_modal_tab", "occupation");
                                          navigate({ search: query.toString() });
                                        }}
                                      >
                                        {person.user_name}
                                      </span>
                                      <span className="text-gray-400">({person.percent}%)</span>
                                    </div>
                                    {person.user_tjms && person.user_tjm && (
                                      <div className="text-gray-500">
                                        {person.user_tjm}€/d → {person.user_tjms}€/d · {Math.round((person.percent / 100) * 22)}d ·
                                        <span className="font-medium ml-1">
                                          €{Math.round(person.user_tjms * (person.percent / 100) * 22).toLocaleString()}
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};