import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import api from "../../services/api";

const PAID_ACTIVITIES = ["project", "startup-project"];

export default () => {

  const [users, setUsers] = useState([]);
  const [budgetData, setBudgetData] = useState([]);
  
  const months = [...Array(2)].map((_, i) => {
    const date = new Date();
    date.setMonth(date.getMonth() - i);
    date.setDate(1);
    return date;
  });

  useEffect(() => {
    loadUsers();
    loadBudgets();
  }, []);


  async function loadUsers() {
    const { data } = await api.post("/user/search", {
      contract: [CONTRACTS.CDD, CONTRACTS.CDI, CONTRACTS.VIE, CONTRACTS.INTERNSHIP, CONTRACTS.APPRENTICESHIP, CONTRACTS.FREELANCE],
      roles: ["user", "admin"],
      status: ["active"],
    });


    setUsers(data.users);
  }

  async function loadBudgets() {
    const { data, ok } = await api.get("/activity/stats/by-user-and-budget-type", {});
    if (!ok) return;
    setBudgetData(data);
  }

  const getBudgetForUserAndMonth = (budgetData, userName, month) => {
    if (!budgetData?.[userName]) return null;
  
    const monthKey = `${month.getFullYear()}-${String(month.getMonth() + 1).padStart(2, "0")}`;
    return budgetData[userName][monthKey];
  };

  const navigate = useNavigate();
  const { search } = useLocation();
  const query = new URLSearchParams(search);

  // Helper function to calculate total hours for paid activities
  const calculateUserTotalPaidActivities = (userName) => {
    const userBudget = getBudgetForUserAndMonth(budgetData, userName, months[0]);
    if (!userBudget) return 0;

    return Object.entries(userBudget)
      .filter(([budgetKey]) => {
        const [type] = budgetKey.split('|');
        return PAID_ACTIVITIES.includes(type);
      })
      .reduce((sum, [_, hours]) => sum + Number(hours), 0);
  };

  const calculateMonthTotal = (month) => {
    let totalHours = 0;
    let totalFinancial = 0;

    if (!users?.length) return { days: "0", percentage: "0", financial: 0 };

    users.forEach(user => {
      const monthBudget = getBudgetForUserAndMonth(budgetData, user.name, month);
      if (!monthBudget) return;

      Object.entries(monthBudget).forEach(([key, hours]) => {
        const [type] = key.split('|');
        if (!PAID_ACTIVITIES.includes(type)) return;
        
        const hoursNum = Number(hours);
        totalHours += hoursNum;
        if (user.tjms) {
          totalFinancial += (hoursNum / 8) * user.tjms;
        }
      });
    });

    return {
      days: (totalHours / 8).toFixed(1),
      percentage: ((totalHours / (8 * 22)) * 100).toFixed(1),
      financial: totalFinancial
    };
  };

  const displayedUsers = users?.length ? [...users].sort((a, b) => {
    const totalA = calculateUserTotalPaidActivities(a.name);
    const totalB = calculateUserTotalPaidActivities(b.name);
    return totalB - totalA;
  }) : [];

  return (
    <div className="overflow-x-auto">
      <table className="w-full">
        <thead>
          <tr className="border-b">
            <th className="text-left p-1 text-xs w-[120px]">
              <span>Person</span>
            </th>
            {months.map((month, index) => (
              <th key={month.toString()} className="text-left p-1 text-xs min-w-[150px]">
                {index === 0 ? "This month" : "Last month"}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {/* Add the total row first */}
          <tr className="border-b-2 font-semibold bg-gray-50">
            <td className="p-1">
              <div className="text-sm">Total</div>
            </td>
            {months.map((month) => {
              const total = calculateMonthTotal(month);
              return (
                <td key={month.toString()} className="p-1">
                  <div className="text-sm">
                    {total.days}d ({total.percentage}%)
                    <span className="ml-2 text-gray-500">
                      {total.financial.toLocaleString('fr-FR')}€
                    </span>
                  </div>
                </td>
              );
            })}
          </tr>
          {displayedUsers.map((user, index) => {
            const isInactive = user.exit_date && new Date() > new Date(user.exit_date);

            return (
              <tr key={user._id} className={`border-b hover:bg-gray-200 hover:bg-opacity-50 cursor-pointer ${isInactive ? "bg-gray-100" : ""}`}>
                <td className="p-1">
                  <div
                    onClick={async () => {
                      query.set("user_modal_id", user._id);
                      query.set("user_modal_tab", "activity");
                      navigate({ search: query.toString() });
                    }}
                    className="text-sm font-medium">
                    {user.name}
                  </div>
                  <div className="text-[11px] text-gray-400 -mt-0.5 space-x-1">
                    <span>{user.office}</span>
                    <span>·</span>
                    <span>{user.contract}</span>
                  </div>
                </td>
                {months.map((month) => {
                  const monthBudget = getBudgetForUserAndMonth(budgetData, user.name, month);
                  const key = month.toString();

                  return (
                    <td key={key} className="p-1 relative">
                      <div className="flex flex-col gap-1">
                        <BudgetBar budgetData={monthBudget} user={user} />
                      </div>
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const BudgetBar = ({ budgetData, user }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  if (!budgetData) return null;

  const HOURS_PER_DAY = 8;
  const DAYS_PER_MONTH = 22;
  const TOTAL_HOURS = HOURS_PER_DAY * DAYS_PER_MONTH;

  const groupedBudgets = Object.entries(budgetData).reduce((acc, [key, hours]) => {
    const [type] = key.split('|');
    if (!PAID_ACTIVITIES.includes(type)) return acc;
    
    if (!acc[type]) acc[type] = [];
    acc[type].push({
      description: key.split('|')[1] || 'Default budget',
      hours: Number(hours),
      days: (Number(hours) / HOURS_PER_DAY).toFixed(1),
      percentage: ((Number(hours) / TOTAL_HOURS) * 100).toFixed(1)
    });
    
    return acc;
  }, {});

  // Calculate totals including financial impact
  const totals = Object.entries(groupedBudgets).reduce((acc, [type, entries]) => {
    const hoursTotal = entries.reduce((sum, entry) => sum + Number(entry.hours), 0);
    const daysTotal = hoursTotal / HOURS_PER_DAY;
    const financialTotal = user?.tjms ? daysTotal * user.tjms : 0;

    acc[type] = {
      hours: hoursTotal,
      days: daysTotal,
      financial: financialTotal
    };
    return acc;
  }, {});

  // Sort budget types by their totals
  const sortedBudgetTypes = Object.entries(groupedBudgets)
    .sort(([typeA, entriesA], [typeB, entriesB]) => {
      const totalA = totals[typeA];
      const totalB = totals[typeB];
      return totalB - totalA; // Sort descending
    });

  const totalHours = Object.values(totals).reduce((sum, { hours }) => sum + hours, 0);
  const totalDays = (totalHours / HOURS_PER_DAY).toFixed(1);
  const totalPercentage = ((totalHours / TOTAL_HOURS) * 100).toFixed(1);
  const totalFinancial = Object.values(totals).reduce((sum, { financial }) => sum + financial, 0);

  const TYPE_COLORS = {
    admin: "bg-red-500",
    prospection: "bg-blue-500",
    invest: "bg-yellow-500",
    overbudget: "bg-black-500",
    undefined: "bg-gray-500",
  };

  return (
    <div className="p-3 bg-white rounded-lg shadow">
      <div className="flex items-center gap-2 mb-2 cursor-pointer" onClick={() => setIsExpanded(!isExpanded)}>
        <div className="flex-grow h-3 bg-gray-100 rounded-full overflow-hidden flex">
          {sortedBudgetTypes.map(([type, entries]) => {
            const percentage = (totals[type].hours / TOTAL_HOURS) * 100;
            return <div key={type} className={`${TYPE_COLORS[type] || "bg-gray-500"} h-full`} style={{ width: `${percentage}%` }} />;
          })}
        </div>
        <span className="text-xs whitespace-nowrap">
          {totalDays}d ({totalPercentage}%)
          {user?.tjms && (
            <span className="ml-2 text-gray-500">
              {totalFinancial.toLocaleString('fr-FR')}€
            </span>
          )}
        </span>
      </div>

      {isExpanded && (
        <div className="mt-4 space-y-4">
          {sortedBudgetTypes.map(([type, entries]) => (
            <div key={type} className="space-y-2">
              <div className="flex justify-between items-center">
                <div className="font-medium text-sm capitalize text-gray-700">
                  {type}
                </div>
                <div className="text-xs text-gray-500">
                  {(totals[type].days).toFixed(1)}d 
                  ({((totals[type].hours / TOTAL_HOURS) * 100).toFixed(1)}%)
                  {user?.tjms && (
                    <span className="ml-2">
                      {totals[type].financial.toLocaleString('fr-FR')}€
                    </span>
                  )}
                </div>
              </div>
              
              {/* Sort entries within each type by hours */}
              {[...entries]
                .sort((a, b) => b.hours - a.hours)
                .map((entry, index) => (
                <div 
                  key={index} 
                  className="bg-gray-50 p-2 rounded text-sm"
                >
                  <div className="flex justify-between">
                    <span className="capitalize">{entry.description}</span>
                    <span>
                      {entry.days}d ({entry.percentage}%)
                    </span>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const CONTRACTS = {
  CDD: "CDD",
  CDI: "CDI",
  VIE: "VIE",
  INTERNSHIP: "INTERNSHIP",
  APPRENTICESHIP: "APPRENTICESHIP",
  FREELANCE: "FREELANCE",
};
