import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { LuChevronsRight, LuFile, LuFileStack } from "react-icons/lu";
import { IoCubeOutline, IoFileTrayFullOutline, IoInformationCircleOutline, IoChatboxEllipsesOutline, IoShareSocialOutline } from "react-icons/io5";

import api from "../../../../services/api";

import PageEdit from "./PageEdit";
import BlocksLibrary from "./BlocksLibrary";
import TemplateLibrary from "./TemplateLibrary";
import Info from "./Info";
import Comment from "./Comment";
import Versions from "./Versions";
import Social from "./Social";

export default ({ quote, pages, blocks, onQuoteChange, onPagesChange, onBlocksChange, selection }) => {
  const [open, setOpen] = useState(true);
  const [menu, setMenu] = useState("info");
  const [loading, setLoading] = useState(false);

  const handleAddBlock = async (block) => {
    try {
      const pId = selection.page || pages[pages.length - 1]._id;
      const obj = {
        ...block,
        _id: undefined,
        quote_id: quote._id,
        quote_name: quote.name,
        quote_page_id: pId,
        position: blocks.filter((b) => b.quote_page_id === pId).length,
      };
      const { data: b } = await api.post(`/quote_block`, obj);
      if (!b) return toast.error("new block is null block");

      onBlocksChange([...blocks, b]);

      toast.success("Added block to quote");
    } catch (error) {
      console.error(error);
      toast.error("Error creating block");
    }
  };

  const handleAddTemplate = async (template) => {
    if (loading) return;
    setLoading(true);

    try {
      const newPages = [];
      const newBlocks = [];

      const resPages = await api.post(`/quote_page/search`, { quote_id: template._id });
      for (const page of resPages.data) {
        const obj = {
          quote_id: quote._id,
          quote_name: quote.name,
          position: pages.length + page.position,
          template_id: template._id,
          template_name: template.name,
          format: page.format,
        };
        const { ok, data } = await api.post(`/quote_page`, obj);
        if (!ok) throw new Error("Error creating page");
        newPages.push(data);

        const resBlocks = await api.post(`/quote_block/search`, { quote_page_id: page._id });

        const b = resBlocks.data
          .sort((a, b) => a.position - b.position)
          .map((block) => ({
            ...block,
            _id: undefined,
            quote_id: quote._id,
            quote_name: quote.name,
            quote_page_id: data._id,
          }));

        for (let j = 0; j < b.length; j++) {
          const { ok, data } = await api.post(`/quote_block`, b[j]);
          if (!ok) throw new Error("Error creating block");
          newBlocks.push(data);
        }
      }

      onPagesChange([...pages, ...newPages]);
      onBlocksChange([...blocks, ...newBlocks]);
      toast.success("Added block to quote");
    } catch (error) {
      console.error(error);
      toast.error("Error creating page");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={`bg-gray-50 flex border-x border-gray-300 h-full ${open ? "w-[30%]" : "w-16"}`}>
      <div className="flex flex-col w-16 items-center border-r border-gray-300">
        <button className="p-6" onClick={() => setOpen(!open)}>
          <LuChevronsRight className={`transform ${open ? "" : "rotate-180"}`} />
        </button>
        <button className={`p-6 ${menu === "info" ? "text-sky-500" : ""}`} onClick={() => setMenu("info")}>
          <IoInformationCircleOutline />
        </button>
        <button className={`p-6 ${menu === "blocks" ? "text-sky-500" : ""}`} onClick={() => setMenu("blocks")}>
          <IoCubeOutline />
        </button>
        <button className={`p-6 ${menu === "templates" ? "text-sky-500" : ""}`} onClick={() => setMenu("templates")}>
          <IoFileTrayFullOutline />
        </button>
        <button className={`p-6 relative ${menu === "comment" ? "text-sky-500" : ""}`} onClick={() => setMenu("comment")}>
          <IoChatboxEllipsesOutline />
          {quote.comments && quote.comments.length > 0 && (
            <div className="absolute inline-flex items-center justify-center w-4 h-4 text-xs font-semibold text-white bg-sky-500/90 border border-white rounded-full top-3.5 end-3.5">
              {quote.comments.length}
            </div>
          )}
        </button>
        {(selection.page !== null || selection.block !== null) && (
          <button className={`p-6 ${menu === "page" ? "text-sky-400" : ""}`} onClick={() => setMenu("page")}>
            <LuFile />
          </button>
        )}
        {quote.versions_files.length > 0 && (
          <button className={`p-6 ${menu === "versions" ? "text-sky-400" : ""}`} onClick={() => setMenu("versions")}>
            <LuFileStack />
          </button>
        )}

        <button className={`p-6 ${menu === "social" ? "text-sky-500" : ""}`} onClick={() => setMenu("social")}>
          <IoShareSocialOutline />
        </button>
      </div>
      <div className={`${open ? "block flex-1" : "bg-red-200 hidden w-0 opacity-0"} transition-all  max-h-full overflow-auto`}>
        {menu === "info" && <Info quote={quote} pages={pages} blocks={blocks} setQuote={onQuoteChange} />}
        {menu === "comment" && <Comment quote={quote} pages={pages} setQuote={onQuoteChange} />}
        {menu === "blocks" && <BlocksLibrary onClick={handleAddBlock} />}
        {menu === "templates" && <TemplateLibrary onClick={handleAddTemplate} onClose={() => setMenu("info")} />}
        {menu === "page" && <PageEdit page={pages.find((p) => p._id === selection.page)} pages={pages} onPagesChange={onPagesChange} />}
        {menu === "versions" && <Versions quote={quote} />}
        {menu === "social" && <Social quote={quote} />}
      </div>
    </div>
  );
};
