import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import api from "../../services/api";
import { format, addMonths } from "date-fns";
import SelectProject from "../selectProject";

export default ({ user }) => {
  const [occupations, setOccupations] = useState([]);
  const [projects, setProjects] = useState([]);
  const [newOccupation, setNewOccupation] = useState({});

  const getOccupations = async () => {
    try {
      const { data, ok } = await api.post("/b_occupation/search", {
        user_id: user._id,
        sort: { month: -1 }
      });
      if (!ok) throw new Error("Error loading occupations");
      setOccupations(data);
    } catch (error) {
      toast.error("Error loading occupations");
    }
  };

  useEffect(() => {
    getOccupations();
  }, []);

  const months = [...Array(6)].map((_, i) => {
    return addMonths(new Date(), i);
  });


  const handleOccupationChange = async (month, project, percentage) => {
    try {
      const projectData = projects.find(p => p._id === project);
      // Find if there's a TJMS override for this user in this project
      const tjmsOverride = projectData?.user_tjms_override?.find(o => o.userId === user._id)?.tjms;
      
      const { ok } = await api.post("/b_occupation", {
        user_id: user._id,
        user_name: user.name,
        user_avatar: user.avatar,
        user_tjms: tjmsOverride || user.tjms, // Only override tjms if exists
        user_tjm: user.tjm, // Always use default tjm
        project_id: project,
        project_name: projectData.name,
        month: month,
        percent: parseInt(percentage),
        soft: false
      });

      if (!ok) throw new Error("Error updating occupation");
      await getOccupations();
      toast.success("Occupation updated");
    } catch (error) {
      toast.error("Error updating occupation");
    }
  };

  const handleDelete = async (occupationId) => {
    try {
      const { ok } = await api.remove(`/b_occupation/${occupationId}`);
      if (!ok) throw new Error("Error deleting occupation");
      await getOccupations();
      toast.success("Occupation deleted");
    } catch (error) {
      toast.error("Error deleting occupation");
    }
  };

  const handleNewOccupationSave = async (month) => {
    try {
      const occupation = newOccupation[format(month, "MMMM yyyy")];
      if (!occupation?.project || !occupation?.percentage) {
        return toast.error("Please fill all fields");
      }

      // Find if there's a TJMS override for this user in this project
      const tjmsOverride = occupation.project?.user_tjms_override?.find(o => o.userId === user._id)?.tjms;

      const { ok } = await api.post("/b_occupation", {
        user_id: user._id,
        user_name: user.name,
        user_avatar: user.avatar,
        user_tjm: user.tjm, // Always use default tjm
        user_tjms: tjmsOverride || user.tjms, // Only override tjms if exists
        project_id: occupation.project._id,
        project_name: occupation.project.name,
        month: month,
        percent: parseInt(occupation.percentage),
        soft: occupation.isSoft || false
      });

      if (!ok) throw new Error("Error saving occupation");
      await getOccupations();
      setNewOccupation({...newOccupation, [month]: null});
      toast.success("Occupation saved");
    } catch (error) {
      toast.error("Error saving occupation");
    }
  };

  return (
    <div className="p-4">
      <div className="space-y-4">
        {months.map((month) => {
          const monthStr = format(month, "MMMM yyyy");
          const monthOccupations = occupations.filter(o => 
            new Date(o.month).getMonth() === month.getMonth() && 
            new Date(o.month).getFullYear() === month.getFullYear()
          );

          return (
            <div key={monthStr} className="border rounded p-4">
              <div className="flex justify-between items-center mb-4">
                <h3 className="font-medium">{monthStr}</h3>
                <button
                  onClick={() => setNewOccupation({...newOccupation, [monthStr]: {}})}
                  className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600"
                >
                  Add
                </button>
              </div>

              <div className="space-y-4">
                {newOccupation[monthStr] && (
                  <div className="flex items-center gap-4 bg-gray-50 p-2 rounded">
                    <SelectProject 
                      value={newOccupation[monthStr]?.project?._id}
                      onChange={(project) => setNewOccupation({
                        ...newOccupation,
                        [monthStr]: {...newOccupation[monthStr], project}
                      })}
                      filters={{ status: "active" }}
                    />
                    <input
                      type="number"
                      min="0"
                      max="100"
                      placeholder="%"
                      value={newOccupation[monthStr]?.percentage || ''}
                      onChange={(e) => setNewOccupation({
                        ...newOccupation,
                        [monthStr]: {...newOccupation[monthStr], percentage: e.target.value}
                      })}
                      className="border rounded px-2 py-1 w-20"
                    />
                    <label className="flex items-center gap-2">
                      <input
                        type="checkbox"
                        checked={newOccupation[monthStr]?.isSoft || false}
                        onChange={(e) => setNewOccupation({
                          ...newOccupation,
                          [monthStr]: {...newOccupation[monthStr], isSoft: e.target.checked}
                        })}
                      />
                      Soft
                    </label>
                    <button
                      onClick={() => handleNewOccupationSave(month)}
                      className="bg-green-500 text-white px-3 py-1 rounded hover:bg-green-600"
                    >
                      Save
                    </button>
                  </div>
                )}

                {monthOccupations.map((occupation) => (
                  <div key={occupation._id} className="flex items-center gap-4">
                    <div className="w-32">{occupation.project_name}</div>
                    <input
                      type="number"
                      min="0"
                      max="100"
                      value={occupation.percent}
                      onChange={(e) => handleOccupationChange(month, occupation.project_id, e.target.value)}
                      className="border rounded px-2 py-1 w-20"
                    />
                    <span>%</span>
                    <div className={`w-4 h-4 rounded ${occupation.soft ? 'bg-striped' : ''}`}></div>
                    <button
                      onClick={() => handleDelete(occupation._id)}
                      className="text-red-500 hover:text-red-700"
                    >
                      Delete
                    </button>
                  </div>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
