import { useState } from "react";
import { IoEllipsisVertical } from "react-icons/io5";

export default ({ actions }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="relative h-[38px]">
      <button
        onClick={(e) => {
          e.stopPropagation();
          setIsOpen(!isOpen);
        }}
        className="inline-flex justify-between items-center h-[38px] gap-4 px-2 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
        <IoEllipsisVertical className="w-4 h-4" />
      </button>
      {isOpen && (
        <>
          <div className="fixed inset-0" onClick={() => setIsOpen(false)} />
          <div className="absolute top-full right-0 w-56 bg-white border border-gray-200 rounded-lg shadow-xl z-10 overflow-hidden animate-fadeIn">
            <div className="py-2">
              {actions.map((action, index) => (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    action.cb(e);
                    setIsOpen(false);
                  }}
                  className="w-full px-4 py-2.5 text-left text-sm font-medium text-gray-700 hover:bg-gray-50 transition-colors duration-200 cursor-pointer flex items-center gap-2">
                  {action.name}
                </button>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
