import React, { useEffect, useState } from "react";
import FileInput from "../../../../components/FileInput";
import RichTextEditorSun from "../../../../components/RichTextEditor";
import { applyFontClassToHtml } from "../utils";

const CvExperience = ({ values, editing, onChange, lang }) => {
  if (editing) return <CvExperienceForm values={values} onChange={onChange} lang={lang} />;

  return (
    <div className="flex gap-6 py-2 bg-white">
      {values.logo_url && (
        <div className="flex-shrink-0 w-16 h-16">
          <img src={values.logo_url} alt="logo" className="w-full h-full object-contain" />
        </div>
      )}
      <div className="flex-1">
        <div className="flex justify-between items-center mb-2">
          <h4 className="font-semibold text-xl text-gray-900">{values.name[lang]}</h4>
          <span className="text-sm text-gray-500 whitespace-nowrap">{values.start_and_end_date}</span>
        </div>
        <div className="text-sm text-gray-600 mb-2">{values.location[lang]}</div>
        <div className="text-sm text-gray-700" dangerouslySetInnerHTML={{ __html: applyFontClassToHtml(values.description[lang]) }}></div>
        {values.image_url && <img src={values.image_url} alt="experience" className="w-full h-auto max-h-64 object-cover rounded-lg mt-4" />}
      </div>
    </div>
  );
};

const CvExperienceForm = ({ values, onChange, lang }) => {
  const handleChange = (field, value) => {
    onChange({ ...values, [field]: value });
  };

  return (
    <div className="flex flex-col gap-2 p-2 bg-white border border-gray-200 rounded-md shadow-md my-6 ">
      <div className="w-[100px]">
        <label htmlFor="image_url">Logo</label>
        <FileInput folder="quotes/cv_experiences" value={values.logo_url} name="logo_url" onChange={(e) => handleChange(e.target.name, e.target.value)} />
      </div>
      <div className="flex flex-row justify-between">
        <div>
          <label htmlFor="title">Experience Name</label>
          <input
            className="input"
            type="text"
            id="name"
            name="name"
            value={values.name[lang]}
            onChange={(e) => handleChange(e.target.name, { ...values[e.target.name], [lang]: e.target.value })}
          />
        </div>
        <div>
          <label htmlFor="title">Start & End Date</label>
          <input
            className="input"
            type="text"
            id="start_and_end_date"
            name="start_and_end_date"
            value={values.start_and_end_date}
            onChange={(e) => handleChange(e.target.name, e.target.value)}
          />
        </div>
      </div>
      <div>
        <label htmlFor="location">Location</label>
        <input
          className="input"
          type="text"
          id="location"
          name="location"
          value={values.location[lang]}
          onChange={(e) => handleChange(e.target.name, { ...values[e.target.name], [lang]: e.target.value })}
        />
      </div>
      <div>
        <label htmlFor="description">Description</label>
        <RichTextEditorSun
          buttonList={[
            ["undo", "redo"],
            ["font", "fontSize", "formatBlock"],
            ["paragraphStyle", "blockquote"],
            ["bold", "underline", "italic", "strike", "subscript", "superscript"],
            ["fontColor", "hiliteColor"],
            ["align", "list", "lineHeight"],
            ["outdent", "indent"],

            ["table", "horizontalRule", "link", "image", "video"],
            ["removeFormat"],
          ]}
          values={values.description[lang]}
          onChange={(e) => handleChange("description", { ...values.description, [lang]: e })}
        />
      </div>
      <label htmlFor="image_url">Image</label>
      <FileInput folder="quotes/cv_experiences" value={values.image_url} name="image_url" onChange={(e) => handleChange(e.target.name, e.target.value)} />
    </div>
  );
};

export default CvExperience;
