import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
import Loader from "../../../components/loader";
import api from "../../../services/api";

const SKILL_LEVEL_MAPPING = {
  'BAD': 'NOT STARTED',
  'FAIR': 'BEGINNER',
  'GOOD': 'INTERMEDIATE',
  'MASTER': 'ADVANCED'
};

const SKILL_DESCRIPTIONS = {
  'Multiple Project Experience': {
    description: `
      NOT STARTED: 0-1 project for the last 6 months
      BEGINNER: Sometimes does some task on some other project
      INTERMEDIATE: Moves through multiple projects seamlessly
      ADVANCED: Different thematics, different clients, different scenarios, lots of experience in front of the client
    `
  },
  'React Native': {
    description: `
      NOT STARTED: No experience
      BEGINNER: Basic experience, follows conventions
      INTERMEDIATE: Published apps, solved complex issues
      ADVANCED: Team expert, deep technical knowledge
    `
  },
  'Data Modeling': {
    description: `
      NOT STARTED: No experience
      BEGINNER: Has done the SELEGO training materials
      INTERMEDIATE: Regular implementation 
      ADVANCED: Keeps data models simple and clean even in complex projects
    `
  },
  'API Principles': {
    description: `
      NOT STARTED: No experience
      BEGINNER: Has done the SELEGO training materials
      INTERMEDIATE: Regular implementation
      ADVANCED: Keeps API simple and clean even in complex projects
    `
  },
  'Conventions': {
    description: `
      NOT STARTED: No experience
      BEGINNER: Has done the SELEGO training materials
      INTERMEDIATE: Audits show that they follow conventions
      ADVANCED: Follows conventions and teaches others
    `
  },
  'Separation of Concerns': {
    description: `
      NOT STARTED: No experience
      BEGINNER: Has done the SELEGO training materials
      INTERMEDIATE: Regular implementation
      ADVANCED: Keeps code clean and simple even in complex projects
    `
  },
  'Componentisation': {
    description: `
      NOT STARTED: No experience
      BEGINNER: Has done the SELEGO training materials
      INTERMEDIATE: Regular implementation
      ADVANCED: Keeps code clean and simple even in complex projects
    `
  },
  'Devops': {
    description: `
      NOT STARTED: No experience
      BEGINNER: Can deploy basic applications, understands CI/CD concepts
      INTERMEDIATE: Proficient with Docker, CI/CD pipelines, cloud platforms (AWS/GCP)
      ADVANCED: Expert in containerization, orchestration (Kubernetes), infrastructure as code, monitoring and security best practices
    `
  },
  'DevOps Selego PMF Kit': {
    description: `
      NOT STARTED: No experience
      BEGINNER: Has set up a project on clevercloud 
      INTERMEDIATE: Can autonomously do all PMF DevOps tasks (clevercloud, sentry, brevo)
      ADVANCED: Can do all PMF DevOps tasks and can help others with their projects
    `
  },
  'AI': {
    description: `
      NOT STARTED: No experience with AI or not known
      BEGINNER: Has previously integrated chatGPT or similar
      INTERMEDIATE: Has experience with multiple LLMs
      ADVANCED: Knows which is the good AI / LLM for which use case
    `
  },
  'Communication': {
    description: `
      NOT STARTED: Ready to start collaborating with the team!
      BEGINNER: Has done / respects the SELEGO communication training
      INTERMEDIATE: Effectively communicates project status, blockers, and solutions with team and clients
      ADVANCED: Can lead dailies / roadmap meetings, client reports, communicate complex ideas simply
    `
  },
  'Ownership': {
    description: `
      NOT STARTED: Takes no responsibility for tasks or outcomes
      BEGINNER: Takes responsibility for assigned tasks but needs guidance
      INTERMEDIATE: Proactively owns projects and their outcomes, anticipates issues
      ADVANCED: Takes full ownership of complex initiatives, mentors others in ownership mindset
    `
  },
  'GetShitDone': {
    description: `
      NOT STARTED: Needs constant guidance, struggles to find solutions independently
      BEGINNER: Can solve simple problems with available resources and documentation
      INTERMEDIATE: Resourceful problem-solver, finds creative workarounds when needed
      ADVANCED: Master of resourcefulness - can tackle any challenge with minimal resources, always finds a way forward
    `
  },
  'Quote Making': {
    description: `
      NOT STARTED: Has not done a quote, or done a really bad one
      BEGINNER: Has already done a quote that we sent
      INTERMEDIATE: Has done a quote that uses context and use cases
      ADVANCED: Their quotes have high winning potential because they make it with the client in mind
    `
  },
  'Hiring': {
    description: `
      NOT STARTED: Never participated in hiring process or interviews
      BEGINNER: Has participated in technical interviews, can evaluate basic skills
      INTERMEDIATE: Can lead interviews, evaluate cultural fit, and make hiring recommendations
      ADVANCED: Expert at identifying top talent, building hiring processes, and growing teams
    `
  },
};

export default () => {
  const { id } = useParams();
  const [techSkills, setTechSkills] = useState();

  useEffect(() => {
    get();
  }, [id]);

  const get = async () => {
    try {
      const { data, ok, code } = await api.get(`/user_tech_skill/${id}`);
      if (!ok) return toast.error("Error!" || code);
      setTechSkills(data);
    } catch (error) {
      console.log(error);
      return toast.error("Error fetching skills!");
    }
  };

  const SkillRow = ({ skill, level }) => (
    <div key={skill} className="flex items-center gap-2 border-l-4 border-gray-100 pl-3">
      <div className="flex items-center justify-between w-full">
        <span className="text-sm font-medium text-gray-700">
          {skill}
        </span>
        <span
          className={`px-2 py-1 text-xs rounded-full ${
            level === "MASTER"
              ? "bg-green-100 text-green-800"
              : level === "GOOD"
              ? "bg-blue-100 text-blue-800"
              : level === "FAIR"
              ? "bg-yellow-100 text-yellow-800"
              : "bg-gray-50 text-gray-600"
          }`}
        >
          {SKILL_LEVEL_MAPPING[level] || 'NOT MAPPED'}
        </span>
      </div>
    </div>
  );

  if (!techSkills) return <Loader />;

  return (
    <div className="p-5 max-w-7xl mx-auto">
      <div className="mb-8">
        <h1 className="text-3xl font-black mb-2">Skills Matrix</h1>
        <p className="text-gray-600">Track your progress across different skill areas.</p>
      </div>

      <div className="overflow-x-auto mb-12 bg-white rounded-xl shadow-sm border">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Category
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Skills & Progression
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Score
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            <tr className="hover:bg-gray-50">
              <td className="px-6 py-4 whitespace-nowrap">
                <span className="text-sm font-medium text-gray-900">Technical Architecture</span>
              </td>
              <td className="px-6 py-4">
                <div className="space-y-1">
                  {["Data Modeling", "API Principles", "Conventions", "Separation of Concerns"].map((skill) => (
                    <SkillRow key={skill} skill={skill} level={techSkills[skill]} />
                  ))}
                </div>
              </td>
              <td className="px-6 py-4">
                <div className="text-lg font-semibold">{techSkills.tech_arch_score?.toFixed(1)}</div>
              </td>
            </tr>

            <tr className="hover:bg-gray-50">
              <td className="px-6 py-4 whitespace-nowrap">
                <span className="text-sm font-medium text-gray-900">Development</span>
              </td>
              <td className="px-6 py-4">
                <div className="space-y-1">
                  {["Componentisation", "React Native", "Multiple Project Experience"].map((skill) => (
                    <SkillRow key={skill} skill={skill} level={techSkills[skill]} />
                  ))}
                </div>
              </td>
              <td className="px-6 py-4">
                <div className="text-lg font-semibold">{techSkills.dev_score?.toFixed(1)}</div>
              </td>
            </tr>

            <tr className="hover:bg-gray-50">
              <td className="px-6 py-4 whitespace-nowrap">
                <span className="text-sm font-medium text-gray-900">Operations</span>
              </td>
              <td className="px-6 py-4">
                <div className="space-y-1">
                  {["Devops", "AI", "DevOps Selego PMF Kit"].map((skill) => (
                    <SkillRow key={skill} skill={skill} level={techSkills[skill]} />
                  ))}
                </div>
              </td>
              <td className="px-6 py-4">
                <div className="text-lg font-semibold">{techSkills.ops_score?.toFixed(1)}</div>
              </td>
            </tr>

            <tr className="hover:bg-gray-50">
              <td className="px-6 py-4 whitespace-nowrap">
                <span className="text-sm font-medium text-gray-900">Soft Skills</span>
              </td>
              <td className="px-6 py-4">
                <div className="space-y-1">
                  {["Communication", "Ownership", "GetShitDone", "Quote Making", "Hiring"].map((skill) => (
                    <SkillRow key={skill} skill={skill} level={techSkills[skill]} />
                  ))}
                </div>
              </td>
              <td className="px-6 py-4">
                <div className="text-lg font-semibold">{techSkills.soft_score?.toFixed(1)}</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="space-y-12">
        {/* Technical Architecture Section */}
        <div className="skill-category-section">
          <h2 className="text-2xl font-bold mb-6 text-gray-800">Technical Architecture Skills</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {["Data Modeling", "API Principles", "Conventions", "Separation of Concerns"].map((skill) => (
              <div key={skill} className="bg-white rounded-lg border p-6 hover:shadow-md transition-shadow">
                <h3 className="font-semibold text-lg mb-4 text-gray-900">{skill}</h3>
                <div className="space-y-3">
                  {SKILL_DESCRIPTIONS[skill]?.description.split('\n').filter(Boolean).map((level, index) => {
                    const levelName = level.split(':')[0].trim();
                    const levelDescription = level.split(':')[1];
                    const isCurrentLevel = SKILL_LEVEL_MAPPING[techSkills[skill]] === levelName;
                    
                    return (
                      <div key={index} className={`text-sm p-2 rounded ${isCurrentLevel ? 'bg-blue-50 border border-blue-200' : ''}`}>
                        <span className={`font-medium ${isCurrentLevel ? 'text-blue-700' : ''}`}>
                          {levelName}:
                        </span>
                        <span className={`${isCurrentLevel ? 'text-blue-600' : 'text-gray-600'}`}>
                          {levelDescription}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Development Section */}
        <div className="skill-category-section">
          <h2 className="text-2xl font-bold mb-6 text-gray-800">Development Skills</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {["Componentisation", "React Native", "Multiple Project Experience"].map((skill) => (
              <div key={skill} className="bg-white rounded-lg border p-6 hover:shadow-md transition-shadow">
                <h3 className="font-semibold text-lg mb-4 text-gray-900">{skill}</h3>
                <div className="space-y-3">
                  {SKILL_DESCRIPTIONS[skill]?.description.split('\n').filter(Boolean).map((level, index) => {
                    const levelName = level.split(':')[0].trim();
                    const levelDescription = level.split(':')[1];
                    const isCurrentLevel = SKILL_LEVEL_MAPPING[techSkills[skill]] === levelName;
                    
                    return (
                      <div key={index} className={`text-sm p-2 rounded ${isCurrentLevel ? 'bg-blue-50 border border-blue-200' : ''}`}>
                        <span className={`font-medium ${isCurrentLevel ? 'text-blue-700' : ''}`}>
                          {levelName}:
                        </span>
                        <span className={`${isCurrentLevel ? 'text-blue-600' : 'text-gray-600'}`}>
                          {levelDescription}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Operations Section */}
        <div className="skill-category-section">
          <h2 className="text-2xl font-bold mb-6 text-gray-800">Operations Skills</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {["Devops", "AI", "DevOps Selego PMF Kit"].map((skill) => (
              <div key={skill} className="bg-white rounded-lg border p-6 hover:shadow-md transition-shadow">
                <h3 className="font-semibold text-lg mb-4 text-gray-900">{skill}</h3>
                <div className="space-y-3">
                  {SKILL_DESCRIPTIONS[skill]?.description.split('\n').filter(Boolean).map((level, index) => {
                    const levelName = level.split(':')[0].trim();
                    const levelDescription = level.split(':')[1];
                    const isCurrentLevel = SKILL_LEVEL_MAPPING[techSkills[skill]] === levelName;
                    
                    return (
                      <div key={index} className={`text-sm p-2 rounded ${isCurrentLevel ? 'bg-blue-50 border border-blue-200' : ''}`}>
                        <span className={`font-medium ${isCurrentLevel ? 'text-blue-700' : ''}`}>
                          {levelName}:
                        </span>
                        <span className={`${isCurrentLevel ? 'text-blue-600' : 'text-gray-600'}`}>
                          {levelDescription}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Soft Skills Section */}
        <div className="skill-category-section">
          <h2 className="text-2xl font-bold mb-6 text-gray-800">Soft Skills</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {["Communication", "Ownership", "GetShitDone", "Quote Making", "Hiring"].map((skill) => (
              <div key={skill} className="bg-white rounded-lg border p-6 hover:shadow-md transition-shadow">
                <h3 className="font-semibold text-lg mb-4 text-gray-900">{skill}</h3>
                <div className="space-y-3">
                  {SKILL_DESCRIPTIONS[skill]?.description.split('\n').filter(Boolean).map((level, index) => {
                    const levelName = level.split(':')[0].trim();
                    const levelDescription = level.split(':')[1];
                    const isCurrentLevel = SKILL_LEVEL_MAPPING[techSkills[skill]] === levelName;
                    
                    return (
                      <div key={index} className={`text-sm p-2 rounded ${isCurrentLevel ? 'bg-blue-50 border border-blue-200' : ''}`}>
                        <span className={`font-medium ${isCurrentLevel ? 'text-blue-700' : ''}`}>
                          {levelName}:
                        </span>
                        <span className={`${isCurrentLevel ? 'text-blue-600' : 'text-gray-600'}`}>
                          {levelDescription}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};