export const MONTHS = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
export const YEARS = [2020, 2021, 2022, 2023, 2024, 2025];

const DEFAULT_OPTIONS = {
  min: null,
  max: null,
};

export const getMonths = (options) => {
  options = { ...DEFAULT_OPTIONS, ...options };
  const res = [];
  for (let i = 0; i < YEARS.length; i++) {
    for (let j = 0; j < 12; j++) {
      if (options.min && new Date(Date.UTC(YEARS[i], j, 1)) < options.min) continue;
      if (options.max && new Date(Date.UTC(YEARS[i], j, 1)) > options.max) continue;
      res.push(new Date(Date.UTC(YEARS[i], j, 1)));
    }
  }
  res.reverse();
  return res;
};
