import React, { useEffect, useState } from "react";

import api from "../../../services/api";

export default ({ article, setArticle }) => {
  const [seoData, setSeoData] = useState({
    title: '',
    metaDescription: '',
    h1Tags: [],
    h2Tags: [],
    h3Tags: [],
    canonicalUrl: '',
    metaRobots: '',
    ogTitle: '',
    ogDescription: '',
    ogImage: '',
    structuredData: []
  });

  useEffect(() => {
    if (!article?.url) return;
    fetchSeoData();
  }, [article]);

  const fetchSeoData = async () => {
    try {
      const response = await api.post("/article/seo", { url: article.url });
      setSeoData(response.data);
    } catch (error) {
      console.error("Error fetching SEO data:", error);
    }
  };

  return (
    <div className="space-y-8 p-4 border rounded-md mt-4 h-[calc(100vh-160px)] overflow-auto">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="space-y-4 bg-white p-4 rounded-lg shadow">
          <h3 className="font-semibold text-lg border-b pb-2">Meta Information</h3>
          <div>
            <div className="font-medium">Title</div>
            <div className="text-gray-600">{seoData.title}</div>
          </div>
          <div>
            <div className="font-medium">Meta Description</div>
            <div className="text-gray-600">{seoData.metaDescription}</div>
          </div>
          <div>
            <div className="font-medium">Canonical URL</div>
            <div className="text-gray-600 break-all">{seoData.canonicalUrl}</div>
          </div>
          <div>
            <div className="font-medium">Robots</div>
            <div className="text-gray-600">{seoData.metaRobots}</div>
          </div>
        </div>

        <div className="space-y-4 bg-white p-4 rounded-lg shadow">
          <h3 className="font-semibold text-lg border-b pb-2">Open Graph</h3>
          <div>
            <div className="font-medium">OG Title</div>
            <div className="text-gray-600">{seoData.ogTitle}</div>
          </div>
          <div>
            <div className="font-medium">OG Description</div>
            <div className="text-gray-600">{seoData.ogDescription}</div>
          </div>
          {seoData.ogImage && (
            <div>
              <div className="font-medium">OG Image</div>
              <img src={seoData.ogImage} alt="OG Image" className="max-h-32 mt-2" />
            </div>
          )}
        </div>
      </div>

      <div className="space-y-6 bg-white p-4 rounded-lg shadow">
        <h3 className="font-semibold text-lg border-b pb-2">Heading Structure</h3>
        
        <div className="space-y-2">
          <h4 className="font-medium">H1 Tags ({seoData.h1Tags.length})</h4>
          <ul className="list-disc pl-4">
            {seoData.h1Tags.map((h, i) => (
              <li key={i} className="text-gray-600">{h}</li>
            ))}
          </ul>
        </div>

        <div className="space-y-2">
          <h4 className="font-medium">H2 Tags ({seoData.h2Tags.length})</h4>
          <ul className="list-disc pl-4">
            {seoData.h2Tags.map((h, i) => (
              <li key={i} className="text-gray-600">{h}</li>
            ))}
          </ul>
        </div>

        <div className="space-y-2">
          <h4 className="font-medium">H3 Tags ({seoData.h3Tags.length})</h4>
          <ul className="list-disc pl-4">
            {seoData.h3Tags.map((h, i) => (
              <li key={i} className="text-gray-600">{h}</li>
            ))}
          </ul>
        </div>
      </div>

      {seoData.structuredData.length > 0 && (
        <div className="bg-white p-4 rounded-lg shadow">
          <h3 className="font-semibold text-lg border-b pb-2 mb-4">Structured Data</h3>
          <pre className="bg-gray-50 p-4 rounded overflow-auto">
            {JSON.stringify(seoData.structuredData, null, 2)}
          </pre>
        </div>
      )}
    </div>
  );
};
