import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import Loader from "../../components/loader";
import Modal from "../../components/modal";
import LoadingButton from "../../components/loadingButton";
import UserSelector from "../../components/selectUser";

import api from "../../services/api";

const SKILL_LEVEL_MAPPING = {
  'BAD': 'NOT STARTED',
  'FAIR': 'BEGINNER',
  'GOOD': 'INTERMEDIATE',
  'MASTER': 'ADVANCED'
};

export default () => {
  const navigate = useNavigate();
  const [techSkills, setTechSkills] = useState();
  const [filter, setFilter] = useState({ search: "", sort: { overall_score: 1 }, lead_mini_cto: null });
  const [median, setMedian] = useState(0);

  const calculateMedian = (techSkills) => { 
    const scores = techSkills.map(user => user.overall_score).filter(Boolean);
    const sortedScores = scores.sort((a, b) => a - b);
    const mid = Math.floor(sortedScores.length / 2);
    
    if (sortedScores.length % 2 === 0) {
      return (sortedScores[mid - 1] + sortedScores[mid]) / 2;
    }
    return sortedScores[mid];
  };

  useEffect(() => {
    get();
  }, [filter]);

  const get = async () => {
    try {
      const { data, ok, code } = await api.post("/user_tech_skill/search", {
        search: filter.search,
        sort: filter.sort,
        limit: 1000,
        lead_mini_cto: filter.lead_mini_cto
      });
      if (!ok) return toast.error("Error!" || code);

      setTechSkills(data);
      setMedian(calculateMedian(data));
    } catch (error) {
      console.log(error);
      return toast.error("Error fetching user skills!");
    }
  };

  if (!techSkills) return <Loader />;


  return (
    <div className="p-5">
      <div className="flex flex-row items-center justify-between mb-6">
        <h1 className="text-2xl font-black">Team Skills Matrix</h1>
      </div>

      <Create />
      <div className="flex gap-3 my-5">
        <div className="bg-white rounded-lg p-4 border transition-shadow">
          <h3 className="text-gray-500 text-sm mb-1">Key Performance Indicator (KPI)</h3>
          <p className="text-base font-bold text-blue-600">Median of more than 3.5</p>
        </div>
        {techSkills?.length > 0 && (
          <>
            <div className="bg-white rounded-lg p-4 border transition-shadow">
              <h3 className="text-gray-500 text-sm mb-1">Current Median</h3>
              <p className={`${median >= 3.5 ? "text-blue-600" : "text-red-700"} text-2xl font-bold`}>
                {median.toFixed(1) || 0}
              </p>
            </div>

            <div className="bg-white rounded-lg p-4 border transition-shadow">
              <h3 className="text-gray-500 text-sm mb-1">Current Lead In Training Count</h3>
              <p className="text-base font-bold text-blue-600">
                {techSkills?.filter(user => user.lead_mini_cto).length}
              </p>
            </div>
          </>
        )}
      </div>

      <div className="flex gap-3 mb-5">
        <div className="bg-white rounded-lg p-4 border transition-shadow">
          <label className="flex items-center gap-2">
            <input
              type="checkbox"
              className="rounded border-gray-300"
              checked={filter.lead_mini_cto === true}
              onChange={(e) => setFilter({ ...filter, lead_mini_cto: e.target.checked ? true : null })}
            />
            <span className="text-sm text-gray-700">Only Lead Mini CTO</span>
          </label>
        </div>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Team Member
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer group" onClick={() => setFilter({ ...filter, sort: { "tech_arch_score": filter.sort?.tech_arch_score === -1 ? 1 : -1 } })}>
                Technical Architecture
                <span className="ml-1 text-gray-400 group-hover:text-gray-600">↑↓</span>
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer group" onClick={() => setFilter({ ...filter, sort: { "dev_score": filter.sort?.dev_score === -1 ? 1 : -1 } })}>
                Development
                <span className="ml-1 text-gray-400 group-hover:text-gray-600">↑↓</span>
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer group" onClick={() => setFilter({ ...filter, sort: { "ops_score": filter.sort?.ops_score === -1 ? 1 : -1 } })}>
                Operations
                <span className="ml-1 text-gray-400 group-hover:text-gray-600">↑↓</span>
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer group" onClick={() => setFilter({ ...filter, sort: { "soft_score": filter.sort?.soft_score === -1 ? 1 : -1 } })}>
                Soft Skills
                <span className="ml-1 text-gray-400 group-hover:text-gray-600">↑↓</span>
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer group" onClick={() => setFilter({ ...filter, sort: { "overall_score": filter.sort?.overall_score === -1 ? 1 : -1 } })}>
                Overall Score
                <span className="ml-1 text-gray-400 group-hover:text-gray-600">↑↓</span>
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {techSkills?.map((user) => (
              <tr onClick={() => navigate(`/tech_skills/${user._id}`)} key={user._id} className="hover:bg-gray-50 cursor-pointer">
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="flex items-center">
                    <div className="flex-shrink-0 h-10 w-10">
                      <img className="h-10 w-10 rounded-full" src={user.user_avatar} alt="" />
                    </div>
                    <div className="ml-4">
                      <div className="text-sm font-medium text-gray-900">
                        {user.user_name}
                        {user.lead_mini_cto && (
                          <span className="ml-2 px-2 py-0.5 text-xs bg-blue-100 text-blue-800 rounded-full">
                            Lead Mini CTO
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </td>
                <td className="px-6 py-4">
                  <div className="space-y-1">
                    <div className="flex items-center gap-2">
                      <span className="text-xs text-gray-500">Data Modeling</span>
                      <span
                        className={`px-2 py-1 text-xs rounded-full ${
                          user["Data Modeling"] === "MASTER"
                            ? "bg-green-100 text-green-800"
                            : user["Data Modeling"] === "GOOD"
                            ? "bg-blue-100 text-blue-800"
                            : user["Data Modeling"] === "FAIR"
                            ? "bg-yellow-100 text-yellow-800"
                            : "bg-gray-50 text-gray-600"
                        }`}>
                        {SKILL_LEVEL_MAPPING[user["Data Modeling"]] || 'NOT MAPPED'}
                      </span>
                    </div>
                    <div className="flex items-center gap-2">
                      <span className="text-xs text-gray-500">API Principles</span>
                      <span
                        className={`px-2 py-1 text-xs rounded-full ${
                          user["API Principles"] === "MASTER"
                            ? "bg-green-100 text-green-800"
                            : user["API Principles"] === "GOOD"
                            ? "bg-blue-100 text-blue-800"
                            : user["API Principles"] === "FAIR"
                            ? "bg-yellow-100 text-yellow-800"
                            : "bg-gray-50 text-gray-600"
                        }`}>
                        {SKILL_LEVEL_MAPPING[user["API Principles"]] || 'NOT MAPPED'}
                      </span>
                    </div>
                    <div className="flex items-center gap-2">
                      <span className="text-xs text-gray-500">Conventions</span>
                      <span
                        className={`px-2 py-1 text-xs rounded-full ${
                          user["Conventions"] === "MASTER"
                            ? "bg-green-100 text-green-800"
                            : user["Conventions"] === "GOOD"
                            ? "bg-blue-100 text-blue-800"
                            : user["Conventions"] === "FAIR"
                            ? "bg-yellow-100 text-yellow-800"
                            : "bg-gray-50 text-gray-600"
                        }`}>
                        {SKILL_LEVEL_MAPPING[user["Conventions"]] || 'NOT MAPPED'}
                      </span>
                    </div>
                    <div className="flex items-center gap-2">
                      <span className="text-xs text-gray-500">Separation of Concerns</span>
                      <span
                        className={`px-2 py-1 text-xs rounded-full ${
                          user["Separation of Concerns"] === "MASTER"
                            ? "bg-green-100 text-green-800"
                            : user["Separation of Concerns"] === "GOOD"
                            ? "bg-blue-100 text-blue-800"
                            : user["Separation of Concerns"] === "FAIR"
                            ? "bg-yellow-100 text-yellow-800"
                            : "bg-gray-50 text-gray-600"
                        }`}>
                        {SKILL_LEVEL_MAPPING[user["Separation of Concerns"]] || 'NOT MAPPED'}
                      </span>
                    </div>
                    <div className="text-xs text-gray-500 mt-1">Score: {user.tech_arch_score?.toFixed(1)}</div>
                  </div>
                </td>
                <td className="px-6 py-4">
                  <div className="space-y-1">
                    <div className="flex items-center gap-2">
                      <span className="text-xs text-gray-500">Componentisation</span>
                      <span
                        className={`px-2 py-1 text-xs rounded-full ${
                          user["Componentisation"] === "MASTER"
                            ? "bg-green-100 text-green-800"
                            : user["Componentisation"] === "GOOD"
                            ? "bg-blue-100 text-blue-800"
                            : user["Componentisation"] === "FAIR"
                            ? "bg-yellow-100 text-yellow-800"
                            : "bg-gray-50 text-gray-600"
                        }`}>
                        {SKILL_LEVEL_MAPPING[user["Componentisation"]] || 'NOT MAPPED'}
                      </span>
                    </div>
                    <div className="flex items-center gap-2">
                      <span className="text-xs text-gray-500">React Native</span>
                      <span
                        className={`px-2 py-1 text-xs rounded-full ${
                          user["React Native"] === "MASTER"
                            ? "bg-green-100 text-green-800"
                            : user["React Native"] === "GOOD"
                            ? "bg-blue-100 text-blue-800"
                            : user["React Native"] === "FAIR"
                            ? "bg-yellow-100 text-yellow-800"
                            : "bg-gray-50 text-gray-600"
                        }`}>
                        {SKILL_LEVEL_MAPPING[user["React Native"]] || 'NOT MAPPED'}
                      </span>
                    </div>
                    <div className="flex items-center gap-2">
                      <span className="text-xs text-gray-500">Multiple Project Experience</span>
                      <span
                        className={`px-2 py-1 text-xs rounded-full ${
                          user["Multiple Project Experience"] === "MASTER"
                            ? "bg-green-100 text-green-800"
                            : user["Multiple Project Experience"] === "GOOD"
                            ? "bg-blue-100 text-blue-800"
                            : user["Multiple Project Experience"] === "FAIR"
                            ? "bg-yellow-100 text-yellow-800"
                            : "bg-gray-50 text-gray-600"
                        }`}>
                        {SKILL_LEVEL_MAPPING[user["Multiple Project Experience"]] || 'NOT MAPPED'}
                      </span>
                    </div>
                    <div className="text-xs text-gray-500 mt-1">Score: {user.dev_score?.toFixed(1)}</div>
                  </div>
                </td>
                <td className="px-6 py-4">
                  <div className="space-y-1">
                    <div className="flex items-center gap-2">
                      <span className="text-xs text-gray-500">DevOps</span>
                      <span
                        className={`px-2 py-1 text-xs rounded-full ${
                          user["Devops"] === "MASTER"
                            ? "bg-green-100 text-green-800"
                            : user["Devops"] === "GOOD"
                            ? "bg-blue-100 text-blue-800"
                            : user["Devops"] === "FAIR"
                            ? "bg-yellow-100 text-yellow-800"
                            : "bg-gray-50 text-gray-600"
                        }`}>
                        {SKILL_LEVEL_MAPPING[user["Devops"]] || 'NOT MAPPED'}
                      </span>
                    </div>
                    <div className="flex items-center gap-2">
                      <span className="text-xs text-gray-500">AI</span>
                      <span
                        className={`px-2 py-1 text-xs rounded-full ${
                          user["AI"] === "MASTER"
                            ? "bg-green-100 text-green-800"
                            : user["AI"] === "GOOD"
                            ? "bg-blue-100 text-blue-800"
                            : user["AI"] === "FAIR"
                            ? "bg-yellow-100 text-yellow-800"
                            : "bg-gray-50 text-gray-600"
                        }`}>
                        {SKILL_LEVEL_MAPPING[user["AI"]] || 'NOT MAPPED'}
                      </span>
                    </div>
                    <div className="flex items-center gap-2">
                      <span className="text-xs text-gray-500">DevOps Selego PMF Kit</span>
                      <span
                        className={`px-2 py-1 text-xs rounded-full ${
                          user["DevOps Selego PMF Kit"] === "MASTER"
                            ? "bg-green-100 text-green-800"
                            : user["DevOps Selego PMF Kit"] === "GOOD"
                            ? "bg-blue-100 text-blue-800"
                            : user["DevOps Selego PMF Kit"] === "FAIR"
                            ? "bg-yellow-100 text-yellow-800"
                            : "bg-gray-50 text-gray-600"
                        }`}>
                        {SKILL_LEVEL_MAPPING[user["DevOps Selego PMF Kit"]] || 'NOT MAPPED'}
                      </span>
                    </div>
                    <div className="text-xs text-gray-500 mt-1">Score: {user.ops_score?.toFixed(1)}</div>
                  </div>
                </td>
                <td className="px-6 py-4">
                  <div className="space-y-1">
                    <div className="flex items-center gap-2">
                      <span className="text-xs text-gray-500">Communication</span>
                      <span
                        className={`px-2 py-1 text-xs rounded-full ${
                          user["Communication"] === "MASTER"
                            ? "bg-green-100 text-green-800"
                            : user["Communication"] === "GOOD"
                            ? "bg-blue-100 text-blue-800"
                            : user["Communication"] === "FAIR"
                            ? "bg-yellow-100 text-yellow-800"
                            : "bg-gray-50 text-gray-600"
                        }`}>
                        {SKILL_LEVEL_MAPPING[user["Communication"]] || 'NOT MAPPED'}
                      </span>
                    </div>
                    <div className="flex items-center gap-2">
                      <span className="text-xs text-gray-500">Ownership</span>
                      <span
                        className={`px-2 py-1 text-xs rounded-full ${
                          user["Ownership"] === "MASTER"
                            ? "bg-green-100 text-green-800"
                            : user["Ownership"] === "GOOD"
                            ? "bg-blue-100 text-blue-800"
                            : user["Ownership"] === "FAIR"
                            ? "bg-yellow-100 text-yellow-800"
                            : "bg-gray-50 text-gray-600"
                        }`}>
                        {SKILL_LEVEL_MAPPING[user["Ownership"]] || 'NOT MAPPED'}
                      </span>
                    </div>
                    <div className="flex items-center gap-2">
                      <span className="text-xs text-gray-500">GetShitDone</span>
                      <span
                        className={`px-2 py-1 text-xs rounded-full ${
                          user["GetShitDone"] === "MASTER"
                            ? "bg-green-100 text-green-800"
                            : user["GetShitDone"] === "GOOD"
                            ? "bg-blue-100 text-blue-800"
                            : user["GetShitDone"] === "FAIR"
                            ? "bg-yellow-100 text-yellow-800"
                            : "bg-gray-50 text-gray-600"
                        }`}>
                        {SKILL_LEVEL_MAPPING[user["GetShitDone"]] || 'NOT MAPPED'}
                      </span>
                    </div>
                    <div className="flex items-center gap-2">
                      <span className="text-xs text-gray-500">Quote Making</span>
                      <span
                        className={`px-2 py-1 text-xs rounded-full ${
                          user["Quote Making"] === "MASTER"
                            ? "bg-green-100 text-green-800"
                            : user["Quote Making"] === "GOOD"
                            ? "bg-blue-100 text-blue-800"
                            : user["Quote Making"] === "FAIR"
                            ? "bg-yellow-100 text-yellow-800"
                            : "bg-gray-50 text-gray-600"
                        }`}>
                        {SKILL_LEVEL_MAPPING[user["Quote Making"]] || 'NOT MAPPED'}
                      </span>
                    </div>
                    <div className="flex items-center gap-2">
                      <span className="text-xs text-gray-500">Hiring</span>
                      <span
                        className={`px-2 py-1 text-xs rounded-full ${
                          user["Hiring"] === "MASTER"
                            ? "bg-green-100 text-green-800"
                            : user["Hiring"] === "GOOD"
                            ? "bg-blue-100 text-blue-800"
                            : user["Hiring"] === "FAIR"
                            ? "bg-yellow-100 text-yellow-800"
                            : "bg-gray-50 text-gray-600"
                        }`}>
                        {SKILL_LEVEL_MAPPING[user["Hiring"]] || 'NOT MAPPED'}
                      </span>
                    </div>
                    <div className="text-xs text-gray-500 mt-1">Score: {user.soft_score?.toFixed(1)}</div>
                  </div>
                </td>
                <td className="px-6 py-4">
                  <div className="text-lg font-semibold">{user.overall_score?.toFixed(1)}</div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const Create = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({});
  const [btnLoading, setBtnLoading] = useState(false);

  const onCreate = async () => {
    setBtnLoading(true);

    try {
      const { ok, error, data } = await api.post("/user_tech_skill", values);

      if (!ok) return toast.error(error);

      toast.success("Tech skill created successfully!");
      setOpen(false);
      setValues({});
      setBtnLoading(false);
      navigate(`/tech_skills/${data._id}`);
    } catch (error) {
      toast.error("Error creating tech skill.");
      setBtnLoading(false);
    }
  };

  return (
    <div>
      <button className="bg-primary-50 border border-primary-700 text-primary-700 px-5 py-1.5 rounded-md text-sm" onClick={() => setOpen(true)}>
        Create Tech Skill
      </button>

      <Modal isOpen={open} className="!w-[90vw] md:!w-[700px] p-6" onClose={() => setOpen(false)}>
        <h1 className="text-lg font-semibold mb-5 mt-3">Create Tech Skill</h1>

        <div className="w-full mb-3">
          <div className="font-medium mb-2 text-sm">Select User</div>
          <UserSelector
            value={{ _id: values.user_id, name: values.user_name, avatar: values.user_avatar }}
            placeholder="Select team member"
            onChange={(user) => {
              if (!user?._id) return;
              setValues({ ...values, user_id: user._id, user_name: user.name, user_avatar: user.avatar });
            }}
          />
        </div>
        <div className="flex justify-end items-center">
          <LoadingButton
            className="bg-primary-50 text-primary-900 border px-4 py-1.5 rounded border-primary-600 mt-4  disabled:cursor-not-allowed"
            loading={btnLoading}
            disabled={!values.user_id}
            onClick={() => onCreate()}>
            Create
          </LoadingButton>
        </div>
      </Modal>
    </div>
  );
};