import { useEffect, useState, Fragment, useRef } from "react";
import toast from "react-hot-toast";
import { IoStatsChart, IoTrashOutline } from "react-icons/io5";
import { HiChevronRight, HiDotsVertical } from "react-icons/hi";
import Table from "../../../../components/Table";
import EpicEditModal from "./EpicModal";
import api from "../../../../services/api";
import DebounceInput from "../../../../components/DebounceInput";
import MultiSelect from "../../../../components/MultiSelect";
import { MultiAction } from "./Composant/MultiAction";
import { HiPencil, HiPlus } from "react-icons/hi2";

export default ({ project }) => {
  const [epics, setEpics] = useState([]);
  const [filters, setFilters] = useState({ page: 1, statuses: ["TODO", "IN_PROGRESS", "DONE", "NEED_INFO"], urgent: [], modules: [], versions: [] });
  const [budgets, setBudgets] = useState([]);
  const [sortBy, setSortBy] = useState({ key: "points", value: 1 });
  const [selectedEpics, setSelectedEpics] = useState([]);
  const [selectedEpic, setSelectedEpic] = useState(null);
  const [isEpicEditModalOpen, setIsEpicEditModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [groupedEpics, setGroupedEpics] = useState({});
  const [expandedModules, setExpandedModules] = useState(new Set(["-"]));
  const [editingId, setEditingId] = useState(null);

  useEffect(() => {
    fetchEpics();
  }, [filters]);

  const fetchEpics = async () => {
    setLoading(true);
    try {
      const { data, ok } = await api.post("/epic/search", { project_id: project._id, ...filters });
      if (!ok) return toast.error("Failed to fetch roadmaps");
      const { data: budgets } = await api.post("/budget/search", { projectId: project._id, status: "active" });
      setBudgets(budgets);
      setEpics(data);

      // Group epics by module
      const grouped = data.reduce((acc, epic) => {
        const module = epic.module || "-";
        if (!acc[module]) acc[module] = [];
        acc[module].push(epic);
        return acc;
      }, {});
      setGroupedEpics(grouped);
      setExpandedModules(new Set(Object.keys(grouped)));
    } catch (error) {
      console.error(error);
      toast.error("Failed to fetch roadmaps");
    } finally {
      setLoading(false);
    }
  };

  const handleMultipleDelete = async () => {
    try {
      if (!window.confirm("Are you sure you want to delete selected epics?")) return;

      for (const epicId of selectedEpics) {
        console.log(epicId);
        const { ok } = await api.remove(`/epic/${epicId._id}`);
        if (!ok) {
          toast.error(`Failed to delete epic ${epicId._id}`);
          continue;
        }
      }
      toast.success("Epics deleted successfully");
      fetchEpics();
      setSelectedEpics([]);
    } catch (error) {
      console.error(error);
      toast.error("Failed to delete epics");
    }
  };

  const handleSave = async (itemId, value) => {
    try {
      const { ok, data } = await api.put(`/epic/${itemId}`, value);
      if (!ok) return toast.error("Failed to update");

      const index = epics.findIndex((item) => item._id === itemId);
      const newEpics = [...epics];
      newEpics[index] = { ...newEpics[index], ...value };
      setEpics(newEpics);

      const grouped = newEpics.reduce((acc, epic) => {
        const module = epic.module || "-";
        if (!acc[module]) acc[module] = [];
        acc[module].push(epic);
        return acc;
      }, {});
      setGroupedEpics(grouped);
    } catch (error) {
      console.error(error);
      toast.error("Failed to update");
    }
  };

  // Add toggle function for modules
  const toggleModule = (module) => {
    const newExpanded = new Set(expandedModules);
    if (newExpanded.has(module)) {
      newExpanded.delete(module);
    } else {
      newExpanded.add(module);
    }
    setExpandedModules(newExpanded);
  };

  return (
    <div className="space-y-4">
      <div className="bg-white p-4 rounded border border-gray-200">
        <div className="flex items-start gap-4">
          <div className="w-full flex justify-between gap-4">
            <div className="grid grid-cols-4 gap-2 w-4/5">
              <DebounceInput
                debounce={300}
                className="inline-flex justify-between items-center h-[38px] gap-4 px-2 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                placeholder="Search by title..."
                value={filters.search}
                onChange={(e) => setFilters((prev) => ({ ...prev, search: e.target.value, page: 1 }))}
              />

              <SelectBudgets value={filters.budget_id} onChange={(e) => setFilters((f) => ({ ...f, budget_id: e }))} project={project} />
              <SelectModules value={filters.modules} onChange={(e) => setFilters((f) => ({ ...f, modules: e }))} project={project} />
              <MultiSelect
                id="select-status"
                options={["TODO", "IN_PROGRESS", "DONE", "NEED_INFO"].map((status) => ({ value: status, label: status }))}
                values={filters.statuses?.map((status) => ({ value: status, label: status })) || []}
                onSelectedChange={(e) => setFilters((f) => ({ ...f, statuses: e.map((e) => e.value) }))}
                placeholder="Status"
              />
            </div>
            <div className="flex gap-4 mb-2 w-1/5">
              <button
                onClick={async () => {
                  const { ok, data } = await api.post("/epic", { project_id: project._id });
                  if (!ok) return toast.error("Failed to create epic");
                  toast.success("Epic created successfully");
                  fetchEpics();
                  setSelectedEpic(data);
                  setIsEpicEditModalOpen(true);
                }}
                className="blue-btn w-full h-[38px]">
                New Epic
              </button>
              <MultiAction selectedEpics={selectedEpics} epics={epics} project={project} handleMultipleDelete={handleMultipleDelete} />
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white rounded-lg border border-gray-200 overflow-hidden">
        <Table
          total={epics.length}
          header={[
            {
              title: (
                <input
                  type="checkbox"
                  className="w-4 h-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedEpics(epics);
                      setExpandedModules(new Set(Object.keys(groupedEpics)));
                    } else {
                      setSelectedEpics([]);
                    }
                  }}
                />
              ),
              position: "center",
            },
            { title: "Title", key: "title" },
            { title: "Owner" },
            { title: "Budget" },
            { title: "Days Required" },
            { title: "Estimation", key: "estimation" },
            { title: "Status", key: "status" },
            { title: "" },
          ]}
          loading={loading}
          sort={{ [sortBy.key]: sortBy.value }}
          onSort={(e) => setSortBy(sortBy.key === e ? { key: e, value: sortBy.value * -1 } : { key: e, value: 1 })}>
          {Object.entries(groupedEpics)
            .sort(([a], [b]) => {
              if (a === "-") return -1;
              if (b === "-") return 1;
              return a.localeCompare(b);
            })
            .map(([module, moduleEpics], index) => (
              <Fragment key={`${module}-${index}`}>
                <tr
                  onClick={() => toggleModule(module)}
                  className={`
                  cursor-pointer border-y border-gray-200
                  ${expandedModules.has(module) ? "bg-blue-100" : "bg-gray-50 hover:bg-gray-100"}
                `}>
                  <td colSpan="8" className="px-4 py-3">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-2">
                        <HiChevronRight
                          className={`
                          transform transition-transform duration-200 text-blue-500 text-lg
                          ${expandedModules.has(module) ? "rotate-90" : ""}
                        `}
                        />
                        <span className="font-medium text-gray-900">{module}</span>
                        <span className="text-sm text-gray-500">({moduleEpics.length} epics)</span>
                      </div>
                      <div className="flex items-center gap-2">
                        <ModuleStats moduleEpics={moduleEpics} />
                        <button
                          onClick={async (e) => {
                            e.stopPropagation();
                            const { ok, data } = await api.post("/epic", { project_id: project._id, module });
                            if (!ok) return toast.error("Failed to create epic");
                            toast.success("Epic created successfully");
                            fetchEpics();
                            setSelectedEpic(data);
                            setIsEpicEditModalOpen(true);
                          }}
                          className="rounded-full p-1 hover:bg-sky-50 text-black bg-white">
                          <HiPlus className="text-gray-500" />
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
                {expandedModules.has(module) &&
                  moduleEpics
                    .sort((a, b) => {
                      if (sortBy.key === "created_at") return sortBy.value * (new Date(b.created_at) - new Date(a.created_at));
                      if (sortBy.key === "points") return sortBy.value * (b.points - a.points);
                      return sortBy.value * (a[sortBy.key] || "").localeCompare(b[sortBy.key] || "");
                    })
                    .map((item, index) => {
                      const selected = selectedEpics.some((e) => e._id === item._id);
                      return (
                        <tr
                          key={`${item._id}-${index}`}
                          className={`
                        relative group hover:bg-blue-50
                        ${index % 2 === 0 ? "bg-white" : "bg-gray-50"}
                      `}>
                          <td
                            className="px-2 py-2 cursor-pointer"
                            onClick={(e) => {
                              const find = selectedEpics.some((e) => e._id === item._id);
                              if (find) {
                                setSelectedEpics(selectedEpics.filter((e) => e._id !== item._id));
                              } else {
                                setSelectedEpics([...selectedEpics, item]);
                              }
                            }}>
                            <input
                              type="checkbox"
                              className="w-4 h-4 rounded border-gray-300 focus:border-blue-600 text-blue-600 focus:ring-0 focus:outline-none focus:shadow-none"
                              checked={selected}
                              onChange={() => {}}
                            />
                          </td>
                          <td className="px-4 py-2 text-sm">
                            <div className="flex flex-col">
                              <div className="flex items-center gap-2">
                                <div className="relative flex items-center gap-2 flex-1 min-w-0">
                                  <span className="font-medium shrink-0">#{item.index}</span>
                                  <div
                                    contentEditable
                                    suppressContentEditableWarning
                                    onBlur={(e) => {
                                      setEditingId(null);
                                      handleSave(item._id, { title: e.target.textContent });
                                    }}
                                    onClick={() => setEditingId(item._id)}
                                    className="outline-none border-b border-transparent hover:border-gray-300 focus:border-blue-500 bg-transparent px-1 line-clamp-3 overflow-hidden cursor-text">
                                    {item.title}
                                  </div>
                                  <button
                                    onClick={() => {
                                      setSelectedEpic(item);
                                      setIsEpicEditModalOpen(true);
                                    }}
                                    className={`absolute top-1/2 -translate-y-1/2 right-0 px-3 py-1.5 rounded-md flex items-center gap-2 bg-white border border-gray-200 shadow-sm
                                      ${editingId === item._id ? "hidden" : "invisible group-hover:visible"}`}>
                                    <HiPencil className="w-4 h-4 text-gray-600" />
                                    <span className="text-xs font-medium text-gray-600">Open</span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="px-4 py-2">
                            {item.user_avatar && (
                              <div className="relative w-6 h-6 shrink-0">
                                <img src={item.user_avatar} alt={item.user_name} className="peer h-6 w-6 rounded-full hover:cursor-pointer" />
                                <div className="absolute -top-8 left-1/2 -translate-x-1/2 hidden peer-hover:block bg-gray-800 text-white text-xs rounded px-2 py-1 whitespace-nowrap">
                                  {item.user_name}
                                </div>
                              </div>
                            )}
                          </td>
                          <td className="px-4 py-2 w-48">
                            <select
                              className={`w-full px-2 py-1 rounded cursor-pointer appearance-none text-xs`}
                              value={item.budget_id || ""}
                              onChange={(e) => {
                                if (!e.target.value) return handleSave(item._id, { budget_id: "", budget_name: "" });
                                const selectedBudget = budgets.find((b) => b._id === e.target.value);
                                handleSave(item._id, { budget_id: selectedBudget._id, budget_name: selectedBudget.name });
                              }}>
                              <option value="">Select a budget</option>
                              {budgets.map((budget) => (
                                <option key={budget._id} value={budget._id}>
                                  {budget.name}
                                </option>
                              ))}
                            </select>
                          </td>
                          <td className="px-4 py-2">
                            <div
                              contentEditable
                              suppressContentEditableWarning
                              onBlur={(e) => handleSave(item._id, { days_required: Number(e.target.textContent), estimation: Number(e.target.textContent) * item.tjm })}
                              className="outline-none border-b border-transparent hover:border-gray-300 focus:border-blue-500 bg-transparent px-1 line-clamp-3 overflow-hidden cursor-text">
                              {item.days_required}
                            </div>
                          </td>
                          <td className="px-4 py-2">{item.estimation}</td>
                          <td className="px-4 py-2 w-36">
                            <StatusTag
                              item={item}
                              onStatusChange={(e) => {
                                handleSave(item._id, { status: e });
                              }}
                            />
                          </td>
                          <td className="w-8 px-2">
                            <Menu
                              epic={item}
                              onDelete={() => {
                                const newEpics = epics.filter((e) => e._id !== item._id);
                                setEpics(newEpics);
                                setGroupedEpics(
                                  newEpics.reduce((acc, epic) => {
                                    const module = epic.module || "-";
                                    if (!acc[module]) acc[module] = [];
                                    acc[module].push(epic);
                                    return acc;
                                  }, {}),
                                );
                              }}
                            />
                          </td>
                        </tr>
                      );
                    })}
              </Fragment>
            ))}
          <tr className="bg-sky-700 text-white text-sm border-t-2 border-gray-300">
            <td className="px-4 py-3"></td>
            <td className="px-4 py-3">Total</td>
            <td className="px-4 py-3"></td>
            <td className="px-4 py-3"></td>
            <td className="px-4 py-3">{epics.reduce((acc, e) => acc + (e.days_required || 0), 0).toLocaleString("fr-FR")} days</td>
            <td className="px-4 py-3">{epics.reduce((acc, e) => acc + (e.estimation || 0), 0).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</td>
            <td className="px-4 py-3" colSpan="2">
              <div className="flex items-center gap-2 text-sm">
                <span>
                  {epics.filter((e) => e.status === "DONE").length}/{epics.length} completed
                </span>
                <span className="text-white">({Math.round((epics.filter((e) => e.status === "DONE").length / epics.length) * 100)}%)</span>
              </div>
            </td>
          </tr>
        </Table>
      </div>

      {isEpicEditModalOpen && (
        <EpicEditModal
          epic={selectedEpic}
          onClose={(e) => {
            if (!selectedEpic) return;
            const index = epics.findIndex((item) => item._id === selectedEpic._id);
            const newEpics = [...epics];
            newEpics[index] = { ...newEpics[index], ...e };
            setEpics(newEpics);

            // Update grouped epics
            const grouped = newEpics.reduce((acc, epic) => {
              const module = epic.module || "-";
              if (!acc[module]) acc[module] = [];
              acc[module].push(epic);
              return acc;
            }, {});
            setGroupedEpics(grouped);

            setSelectedEpic(null);
            setIsEpicEditModalOpen(false);
          }}
          onDelete={async () => {
            setSelectedEpic(null);
            setIsEpicEditModalOpen(false);
            const newEpics = epics.filter((e) => e._id !== selectedEpic._id);
            setEpics(newEpics);
            setGroupedEpics(
              newEpics.reduce((acc, epic) => {
                const module = epic.module || "-";
                if (!acc[module]) acc[module] = [];
                acc[module].push(epic);
                return acc;
              }, {}),
            );
          }}
        />
      )}
    </div>
  );
};

const StatusTag = ({ item, onStatusChange }) => {
  if (!item?.status) return null;
  const colors = {
    TODO: "bg-blue-600 text-xs text-white border-gray-700",
    IN_PROGRESS: "bg-blue-100 text-xs text-blue-800",
    NEED_INFO: "bg-yellow-100 text-xs text-yellow-800",
    DONE: "bg-green-100 text-xs text-green-800",
  };

  const handleStatusChange = (e) => {
    e.stopPropagation();
    onStatusChange(e.target.value);
  };

  return (
    <select className={`w-full px-2 py-1 rounded ${colors[item?.status]} cursor-pointer appearance-none hover:opacity-80`} value={item?.status} onChange={handleStatusChange}>
      <option value="TODO">TODO</option>
      <option value="IN_PROGRESS">IN PROGRESS</option>
      <option value="NEED_INFO">NEED INFO</option>
      <option value="DONE">DONE</option>
    </select>
  );
};

const SelectModules = ({ value, onChange, project }) => {
  const [options, setOptions] = useState([]);
  const [lastFetch, setLastFetch] = useState(0);

  async function fetchModules() {
    const { data } = await api.post("/epic/aggregate", { project_id: project._id, key: "module" });
    const arr = data.filter((e) => e._id !== null).map((e) => ({ label: e._id, value: e._id, count: e.count }));
    setOptions(arr);
    setLastFetch(Date.now());
  }

  useEffect(() => {
    // Initial fetch when component mounts
    fetchModules();
  }, [project._id]);

  // Refetch when component gains focus if more than 5 seconds have passed
  useEffect(() => {
    function handleFocus() {
      if (Date.now() - lastFetch > 5000) {
        fetchModules();
      }
    }
    window.addEventListener("focus", handleFocus);
    return () => window.removeEventListener("focus", handleFocus);
  }, [lastFetch]);

  return (
    <MultiSelect
      id="select-modules"
      options={options}
      values={value?.map((tag) => ({ value: tag, label: tag }))}
      onSelectedChange={(e) => onChange(e.map((e) => e.value))}
      placeholder="Modules"
    />
  );
};

const SelectBudgets = ({ value, onChange, project }) => {
  const [options, setOptions] = useState([]);

  async function fetchBudgets() {
    const { data } = await api.post("/budget/search", { projectId: project._id, status: "active" });
    setOptions(data);
  }

  useEffect(() => {
    fetchBudgets();
  }, [project._id]);

  return (
    <select
      className="min-w-[140px] h-[38px] inline-flex justify-between items-center gap-4 px-2 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      value={value}
      onChange={(e) => onChange(e.target.value)}>
      <option value="">Select a budget</option>
      {options.map((option) => (
        <option key={option._id} value={option._id}>
          {option.name}
        </option>
      ))}
    </select>
  );
};

const ModuleStats = ({ moduleEpics }) => {
  const stats = {
    total: moduleEpics.length,
    done: moduleEpics.filter((e) => e.status === "DONE").length,
    doneEpics: moduleEpics.filter((e) => e.status === "DONE"),
    inProgress: moduleEpics.filter((e) => e.status === "IN_PROGRESS").length,
    totalDays: moduleEpics.reduce((acc, e) => acc + (e.days_required || 0), 0),
    totalEstimation: moduleEpics.reduce((acc, e) => acc + (e.estimation || 0), 0),
  };

  const spentEstimation = stats.doneEpics.reduce((acc, e) => acc + (e.estimation || 0), 0);

  return (
    <div className="flex items-center gap-4 ml-4 text-xs text-gray-600">
      <span className="flex items-center gap-1">
        <IoStatsChart className="text-blue-500" />
        {stats.done}/{stats.total} completed
      </span>
      <span className="text-blue-600">{Math.round((stats.done / stats.total) * 100)}%</span>
      <span>{stats.totalDays?.toLocaleString("fr-FR")} days</span>
      <span>
        {spentEstimation?.toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}
        {" / "}
        {stats.totalEstimation?.toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}
      </span>
    </div>
  );
};

const Menu = ({ epic, onDelete }) => {
  const [open, setOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleDelete = async () => {
    try {
      if (!window.confirm("Are you sure you want to delete this epic?")) return;
      const { ok } = await api.remove(`/epic/${epic._id}`);
      if (!ok) return toast.error("Failed to delete epic");
      toast.success("Epic deleted successfully");
      setOpen(false);
      onDelete();
    } catch (error) {
      console.error(error);
      toast.error("Failed to delete epic");
    }
  };

  return (
    <div className="relative" ref={menuRef}>
      <button onClick={() => setOpen((p) => !p)} className="invisible group-hover:visible p-1 cursor-pointer rounded transition-colors duration-200">
        <HiDotsVertical className="w-5 h-5 text-gray-500" />
      </button>
      {open && (
        <div className="text-sm absolute right-0 top-full mt-1 bg-white border border-gray-200 rounded-md shadow-md min-w-40 z-10">
          <button
            onClick={handleDelete}
            className="w-full px-4 py-2.5 text-left text-sm font-medium text-red-600 hover:bg-red-50 transition-colors duration-200 cursor-pointer flex items-center gap-2">
            <IoTrashOutline className="w-4 h-4" />
            Delete Epic
          </button>
        </div>
      )}
    </div>
  );
};
