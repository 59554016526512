import React from "react";
import { RiDeleteBin2Fill } from "react-icons/ri";
import SelectUser from "../../../../components/selectUser";

const CvSkills = ({ values, editing, onChange, lang }) => {
  if (editing) return <CvSkillsForm values={values} onChange={onChange} lang={lang} />;

  return (
    <div className="flex flex-wrap gap-2 bg-white">
      {values.skills.map((skill, index) => (
        <span key={index} className="bg-blue-200 text-gray-800 text-sm font-medium px-3 py-1 rounded">
          {skill[lang]}
        </span>
      ))}
    </div>
  );
};

const CvSkillsForm = ({ values, onChange, lang }) => {
  const handleChange = (index, value) => {
    const updatedSkills = [...values.skills];
    updatedSkills[index] = { ...updatedSkills[index], [lang]: value };
    onChange({ ...values, skills: updatedSkills });
  };

  const addSkill = () => {
    onChange({ ...values, skills: [...values.skills, { [lang]: "" }] });
  };

  const removeSkill = (index) => {
    const updatedSkills = values.skills.filter((_, i) => i !== index);
    onChange({ ...values, skills: updatedSkills });
  };

  return (
    <div className="flex flex-col gap-2 p-2 bg-white border border-gray-200 rounded-md shadow-md my-6">
      <SelectUser
        userRoles={["admin", "user", "applicant"]}
        onChange={(user) => {
          if (!user?._id) return;
          const skills =
            user.key_words?.map((keyword) => ({
              fr: keyword.name,
              en: keyword.name,
            })) || [];
          onChange({ ...values, skills });
        }}
      />
      <div className="grid grid-cols-4 gap-4">
        {values.skills.map((skill, index) => (
          <div key={index} className="relative">
            <input className="input w-full rounded-lg px-3 py-2" type="text" value={skill[lang]} onChange={(e) => handleChange(index, e.target.value)} />
            <button type="button" onClick={() => removeSkill(index)} className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full p-1">
              <RiDeleteBin2Fill className="w-3 h-3" />
            </button>
          </div>
        ))}
      </div>
      <button type="button" onClick={addSkill} className="mt-2 bg-blue-500 text-white px-4 py-2 rounded">
        Add Skill
      </button>
    </div>
  );
};

export default CvSkills;
