import { Mark } from "@tiptap/core";

export default Mark.create({
  name: "comment",

  addAttributes() {
    return { commentId: { default: null } };
  },

  parseHTML() {
    return [
      {
        tag: "span[data-comment-id]",
        getAttrs: (element) => ({
          commentId: element.getAttribute("data-comment-id"),
        }),
      },
    ];
  },

  renderHTML({ mark, HTMLAttributes }) {
    return ["span", { ...HTMLAttributes, "data-comment-id": mark.attrs.commentId, class: "comment-mark" }, 0];
  },

  // Optional: Add specific commands
  addCommands() {
    return {
      setComment:
        (commentId) =>
        ({ commands }) => {
          return commands.setMark(this.name, { commentId });
        },
      unsetComment:
        () =>
        ({ commands }) => {
          return commands.unsetMark(this.name);
        },

      removeCommentById:
        (targetCommentId) =>
        ({ tr, state, dispatch }) => {
          let removed = false;

          // Loop through all marks in the document
          state.doc.descendants((node, pos) => {
            const marks = node.marks.filter((mark) => mark.type.name === "comment" && mark.attrs.commentId === targetCommentId);
            if (marks.length > 0) {
              tr.removeMark(pos, pos + node.nodeSize, state.schema.marks.comment);
              removed = true;
            }
          });
          if (dispatch && removed) dispatch(tr);

          return removed;
        },
      toggleComment:
        (commentId) =>
        ({ commands }) => {
          return commands.toggleMark(this.name, { commentId });
        },
    };
  },
});
