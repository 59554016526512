import React, { useState, useEffect } from "react";
import SelectUser from "../../../../components/selectUser";
import api from "../../../../services/api";

import {
  TEXT_BLOCK,
  TEAM_BLOCK,
  TABLE_BLOCK,
  CV_HEADER_BLOCK,
  CV_SKILLS_BLOCK,
  CV_EXPERIENCES_BLOCK,
  CV_FORMATION_BLOCK,
  CONTACT_BLOCK,
  SIGNATURE_BLOCK,
  CARDS_BLOCK,
  TWO_COLS,
  IMAGE,
  MULTIPLE_IMAGES_BLOCK,
} from "../../constant";

const BLOCKS = [
  TEXT_BLOCK,
  IMAGE,
  TWO_COLS,
  TEAM_BLOCK,
  TABLE_BLOCK,
  // PORTFOLIO_BLOCK, no need. double column doing the job
  MULTIPLE_IMAGES_BLOCK,
  CV_HEADER_BLOCK,
  CV_SKILLS_BLOCK,
  CV_EXPERIENCES_BLOCK,
  CV_FORMATION_BLOCK,
  // HEADER_BLOCK, , no need. double column doing the job
  SIGNATURE_BLOCK,
  CARDS_BLOCK,
  CONTACT_BLOCK,
];

export default ({ onClick }) => {
  return (
    <>
      <div className="p-2 border-b border-gray-300 text-center">
        <label className="text-lg font-semibold">Blocks</label>
      </div>

      <div className="grid grid-cols-2 gap-4 p-4">
        {BLOCKS.map((block, i) => (
          <div
            key={i}
            item={block}
            onClick={() => onClick(block)}
            className="flex items-center justify-center p-4 border rounded-md shadow-sm cursor-pointer hover:bg-gray-50 bg-white">
            <div className="flex flex-col items-center">
              {block.icon}
              <span className="mt-2 text-sm whitespace-nowrap">{block.label}</span>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
