import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { FaBell, FaTimes } from "react-icons/fa";
import { RiRobot2Line } from "react-icons/ri";
import { Popover } from "@headlessui/react";

import { setUser } from "../../redux/auth/actions";
import api from "../../services/api";
import toast from "react-hot-toast";
import LoadingButton from "../loadingButton";
import { Formik } from "formik";
import { IoCloseSharp } from "react-icons/io5";
import Birthday from "./Birthday";
import Organisations from "./Organisations";
import { classNames } from "../../utils";
import useStore from "../../services/store";
import CommandPalette from "./CommandPalette";
import moment from "moment";

const Header = () => {
  const { sideBarOpen, setSideBarOpen } = useStore();
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const dispatch = useDispatch();
  const user = useSelector((state) => state.Auth.user);
  const location = useLocation();

  if (location.pathname.indexOf("/auth") !== -1) return null;

  async function logout() {
    await api.post(`/user/logout`);
    dispatch(setUser(null));
  }

  async function handleAvailability(availability) {
    if (availability === "available" || availability === "not available") {
      const res = await api.put(`/user/${user._id}`, { availability });
      dispatch(setUser(res.data));
      toast.success("Status Updated!");
    } else {
      setOpenModal(true);
    }
  }

  const menuStyling = {
    menuOpacity: `${open ? "opacity-10" : "opacity-0"}`,
    menuVisibility: `${open ? "block p-4 sm:p-0" : "hidden"}`,
    menuTransition: `${open ? "translate-x-0" : "translate-x-[105%]"}`,
  };

  return (
    <header className="sticky top-0 py-2 bg-white border-b border-slate-300 flex justify-between items-center w-full right-4 z-40 px-3">
      <div className="flex items-center">
        <div className="flex items-center space-x-6">
          <Organisations />
        </div>
        {user ? <CommandPalette /> : null}
      </div>
      <div className="flex items-center gap-8">
        <div className="hidden sm:block">
          <Birthday />
        </div>
        <NotificationsCenter />
        <div className="relative flex gap-2">
          {openModal && <Create open={openModal} setOpen={setOpenModal} />}
          <div className="relative my-auto" onClick={() => setOpen(!open)}>
            <img className="min-w-[32px] w-9 sm:h-9 bg-[#aaa] rounded-full cursor-pointer object-cover" src={user.avatar} />
            <div
              className={classNames(`absolute -bottom-1 -left-1 border-2 border-white w-4 h-4 rounded-full`, user.availability === "available" ? "bg-green-600" : "bg-red-600")}
            />
          </div>
          <div
            className={`w-screen md:w-[200px] h-screen md:h-fit rounded overflow-hidden z-0 bg-[#fff] 
          ${menuStyling.menuVisibility} transition duration-100 ease-in fixed top-0 right-0  md:absolute md:top-[calc(100%_+_10px)] z-50 md:z-10 shadow-menuShadow `}
            open={open}>
            <div className="text-3xl mt-3 cursor-pointer	text-[#666] block w-[45px] py-0	px-3.5	ml-auto	md:hidden " onClick={() => setOpen(false)}>
              &times;
            </div>
            <div className="p-2 border-b border-gray-200 text-xs">
              <p className="font-semibold">{user.name}</p>
              <p className="text-gray-500 italic">{user.email}</p>
            </div>
            <div className="text-sm text-left text-black cursor-pointer hover:bg-blue-100 p-2">
              <Link to="/account">My account</Link>
            </div>
            {user.role === "admin" && (
              <div className="text-sm text-left text-black cursor-pointer hover:bg-blue-100 p-2">
                <Link to={`/organisation/${user.organisation_id}`}>My Organisation</Link>
              </div>
            )}
            <div className="text-sm text-left text-black cursor-pointer hover:bg-red-100 p-2" onClick={logout}>
              Logout
            </div>
            {/* <hr className="my-2" />
            <div>
              <p className="text-xs text-gray-600 px-2">Status</p>
              <select
                value={user.availability}
                onChange={(e) => handleAvailability(e.target.value)}
                className="w-full bg-white text-sm font-normal px-2 cursor-pointer border-none hover:bg-blue-100">
                <option value="available">Available</option>
                <option value="not available">Not Available</option>
                {user.availability !== "available" && user.availability !== "not available" && <option value="custom">Personalised</option>}
                <option value={user.availability !== "available" && user.availability !== "not available" ? user.availability : "custom"}>
                  {user.availability !== "available" && user.availability !== "not available" ? user.availability : "Personalised"}
                </option>
              </select>
            </div> */}
          </div>
        </div>
      </div>
    </header>
  );
};

const Create = ({ open, setOpen }) => {
  const dispatch = useDispatch();
  return (
    <div
      style={{ marginBottom: 10 }}
      onClick={() => {
        // close modal when outside of modal is clicked
        setOpen(false);
      }}
      className="fixed top-0 left-0 w-screen bg-[#00000066] h-screen z-50">
      {open ? (
        <div className=" absolute top-1/4 bottom-0 left-32 right-0   flex justify-center p-[1rem] z-50 ">
          <div
            className="w-full md:w-[40%] h-fit  bg-[white] px-[30px] py-[20px] rounded-md"
            onClick={(e) => {
              // do not close modal if anything inside modal content is clicked
              e.stopPropagation();
            }}>
            <div className="flex justify-between items-center mb-5">
              <h2 className="text-lg font-semibold">Select your availability date</h2>
              <IoCloseSharp onClick={() => setOpen(false)} className="cursor-pointer" size={"20"} fontWeight={"bold"} />
            </div>
            <Formik
              initialValues={{ date: "", hour: "" }}
              onSubmit={async (values, { setSubmitting }) => {
                try {
                  let availability = values.date.slice(0, 10) + " " + values.hour;
                  console.log(availability);
                  const res = await api.put(`/user`, { availability });
                  dispatch(setUser(res.data));
                  toast.success("Status Updated!");
                  setOpen(false);
                } catch (e) {
                  console.log(e);
                  toast.error("Some Error!", e.code);
                }
                setSubmitting(false);
              }}>
              {({ values, handleChange, handleSubmit, isSubmitting }) => (
                <React.Fragment>
                  <div>
                    {/* first Row */}
                    <div className="">
                      {/* Name */}
                      <div className="w-full  mt-4">
                        <div className="text-[14px] text-[#212325] font-medium	">Date</div>
                        <input
                          type="date"
                          className="projectsInput text-[14px] font-normal text-[#212325] rounded-[10px]"
                          name="date"
                          value={values.date}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="w-full  mt-4">
                        <div className="text-[14px] text-[#212325] font-medium	">Hour</div>
                        <input
                          type="time"
                          className="projectsInput text-[14px] font-normal text-[#212325] rounded-[10px]"
                          name="hour"
                          value={values.hour}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>

                  <br />
                  <div className="flex justify-end">
                    <LoadingButton
                      className="mt-[1rem] w-[150px]  bg-[#0560FD] text-[16px] font-medium text-[#FFFFFF] py-[10px] px-[22px] rounded-[10px]"
                      loading={isSubmitting}
                      disabled={!values.date || !values.hour}
                      onClick={handleSubmit}>
                      Save
                    </LoadingButton>
                  </div>
                </React.Fragment>
              )}
            </Formik>
          </div>
        </div>
      ) : null}
    </div>
  );
};

const NotificationsCenter = () => {
  const user = useSelector((state) => state.Auth.user);
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState([]);
  const [unreadNotifications, setUnreadNotifications] = useState();

  const fetchNotifications = async () => {
    try {
      const res = await api.post(`/notification/search`, { userIds: [user._id] });
      setNotifications(res.data);
      setUnreadNotifications(res.data.filter((notification) => !notification.readAt).length);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  const deleteNotification = async (notification) => {
    try {
      await api.remove(`/notification/${notification._id}`);
      fetchNotifications();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Popover className="relative">
      <Popover.Button className="relative flex p-3 rounded-xl ease-in-out transition-colors duration-200 hover:bg-gray-100">
        <FaBell className="text-xl cursor-pointer" />
        {unreadNotifications > 0 && (
          <span className="absolute top-1 right-0">
            <span className="relative flex rounded-full w-fit px-2 bg-red-600 ring-2 ring-white text-center">
              <span className="text-xs text-white text-center w-full">{unreadNotifications}</span>
            </span>
          </span>
        )}
      </Popover.Button>

      <Popover.Panel className="absolute right-0 top-12 w-80 bg-white shadow-md border border-slate-300 rounded-lg z-50">
        <div className="flex justify-between items-center p-4 border-b border-gray-200">
          <h2 className="text-lg font-semibold">Notifications</h2>
          <Popover.Button className="p-2 rounded-xl hover:bg-gray-100">
            <FaTimes className="text-base cursor-pointer" />
          </Popover.Button>
        </div>
        <div className="max-h-[80vh] overflow-y-auto">
          {notifications.length ? (
            notifications.map((notification) => (
              <React.Fragment key={notification._id}>
                <div
                  className={`py-2 select-none cursor-pointer px-4 flex items-center hover:bg-sky-50 group relative ${!notification.readAt ? "bg-sky-100" : ""}`}
                  onClick={async () => {
                    if (!notification.readAt) {
                      await api.put(`/notification/${notification._id}/read`);
                    }
                    fetchNotifications();
                    if (notification.href) window.open(notification.href);
                  }}>
                  <div className="absolute top-2 right-2 group">
                    <FaTimes
                      className="text-gray-500 hover:text-gray-500 active:scale-90 transition-all ease-in-out opacity-0 group-hover:opacity-100"
                      onClick={(e) => {
                        e.stopPropagation();
                        deleteNotification(notification);
                      }}
                    />
                  </div>
                  <div className="relative mr-3">
                    <RiRobot2Line className="text-2xl text-gray-500" />
                  </div>
                  <div className="pr-2">
                    <div className="text-sm font-semibold mb-1">{notification.title}</div>
                    <div className="text-xs text-gray-700 mb-1">{notification.message}</div>
                    <div className="text-xs text-gray-500 font-medium">{moment(new Date(notification.createdAt)).format("DD MMM, HH:mm")}</div>
                  </div>
                </div>
                <hr className="border-t border-gray-300" />
              </React.Fragment>
            ))
          ) : (
            <p className="text-sm text-gray-500 p-4">No notifications</p>
          )}
        </div>
      </Popover.Panel>
    </Popover>
  );
};
export default Header;
