import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Loader from "../../../../../components/loader";
import { BiCheck, BiPencil } from "react-icons/bi";
import API from "../../../../../services/api";
import toast from "react-hot-toast";

export default function ({ notion: initialNotion }) {
  const navigate = useNavigate();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const user = useSelector((state) => state.Auth.user);
  const [isEditing, setIsEditing] = useState(false);

  const [notion, setNotion] = useState(initialNotion);

  useEffect(() => {
    setNotion(initialNotion);
  }, [initialNotion]);

  const handleUpdate = async () => {
    try {
      const { ok, data } = await API.put(`/notion/${notion._id}`, notion);
      if (!ok) throw new Error("Failed to update");
      setNotion(data);
      toast.success(`Notion #${notion.index} updated`);
    } catch (error) {
      toast.error(`Failed to update notion #${notion.index}`);
      console.error("Failed to update notion:", error);
    }
  };

  const CategoryTag = () => {
    if (!notion?.category) return null;
    const colors = {
      FEATURE: "bg-blue-100 text-xs text-blue-800",
      BUG: "bg-red-100 text-xs text-red-800",
    };
    return <div className={`px-2 py-1 rounded w-fit ${colors[notion?.category]}`}>{notion?.category}</div>;
  };

  const NotionTags = () => {
    if (!notion?.tags) return null;
    return (
      <div className="flex flex-wrap gap-1">
        {notion?.tags.map((tag) => (
          <span key={tag} className={` text-xs w-fit  text-gray-600 bg-gray-100 px-2 py-1 rounded border border-gray-200`}>
            {tag}
          </span>
        ))}
      </div>
    );
  };

  if (!user) return <Loader />;

  return (
    <div className="w-[350px]">
      <div
        className="relative p-3 py-2 mb-2 flex flex-col justify-center bg-gray-50 rounded shadow-md cursor-pointer border border-gray-200 group"
        onClick={() => {
          if (!isEditing) {
            query.set("task_modal_id", notion._id);
            navigate({ search: query.toString() });
          }
        }}>
        <div className="flex items-center justify-between w-full mb-2">
          <div className="flex items-start gap-1 text-sm font-bold whitespace-nowrap text-ellipsis overflow-hidden">
            {isEditing ? (
              <div className="flex items-center gap-1 flex-1" onClick={(e) => e.stopPropagation()}>
                <input
                  type="text"
                  value={notion?.name}
                  onChange={(e) => {
                    e.stopPropagation();
                    e.persist();
                    setNotion((prev) => ({ ...prev, name: e.target.value }));
                  }}
                  className="input"
                  onClick={(e) => e.stopPropagation()}
                  onFocus={(e) => e.stopPropagation()}
                />
              </div>
            ) : (
              <div className="flex items-center gap-1 flex-1 whitespace-normal">#{notion?.index} - {notion?.name}</div>
            )}
          </div>
          <div className="flex items-center gap-1">
            {isEditing ? (
              <select
                value={notion?.priority}
                onChange={(e) => {
                  e.stopPropagation();
                  e.persist();
                  console.log("✌️  e.target.value", e.target.value);
                  setNotion((prev) => ({ ...prev, priority: e.target.value }));
                }}
                onClick={(e) => e.stopPropagation()}
                className="text-xs p-1 rounded border border-gray-300">
                <option value="">-</option>
                <option value="P0">P0</option>
                <option value="P1">P1</option>
                <option value="P2">P2</option>
                <option value="P3">P3</option>
              </select>
            ) : (
              <span className={`text-xs ${getPriorityColor(notion?.priority)}`}>{notion?.priority}</span>
            )}
          </div>
        </div>

        <div className="mb-1 flex gap-2">
          <CategoryTag />
          <NotionTags />
        </div>
        {notion.budget_name && (
          <div className="flex items-center gap-1 mb-2">
            <span className="flex items-center gap-1 px-2 py-1 text-xs font-medium text-purple-700 bg-purple-100 rounded-lg">
              <svg className="w-3 h-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 4a2 2 0 00-2 2v1h16V6a2 2 0 00-2-2H4z" />
                <path fillRule="evenodd" d="M18 9H2v5a2 2 0 002 2h12a2 2 0 002-2V9zM4 13a1 1 0 011-1h1a1 1 0 110 2H5a1 1 0 01-1-1zm5-1a1 1 0 100 2h1a1 1 0 100-2H9z" clipRule="evenodd" />
              </svg>
              {notion.budget_name}
            </span>
          </div>
        )}
        {notion?.effort_tshirt_size && (
          <div className="text-xs mb-3 gap-1 flex items-center">
            Effort<p className=" bg-gray-500/70 text-white px-1 font-medium rounded-lg">{notion?.effort_tshirt_size}</p>
          </div>
        )}
        <div>
          <div className="flex items-center justify-between">
            <div className="flex flex-wrap">
              {notion.users?.map((e) => (
                <div>
                  <img key={e.user_avatar} className="h-6 w-6 rounded-full mr-1" src={e.user_avatar} alt={e.user_name} />
                  {/* <span className="text-xs">{e.user_name}</span> */}
                </div>
              ))}
            </div>
            <div className={"text-xs text-gray-500"}>{`${daysAgo(notion?.created_at)} days ago`}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

const daysAgo = (date) => {
  const today = new Date();
  const diff = today - new Date(date);
  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  return days;
};

const getPriorityColor = (priority) => {
  if (priority === "P0") return "px-2 py-1 rounded w-fit bg-green-100 text-xs text-green-800";
  if (priority === "P1") return "px-2 py-1 rounded w-fit bg-yellow-100 text-xs text-yellow-800";
  if (priority === "P2") return "px-2 py-1 rounded w-fit bg-orange-100 text-xs text-orange-800";
  if (priority === "P3") return "px-2 py-1 rounded w-fit bg-red-100 text-xs text-red-800";
  return "px-2 py-1 rounded w-fit text-gray-600";
};
