import React, { useState } from "react";

import TabItem from "../../components/TabItem";
import Training1 from "./training1";
import Training2 from "./training2";

const TRAINING_1 = "TRAINING_1";
const TRAINING_2 = "TRAINING_2";

export default () => {
  const [tab, setTab] = useState(TRAINING_1);


  return (
    <div className="p-5">
      <div className="w-full gap-x-4 gap-y-2 ">
        <nav className="bg-white rounded-md flex flex-row w-full border-[1px] border-gray-200 overflow-hidden my-2">
          <TabItem title="Training 1" tab={TRAINING_1} onClick={() => setTab(TRAINING_1)} active={tab === TRAINING_1} />
          <TabItem title="Training 2" tab={TRAINING_2} onClick={() => setTab(TRAINING_2)} active={tab === TRAINING_2} />
        </nav>
        <div className="bg-white rounded-md p-6 border border-primary-black-50 w-full">
          {tab === TRAINING_1 && <Training1 />}
          {tab === TRAINING_2 && <Training2 />}
        </div>
      </div>
    </div>
  );
};
