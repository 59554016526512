import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

import api from "../../../../services/api";

export default ({ activity, setActivity }) => {
  const user = useSelector((state) => state.Auth.user);
  const [values, setValues] = useState(null);

  useEffect(() => {
    setValues(activity);
  }, [activity]);

  if (!values) return <></>;

  return (
    <div className="grid grid-cols-2 gap-4 p-6">
      <div>
        <div className="space-y-4">
          <Contacts activity={values} />
        </div>
        <SelectContact
          value={values.contact_id}
          onChange={(contact) => setValues({ ...values, contact_id: contact._id, contact_name: contact.first_name + " " + contact.last_name })}
        />
      </div>
      <div>
        <div dangerouslySetInnerHTML={{ __html: values.raw.body }} />
      </div>
    </div>
  );
};

const Contacts = ({ activity }) => {
  if (!activity) return <></>;
  if (!activity.raw) return <></>;

  const people = [];
  if (activity?.raw?.from_attendee) people.push(activity.raw.from_attendee);
  if (activity?.raw?.to_attendees) people.push(...activity.raw.to_attendees);
  if (activity?.raw?.cc_attendees) people.push(...activity.raw.cc_attendees);

  return (
    <div>
      {people?.map((attendee, index) => (
        <div key={index} className="flex items-center gap-2 p-2 bg-white rounded">
          <div>
            <div className="font-medium">{attendee.display_name}</div>
            <div className="text-sm text-gray-500">{attendee.identifier}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

const SelectContact = ({ value, onChange }) => {
  const [search, setSearch] = useState("");
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(false);

  const searchContacts = async () => {
    try {
      setLoading(true);
      const { data } = await api.post("/s_contact/search", { search: search, limit: 10 });
      setContacts(data);
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (search) {
      const timeoutId = setTimeout(() => {
        searchContacts();
      }, 300);
      return () => clearTimeout(timeoutId);
    } else {
      setContacts([]);
    }
  }, [search]);
  return (
    <div className="relative">
      <input type="text" className="input w-full" placeholder="Search contacts..." value={search} onChange={(e) => setSearch(e.target.value)} />
      {(contacts.length > 0 || loading) && (
        <div className="absolute z-10 w-full mt-1 bg-white rounded-md shadow-lg max-h-60 overflow-auto">
          {loading ? (
            <div className="p-2 text-center text-gray-500">Loading...</div>
          ) : (
            contacts.map((contact) => (
              <div
                key={contact._id}
                className="p-2 hover:bg-gray-100 cursor-pointer"
                onClick={() => {
                  onChange(contact);
                  setSearch("");
                  setContacts([]);
                }}>
                <div className="flex justify-between">
                  <div>
                    {contact.first_name} {contact.last_name}
                    {contact.personnae && <span className="ml-2 text-sm text-gray-500">({contact.personnae})</span>}
                  </div>
                  <div className="text-sm text-gray-400">{new Date(contact.created_at).toLocaleDateString()}</div>
                </div>
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
};
