import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import api from "../../services/api";

const PROJECT_COLORS = [
  "#4f46e5", // Indigo
  "#06b6d4", // Cyan
  "#8b5cf6", // Purple
  "#ec4899", // Pink
  "#f97316", // Orange
  "#14b8a6", // Teal
  "#8b5cf6", // Violet
  "#f43f5e", // Rose
  "#10b981", // Emerald
  "#6366f1", // Indigo (slightly different shade)
];

export default () => {
  const [users, setUsers] = useState([]);
  const [occupations, setOccupations] = useState([]);
  const months = [...Array(6)].map((_, i) => {
    const date = new Date();
    date.setDate(1);
    date.setMonth(date.getMonth() + i);
    return date;
  });
  const [isSorted, setIsSorted] = useState(false);

  useEffect(() => {
    loadUsers();
    loadOccupations();
  }, []);

  async function loadUsers() {
    const { data } = await api.post("/user/search", {
      contract: [CONTRACTS.CDD, CONTRACTS.CDI, CONTRACTS.VIE, CONTRACTS.INTERNSHIP, CONTRACTS.APPRENTICESHIP, CONTRACTS.FREELANCE],
      roles: ["user", "admin"],
      status: ["active"],
    });

    const filteredUsers = data.users.filter((user) => user.payment_type !== "DAILY");
    setUsers(filteredUsers);
  }

  async function loadOccupations() {
    const { data } = await api.post("/b_occupation/search", { });
    setOccupations(data);
  }

  const navigate = useNavigate();
  const { search } = useLocation();
  const query = new URLSearchParams(search);

  const getCurrentMonthOccupancy = (user, currentMonth) => {
    const monthOccupations = occupations.filter(
      o => o.user_id === user._id && 
          new Date(o.month).getMonth() === currentMonth.getMonth() && 
          new Date(o.month).getFullYear() === currentMonth.getFullYear()
    );
    return monthOccupations.reduce((sum, o) => sum + o.percent, 0);
  };

  const currentMonth = new Date();
  const displayedUsers = isSorted 
    ? [...users].sort((a, b) => {
        const aOccupancy = getCurrentMonthOccupancy(a, currentMonth);
        const bOccupancy = getCurrentMonthOccupancy(b, currentMonth);
        return aOccupancy - bOccupancy;
      })
    : users;

  return (
    <div className="overflow-x-auto">
      <table className="w-full">
        <thead>
          <tr className="border-b">
            <th className="text-left p-1 text-xs w-[120px]">
              <div className="flex items-center gap-2">
                <span>Person</span>
                <span 
                  className="text-blue-500 hover:text-blue-600 cursor-pointer"
                  onClick={() => setIsSorted(!isSorted)}
                >
                  {isSorted ? "(unsort)" : "(sort by occupancy)"}
                </span>
              </div>
            </th>
            {months.map((month) => {
              const monthOccupations = occupations.filter(
                (o) => new Date(o.month).getMonth() === month.getMonth() && 
                       new Date(o.month).getFullYear() === month.getFullYear()
              );
              const totalPercentage = monthOccupations.reduce((sum, o) => sum + o.percent, 0) / users.length;
              
              return (
                <th key={month.toString()} className="text-left p-1 text-xs min-w-[150px]">
                  <div className="text-gray-400 mb-1">{totalPercentage.toFixed(0)}%</div>
                  {month.toLocaleString("default", { month: "short", year: "numeric" })}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {displayedUsers.map((user, index) => {
            const isInactive = user.exit_date && new Date() > new Date(user.exit_date);
            return (
              <tr
                key={user._id}
                className={`border-b hover:bg-gray-200 hover:bg-opacity-50 cursor-pointer ${isInactive ? "bg-gray-100" : ""}`}
                onClick={async () => {
                  query.set("user_modal_id", user._id);
                  query.set("user_modal_tab", "occupation");
                  navigate({ search: query.toString() });
                }}>
                <td className="p-1">
                  <div className="text-sm font-medium">{user.name}</div>
                  <div className="text-[11px] text-gray-400 -mt-0.5 space-x-1">
                    <span>{user.office}</span>
                    <span>·</span>
                    <span>{user.contract}</span>
                  </div>
                </td>
                {months.map((month) => {
                  return (
                    <td key={month.toString()} className="p-1">
                      <OccupationBar
                        user={user}
                        month={month}
                        occupations={occupations.filter(
                          (o) => o.user_id === user._id && new Date(o.month).getMonth() === month.getMonth() && new Date(o.month).getFullYear() === month.getFullYear(),
                        )}
                      />
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const OccupationBar = ({ user, month, occupations }) => {
  const isInactive = user?.exit_date && new Date(month).getTime() > new Date(user.exit_date).getTime();
  const incoming = user?.starting_date && (
    new Date(month).getFullYear() < new Date(user.starting_date).getFullYear() ||
    (new Date(month).getFullYear() === new Date(user.starting_date).getFullYear() &&
     new Date(month).getMonth() < new Date(user.starting_date).getMonth())
  );

  if (isInactive) return <div className="h-5 w-full overflow-hidden bg-gray-50 flex items-center justify-center text-gray-400 text-xs font-medium">inactive</div>;
  if (incoming) return <div className="h-5 w-full overflow-hidden bg-gray-50 flex items-center justify-center text-gray-400 text-xs font-medium">incoming</div>;

  return (
    <div className="h-5 w-full overflow-hidden bg-gray-50 rounded-sm">
      {occupations.map((occupation, index) => (
        <div
          key={occupation._id}
          className="h-full float-left transition-opacity hover:opacity-100"
          style={{
            width: `${occupation.percent}%`,
            backgroundColor: PROJECT_COLORS[index % PROJECT_COLORS.length],
            backgroundImage: occupation.soft ? "repeating-linear-gradient(45deg, transparent, transparent 5px, rgba(255,255,255,0.2) 5px, rgba(255,255,255,0.2) 10px)" : "none",
            opacity: 0.85,
          }}
          title={`${occupation.project_name}: ${occupation.percent}%`}
        />
      ))}
    </div>
  );
};

const CONTRACTS = {
  CDD: "CDD",
  CDI: "CDI",
  VIE: "VIE",
  INTERNSHIP: "INTERNSHIP",
  APPRENTICESHIP: "APPRENTICESHIP",
  FREELANCE: "FREELANCE",
};
