import { useEffect, useState } from "react";
import { IoDownloadOutline, IoEllipsisVertical, IoLinkOutline, IoTrashOutline } from "react-icons/io5";
import API from "../../../../../services/api";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const exportColumns = [
  "module",
  "title",
  "resource_profile",
  "business_objective",
  "charge",
  "business_contribution",
  "urgent",
  "module",
  "points",
  "estimation",
  "days_required",
  "status",
  "priority",
  "comments",
];

export const MultiAction = ({ selectedEpics, epics, handleMultipleDelete, project }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [epicsToProcess, setEpicsToProcess] = useState(selectedEpics.length > 0 ? selectedEpics : epics);

  useEffect(() => {
    setEpicsToProcess(selectedEpics.length > 0 ? selectedEpics : epics);
  }, [selectedEpics, epics]);

  const downloadCSV = () => {
    const csvData = [exportColumns.map((column) => column)];
    epicsToProcess.forEach((item) => {
      const rowData = exportColumns?.map((column) => {
        if (column === "comments") {
          if (!item.comments) return '""';
          return `"${item.comments
            .map((e) => {
              if (!e?.text) return "";
              return e.text.replace(/<[^>]*>?/gm, "");
            })
            .join("\n")
            .replace(/"/g, '""')}"`;
        }
        const value = item[column];
        return `"${value ? String(value).replace(/"/g, '""') : ""}"`;
      });
      csvData.push(rowData);
    });

    const csvContent = csvData.map((row) => row.join(",")).join("\n");
    const blob = new Blob([csvContent], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "exported_data.csv";
    link.click();
  };

  const linkToQuote = async () => {
    const { data, ok } = await API.post("/quote", {
      name: project.name + " - " + new Date().toLocaleDateString("fr", { day: "numeric", month: "short", year: "numeric" }),
      project_id: project._id,
      project_name: project.name,
      category: "PROPAL",
      lang: "en",
    });
    if (!ok) return toast.error("Error creating quote");

    generateQuotePages(data);

    toast.success("Epics linked to quote");
  };

  const generateQuotePages = async (quote) => {
    try {
      // Split selected epics into chunks of 15 items each
      const chunks = [];
      for (let i = 0; i < epicsToProcess.length; i += 20) {
        chunks.push(epicsToProcess.slice(i, i + 20));
      }

      let lastPosition = 0;
      let totalEstimation = 0;
      let totalDays = 0;

      for (const chunk of chunks) {
        const page = {
          position: lastPosition,
          format: "A4",
          quote_id: quote._id,
          quote_name: quote.name,
          background_image_url: "https://bank.cellar-c2.services.clever-cloud.com/filebgImage/9146709e9582992ec6bc4910ed545904/background.png",
        };

        const resP = await API.post("/quote_page", page);
        if (!resP.ok) throw resP;

        const block = {
          type: "table",
          quote_id: quote._id,
          quote_name: quote.name,
          quote_page_id: resP.data._id,
          isTemplate: false,
          display_footer: false,
          arr: chunk.map((epic) => {
            const description = {
              fr: `
                <div>
                  ${epic.module ? `<span>[${epic.module}] ${epic.title}</span> ` : ""}
                </div>
              `,
              en: `
                <div>
                  ${epic.module ? `<span>[${epic.module}] ${epic.title}</span> ` : ""}
                </div>
              `,
            };

            // Calculate price and quantities
            const daysRequired = epic.days_required || 1;
            const estimation = epic.estimation;
            const pricePerDay = estimation / daysRequired;

            totalEstimation += estimation;
            totalDays += daysRequired;

            return {
              description,
              price: pricePerDay,
              min_quantity: daysRequired,
              max_quantity: daysRequired,
              min_total: estimation,
              max_total: estimation,
              tax: 0,
              quantity: daysRequired,
              total: estimation,
            };
          }),
        };

        const resB = await API.post("/quote_block", block);
        if (!resB.ok) throw resB;

        // Add total summary block on the last page
        if (lastPosition === chunks.length - 1) {
          const titleTotalBlock = {
            type: "text",
            quote_id: quote._id,
            quote_name: quote.name,
            quote_page_id: resP.data._id,
            isTemplate: false,
            description: {
              fr: `<div><span>Total : ${totalDays} jours - ${totalEstimation}€</span></div>`,
              en: `<div><span>Total: ${totalDays} days - ${totalEstimation}€</span></div>`,
            },
          };

          const resTitleBlock = await API.post("/quote_block", titleTotalBlock);
          if (!resTitleBlock.ok) throw resTitleBlock;
        }

        lastPosition += 1;
      }

      toast.success(`Created ${chunks.length} pages with ${epicsToProcess.length} items`);

      if (window.confirm(`Epics linked to quote across ${chunks.length} pages!\n\nDo you want to be redirected to the quote page?\nCancel to stay on this page.`)) {
        navigate(`/quote/${quote._id}`);
      }
    } catch (error) {
      console.error(error);
      toast.error("Failed to link quote");
    }
  };

  return (
    <div className="relative h-[38px]">
      <button
        onClick={(e) => {
          e.stopPropagation();
          setIsOpen(!isOpen);
        }}
        className="inline-flex justify-between items-center h-[38px] gap-4 px-2 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
        <IoEllipsisVertical className="w-4 h-4" />
      </button>

      {isOpen && (
        <>
          <div className="fixed inset-0" onClick={() => setIsOpen(false)} />
          <div className="absolute top-full right-0 w-56 bg-white border border-gray-200 rounded-lg shadow-xl z-10 overflow-hidden animate-fadeIn">
            <div className="py-2">
              <button
                onClick={handleMultipleDelete}
                className="w-full px-4 py-2.5 text-left text-sm font-medium text-red-600 hover:bg-red-50 transition-colors duration-200 cursor-pointer flex items-center gap-2">
                <IoTrashOutline className="w-4 h-4" />
                Delete {selectedEpics.length > 0 ? `(${selectedEpics.length} items)` : "All"}
              </button>

              <button
                onClick={downloadCSV}
                className="w-full px-4 py-2.5 text-left text-sm font-medium text-gray-700 hover:bg-gray-50 transition-colors duration-200 cursor-pointer flex items-center gap-2">
                <IoDownloadOutline className="w-4 h-4" />
                Export CSV {selectedEpics.length > 0 ? `(${selectedEpics.length} items)` : ""}
              </button>

              <button
                onClick={linkToQuote}
                className="w-full px-4 py-2.5 text-left text-sm font-medium text-gray-700 hover:bg-gray-50 transition-colors duration-200 cursor-pointer flex items-center gap-2">
                <IoLinkOutline className="w-4 h-4" />
                Link to Quote {selectedEpics.length > 0 ? `(${selectedEpics.length} items)` : ""}
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
