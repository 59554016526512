import { useState } from "react";
import api from "../../../services/api";
import LoadingButton from "../../../components/loadingButton";
import toast from "react-hot-toast";

const Test = ({ audit }) => {
    const [values, setValues] = useState(audit?.selego_test || {});
    const [btnLoading, setBtnLoading] = useState(false);
  
    const onUpdate = async () => {
      setBtnLoading(true);
  
      const { ok } = await api.put(`/audit/${audit?._id}`, { ...audit, selego_test: { ...values, score: Object.values(values).filter((v) => v === true).length } });
      if (!ok) return toast.error("Error updating test!");
      toast.success("Test updated!");
      setBtnLoading(false);
    };
  
    return (
      <div>
        <div className="mb-8 p-4 bg-gray-50 rounded-lg">
          <h3 className="font-medium mb-2">About the Selego Test</h3>
          <p className="text-sm text-gray-600 mb-2">This is a quick 3-minute test to rate the quality of a software team. Each "yes" answer is worth 1 point.</p>
          <p className="text-sm text-gray-600">
            The test focuses on key aspects like deployment frequency, code review practices, team knowledge, and business understanding. Answer honestly to get an accurate
            assessment of your team's current state.
          </p>
        </div>
  
        <div className="grid grid-cols-1 gap-4">
          <div>
            <label className="flex items-center gap-2">
              <input type="checkbox" checked={values?.release_multiple_times_per_day} onChange={(e) => setValues({ ...values, release_multiple_times_per_day: e.target.checked })} />
              <span>Do you release multiple times per day?</span>
            </label>
          </div>
  
          <div>
            <label className="flex items-center gap-2">
              <input type="checkbox" checked={values?.prs_reviewed} onChange={(e) => setValues({ ...values, prs_reviewed: e.target.checked })} />
              <span>Are all PRs reviewed by someone else?</span>
            </label>
          </div>
  
          <div>
            <label className="flex items-center gap-2">
              <input type="checkbox" checked={values?.know_roadmap} onChange={(e) => setValues({ ...values, know_roadmap: e.target.checked })} />
              <span>Do all developers working on the project know the roadmap?</span>
            </label>
          </div>
  
          <div>
            <label className="flex items-center gap-2">
              <input type="checkbox" checked={values?.know_cash} onChange={(e) => setValues({ ...values, know_cash: e.target.checked })} />
              <span>Do you know how long the company has (in cash) before bankruptcy?</span>
            </label>
          </div>
  
          <div>
            <label className="flex items-center gap-2">
              <input type="checkbox" checked={values?.know_users} onChange={(e) => setValues({ ...values, know_users: e.target.checked })} />
              <span>Do you know who the users are and their usage patterns?</span>
            </label>
          </div>
  
          <div>
            <label className="flex items-center gap-2">
              <input type="checkbox" checked={values?.know_tech_spent} onChange={(e) => setValues({ ...values, know_tech_spent: e.target.checked })} />
              <span>Do you know what the current monthly cash spent on tech/product/growth is?</span>
            </label>
          </div>
  
          <div>
            <label className="flex items-center gap-2">
              <input type="checkbox" checked={values?.code_simple} onChange={(e) => setValues({ ...values, code_simple: e.target.checked })} />
              <span>Is the code simple enough for junior developers to start coding in less than 0.5 days?</span>
            </label>
          </div>
  
          <div>
            <label className="flex items-center gap-2">
              <input type="checkbox" checked={values?.challenge_spec} onChange={(e) => setValues({ ...values, challenge_spec: e.target.checked })} />
              <span>Did you challenge/change specs in the last 2 weeks to simplify development?</span>
            </label>
          </div>
  
          <div>
            <label className="flex items-center gap-2">
              <input type="checkbox" checked={values?.tshirt_pricing} onChange={(e) => setValues({ ...values, tshirt_pricing: e.target.checked })} />
              <span>Did you do T-shirt sizing for current features (business value vs dev cost)?</span>
            </label>
          </div>
  
          <div className="mt-4 flex items-center justify-between">
            <div className="font-medium"> Total Score: {Object.values(values).filter((v) => v === true).length}/9</div>
            <LoadingButton className="btn bg-primary text-white" loading={btnLoading} onClick={onUpdate}>
              Update Test
            </LoadingButton>
          </div>
        </div>
      </div>
    );
  };

  export default Test;