import React, { useEffect, useState } from "react";
import api from "../../services/api";
import { toast } from "react-hot-toast";
import { formatDistanceToNow } from "date-fns";

export default () => {
  const [needs, setNeeds] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchNeeds();
  }, []);

  async function fetchNeeds() {
    setLoading(true);
    try {
      const { data, ok } = await api.post("/s_company/search", {
        pipeline: true,
        status: ["PROSPECTION", "QUALIFIED", "DISCOVERY", "PROPOSAL", "NEGOTIATION", "CLOSING"],
        sort: {
          priority: -1,
          confidence: -1,
        },
      });
      if (!ok) throw new Error("Error loading needs");
      setNeeds(data);
    } catch (error) {
      toast.error("Error loading needs");
    }
    setLoading(false);
  }

  if (loading) return <div>Loading...</div>;

  return (
    <div className="bg-white h-full">
      <div className="p-4">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
          {needs.map((need) => (
            <NeedCard key={need._id} need={need} />
          ))}
        </div>
      </div>
    </div>
  );
};

const NeedCard = ({ need }) => {
  return (
    <div 
      onClick={() => window.open('https://accounting.selego.co/acquiz/pipe', '_blank')}
      className={`bg-white p-4 rounded-lg shadow cursor-pointer transition-all hover:shadow-lg border border-gray-100 h-full flex flex-col justify-between`}>
      <div>
        <div className="flex justify-between items-start gap-2">
          <h3 className="font-medium text-lg line-clamp-2">{need.name}</h3>
          <div className="flex flex-col gap-2 items-end shrink-0">
            {need.deal_amount && <span className="text-sm font-medium text-green-700">${(need.deal_amount / 1000).toFixed(0)}k</span>}
            {need.priority && (
              <span
                className={`text-xs px-2 py-1 rounded-full ${
                  need.priority === "urgent" ? "bg-red-100 text-red-800" : need.priority === "medium" ? "bg-yellow-100 text-yellow-800" : "bg-gray-100 text-gray-800"
                }`}>
                {need.priority}
              </span>
            )}
            {need.status && (
              <span className="text-xs px-2 py-1 rounded-full bg-blue-100 text-blue-800">
                {need.status.toLowerCase()}
              </span>
            )}
          </div>
        </div>

        {need.need && (
          <div className="mt-2 text-base text-gray-900 line-clamp-3">
            {need.need}
          </div>
        )}

        <div className="mt-3 flex flex-wrap gap-2">
          {need.personnae && (
            <span className="text-xs bg-purple-100 text-purple-800 px-2 py-1 rounded-full">
              {need.personnae.toLowerCase().replace(/_/g, " ")}
            </span>
          )}
          {need.duration && (
            <span className="text-xs bg-blue-100 text-blue-800 px-2 py-1 rounded-full">
              {need.duration} months
            </span>
          )}
          {need.confidence && (
            <span className="text-xs bg-green-100 text-green-800 px-2 py-1 rounded-full">
              {need.confidence}% confidence
            </span>
          )}
        </div>
      </div>

      <div className="mt-3 flex justify-between items-center text-xs text-gray-500">
        <div>
          {need.source_name && (
            <span className="bg-gray-100 px-2 py-1 rounded">
              {need.source_name}
            </span>
          )}
        </div>
        {need.created_at && <span>{formatDistanceToNow(new Date(need.created_at))} ago</span>}
      </div>
    </div>
  );
};
