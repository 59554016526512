import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Routes, Route, NavLink } from "react-router-dom";

import FrameworkTShirt from "./FrameworkTShirt";
import FrameworkTShirt2 from "./FrameworkTShirt2";
import Planning from "./Planning";
import FrameworkNNL from "./FrameworkNNL";
import FrameworkBudget from "./FrameworkBudget";
import FrameworkNNL2 from "./FrameworkNNL2";
import FrameworkByModule from "./FrameworkByModule";

const Roadmap = ({ project }) => {
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    if (location.pathname.endsWith("/roadmap") && project.roadmap_tabs?.length) {
      navigate(`/project/${id}/roadmap/${project.roadmap_tabs[0]}`);
    }
  }, []);

  return (
    <div className="space-y-2">
      <nav className="flex items-center border-b border-gray-300">
        {project.roadmap_tabs?.map((tab, index) => (
          <TabItem key={index} href={tab} title={tab} />
        ))}
      </nav>

      <Routes>
        <Route exact path="nnl" element={<FrameworkNNL project={project} />} />
        <Route path="nnl2" element={<FrameworkNNL2 project={project} />} />
        <Route path="tshirt" element={<FrameworkTShirt project={project} />} />
        <Route path="tshirt2" element={<FrameworkTShirt2 project={project} />} />
        <Route path="planning" element={<Planning project={project} />} />
        <Route path="budget" element={<FrameworkBudget project={project} />} />
        <Route path="by_module" element={<FrameworkByModule project={project} />} />
      </Routes>
    </div>
  );
};

const TabItem = ({ href, title, index }) => {
  const { id } = useParams();
  return (
    <NavLink
      key={index}
      to={`/project/${id}/roadmap${href ? `/${href}` : ""}`}
      end
      className={({ isActive }) =>
        `text-sm px-4 py-2 hover:bg-gray-100 transition-colors border-b-2 ${isActive ? "border-sky-700 text-sky-700" : "border-transparent text-gray-500"}`
      }>
      {title}
    </NavLink>
  );
};

export default Roadmap;
