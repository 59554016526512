import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { HiMagnifyingGlass, HiOutlineGlobeAlt } from "react-icons/hi2";

import Loader from "../../components/loader";
import Pagination from "../../components/pagination";

import api from "../../services/api";

export default () => {
  const [commits, setCommits] = useState();
  const [filter, setFilter] = useState({
    search: "",
    per_page: 50,
    page: 1,
  });

  const [total, setTotal] = useState();
  const [repos, setRepos] = useState([]);
  const [branches, setBranches] = useState([]);
  const [committers, setCommitters] = useState([]);

  const handleNextPage = () => setFilter((prev) => ({ ...prev, page: prev.page + 1 }));
  const handlePreviousPage = () => setFilter((prev) => ({ ...prev, page: prev.page - 1 }));

  useEffect(() => {
    get();
  }, [filter]);

  const get = async () => {
    try {
      const { data, ok, total, code, repos, branches, committers } = await api.post("/git_commits/search", filter);
      if (!ok) return toast.error("Error!" || code);
      setCommits(data);
      setRepos(repos);
      setBranches(branches);
      setCommitters(committers);
      setTotal(total);
    } catch (error) {
      console.log(error);
      return toast.error("Error fetching commits!");
    }
  };

  if (!commits) return <Loader />;

  return (
    <div className="p-4 space-y-4">
      <div className="flex items-center justify-between mb-2">
        <h2 className="text-xl font-bold text-gray-900">All commits</h2>
        <div className="relative w-80">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <HiMagnifyingGlass className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
          <input
            type="text"
            name="search"
            value={filter.search}
            onChange={(e) => setFilter({ ...filter, search: e.target.value })}
            className="block w-full rounded-lg border border-gray-300 bg-white py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
            placeholder="Search commits..."
          />
        </div>
      </div>
      <div className="grid items-center grid-cols-4 gap-x-2 mb-2">
        <select
          onChange={(evt) => setFilter({ ...filter, repo: evt.target.value })}
          className="block w-full rounded-lg border border-gray-300 py-2 px-3 text-sm placeholder-gray-500 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500">
          <option value="">All Repos</option>
          {repos.map((repo) => (
            <option key={repo} value={repo}>
              {repo}
            </option>
          ))}
        </select>
        <select
          onChange={(evt) => setFilter({ ...filter, branch: evt.target.value })}
          className="block w-full rounded-lg border border-gray-300 py-2 px-3 text-sm placeholder-gray-500 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500">
          <option value="">All Branches</option>
          {branches.map((branch) => (
            <option key={branch} value={branch}>
              {branch}
            </option>
          ))}
        </select>
        <select
          onChange={(evt) => setFilter({ ...filter, committer: evt.target.value })}
          className="block w-full rounded-lg border border-gray-300 py-2 px-3 text-sm placeholder-gray-500 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500">
          <option value="">All Committers</option>
          {committers.map((committer) => (
            <option key={committer} value={committer}>
              {committer}
            </option>
          ))}
        </select>
      </div>

      <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-lg">
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-gray-50">
            <tr className="text-left text-xs font-semibold text-gray-900">
              <th className="py-3 px-2 w-52">Commit SHA</th>
              <th className="py-3 px-2 w-32">Committer</th>
              <th className="py-3 px-2">Message</th>
              <th className="py-3 px-2 w-20">+</th>
              <th className="py-3 px-2 w-20">-</th>
              <th className="py-3 px-2 w-32">Repo</th>
              <th className="py-3 px-2 w-32">Branch</th>
              <th className="py-3 px-2 w-24">Date</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {commits.map((commit) => (
              <tr key={commit.commit_sha} className="hover:bg-gray-100">
                <td className="py-0.5 px-2 text-xs text-gray-500">
                  <div className="flex flex-col">
                    <span className="text-xs text-gray-400">{commit.repo_owner}/{commit.repo}</span>
                    <div className="flex items-center gap-1">
                      <span className="font-mono">{commit.commit_sha.substring(0, 7)}</span>
                      <a 
                        href={commit.commit_url} 
                        title="View commit on GitHub" 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className="inline-flex items-center text-xs text-blue-600 hover:text-blue-800"
                      >
                        <HiOutlineGlobeAlt className="h-3 w-3" />
                      </a>
                    </div>
                  </div>
                </td>
                <td className="py-0.5 px-2 text-xs text-gray-500 truncate max-w-[8rem]">{commit.committer}</td>
                <td className="py-0.5 px-2 text-xs text-gray-500">
                  <div className="line-clamp-2 min-h-[2.5em]" title={commit.message}>
                    {commit.message}
                  </div>
                </td>
                <td className="py-0.5 px-2 text-xs text-green-500">{commit.added_code}</td>
                <td className="py-0.5 px-2 text-xs text-red-500">{commit.removed_code}</td>
                <td className="py-0.5 px-2 text-xs text-gray-500 truncate">{commit.repo}</td>
                <td className="py-0.5 px-2 text-xs text-gray-500 truncate">{commit.branch}</td>
                <td className="py-0.5 px-2 text-xs text-gray-500 whitespace-nowrap">
                  {new Date(commit.commit_date).toLocaleDateString("fr", {
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                  })}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination per_page={filter.per_page} total={total} currentPage={filter.page} onNext={handleNextPage} onPrevious={handlePreviousPage} />
    </div>
  );
};
