import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import TablePagination from "../../../../components/TablePagination";
import api from "../../../../services/api";

export default ({ project, filters }) => {
  const [activities, setActivities] = useState([]);

  const HEADER_ACTIVITIES = [
    { title: "Name", position: "right" },
    { title: "Job Title", position: "right" },
    { title: "TJM", position: "right" },
    { title: "Days", position: "right" },
    { title: "Cost", position: "right" },
    { title: "%", position: "right" },
  ];

  useEffect(() => {
    getActivities();
  }, [filters.date, filters.budget]);

  const getActivities = async () => {
    try {
      const { ok, data } = await api.post(`/activity/groupBy`, {
        projectId: project._id,
        groupBy: "userName",
        userName: filters.userName,
        date: filters.date,
        budgetId: filters?.budget?._id,
      });
      if (!ok) return toast.error("Error while fetching activities");
      setActivities(data);
    } catch (error) {
      toast.error("Error while fetching activities");
    }
  };

  if (!activities.length)
    return (
      <div>
        <h2 className="text-lg font-semibold mb-3">Activities</h2>
        <p className="text-sm text-gray-500 mb-3">These are the people that have worked on the product</p>
        <p className="text-md mt-10">No activities found</p>
      </div>
    );

  const totalCost = activities.reduce((acc, a) => acc + a.value, 0).toFixed(2);
  const totalInDays = activities.reduce((acc, a) => acc + (a.total / 8 || 0), 0).toFixed(2);

  return (
    <div>
      <h2 className="text-lg font-semibold mb-3">Activities</h2>
      <p className="text-sm text-gray-500 mb-3">These are the people that have worked on the product</p>

      <TablePagination sticky header={HEADER_ACTIVITIES} total={activities.length} pageSize={200}>
        <>
          <tr className="h-10 bg-gray-100">
            <td className="text-right p-1 text-xs font-semibold"></td>
            <td className="text-right p-1 text-xs font-semibold"></td>
            <td className="text-right p-1 text-xs font-semibold"></td>
            <td className="text-right p-1 text-xs font-semibold">{totalInDays}</td>
            <td className="text-right p-1 text-xs font-semibold">{totalCost.toLocaleString("fr", { style: "currency", currency: "EUR" })}</td>
            <td className="text-right p-1 text-xs font-semibold"></td>
          </tr>

          {activities.map((item, index) => (
            <ActivityRow key={index} item={item} totalCost={totalCost} />
          ))}
        </>
      </TablePagination>
    </div>
  );
};

const ActivityRow = ({ item, totalCost }) => {
  const value = item.value || 0;
  const percentage = totalCost ? ((value / totalCost) * 100).toFixed(2) : 0;

  return (
    <tr className="h-10 hover:bg-gray-50">
      <td className="p-1 text-xs flex items-center">
        <img src={item.userAvatar} alt={item.name} className="w-6 h-6 rounded-full" />
        <p className="ml-2">{item.name}</p>
      </td>
      <td className="p-1 text-xs text-right">{item.userJobTitle}</td>
      <td className="p-1 text-xs text-right">{(item.userTjms || 0).toLocaleString("fr", { style: "currency", currency: "EUR" })}</td>
      <td className="p-1 text-xs text-right">{(item.total / 8).toFixed(2)}</td>
      <td className="p-1 text-xs text-right">{(item.value || 0).toLocaleString("fr", { style: "currency", currency: "EUR" })}</td>
      <td className="p-1 text-xs text-right">{percentage}%</td>
    </tr>
  );
};
