import { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import toast from "react-hot-toast";
import { useNavigate, useSearchParams } from "react-router-dom";
import { IoPencilOutline, IoCheckmarkCircle, IoCheckmark, IoClose } from "react-icons/io5";

import Table from "../../../../components/Table";
import DebounceInput from "../../../../components/DebounceInput";
import EpicEditModal from "./EpicModal";
import EpicCreationModal from "./EpicCreationModal";
import api from "../../../../services/api";
import Modal from "../../../../components/modal";
import MultiSelect from "../../../../components/MultiSelect";
import { MultiAction } from "./Composant/MultiAction";

export default ({ project }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [epics, setEpics] = useState([]);
  const [filters, setFilters] = useState({
    page: 1,
    search: searchParams.get("search") || "",
    statuses: searchParams.get("statuses")?.split(",") || ["TODO", "IN_PROGRESS", "NEED_INFO"],
    priorities: searchParams.get("priorities")?.split(",") || [],
    modules: searchParams.get("modules")?.split(",") || [],
    resource_profile: searchParams.get("resource_profile")?.split(",") || [],
    is_sent_to_backlog: searchParams.get("is_sent_to_backlog") || "",
  });
  const [sortBy, setSortBy] = useState({ key: "created_at", value: 1 });
  const [selectedEpics, setSelectedEpics] = useState([]);
  const [selectedEpic, setSelectedEpic] = useState(null);
  const [isEpicEditModalOpen, setIsEpicEditModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchEpics();
  }, [filters]);

  const fetchEpics = async () => {
    setLoading(true);
    try {
      const { data, ok } = await api.post("/epic/search", { project_id: project._id, ...filters });
      if (!ok) return toast.error("Failed to fetch roadmaps");
      // sort by created_at ( date value)
      // data.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
      // sort on module
      data.sort((a, b) => (a.module || "").localeCompare(b.module || ""));
      setEpics(data);

      const newSearchParams = new URLSearchParams(searchParams);
      if (filters.search) newSearchParams.set("search", filters.search);
      else newSearchParams.delete("search");
      if (filters.priorities.length > 0) newSearchParams.set("priorities", filters.priorities.join(","));
      else newSearchParams.delete("priorities");
      if (filters.statuses.length > 0) newSearchParams.set("statuses", filters.statuses.join(","));
      else newSearchParams.delete("statuses");
      if (filters.modules.length > 0) newSearchParams.set("modules", filters.modules.join(","));
      else newSearchParams.delete("modules");
      if (filters.resource_profile.length > 0) newSearchParams.set("resource_profile", filters.resource_profile.join(","));
      else newSearchParams.delete("resource_profile");
      if (filters.is_sent_to_backlog) newSearchParams.set("is_sent_to_backlog", filters.is_sent_to_backlog);
      else newSearchParams.delete("is_sent_to_backlog");
      setSearchParams(newSearchParams);
    } catch (error) {
      console.error(error);
      toast.error("Failed to fetch roadmaps");
    } finally {
      setLoading(false);
    }
  };

  const handleMultipleDelete = async () => {
    try {
      if (!window.confirm("Are you sure you want to delete selected epics?")) return;

      for (let i = 0; i < selectedEpics.length; i++) {
        const { ok } = await api.remove(`/epic/${selectedEpics[i]._id}`);
        if (!ok) {
          toast.error(`Failed to delete epic ${selectedEpics[i]._id}`);
          continue;
        }
      }

      toast.success("Epics deleted successfully");
      fetchEpics();
      setSelectedEpics([]);
    } catch (error) {
      console.error(error);
      toast.error("Failed to delete epics");
    }
  };

  const totalNow = epics.filter((e) => e.priority === "NOW");
  const totalNext = epics.filter((e) => e.priority === "NEXT");
  const totalLater = epics.filter((e) => e.priority === "LATER");

  function selectEpic(e) {
    setSelectedEpic(e);
    setIsEpicEditModalOpen(true);
  }

  return (
    <div className="space-y-4">
      <div className="bg-white p-4 rounded border border-gray-200">
        <div className="flex items-start gap-4">
          <div className="w-full flex justify-between gap-4">
            <div className="grid grid-cols-6 gap-2 w-4/5">
              <DebounceInput
                debounce={300}
                className="inline-flex justify-between items-center col-span-6 h-[38px] gap-4 px-2 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                placeholder="Search by title..."
                value={filters.search}
                onChange={(e) => setFilters((prev) => ({ ...prev, search: e.target.value, page: 1 }))}
              />
              <SelectModule value={filters.modules} onChange={(modules) => setFilters((f) => ({ ...f, modules: modules }))} project={project} />
              <MultiSelect
                id="select-resource-profile"
                options={["DEVELOPER", "EXPERIMENTED DEVELOPER", "CTO", "PRODUCT"].map((resourceProfile) => ({ value: resourceProfile, label: resourceProfile }))}
                values={filters.resource_profile?.map((resourceProfile) => ({ value: resourceProfile, label: resourceProfile })) || []}
                onSelectedChange={(e) => setFilters((f) => ({ ...f, resource_profile: e.map((e) => e.value) }))}
                placeholder="Resource"
              />
              <MultiSelect
                id="select-priority"
                options={["NOW", "NEXT", "LATER"].map((priority) => ({ value: priority, label: priority }))}
                values={filters.priorities?.map((priority) => ({ value: priority, label: priority })) || []}
                onSelectedChange={(e) => setFilters((f) => ({ ...f, priorities: e.map((e) => e.value) }))}
                placeholder="Priority"
              />
              <MultiSelect
                id="select-status"
                options={["TODO", "IN_PROGRESS", "DONE", "NEED_INFO"].map((status) => ({ value: status, label: status }))}
                values={filters.statuses?.map((status) => ({ value: status, label: status })) || []}
                onSelectedChange={(e) => setFilters((f) => ({ ...f, statuses: e.map((e) => e.value) }))}
                placeholder="Status"
              />
              <SelectAssigned value={filters.assigned} onChange={(e) => setFilters((f) => ({ ...f, assigned: e }))} project={project} />
              <select
                id="select-sent-to-backlog"
                value={filters.is_sent_to_backlog || ""}
                onChange={(e) => setFilters((f) => ({ ...f, is_sent_to_backlog: e.target.value }))}
                className="min-w-[140px] h-[38px] inline-flex justify-between items-center gap-4 px-2 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                <option value="">Backlog</option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
            </div>
            <div className="flex gap-4 mb-2 w-1/5">
              <button
                onClick={async () => {
                  const { ok, data } = await api.post("/epic", { project_id: project._id });
                  if (!ok) return toast.error("Failed to create epic");
                  toast.success("Epic created successfully");
                  fetchEpics();
                  setSelectedEpic(data);
                  setIsEpicEditModalOpen(true);
                }}
                className="blue-btn w-full h-[38px]">
                New Epic
              </button>
              <MultiAction selectedEpics={selectedEpics} epics={epics} project={project} handleMultipleDelete={handleMultipleDelete} />
            </div>
          </div>
        </div>
      </div>
      {isEpicEditModalOpen && (
        <EpicEditModal
          epic={selectedEpic}
          onClose={(e) => {
            if (!selectedEpic) return;
            const index = epics.findIndex((item) => item._id === selectedEpic._id);
            const newEpics = [...epics];
            newEpics[index] = { ...newEpics[index], ...e };
            setEpics(newEpics);
            setSelectedEpic(null);
            setIsEpicEditModalOpen(false);
          }}
          onDelete={() => {
            const newEpics = epics.filter((item) => item._id !== selectedEpic._id);
            setEpics(newEpics);
            setSelectedEpic(null);
            setIsEpicEditModalOpen(false);
          }}
        />
      )}
      <Table
        total={epics.length}
        header={[
          {
            title: (
              <input
                type="checkbox"
                className="w-4 h-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedEpics(epics);
                  } else {
                    setSelectedEpics([]);
                  }
                }}
              />
            ),
            position: "center",
          },
          { title: "#", key: "index" },
          { title: "Personae", key: "persona" },
          { title: "Module", key: "module" },
          { title: "Title", key: "title" },
          { title: "Priority", key: "priority" },
          { title: "Resource Profile", key: "resource_profile" },
          { title: "Assigned", key: "assigned" },
          { title: "Days Required", key: "days_required" },
          { title: "Estimation", key: "estimation" },
          { title: "Status", key: "status" },
          { title: "In Backlog", key: "sent_to_backlog_at" },
        ]}
        loading={loading}
        height="h-[32rem]"
        sort={{ [sortBy.key]: sortBy.value }}
        onSort={(e) => setSortBy(sortBy.key === e ? { key: e, value: sortBy.value * -1 } : { key: e, value: 1 })}>
        <AnimatePresence>
          {epics
            .sort((a, b) => {
              if (sortBy.key === "created_at") {
                return sortBy.value * (new Date(b.created_at) - new Date(a.created_at));
              }
              // Handle numeric fields
              if (["days_required", "estimation", "index"].includes(sortBy.key)) {
                return sortBy.value * ((a[sortBy.key] || 0) - (b[sortBy.key] || 0));
              }
              // Default string comparison
              return sortBy.value * (a[sortBy.key] || "").localeCompare(b[sortBy.key] || "");
            })
            .map((item, index) => {
              const selected = selectedEpics.some((e) => e._id === item._id);

              let color,
                priorityColor = "bg-white";
              if (item.priority === "NEXT") {
                color = "bg-blue-200";
                priorityColor = "bg-blue-300";
              }
              if (item.priority === "LATER") {
                priorityColor = "bg-gray-300";
                color = "bg-gray-200";
              }

              return (
                <motion.tr
                  key={index}
                  layout
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.3 }}
                  className={`cursor-pointer hover:brightness-95 ${color}`}>
                  <td
                    className="border px-4 py-2 cursor-pointer"
                    onClick={(e) => {
                      const find = selectedEpics.some((e) => e._id === item._id);
                      if (find) {
                        setSelectedEpics(selectedEpics.filter((e) => e._id !== item._id));
                      } else {
                        setSelectedEpics([...selectedEpics, item]);
                      }
                    }}>
                    <input type="checkbox" className="w-4 h-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500" checked={selected} onChange={() => {}} />
                  </td>
                  <td onClick={() => selectEpic(item)} className="border px-4 py-2 max-w-xs truncate text-sm">
                    {item.index}
                  </td>
                  <td onClick={() => selectEpic(item)} className="border px-4 py-2 max-w-xs truncate text-sm">
                    {item.persona}
                  </td>
                  <td onClick={() => selectEpic(item)} className="border px-4 py-2 max-w-xs truncate text-sm">
                    {item.module}
                  </td>
                  <td className="border px-4 py-2 text-sm min-w-64">
                    <div
                      contentEditable
                      suppressContentEditableWarning
                      onBlur={async (e) => {
                        {
                          const { ok, data } = await api.put(`/epic/${item._id}`, { title: e.target.textContent });
                          if (!ok) return toast.error("Failed to update title");
                          setEpics(epics.map((epic) => (epic._id === item._id ? data : epic)));
                          toast.success("Title updated!");
                        }
                      }}
                      className="outline-none border-b border-transparent hover:border-gray-300 focus:border-blue-500 bg-transparent px-1  cursor-text">
                      {item.title}
                    </div>
                  </td>

                  <td onClick={(e) => e.preventDefault()} className="border px-4 py-2 max-w-xs truncate">
                    <select
                      value={item.priority}
                      onChange={async (e) => {
                        const { ok, data } = await api.put(`/epic/${item._id}`, { priority: e.target.value });
                        if (!ok) return toast.error("Failed to update priority");
                        setEpics(epics.map((e) => (e._id === item._id ? data : e)));
                        toast.success("Priority updated!");
                      }}
                      className={`input ${priorityColor} mr-5 w-20`}>
                      <option value="NOW">NOW</option>
                      <option value="NEXT">NEXT</option>
                      <option value="LATER">LATER</option>
                    </select>
                  </td>
                  <td onClick={(e) => e.preventDefault()} className="border px-4 py-2 max-w-xs truncate">
                    <select
                      value={item.resource_profile}
                      onChange={async (e) => {
                        let tjm = 500;
                        if (e.target.value === "CTO") {
                          tjm = 650;
                        } else if (e.target.value === "EXPERIMENTED DEVELOPER") {
                          tjm = 500;
                        } else if (e.target.value === "DEVELOPER") {
                          tjm = 350;
                        } else if (e.target.value === "PRODUCT") {
                          tjm = 500;
                        }
                        const estimation = ((item.days_required || 0) * tjm).toFixed(2);
                        const { ok, data } = await api.put(`/epic/${item._id}`, { resource_profile: e.target.value, tjm, estimation });
                        if (!ok) return toast.error("Failed to update resource_profile");
                        setEpics(epics.map((e) => (e._id === item._id ? data : e)));
                        toast.success("resource_profile updated!");
                      }}
                      className={`input w-fit pr-8 bg-transparent`}>
                      <option value="DEVELOPER">Developer</option>
                      <option value="EXPERIMENTED DEVELOPER">Experimented Developer</option>
                      <option value="CTO">CTO</option>
                      <option value="PRODUCT">Product</option>
                    </select>
                  </td>

                  <td onClick={(e) => e.preventDefault()} className="border px-4 py-2 max-w-xs truncate">
                    <DebounceInput
                      type="text"
                      list="assigned-options"
                      debounce={300}
                      value={item.assigned || ""}
                      onChange={async (e) => {
                        const { ok, data } = await api.put(`/epic/${item._id}`, { assigned: e.target.value });
                        if (!ok) return toast.error("Failed to update assigned");
                        setEpics(epics.map((epic) => (epic._id === item._id ? data : epic)));
                        toast.success("Assigned updated!");
                      }}
                      className="input w-fit bg-transparent"
                      placeholder="Assign to..."
                    />
                    <datalist id="assigned-options">
                      {epics
                        .map((e) => e.assigned)
                        .filter((value, index, self) => value && self.indexOf(value) === index)
                        .map((assignedValue) => (
                          <option key={assignedValue} value={assignedValue} />
                        ))}
                    </datalist>
                  </td>
                  <SelectedTd
                    value={item.days_required}
                    onChange={async (e) => {
                      const tjm = item.tjm || 500;
                      const estimation = (e * tjm).toFixed(2);
                      const { ok, data } = await api.put(`/epic/${item._id}`, { days_required: e, estimation });
                      if (!ok) return toast.error("Failed to update days_required");
                      setEpics(epics.map((e) => (e._id === item._id ? data : e)));
                      toast.success("days_required updated!");
                    }}
                    type="number"
                  />
                  <td onClick={() => selectEpic(item)} className="border px-4 py-2 max-w-xs truncate hover:bg-gray-200">
                    {item.estimation ? `${formatNumberThreeDigits(item.estimation)} €` : ""}
                  </td>
                  <td onClick={(e) => e.preventDefault()} className="border px-4 py-2 truncate">
                    <SelectStatus
                      value={item.status}
                      onChange={async (e) => {
                        const { ok, data } = await api.put(`/epic/${item._id}`, { status: e.target.value });
                        if (!ok) return toast.error("Failed to update status");
                        setEpics(epics.map((e) => (e._id === item._id ? data : e)));
                        toast.success("Status updated!");
                      }}
                    />
                  </td>
                  <td onClick={() => selectEpic(item)} className="border px-4 py-2 max-w-xs truncate text-sm">
                    {item.sent_to_backlog_at ? (
                      <div className="flex items-center justify-center bg-green-100 text-green-600 rounded-lg px-2 py-1 w-fit">Yes</div>
                    ) : (
                      <div className="flex items-center justify-center bg-red-100 text-red-600 rounded-lg px-2 py-1 w-fit">No</div>
                    )}
                  </td>
                </motion.tr>
              );
            })}
        </AnimatePresence>
        <TotalTable totalNow={totalNow} totalNext={totalNext} totalLater={totalLater} epics={epics} />
      </Table>
    </div>
  );
};

const TotalTable = ({ totalNow, totalNext, totalLater, epics }) => {
  return (
    <>
      <tr>
        <td className="my-2 bg-gray-100" colSpan="8">
          &nbsp;
        </td>
      </tr>
      <tr className="">
        <td colSpan="3"></td>
        <td className="border px-4 py-2 max-w-xs truncate text-sm">{totalNow.length} epics</td>
        <td className="border px-4 py-2 whitespace-normal text-sm">
          Short-term features that are priority and MUST for an MVP. These are the key features of a product in addition to the basics (admin + deployment + metabase). The features
          that will be developed in the MVP.{" "}
        </td>
        <td className="border px-4 py-2 max-w-xs truncate text-sm">{totalNow.reduce((acc, item) => acc + (item.days_required || 0), 0).toFixed(2)}</td>
        <td className="border px-4 py-2 max-w-xs truncate text-sm"></td>
        <td className="border px-4 py-2 max-w-xs truncate text-sm">{formatNumberThreeDigits(totalNow.reduce((acc, item) => acc + (item.estimation || 0), 0))} €</td>
        <td className="border px-4 py-2 max-w-xs truncate text-sm">NOW</td>
      </tr>
      <tr className="bg-blue-200">
        <td colSpan="3"></td>
        <td className="border px-4 py-2 max-w-xs truncate text-sm">{totalNext.length} epics</td>
        <td className="border px-4 py-2 whitespace-normal text-sm">The medium-term functionalities which enrich the product, without these the product already works. </td>
        <td className="border px-4 py-2 max-w-xs truncate text-sm">{totalNext.reduce((acc, item) => acc + (item.days_required || 0), 0).toFixed(2)}</td>
        <td className="border px-4 py-2 max-w-xs truncate text-sm"></td>
        <td className="border px-4 py-2 max-w-xs truncate text-sm">{formatNumberThreeDigits(totalNext.reduce((acc, item) => acc + (item.estimation || 0), 0))} €</td>
        <td className="border px-4 py-2 max-w-xs truncate text-sm">NEXT</td>
      </tr>
      <tr className="bg-gray-200">
        <td colSpan="3"></td>
        <td className="border px-4 py-2 max-w-xs truncate text-sm">{totalLater.length} epics</td>
        <td className="border px-4 py-2 whitespace-normal text-sm">Long-term functionalities are functionalities which are not defined and difficult to estimate.</td>
        <td className="border px-4 py-2 max-w-xs truncate text-sm">{totalLater.reduce((acc, item) => acc + (item.days_required || 0), 0)}</td>
        <td className="border px-4 py-2 max-w-xs truncate text-sm"></td>
        <td className="border px-4 py-2 max-w-xs truncate text-sm">{formatNumberThreeDigits(totalLater.reduce((acc, item) => acc + (item.estimation || 0), 0))} €</td>
        <td className="border px-4 py-2 max-w-xs truncate text-sm">LATER</td>
      </tr>
      <tr className="bg-sky-700 text-white font-bold">
        <td colSpan="3"></td>
        <td className="border px-4 py-2 max-w-xs truncate text-sm">{epics.length} epics</td>
        <td className="border px-4 py-2 max-w-xs truncate text-sm"></td>
        <td className="border px-4 py-2 max-w-xs truncate text-sm">{epics.reduce((acc, item) => acc + (item.days_required || 0), 0).toFixed(2)}</td>
        <td className="border px-4 py-2 max-w-xs truncate text-sm"></td>
        <td className="border px-4 py-2 max-w-xs truncate text-sm">{formatNumberThreeDigits(epics.reduce((acc, item) => acc + (item.estimation || 0), 0))} €</td>
        <td className="border px-4 py-2 max-w-xs truncate text-sm">Total</td>
      </tr>
    </>
  );
};

const SelectedTd = ({ value, onChange, type = "text", className }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [input, setInput] = useState(value);

  useEffect(() => {
    setInput(value);
  }, [value]);

  return (
    <td className={`px-4 py-2 border truncate relative group ${className}`}>
      {isEditing ? (
        <div className="flex items-center gap-2 z-10">
          <input type={type} className="w-full p-1 border rounded text-sm" value={input} onChange={(e) => setInput(e.target.value)} />
          <button
            className="text-gray-500 hover:text-gray-700"
            onClick={() => {
              console.log(input);
              onChange(input);
              setIsEditing(false);
            }}>
            <IoCheckmarkCircle className="h-4 w-4" />
          </button>
        </div>
      ) : (
        <>
          <span className="whitespace-normal text-sm">{value}</span>

          <button className="absolute right-2 top-1/2 -translate-y-1/2 text-gray-500 hover:text-gray-700 group-hover:block hidden" onClick={() => setIsEditing(true)}>
            <IoPencilOutline className="h-4 w-4" />
          </button>
        </>
      )}
    </td>
  );
};

const SelectStatus = ({ value, onChange }) => {
  let style = "";
  if (value === "TODO") {
    style = "bg-blue-600 text-white border-transparent hover:shadow-md transition-shadow duration-200";
  }
  if (value === "IN_PROGRESS") {
    style = "bg-blue-100 text-blue-800 border-transparent hover:shadow-md transition-shadow duration-200";
  }
  if (value === "NEED_INFO") {
    style = "bg-yellow-100 text-yellow-800 border-transparent hover:shadow-md transition-shadow duration-200";
  }
  if (value === "DONE") {
    style = "bg-green-100 text-green-800 border-transparent hover:shadow-md transition-shadow duration-200";
  }

  return (
    <select value={value} onChange={onChange} className={`input w-fit pr-8 rounded-md ${style} focus:outline-none focus:ring-1 focus:ring-offset-1`}>
      <option value="TODO">TODO</option>
      <option value="IN_PROGRESS">IN PROGRESS</option>
      <option value="NEED_INFO">NEED INFO</option>
      <option value="DONE">DONE</option>
    </select>
  );
};

const formatNumberThreeDigits = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

const SelectAssigned = ({ value, onChange, project }) => {
  const [options, setOptions] = useState([]);
  const [lastFetch, setLastFetch] = useState(0);

  async function fetchAssigned() {
    const { data } = await api.post("/epic/aggregate", { project_id: project._id, key: "assigned" });
    const arr = data.filter((e) => e._id !== null).map((e) => ({ label: e._id, value: e._id, count: e.count }));
    setOptions(arr);
    setLastFetch(Date.now());
  }

  useEffect(() => {
    fetchAssigned();
  }, [project._id]);

  useEffect(() => {
    function handleFocus() {
      if (Date.now() - lastFetch > 5000) {
        fetchAssigned();
      }
    }
    window.addEventListener("focus", handleFocus);
    return () => window.removeEventListener("focus", handleFocus);
  }, [lastFetch]);

  return (
    <MultiSelect
      id="select-assigned"
      options={options}
      value={value ? [{ value, label: value }] : []}
      onSelectedChange={(e) => onChange(e[0]?.value || null)}
      placeholder="Assigned"
    />
  );
};

const SelectModule = ({ value, onChange, project }) => {
  const [options, setOptions] = useState([]);
  const [lastFetch, setLastFetch] = useState(0);

  async function fetchModules() {
    const { data } = await api.post("/epic/aggregate", { project_id: project._id, key: "module" });
    const arr = data.filter((e) => e._id !== null).map((e) => ({ label: e._id, value: e._id, count: e.count }));
    setOptions(arr);
    setLastFetch(Date.now());
  }

  useEffect(() => {
    fetchModules();
  }, [project._id]);

  useEffect(() => {
    function handleFocus() {
      if (Date.now() - lastFetch > 5000) {
        fetchModules();
      }
    }
    window.addEventListener("focus", handleFocus);
    return () => window.removeEventListener("focus", handleFocus);
  }, [lastFetch]);

  // Convert single value or array to proper format for MultiSelect
  const selectedValues = Array.isArray(value) ? value.map((v) => ({ value: v, label: v })) : value ? [{ value, label: value }] : [];

  return <MultiSelect id="select-module" options={options} values={selectedValues} onSelectedChange={(selected) => onChange(selected.map((s) => s.value))} placeholder="Module" />;
};
