import React, { useEffect, useState } from "react";
import API from "../../../../../services/api";
import { useParams } from "react-router-dom";

const statusBadges = {
  TODO: <span className="bg-blue-100 border border-blue-300 text-blue-700 text-sm font-medium px-2 py-1 rounded">Todo</span>,
  READY_TO_DEV: <span className="bg-pink-100 border border-pink-300 text-pink-700 text-sm font-medium px-2 py-1 rounded">Ready to dev</span>,
  IN_PROGRESS: <span className="bg-blue-100 border border-blue-300 text-blue-700 text-sm font-medium px-2 py-1 rounded">In progress</span>,
  TESTING: <span className="bg-purple-100 border border-purple-300 text-purple-700 text-sm font-medium px-2 py-1 rounded">Testing</span>,
  DONE: <span className="bg-green-100 border border-green-300 text-green-700 text-sm font-medium px-2 py-1 rounded">Done</span>,
  ARCHIVED: <span className="bg-gray-100 border border-gray-300 text-gray-700 text-sm font-medium px-2 py-1 rounded">Archived</span>,
};

const statusCategories = {
  BUG: <span className="bg-red-100 border border-red-300 text-red-700 text-sm font-medium px-2 py-1 rounded">Bug</span>,
  FEATURE: <span className="bg-yellow-100 border border-yellow-300 text-yellow-700 text-sm font-medium px-2 py-1 rounded">Feature</span>,
};

export default function History() {
  const { id } = useParams();
  const [notions, setNotions] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch();
  }, []);

  async function fetch() {
    try {
      setLoading(true);
      const { data, ok } = await API.post("/notion/search", { project_id: id });
      if (!ok) return toast.error("Error fetching notions");
      setNotions(data);
    } catch (error) {
      console.error("Error fetching notions:", error);
      toast.error("Error fetching notions");
    } finally {
      setLoading(false);
    }
  }

  if (loading) return <div className="p-4">Loading...</div>;

  const allLogs = notions
    .flatMap((notion) =>
      notion.logs.map((log) => ({
        ...log,
        notionName: notion.name,
        notionId: notion._id,
      })),
    )
    .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

  return (
    <div className="p-2 flex flex-col gap-2 w-full">
      {allLogs.map((log, index) => (
        <div key={index} className="flex items-center gap-2 p-2 border rounded text-sm">
          <img src={log.user_avatar} alt={log.user_name} className="w-6 h-6 rounded-full" />
          <div className="flex-1 min-w-0">
            <div className="flex items-center gap-1 text-gray-600">
              <span className="font-medium text-gray-900">{log.user_name}</span>
              <span>modified</span>
              <span className="font-medium text-gray-900">{log.field}</span>
              <span>in</span>
              <span className="font-medium text-gray-900 truncate">{log.notionName}</span>
              <span className="text-xs ml-auto">{new Date(log.created_at).toLocaleString()}</span>
            </div>
            <div className="text-gray-600 mt-0.5">
              {log.text || (
                <span className="flex gap-2">
                  <span>
                    from <span className="font-medium">{log.old_value || "(empty)"}</span>
                  </span>
                  <span>
                    to <span className="font-medium">{log.new_value || "(empty)"}</span>
                  </span>
                </span>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
