import React from "react";


const History = ({ history }) => (
    <div>
      <h3 className="font-semibold mb-4">Audit History</h3>
      <div className="flow-root mb-5">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full align-middle">
            <div className="overflow-hidden border shadow-md rounded-lg bg-white">
              <table className="min-w-[900px] w-full divide-y divide-primary-black-50 rounded-lg overflow-hidden">
                <thead className="">
                  <tr>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Last Audit Date
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Status
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer">
                      High Issues
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Medium Issues
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Low Issues
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Team
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Effort
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Audit Doc
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Tracker
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {history?.map((history, i) => (
                    <tr key={i}>
                      <td className="px-3 py-4 text-sm text-gray-500">
                        <div className="max-w-xl"> {history?.data?.last_audit_date ? new Date(history?.data?.last_audit_date).toLocaleDateString() : "-"}</div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">{Status[history?.data?.status] || "-"}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm">
                        <span
                          className={`border p-1 px-3 rounded-md ${
                            history?.data?.high_issues > 5
                              ? "text-red-600 font-semibold bg-red-100 border-red-200 "
                              : "text-yellow-700 font-semibold bg-yellow-100 border-yellow-300 "
                          }`}>
                          {history?.data?.high_issues || "-"}
                        </span>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{history?.data?.medium_issues || "-"}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{history?.data?.low_issues || "-"}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {!history?.data?.team?.length ? (
                          "-"
                        ) : (
                          <div className="flex gap-1">
                            {history?.data?.team?.map((person) => (
                              <img src={person.avatar} alt={person.name} className="w-7 h-7 rounded-full object-cover" />
                            ))}
                          </div>
                        )}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{Effort[history?.data?.effort_to_audit] || "-"}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm">
                        {history?.data.audit_document ? (
                          <a
                            href={history?.data.audit_document}
                            target="_blank"
                            onClick={(e) => e.stopPropagation()}
                            className="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-sm font-medium text-blue-700 hover:bg-blue-100 border-blue-200 border">
                            Audit
                          </a>
                        ) : (
                          "-"
                        )}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm">
                        {history?.data.tracker ? (
                          <a
                            href={history?.data.tracker}
                            target="_blank"
                            onClick={(e) => e.stopPropagation()}
                            className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-sm font-medium text-green-700 hover:bg-green-100 border-green-200 border">
                            Tracker
                          </a>
                        ) : (
                          "-"
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const Effort = {
    S: <div className="border border-green-200 bg-green-50 text-green-800 rounded p-1 px-3 text-xs flex flex-row items-center space-x-2 w-fit  font-semibold">S</div>,
    M: <div className="border border-yellow-300 bg-yellow-50 text-yellow-600 rounded p-1 text-xs flex flex-row items-center px-2 space-x-2 w-fit  font-semibold">M</div>,
    L: <div className="border border-purple-300 bg-purple-50 text-purple-600 rounded p-1 text-xs flex flex-row items-center px-2 space-x-2 w-fit  font-semibold">L</div>,
    XL: <div className="border border-red-200 bg-red-50 text-red-600 rounded p-1 text-xs flex flex-row items-center px-2 space-x-2 w-fit  font-semibold">XL</div>,
  };
  
  const Status = {
    done: <div className="border border-green-200 bg-green-50 text-green-800 rounded p-1 px-3 text-xs flex flex-row items-center space-x-2 w-fit  font-semibold">Done</div>,
    todo: <div className="border border-yellow-300 bg-yellow-50 text-yellow-600 rounded p-1 text-xs flex flex-row items-center px-2 space-x-2 w-fit  font-semibold">Todo</div>,
  };



export default History;

