import React, { useEffect, useState } from "react";

import api from "../../../services/api";

export default () => {
  return (
    <div className="bg-white h-full p-4 space-y-8">
      <Performance />
      <StatsUnipile />
    </div>
  );
};

function Performance() {
  const [stats, setStats] = useState({ new: 0, contact: 0, lead: 0, close: 0 });
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState({ start: new Date(new Date().setDate(1)), end: new Date() });

  async function fetch() {
    try {
      setLoading(true);
      const { total: totalNew } = await api.post("/s_company/search", {
        startups_early: true,
        created_at: { $gte: dateRange.start.toISOString(), $lte: dateRange.end.toISOString() },
      });

      const { total: totalLead } = await api.post("/s_company/search", {
        startups_early: true,
        pipeline: true,
        created_at: { $gte: dateRange.start.toISOString(), $lte: dateRange.end.toISOString() },
      });

      const { total: totalContact } = await api.post("/s_company/search", {
        startups_early: true,
        pipeline: true,
        status: "contact",
        created_at: { $gte: dateRange.start.toISOString(), $lte: dateRange.end.toISOString() },
      });

      const { total: totalClose } = await api.post("/s_company/search", {
        startups_early: true,
        pipeline: true,
        status: "close",
        created_at: { $gte: dateRange.start.toISOString(), $lte: dateRange.end.toISOString() },
      });
      
      setStats({ new: totalNew, contact: totalContact, lead: totalLead, close: totalClose });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetch();
  }, [dateRange]);

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold text-gray-800">Performance ({dateRange.start.toLocaleDateString()} - {dateRange.end.toLocaleDateString()})</h2>
        <div className="flex gap-3 items-center bg-gray-50 p-2 rounded-lg">
          <input
            type="date"
            value={dateRange.start.toISOString().split("T")[0]}
            onChange={(e) => setDateRange((prev) => ({ ...prev, start: new Date(e.target.value) }))}
            max={dateRange.end.toISOString().split("T")[0]}
            className="border rounded-md p-2 text-sm focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          />
          <span className="text-sm text-gray-500 font-medium">to</span>
          <input
            type="date"
            value={dateRange.end.toISOString().split("T")[0]}
            onChange={(e) => setDateRange((prev) => ({ ...prev, end: new Date(e.target.value) }))}
            min={dateRange.start.toISOString().split("T")[0]}
            max={new Date().toISOString().split("T")[0]}
            className="border rounded-md p-2 text-sm focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          />
        </div>
      </div>
      <div className="grid grid-cols-4 gap-6">
        <div className="bg-white rounded-xl shadow-sm p-6 border border-gray-100 hover:shadow-md transition-shadow">
          <div className="text-gray-600 font-medium mb-3">New Companies</div>
          <div className="text-3xl font-bold">{loading ? "..." : stats.new}</div>
          <div className="text-xs text-gray-500 mt-2">
            <div>Early-stage startups added during selected period</div>
            <div className="mt-1 text-gray-400">Filter: startups_early=true</div>
          </div>
        </div>
        <div className="bg-white rounded-xl shadow-sm p-6 border border-gray-100 hover:shadow-md transition-shadow">
          <div className="text-gray-600 font-medium mb-3">Contacts Made</div>
          <div className="text-3xl font-bold text-blue-600">{loading ? "..." : stats.contact}</div>
          <div className="text-xs text-gray-500 mt-2">
            <div>Early-stage startups with "contact" status in pipeline</div>
            <div className="mt-1 text-gray-400">Filter: startups_early=true, pipeline=true, status="contact"</div>
          </div>
        </div>
        <div className="bg-white rounded-xl shadow-sm p-6 border border-gray-100 hover:shadow-md transition-shadow">
          <div className="text-gray-600 font-medium mb-3">Leads Generated</div>
          <div className="text-3xl font-bold text-green-600">{loading ? "..." : stats.lead}</div>
          <div className="text-xs text-gray-500 mt-2">
            <div>Early-stage startups in pipeline during period</div>
            <div className="mt-1 text-gray-400">Filter: startups_early=true, pipeline=true</div>
          </div>
        </div>
        <div className="bg-white rounded-xl shadow-sm p-6 border border-gray-100 hover:shadow-md transition-shadow">
          <div className="text-gray-600 font-medium mb-3">Deals Closed</div>
          <div className="text-3xl font-bold text-purple-600">{loading ? "..." : stats.close}</div>
          <div className="text-xs text-gray-500 mt-2">
            <div>Early-stage startups with "close" status in pipeline</div>
            <div className="mt-1 text-gray-400">Filter: startups_early=true, pipeline=true, status="close"</div>
          </div>
        </div>
      </div>
    </div>
  );
}

function StatsUnipile() {
  const [stats, setStats] = useState({ dailyInvites: 0, weeklyInvites: 0, dailyVisits: 0 });

  async function fetchStats() {
    const today = new Date().setHours(0, 0, 0, 0);
    const startOfWeek = new Date(today - 7 * 24 * 60 * 60 * 1000);
    const { data: dailyInvitesData } = await api.post("/s_activity/search", { type: "linkedin_invite_sent", created_at: { $gte: new Date(today).toISOString() } });
    const { data: weeklyInvitesData } = await api.post("/s_activity/search", { type: "linkedin_invite_sent", created_at: { $gte: startOfWeek.toISOString() }, limit: 300 });
    const { data: dailyVisitsData } = await api.post("/s_activity/search", { type: "linkedin_profil_visited", created_at: { $gte: new Date(today).toISOString() } });
    setStats({ dailyInvites: dailyInvitesData.length, weeklyInvites: weeklyInvitesData.length, dailyVisits: dailyVisitsData.length });
  }

  useEffect(() => {
    fetchStats();
  }, []);

  return (
    <div className="space-y-4">
      <h2 className="text-2xl font-bold text-gray-800">LinkedIn Activity</h2>

      <div className="grid grid-cols-3 gap-6">
        <div className="bg-white rounded-xl shadow-sm p-6 border border-gray-100 hover:shadow-md transition-shadow">
          <h3 className="font-medium text-gray-600 mb-3">Daily Invites</h3>
          <p className={`text-3xl font-bold ${stats.dailyInvites > 40 ? "text-red-600" : "text-green-600"}`}>{stats.dailyInvites}/40</p>
          <div className="text-xs text-gray-500 mt-2">
            <div>Number of LinkedIn invites sent today</div>
            <div className="mt-1">A paid and active LinkedIn connected account can send between 80 and 100 invitations per day</div>
          </div>
        </div>
        <div className="bg-white rounded-xl shadow-sm p-6 border border-gray-100 hover:shadow-md transition-shadow">
          <h3 className="font-medium text-gray-600 mb-3">Weekly Invites</h3>
          <p className={`text-3xl font-bold ${stats.weeklyInvites > 150 ? "text-red-600" : "text-green-600"}`}>{stats.weeklyInvites}/150</p>
          <div className="text-xs text-gray-500 mt-2">
            <div>Total invites sent in the last 7 days</div>
            <div className="mt-1">LinkedIn allows approximately 200 invitations per week</div>
          </div>
        </div>
        <div className="bg-white rounded-xl shadow-sm p-6 border border-gray-100 hover:shadow-md transition-shadow">
          <h3 className="font-medium text-gray-600 mb-3">Daily Profile Visits</h3>
          <p className={`text-3xl font-bold ${stats.dailyVisits > 100 ? "text-red-600" : "text-green-600"}`}>{stats.dailyVisits}/100</p>
          <div className="text-xs text-gray-500 mt-2">
            <div>Number of LinkedIn profiles visited today</div>
            <div className="mt-1">Free and Premium accounts can visit between 80 and 100 profiles per day</div>
          </div>
        </div>
      </div>
    </div>
  );
}
