import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Loader from "../../../components/loader";
import TabItem from "../../../components/TabItem";
import Edit from "./edit";
import Skills from "./skills";
import RawData from "./raw";
import api from "../../../services/api";

const SKILLS = "SKILLS";
const EDIT = "EDIT";
const RAW_DATA = "RAW_DATA";

export default () => {
  const { id } = useParams();
  const [techSkill, setTechSkill] = useState();
  const [tab, setTab] = useState("SKILLS");

  useEffect(() => {
    fetchTechSkill();
  }, [id]);

  const fetchTechSkill = async () => {
    try {
      const { data, ok } = await api.get(`/user_tech_skill/${id}`);
      if (!ok) return toast.error("Error fetching tech skill data.");
      setTechSkill(data);
    } catch (e) {
      toast.error("Error fetching tech skill data.");
    }
  };

  if (!techSkill) return <Loader />;

  return (
    <div className="p-5">
      <div className="w-full gap-x-4 gap-y-2 ">
        <nav className="bg-white rounded-md flex flex-row w-full border-[1px] border-gray-200 overflow-hidden my-2">
          <TabItem title="Skills" tab="SKILLS" onClick={() => setTab("SKILLS")} active={tab === "SKILLS"} />
          <TabItem title="Edit" tab="EDIT" onClick={() => setTab("EDIT")} active={tab === "EDIT"} />
          <TabItem title="Raw Data" tab="RAW_DATA" onClick={() => setTab("RAW_DATA")} active={tab === "RAW_DATA"} />
        </nav>
        <div className="bg-white rounded-md p-6 border border-primary-black-50 w-full">
          {tab === EDIT && <Edit techSkill={techSkill} setTechSkill={setTechSkill} />}
          {tab === RAW_DATA && <RawData techSkill={techSkill} />}
          {tab === SKILLS && <Skills techSkill={techSkill} />}
        </div>
      </div>
    </div>
  );
};
