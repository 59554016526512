import React, { useEffect, useState } from "react";

import api from "../../../services/api";
import Loader from "../../../components/loader";
import toast from "react-hot-toast";

// const DATES = [];
// var date = new Date(Date.UTC(2023, 4, 1, 0, 0, 0));
// for (let i = -6; i < 8; i++) {
//   DATES.push(new Date(date.setMonth(date.getMonth() + 1)));
// }
const DATES = [];
for (let i = -10; i < 1; i++) {
  DATES.push(new Date(new Date().getFullYear(), new Date().getMonth() + i, 1, 0, 0, 0));
}

const CashActivity = () => {
  const [stats, setStats] = useState([]);
  const [filters, setFilters] = useState({ bank: "" });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        const dateFrom = new Date(DATES[0]);
        dateFrom.setDate(dateFrom.getDate() - 1);

        const query2 = { type: "invoice", sent: "yes" };
        if (filters.bank === "BNP") query2.organisation_id = { $in: ["645131208989391af9bc9e33"] };
        if (filters.bank === "BUNQ") query2.organisation_id = { $nin: ["645131208989391af9bc9e33"] };
        const res1 = await api.post(`/invoice/search`, query2);
        const invoices = res1.data;

        const query3 = { startDate: dateFrom, per_page: 20000, source: filters.bank };
        const res2 = await api.post(`/bank/search`, query3);
        const banks = res2.data.banks;

        const query5 = { projectType: { $nin: ["timeoff"] } };
        if (filters.bank === "BNP") query5.organisation_id = { $in: ["645131208989391af9bc9e33"] };
        if (filters.bank === "BUNQ") query5.organisation_id = { $nin: ["645131208989391af9bc9e33"] };
        const res5 = await api.post(`/activity/search`, query5);
        const activities = res5.data;

        const res6 = await api.post(`/wallet_transaction/search`);
        const transactions = res6.data;

        const res8 = await api.post(`/project/search`);
        const projects = res8.data.projects

        const res7 = await api.post(`/budget/search`);
        const budgets = res7.data
    
        for (let i = 0; i < budgets.length; i++) {
          const budget = budgets[i]
          const project = projects?.find(e => e._id.toString() === budget.projectId.toString())
    
          if ((budget.amountUsed > budget.amount && (budget.type == "project" || budget.type == "anticipation"))) {
            budget.length = getMonthDifference(budget.startAt, budget.endAt) +1
            budget.depassementMensuel = (budget.amountUsed - budget.amount) / budget.length
          }
    
          if ((budget.type == "overbudget")) {
            budget.length = getMonthDifference(budget.startAt, budget.endAt) +1
            budget.depassementMensuel = (budget.amountUsed) / budget.length
          }
    
           if (project?.type == "startup-project" && (budget.type == "prospection" || budget.type == "admin" || budget.type == "invest" )) {
            budget.length = getMonthDifference(budget.startAt, budget.endAt) + 1
            budget.depassementMensuel = (budget.amountUsed) / budget.length
          }
    
          if (budget.type == "anticipation") {
            budget.length = getMonthDifference(budget.startAt, budget.endAt) + 1
            budget.depassementMensuel = (budget.amountUsed) / budget.length
          }
        }


        for (let i=0; i < activities.length; i++) {
          const activity = activities[i]
     
          if (activity.userPosition === "FULLSTACK" && activity.userDivision === "LE_COLLECTIF" && activity.userContract != "FREELANCE") {
            activity.subventionFrench = 0.3 * activity.cost
          }
          if (activity.userPosition === "FULLSTACK" && activity.userDivision === "SELEGO_NL" && activity.userContract != "FREELANCE") {
            activity.subventionDutch = 0.3 * activity.cost
          } 
    
          if (activity.projectType === "admin" || activity.projectType === "prospection") {
            activity.value = 0
          }
        } 

        const obj = {};
        for (let i = 0; i < DATES.length; i++) {
          const date = DATES[i];

          const filteredInvoice = invoices.filter((f) => areSameMonth(f.date, date));
          const revenue = parseInt(filteredInvoice.reduce((acc, f) => acc + (parseInt(f.netTotal) || 0), 0));

          const filteredSubventions = banks.filter((e) => e.sub_category === "SUBVENTION").filter((f) => areSameMonth(f.month, date));
          const alternants = parseInt(filteredSubventions.reduce((acc, f) => acc + (parseInt(f.amount) || 0), 0));

          const filteredActivities = activities.filter((e) => e.projectType != "timeoff").filter((f) => areSameMonth(f.date, date));

          const filteredCostsCompany = banks
            .filter((e) => e.category === "GENERAL_COST" && (e.projectName == "Selego" || e.projectName == "le collectif" || e.projectName == "le stud" || e.projectName === ""))
            .filter((f) => areSameMonth(f.month, date));
          const fixedCostsCompany = parseInt(filteredCostsCompany.reduce((acc, f) => acc + (parseInt(f.amount) || 0), 0));

          const filteredCostsClient = banks
            .filter((e) => e.category === "GENERAL_COST" && e.projectName != "Selego" && e.projectName != "le collectif" || e.projectName == "le stud" && e.projectName != "")
            .filter((f) => areSameMonth(f.month, date));
          const costsClient = parseInt(filteredCostsClient.reduce((acc, f) => acc + (parseInt(f.amount) || 0), 0));
              
          const potentialRevenue = parseInt(filteredActivities.reduce((acc, f) => acc + (parseInt(f.value) || 0), 0)) + (-costsClient)

          const filteredBudgets = (budgets.filter((f) => f.depassementMensuel > 0)).filter(((f) => isInsideDates(f.startAt, f.endAt, date))).sort((a, b) => b.depassementMensuel - a.depassementMensuel)
          const depassement = -parseInt(filteredBudgets.reduce((acc, f) => acc + (parseInt(f.depassementMensuel) || 0), 0))
    
          const doneRevenue = potentialRevenue + depassement

          const inoccupationCosts = -parseInt(filteredActivities.filter((f) => f.projectName === "le stud").reduce((acc, f) => acc + (parseInt(f.cost) || 0), 0));

          const fixedCostsFromActivities = parseInt(filteredActivities.reduce((acc, f) => acc + (parseInt(f.tjm_expense) || 0), 0));

          const filteredActivitiesEmployee = filteredActivities
            .filter((f) => f.userContract != "FREELANCE")
            .filter((e) => e.projectType != "admin" && e.projectType != "prospection");
          const variableCostActivitiesEmployee = -parseInt(filteredActivitiesEmployee.reduce((acc, f) => acc + (parseInt(f.cost) || 0), 0)) + fixedCostsFromActivities;

          const filteredActivitiesFreelance = filteredActivities
            .filter((f) => f.userContract === "FREELANCE")
            .filter((e) => e.projectType != "admin" && e.projectType != "prospection");
          const costActivitiesFreelance = -parseInt(filteredActivitiesFreelance.reduce((acc, f) => acc + (parseInt(f.cost) || 0), 0));

          const filteredTransactions = transactions.filter((e) => e.amount >= 0 && e.role == "lead" && e.status != "not valid").filter((f) => areSameMonth(f.date, date));
          const bonus = -parseInt(filteredTransactions.reduce((acc, f) => acc + (parseInt(f.amount) || 0), 0));

          const filteredBanksBusinessIntroducer = banks.filter((e) => e.sub_category === "BUSINESS_INTRODUCER_COST" && !e.walletUserId).filter((f) => areSameMonth(f.month, date));
          const costBusinessIntroducerFromBanks = parseInt(filteredBanksBusinessIntroducer.reduce((acc, f) => acc + (parseInt(f.amount) || 0), 0));

          const filteredTransactionsBusinessIntroducer = transactions.filter((e) => e.role === "business introducer" && e.amount > 0).filter((f) => areSameMonth(f.date, date));
          const costBusinessIntroducer = -parseInt(filteredTransactionsBusinessIntroducer.reduce((acc, f) => acc + (parseInt(f.amount) || 0), 0)) + costBusinessIntroducerFromBanks;

          const totalVariableCosts = costBusinessIntroducer + costActivitiesFreelance + costsClient + variableCostActivitiesEmployee + inoccupationCosts;

          const brutMargin = ((revenue + totalVariableCosts) / revenue).toFixed(2) * 100;

          const filteredAdminCostsFromActivities = filteredActivities.filter((e) => e.projectType === "admin");
          const fixedAdminCostsFromActivities = -parseInt(filteredAdminCostsFromActivities.reduce((acc, f) => acc + (parseInt(f.cost) || 0), 0)) - inoccupationCosts;

          const filteredProspectionCostsFromActivities = filteredActivities.filter((e) => e.projectType === "prospection");
          const fixedProspectionCostsFromActivities = -parseInt(filteredProspectionCostsFromActivities.reduce((acc, f) => acc + (parseInt(f.cost) || 0), 0)) ;

          const totalGeneralCosts = fixedCostsCompany + fixedAdminCostsFromActivities + fixedProspectionCostsFromActivities;

          const ebitda = revenue + alternants + totalGeneralCosts + totalVariableCosts;

          const margin = (ebitda / (revenue + alternants)).toFixed(2) * 100;

          const filteredDepreciation = banks
          .filter((e) => e.sub_category === "IT_EQUIPMENT")
          .filter((f) => areSameMonth(f.month, date));
          const depreciation = parseInt(filteredDepreciation.reduce((acc, f) => acc + (parseInt(f.amount) || 0), 0));

          const filteredBankImpots = banks.filter((e) => e.sub_category === "CORPORATION_TAX").filter((f) => areSameMonth(f.month, date));
          const impots = filteredBankImpots.reduce((acc, f) => acc + (parseInt(f.amount) || 0), 0);

          const subventionsFrench = parseInt(0.5 * filteredActivities.reduce((acc, f) => acc + (parseInt(f.subventionFrench) || 0), 0));
          const subventionsDutch = parseInt(0.3 * filteredActivities.reduce((acc, f) => acc + (parseInt(f.subventionDutch) || 0), 0));

          let impotsEstimation = 0;
          if (ebitda > 0) impotsEstimation = -Math.round(ebitda * 0.2);

          const profits = ebitda + depreciation + impotsEstimation + subventionsFrench + subventionsDutch;

          const netMargin = parseInt((profits / (revenue + alternants)) * 100);
          const netMarginWithBonuses = parseInt(((profits + bonus) / (revenue + alternants)) * 100);

          obj[date] = {
            revenue,
            potentialRevenue,
            doneRevenue,
            alternants,
            subventionsFrench,
            subventionsDutch,
            costActivitiesFreelance,
            bonus,
            costBusinessIntroducer,
            inoccupationCosts,
            fixedAdminCostsFromActivities,
            fixedProspectionCostsFromActivities,
            costsClient,
            totalVariableCosts,
            brutMargin,
            variableCostActivitiesEmployee,
            fixedCostsCompany,
            totalGeneralCosts,
            ebitda,
            depreciation,
            impots,
            impotsEstimation,
            profits,
            margin,
            netMargin,
            netMarginWithBonuses,
          };
        }
        setStats(obj);
      } catch (error) {
        console.error(error);
        toast.error("Error while fetching data");
      }
      setLoading(false);
    };
    fetchData();
  }, [filters]);

  return (
    <div className="space-y-4 py-4 w-full">
      <div className="flex items-center gap-4 justify-end">
        <a className="empty-btn" target="_blank" href="https://docs.google.com/spreadsheets/d/1t80VC7fwllIjzkBPcgLEJBMhR0PftdCwkXUq2ksXehY/edit?usp=sharing">
          Link to actual BP
        </a>
        <a className="empty-btn" target="_blank" href="https://selego-metabase.cleverapps.io/dashboard/198-monthly-reporting?tab=9-money">
          Link to Metabase for deeper analysis
        </a>
      </div>

      <div className="flex items-center gap-4 justify-between">
        <select class="select w-56" value={filters.bank} onChange={(evt) => setFilters({ ...filters, bank: evt.target.value })}>
          <option value="">Select Organisation</option>
          <option value="BNP">LE COLLECTIF</option>
          <option value="BUNQ">SELEGO</option>
        </select>
      </div>

      <div className="overflow-x-auto space-y-4">
        {loading ? (
          <div className="flex items-center justify-center w-full">
            <Loader />
          </div>
        ) : (
          <>
            <div className="flex">
              <table className="flex-1 table-auto overflow-x-scroll w-full">
                <thead className="bg-gray-100 round-t">
                  <tr className="sticky font-semibold p-2 text-sm text-center">
                    <th />
                    {DATES.map((e, i) => (
                      <th key={i} className="w-20">
                        {e.toLocaleDateString("en-US", { month: "short", year: "numeric" }) || ""}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan={DATES.length + 3} className="bg-sky-200 text-sm text-center font-bold uppercase p-2">
                      Revenue
                    </td>
                  </tr>
                  <tr>
                    <td className="sticky border border-gray-200 text-center text-sm">
                      <div>Revenue</div>
                      <div className="text-xs text-gray-500">Sum of all we billed this month</div>
                    </td>
                    {DATES.map((e, i) => (
                      <td className="border border-gray-200 text-sm text-right p-2" key={i}>
                        {(stats[e]?.revenue || 0).toLocaleString("fr", { style: "currency", currency: "EUR", maximumFractionDigits: 0 })}
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="border border-gray-200 text-center text-sm">
                      <div>Subventions alternants</div>
                      <div className="text-xs text-gray-500">Alternants</div>
                    </td>
                    {DATES.map((e, i) => (
                      <td className="border border-gray-200 text-sm text-right p-2" key={i}>
                        {(stats[e]?.alternants || 0).toLocaleString("fr", { style: "currency", currency: "EUR", maximumFractionDigits: 0 })}
                      </td>
                    ))}
                  </tr>
                  <tr className="bg-sky-50">
                    <td className="border border-gray-200 text-center font-bold text-sm p-2">
                      <div>Total revenue</div>
                      <div className="text-xs text-gray-500">Sum of all that we billed + subventions</div>
                    </td>
                    {DATES.map((e, i) => (
                      <td className="border border-gray-200 text-sm text-center font-bold" key={i}>
                        {((stats[e]?.revenue || 0) + (stats[e]?.alternants || 0)).toLocaleString("fr", { style: "currency", currency: "EUR", maximumFractionDigits: 0 })}
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="border border-gray-200 text-center text-sm p-2">
                      <div>Done revenue</div>
                      <div className="text-xs text-gray-500">To compare with what should have been charged</div>
                    </td>
                    {DATES.map((e, i) => (
                      <td className="border border-gray-200 text-sm text-center" key={i}>
                        {((stats[e]?.doneRevenue || 0) + (stats[e]?.alternants || 0)).toLocaleString("fr", { style: "currency", currency: "EUR", maximumFractionDigits: 0 })}
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="border border-gray-200 text-center text-sm p-2">
                      <div>diff between done and invoiced</div>
                      <div className="text-xs text-gray-500">To compare with what should have been charged</div>
                    </td>
                    {DATES.map((e, i) => (
                      <td className="border border-gray-200 text-sm text-center" key={i}>
                        {((stats[e]?.doneRevenue || 0) - (stats[e]?.revenue)).toLocaleString("fr", { style: "currency", currency: "EUR", maximumFractionDigits: 0 })}
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td colSpan={DATES.length + 3} className="bg-sky-200 text-sm text-center font-bold uppercase p-2">
                      VARIABLE COSTS
                    </td>
                  </tr>
                  <tr>
                    <td className="border border-gray-200 text-center text-sm">
                      <div>Variable Employee Costs</div>
                      <div className="text-xs text-gray-500">Salaries of employees - on time spent on projects</div>
                    </td>
                    {DATES.map((e, i) => (
                      <td className="border border-gray-200 text-sm text-right p-2" key={i}>
                        {(stats[e]?.variableCostActivitiesEmployee || 0).toLocaleString("fr", { style: "currency", currency: "EUR", maximumFractionDigits: 0 })}
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="border border-gray-200 text-center text-sm">
                      <div>Inoccupation / Internal projects costs</div>
                      <div className="text-xs text-gray-500">Salaries of employees - on time spent on Le Stud project</div>
                    </td>
                    {DATES.map((e, i) => (
                      <td className="border border-gray-200 text-sm text-right p-2" key={i}>
                        {(stats[e]?.inoccupationCosts || 0).toLocaleString("fr", { style: "currency", currency: "EUR", maximumFractionDigits: 0 })}
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="border border-gray-200 text-center text-sm">
                      <div>Variable Freelance Costs</div>
                      <div className="text-xs text-gray-500">Payments of freelancers</div>
                    </td>
                    {DATES.map((e, i) => (
                      <td className="border border-gray-200 text-sm text-right p-2" key={i}>
                        {(stats[e]?.costActivitiesFreelance || 0).toLocaleString("fr", { style: "currency", currency: "EUR", maximumFractionDigits: 0 })}
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="border border-gray-200 text-center text-sm">
                      <div>Costs charged to the client</div>
                      <div className="text-xs text-gray-500">Fixed costs that we charge to the client</div>
                    </td>
                    {DATES.map((e, i) => (
                      <td className="border border-gray-200 text-sm text-right p-2" key={i}>
                        {(stats[e]?.costsClient || 0).toLocaleString("fr", { style: "currency", currency: "EUR", maximumFractionDigits: 0 })}
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="border border-gray-200 text-center text-sm">
                      <div>Cost business introducer</div>
                      <div className="text-xs text-gray-500">Bonuses for business introducers</div>
                    </td>
                    {DATES.map((e, i) => (
                      <td className="border border-gray-200 text-sm text-right p-2" key={i}>
                        {(stats[e]?.costBusinessIntroducer || 0).toLocaleString("fr", { style: "currency", currency: "EUR", maximumFractionDigits: 0 })}
                      </td>
                    ))}
                  </tr>
                  <tr className="bg-sky-50">
                    <td className="border border-gray-200 text-center font-bold text-sm p-2">
                      <div>Total variable costs</div>
                      <div className="text-xs text-gray-500">Free + employees + business introducer + charged costs + bonuses</div>
                    </td>
                    {DATES.map((e, i) => (
                      <td className="border border-gray-200 text-sm text-right font-bold p-2" key={i}>
                        {(stats[e]?.totalVariableCosts || 0).toLocaleString("fr", { style: "currency", currency: "EUR", maximumFractionDigits: 0 })}
                      </td>
                    ))}
                  </tr>
                  <tr className="bg-sky-50">
                    <td className="border border-gray-200 text-center font-bold text-sm p-2">
                      <div>Brut margin - Target 38%</div>
                      <div className="text-xs text-gray-500">(Revenue - total variable costs) / revenue</div>
                    </td>
                    {DATES.map((e, i) => (
                      <td className={`border border-gray-200 text-sm text-right font-bold p-2 ${stats[e]?.brutMargin < 38 ? "text-red-700" : "text-green-700"}`} key={i}>
                        {(stats[e]?.brutMargin / 100 || 0).toLocaleString("fr", { style: "percent" })}
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td colSpan={DATES.length + 3} className="bg-sky-200 text-sm text-center font-bold uppercase p-2">
                      GENERAL COSTS
                    </td>
                  </tr>
                  <tr>
                    <td className="border border-gray-200 text-center text-sm">
                      <div>General Costs Admin</div>
                      <div className="text-xs text-gray-500">Salaries of employees/freelancers - on time spent on admin</div>
                    </td>
                    {DATES.map((e, i) => (
                      <td className="border border-gray-200 text-sm text-right p-2" key={i}>
                        {(stats[e]?.fixedAdminCostsFromActivities || 0).toLocaleString("fr", { style: "currency", currency: "EUR", maximumFractionDigits: 0 })}
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="border border-gray-200 text-center text-sm">
                      <div>General Costs Prospection</div>
                      <div className="text-xs text-gray-500">Salaries of employees/freelancers - on time spent on prospection</div>
                    </td>
                    {DATES.map((e, i) => (
                      <td className="border border-gray-200 text-sm text-right p-2" key={i}>
                        {(stats[e]?.fixedProspectionCostsFromActivities || 0).toLocaleString("fr", { style: "currency", currency: "EUR", maximumFractionDigits: 0 })}
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="border border-gray-200 text-center text-sm">
                      <div>Fixed costs</div>
                      <div className="text-xs text-gray-500">Our fixed costs (rent, office costs etc)</div>
                    </td>
                    {DATES.map((e, i) => (
                      <td className="border border-gray-200 text-sm text-right p-2" key={i}>
                        {(stats[e]?.fixedCostsCompany || 0).toLocaleString("fr", { style: "currency", currency: "EUR", maximumFractionDigits: 0 })}
                      </td>
                    ))}
                  </tr>
                  <tr className="bg-sky-50">
                    <td className="border border-gray-200 text-center font-bold text-sm">
                      <div>Total general costs</div>
                      <div className="text-xs text-gray-500">Fixed costs + General costs</div>
                    </td>
                    {DATES.map((e, i) => (
                      <td className="border border-gray-200 text-sm text-right font-bold p-2" key={i}>
                        {(stats[e]?.totalGeneralCosts || 0).toLocaleString("fr", { style: "currency", currency: "EUR", maximumFractionDigits: 0 })}
                      </td>
                    ))}
                  </tr>
                  <tr className="bg-sky-50">
                    <td className="border border-gray-200 text-center font-bold text-sm">
                      <div>% of total general costs on revenue - Target 20%</div>
                      <div className="text-xs text-gray-500">total general costs / revenue</div>
                    </td>
                    {DATES.map((e, i) => {
                      const value = Math.abs(stats[e]?.totalGeneralCosts || 0) / ((stats[e]?.revenue || 0) + (stats[e]?.alternants || 0));
                      return (
                        <td className={`border border-gray-200 text-sm text-right font-bold p-2 ${value > 0.2 ? "text-red-700" : "text-green-700"}`} key={i}>
                          {value.toLocaleString("fr", { style: "percent" })}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <td colSpan={DATES.length + 3} className="bg-sky-200 text-sm text-center font-bold uppercase p-2">
                      RESULTS
                    </td>
                  </tr>
                  <tr className="bg-sky-50">
                    <td className="border border-gray-200 text-center font-bold text-sm">
                      <div>EBITDA</div>
                      <div className="text-xs text-gray-500">Profits before corporation taxes and invests (total revenue - general costs - variable costs)</div>
                    </td>
                    {DATES.map((e, i) => (
                      <td className="border border-gray-200 text-sm text-right font-bold p-2" key={i}>
                        {(stats[e]?.ebitda || 0).toLocaleString("fr", { style: "currency", currency: "EUR", maximumFractionDigits: 0 })}
                      </td>
                    ))}
                  </tr>
                  <tr className="bg-sky-50">
                    <td className="border border-gray-200 text-center font-bold text-sm">
                      <div>Margin - Target 18%</div>
                      <div className="text-xs text-gray-500">EBITDA / Revenue</div>
                    </td>
                    {DATES.map((e, i) => (
                      <td className={`border border-gray-200 text-sm text-right font-bold p-2 ${stats[e]?.margin < 18 ? "text-red-700" : "text-green-700"}`} key={i}>
                        {(stats[e]?.margin / 100 || 0).toLocaleString("fr", { style: "percent" })}
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="border border-gray-200 text-center text-sm">
                      <div>Bonus - lead</div>
                      <div className="text-xs text-gray-500">Bonuses with role = lead</div>
                    </td>
                    {DATES.map((e, i) => (
                      <td className="border border-gray-200 text-sm text-right p-2" key={i}>
                        {(stats[e]?.bonus || 0).toLocaleString("fr", { style: "currency", currency: "EUR", maximumFractionDigits: 0 })}
                      </td>
                    ))}
                  </tr>
                  <tr className="bg-sky-50">
                    <td className="border border-gray-200 text-center font-bold text-sm">
                      <div>EBITDA after bonuses</div>
                      <div className="text-xs text-gray-500">Profits before corporation taxes and invests (total revenue - general costs - variable costs)</div>
                    </td>
                    {DATES.map((e, i) => (
                      <td className={`border border-gray-200 text-sm text-right font-bold p-2 ${stats[e]?.netMarginWithoutBonuses < 18 ? "text-red-700" : "text-green-700"}`} key={i}>
                        {(stats[e]?.netMarginWithBonuses / 100 || 0).toLocaleString("fr", { style: "percent" })}
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="border border-gray-200 text-center text-sm">
                      <div>Depreciation</div>
                      <div className="text-xs text-gray-500">Depreciation and Amortization (to do)</div>
                    </td>
                    {DATES.map((e, i) => (
                      <td className="border border-gray-200 text-sm text-right p-2" key={i}>
                        {(stats[e]?.depreciation || 0).toLocaleString("fr", { style: "currency", currency: "EUR", maximumFractionDigits: 0 })}
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="border border-gray-200 text-center text-sm">
                      <div>Taxes</div>
                      <div className="text-xs text-gray-500">Estimation of corporation tax, 20% of EBITDA</div>
                    </td>
                    {DATES.map((e, i) => (
                      <td className="border border-gray-200 text-sm text-right p-2" key={i}>
                        {(stats[e]?.impotsEstimation || 0).toLocaleString("fr", { style: "currency", currency: "EUR", maximumFractionDigits: 0 })}
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="border border-gray-200 text-center text-sm">
                      <div>CII/CIR First Estimation</div>
                      <div className="text-xs text-gray-500">30% of 50% of the dev activities from Le Collectif</div>
                    </td>
                    {DATES.map((e, i) => (
                      <td className="border border-gray-200 text-sm text-right p-2" key={i}>
                        {(stats[e]?.subventionsFrench || 0).toLocaleString("fr", { style: "currency", currency: "EUR", maximumFractionDigits: 0 })}
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="border border-gray-200 text-center text-sm">
                      <div>WBSO First Estimation</div>
                      <div className="text-xs text-gray-500">30% of 30% of the dev activities from Selego NL</div>
                    </td>
                    {DATES.map((e, i) => (
                      <td className="border border-gray-200 text-sm text-right p-2" key={i}>
                        {(stats[e]?.subventionsDutch || 0).toLocaleString("fr", { style: "currency", currency: "EUR", maximumFractionDigits: 0 })}
                      </td>
                    ))}
                  </tr>
                  <tr className="bg-sky-50">
                    <td className="border border-gray-200 text-center font-bold text-sm p-2">Net profits</td>
                    {DATES.map((e, i) => (
                      <td className="border border-gray-200 text-sm text-right font-bold p-2" key={i}>
                        {(stats[e]?.profits || 0).toLocaleString("fr", { style: "currency", currency: "EUR", maximumFractionDigits: 0 })}
                      </td>
                    ))}
                  </tr>
                  <tr className="bg-sky-50">
                    <td className="border border-gray-200 text-center font-bold text-sm p-2">Net margin</td>
                    {DATES.map((e, i) => (
                      <td className="border border-gray-200 text-sm text-right font-bold p-2" key={i}>
                        {(stats[e]?.netMargin / 100 || 0).toLocaleString("fr", { style: "percent" })}
                      </td>
                    ))}
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

function areSameMonth(a, b) {
  let aDate = new Date(a);
  let bDate = new Date(b);
  return aDate.getMonth() === bDate.getMonth() && aDate.getFullYear() === bDate.getFullYear();
}


function isInsideDates(start, end, date) {
  let startDate = new Date(start)
  let endDate = new Date(end)
  let targetDate = new Date(date)
  return targetDate >= startDate && targetDate <= endDate;
}

function getMonthDifference(startDate, endDate) {
  const start = new Date(startDate);
  const end = new Date(endDate);
  
  // Calcul du nombre total de mois entre les deux dates
  return (end.getFullYear() - start.getFullYear()) * 12 + (end.getMonth() - start.getMonth());
}


export default CashActivity;
