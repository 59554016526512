import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";

import { FaStar } from "react-icons/fa";
import { HiTrendingUp, HiTrendingDown } from "react-icons/hi";

import toast from "react-hot-toast";
import Modal from "../../components/modal";
import api from "../../services/api";

export default () => {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({ status: "live", collections: [] });

  useEffect(() => {
    get();
  }, [filters]);

  async function get() {
    setLoading(true);
    const newF = {};
    if (filters.status) newF.status = filters.status;
    if (filters.collections && filters.collections.length > 0) newF.collection_name = filters.collections;
    const { data } = await api.post("/article/search", newF);
    setArticles(data);
    setLoading(false);
  }
  return (
    <div className="py-8 px-10">
      <div className="flex gap-8 mb-2">
        <div className="flex-1">
          <GlobalStats articles={articles} />
        </div>
        <div className="flex justify-end items-center gap-4">
          <div className="flex flex-col items-end gap-4">
            <Create />
            <Link
              to="https://docs.google.com/spreadsheets/d/1Vd4u5JfbNx9wzrsgJsFLy1J_Yys8el39x-X_Rd6i6x8/edit?usp=sharing"
              target="_blank"
              rel="noopener noreferrer"
              className="w-full inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-blue-600 bg-white border border-blue-600 rounded-md hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
              Todo List
            </Link>
          </div>
        </div>
      </div>
      <Selects filters={filters} setFilters={setFilters} />
      <ul className="flex flex-col gap-1">
        <li className="w-full list-none flex flex-row gap-2 px-4">
          <h3 className="flex-[3] text-gray-800 text-sm mb-0">Name</h3>
          <h3 className="flex-1 text-gray-800 text-sm mb-0">Author</h3>
          <h3 className="flex-1 text-gray-800 text-sm mb-0">Status</h3>
          <h3 className="flex-1 text-gray-800 text-sm mb-0">Score</h3>
          <h3 className="flex-1 text-gray-800 text-sm mb-0">Impressions</h3>
          <h3 className="flex-1 text-gray-800 text-sm mb-0">Clicks</h3>
          <h3 className="flex-1 text-gray-800 text-sm mb-0">CTR</h3>
          <h3 className="flex-1 text-gray-800 text-sm mb-0">Updated at</h3>
        </li>
        {loading && <div className="flex justify-center items-center w-full">Loading...</div>}
        {!loading &&
          articles.map((e, i) => (
            <li key={i} className="list-none">
              <Link to={`/articles/${e._id}`}>
                <div className="p-1 flex flex-col gap-2 md:flex-row items-center w-full rounded border border-gray-200 text-sm text-gray-700 hover:border-sky-700 appearance-none">
                  <div className="flex-[3] flex-col items-center gap-2">
                    <div className="text-sm font-semibold truncate">{e.title}</div>
                    <div className="text-xs text-gray-500 truncate">{e.url?.replace("https://www.selego.co", "")}</div>
                  </div>
                  <div className="flex-1 flex items-center gap-2">
                    {e.user_avatar && <img src={e.user_avatar} className="h-6 w-6 rounded-full object-cover" alt={e.user_name} />}
                  </div>
                  <div className="flex-1 flex items-center gap-2">
                    <span
                      className={`px-2 py-1 rounded-lg text-xs ${
                        e.status === "live" ? "border border-green-300 bg-green-50 text-green-600" : "border border-yellow-300 bg-yellow-50 text-yellow-600"
                      }`}>
                      {e.status}
                    </span>
                  </div>
                  <div className="flex-1 flex items-center gap-2">
                    {e.score ? (
                      <div className="flex items-center space-x-1">
                        {e.score} <FaStar size={16} className="text-yellow-500" />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="flex-1 flex items-center gap-2">
                    <div className="flex flex-col">
                      <div className="flex items-center">
                        {e.search_impression_current}
                        {e.search_impression_trend > 0 ? (
                          <HiTrendingUp className="text-green-500 ml-1" />
                        ) : e.search_impression_trend < 0 ? (
                          <HiTrendingDown className="text-red-500 ml-1" />
                        ) : null}
                      </div>
                      <div className="text-xs text-gray-500">Prior: {e.search_impression_prior}</div>
                    </div>
                  </div>
                  <div className="flex-1 flex items-center gap-2">
                    <div className="flex flex-col">
                      <div className="flex items-center">
                        {e.search_click_current}
                        {e.search_click_trend > 0 ? (
                          <HiTrendingUp className="text-green-500 ml-1" />
                        ) : e.search_click_trend < 0 ? (
                          <HiTrendingDown className="text-red-500 ml-1" />
                        ) : null}
                      </div>
                      <div className="text-xs text-gray-500">Prior: {e.search_click_prior}</div>
                    </div>
                  </div>
                  <div className="flex-1 flex items-center gap-2">
                    <div className="flex items-center">
                      {(e.search_ctr_current * 100).toFixed(1)}%
                      {e.search_ctr_trend > 0 ? <HiTrendingUp className="text-green-500 ml-1" /> : e.search_ctr_trend < 0 ? <HiTrendingDown className="text-red-500 ml-1" /> : null}
                    </div>
                  </div>
                  <div className="flex-1 flex items-center gap-2">{new Date(e.updated_at).toLocaleDateString()}</div>
                </div>
              </Link>
            </li>
          ))}
      </ul>
    </div>
  );
};

const Selects = ({ filters, setFilters }) => {
  const collections = ["perks", "use-cases", "glossaire", "blog", "jobs","companies"];
  const statuses = ["live", "draft", "archived"];

  return (
    <div className="flex gap-8 mb-4 p-4 bg-white rounded-lg shadow">
      <div>
        <label className="block text-sm font-semibold text-gray-700 mb-3">Collections</label>
        <div className="flex flex-wrap gap-3">
          {collections.map((collection) => (
            <label key={collection} className="flex items-center gap-2 text-sm text-gray-600 hover:text-gray-900 cursor-pointer">
              <input
                type="checkbox"
                className="w-4 h-4 text-blue-600 rounded border-gray-300 focus:ring-blue-500"
                checked={filters.collections?.includes(collection)}
                onChange={(e) => {
                  if (e.target.checked) {
                    setFilters({ ...filters, collections: [...(filters.collections || []), collection] });
                  } else {
                    setFilters({ ...filters, collections: (filters.collections || []).filter((v) => v !== collection) });
                  }
                }}
              />
              {collection}
            </label>
          ))}
        </div>
      </div>

      <div>
        <label className="block text-sm font-semibold text-gray-700 mb-3">Status</label>
        <div className="flex flex-wrap gap-3">
          <label className="flex items-center gap-2 text-sm text-gray-600 hover:text-gray-900 cursor-pointer">
            <input
              type="checkbox"
              className="w-4 h-4 text-blue-600 rounded border-gray-300 focus:ring-blue-500"
              checked={!filters.status}
              onChange={() => setFilters({ ...filters, status: null })}
            />
            All
          </label>
          {statuses.map((status) => (
            <label key={status} className="flex items-center gap-2 text-sm text-gray-600 hover:text-gray-900 cursor-pointer">
              <input
                type="checkbox"
                className="w-4 h-4 text-blue-600 rounded border-gray-300 focus:ring-blue-500"
                checked={filters.status === status}
                onChange={() => setFilters({ ...filters, status: status })}
              />
              {status.charAt(0).toUpperCase() + status.slice(1)}
            </label>
          ))}
        </div>
      </div>
    </div>
  );
};

const GlobalStats = ({ articles }) => {
  const stats = {
    totalImpressions: articles.reduce((acc, article) => acc + (article.search_impression_current || 0), 0),
    totalImpressionsPrior: articles.reduce((acc, article) => acc + (article.search_impression_prior || 0), 0),
    totalClicks: articles.reduce((acc, article) => acc + (article.search_click_current || 0), 0),
    totalClicksPrior: articles.reduce((acc, article) => acc + (article.search_click_prior || 0), 0),
    livePages: articles.filter((a) => a.status === "live").length,
    draftPages: articles.filter((a) => a.status === "draft").length,
  };

  const impressionTrend = ((stats.totalImpressions - stats.totalImpressionsPrior) / stats.totalImpressionsPrior) * 100;
  const clickTrend = ((stats.totalClicks - stats.totalClicksPrior) / stats.totalClicksPrior) * 100;
  const ctr = stats.totalImpressions ? (stats.totalClicks / stats.totalImpressions) * 100 : 0;
  const ctrPrior = stats.totalImpressionsPrior ? (stats.totalClicksPrior / stats.totalImpressionsPrior) * 100 : 0;
  const ctrTrend = ((ctr - ctrPrior) / ctrPrior) * 100;

  const today = new Date();
  const lastMonth = new Date();
  lastMonth.setMonth(lastMonth.getMonth() - 1);

  return (
    <div className="flex-1 grid grid-cols-4 gap-4">
      <div className="bg-white rounded-lg shadow p-6">
        <h3 className="text-gray-500 text-sm mb-2">Total Impressions</h3>
        <div className="text-xs text-gray-500 mb-2">
          {lastMonth.toLocaleDateString()} - {today.toLocaleDateString()}
        </div>
        <div className="flex items-center">
          <span className="text-2xl font-bold">{stats.totalImpressions.toLocaleString()}</span>
          {impressionTrend !== 0 && (
            <span className={`ml-2 flex items-center ${impressionTrend > 0 ? "text-green-500" : "text-red-500"}`}>
              {impressionTrend > 0 ? <HiTrendingUp size={20} /> : <HiTrendingDown size={20} />}
              {Math.abs(impressionTrend).toFixed(1)}%
            </span>
          )}
        </div>
      </div>
      <div className="bg-white rounded-lg shadow p-6">
        <h3 className="text-gray-500 text-sm mb-2">Total Clicks</h3>
        <div className="text-xs text-gray-500 mb-2">
          {lastMonth.toLocaleDateString()} - {today.toLocaleDateString()}
        </div>
        <div className="flex items-center">
          <span className="text-2xl font-bold">{stats.totalClicks.toLocaleString()}</span>
          {clickTrend !== 0 && (
            <span className={`ml-2 flex items-center ${clickTrend > 0 ? "text-green-500" : "text-red-500"}`}>
              {clickTrend > 0 ? <HiTrendingUp size={20} /> : <HiTrendingDown size={20} />}
              {Math.abs(clickTrend).toFixed(1)}%
            </span>
          )}
        </div>
      </div>
      <div className="bg-white rounded-lg shadow p-6">
        <h3 className="text-gray-500 text-sm mb-2">CTR</h3>
        <div className="text-xs text-gray-500 mb-2">
          {lastMonth.toLocaleDateString()} - {today.toLocaleDateString()}
        </div>
        <div className="flex items-center">
          <span className="text-2xl font-bold">{ctr.toFixed(1)}%</span>
          {ctrTrend !== 0 && (
            <span className={`ml-2 flex items-center ${ctrTrend > 0 ? "text-green-500" : "text-red-500"}`}>
              {ctrTrend > 0 ? <HiTrendingUp size={20} /> : <HiTrendingDown size={20} />}
              {Math.abs(ctrTrend).toFixed(1)}%
            </span>
          )}
        </div>
      </div>
      <div className="bg-white rounded-lg shadow p-6">
        <h3 className="text-gray-500 text-sm mb-2">Total Pages</h3>
        <div className="flex flex-col">
          <div className="flex items-center mb-2">
            <span className="text-green-600 font-bold">{stats.livePages}</span>
            <span className="text-gray-500 ml-2">Live</span>
          </div>
          <div className="flex items-center">
            <span className="text-yellow-600 font-bold">{stats.draftPages}</span>
            <span className="text-gray-500 ml-2">Draft</span>
          </div>
        </div>
      </div>
    </div>
  );
};

const Create = () => {
  const [values, setValues] = useState({ name: "" });
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async () => {
    try {
      const res = await api.post("/article", values);
      if (!res.ok) throw res;
      toast.success("Created!");
      setOpen(false);
      navigate(`/articles/${res.data._id}`);
    } catch (e) {
      console.log(e);
      toast.error("Some Error!", e.code);
    }
  };

  return (
    <>
      <button className="btn blue-btn" onClick={() => setOpen(true)}>
        Create a new article
      </button>
      <Modal isOpen={open} className="max-w-3xl w-full p-6" onClose={() => setOpen(false)}>
        <div className="p-8">
          <h2 className="text-2xl font-semibold mb-4">Create article</h2>
          <div className="grid grid-cols-2 gap-4 ">
            <div className="w-full col-span-2">
              <div className="text-sm font-medium mb-2">Name</div>
              <input className="projectsInput block" name="name" value={values.name} onChange={(e) => setValues({ ...values, name: e.target.value })} />
            </div>
          </div>
          <div className="flex justify-end mt-6">
            <button className="btn blue-btn w-32" onClick={handleSubmit}>
              Create
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};
