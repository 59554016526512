import React, { useState } from "react";
import toast from "react-hot-toast";
import { MdDeleteForever, MdMoreVert } from "react-icons/md";
import { Menu } from "@headlessui/react";

import api from "../../../../services/api";
import { readFileAsync } from "../../../../utils";
import LoadingButton from "../../../../components/loadingButton";

export default function DataRoomTab({ answer, setAnswer }) {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleSave = async () => {
    try {
      const { ok, data } = await api.put(`/mission_ao_answer/${answer._id}`, answer);
      if (!ok) throw new Error("Failed to update files");
      setAnswer(data);
      toast.success("Data Room updated successfully!");
    } catch (e) {
      console.error(e);
      toast.error("Error updating files");
    }
  };

  return (
    <div className="p-4">
      <div className="flex gap-4">
        <div className="flex-1">
          <Documents files={answer.files || []} setFiles={(files) => setAnswer({ ...answer, files })} onSelectFile={setSelectedFile} selectedFile={selectedFile} />
          <LoadingButton className="btn btn-primary mt-4" onClick={handleSave}>
            Save Changes
          </LoadingButton>
        </div>
        <div className="w-1/2">
          <FilePreview file={selectedFile} />
        </div>
      </div>
    </div>
  );
}

const Documents = ({ files, setFiles, onSelectFile, selectedFile }) => {
  const handleFileUpload = async (e) => {
    const uploadedFiles = e.target.files;
    const newFiles = [];

    for (let file of uploadedFiles) {
      const rawBody = await readFileAsync(file);
      const { data } = await api.post(`/file`, { file: { rawBody, name: file.name }, folder: "answers" });
      newFiles.push({
        label: file.name,
        url: data,
        id: Math.random().toString(36).substring(2, 15),
        type: file.type,
      });
    }

    setFiles([...files, ...newFiles]);
  };

  const handleDelete = (fileId) => {
    const updatedFiles = files.filter((f) => f.id !== fileId);
    setFiles(updatedFiles);
    if (selectedFile?.id === fileId) {
      onSelectFile(null);
    }
  };

  return (
    <div className="bg-white rounded-lg p-4">
      <table className="min-w-full divide-y divide-gray-200">
        <thead>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {files.map((file) => (
            <tr key={file.id} className={`hover:bg-gray-50 cursor-pointer ${selectedFile?.id === file.id ? "bg-blue-50" : ""}`} onClick={() => onSelectFile(file)}>
              <td className="px-4 py-2">
                <span className="text-blue-600">{file.label}</span>
              </td>
              <td className="px-4 py-2 text-center">
                <Menu as="div" className="relative inline-block text-left">
                  <Menu.Button className="p-1 hover:bg-gray-100 rounded">
                    <MdMoreVert className="text-gray-500" />
                  </Menu.Button>
                  <Menu.Items className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-10">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          className={`${active ? "bg-red-50 text-red-700" : "text-red-600"} flex w-full items-center px-4 py-2 text-sm`}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDelete(file.id);
                          }}>
                          <MdDeleteForever className="mr-2" /> Delete
                        </button>
                      )}
                    </Menu.Item>
                  </Menu.Items>
                </Menu>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="mt-4">
        <label htmlFor="multi-file-upload" className="btn btn-primary cursor-pointer">
          Upload Files
        </label>
        <input id="multi-file-upload" type="file" multiple className="hidden" onChange={handleFileUpload} />
      </div>
    </div>
  );
};
const FilePreview = ({ file }) => {
  if (!file) return null;

  const extension = file.url.split(".").pop().toLowerCase();

  if (extension === "pdf") {
    return <iframe src={file.url} className="w-full h-[800px] border rounded" title={file.label} />;
  }

  if (["jpg", "jpeg", "png", "gif", "webp"].includes(extension)) {
    return <img src={file.url} alt={file.label} className="max-w-full h-auto rounded" />;
  }

  if (["xlsx", "xls", "doc", "docx"].includes(extension)) {
    return <iframe src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(file.url)}`} className="w-full h-[800px] border rounded" title={file.label} />;
  }

  return (
    <div className="p-4 bg-gray-100 rounded">
      <p>Preview not available for this file type</p>
      <a href={file.url} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">
        Download file
      </a>
    </div>
  );
};
