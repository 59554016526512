import React, { useState, useEffect } from "react";
import api from "../../../services/api";
import toast from "react-hot-toast";
import SelectUser from "../../../components/selectUser";
import { IoClose } from "react-icons/io5";

export default function AddTjmOverride({ project }) {
  const [userTjmsOverride, setUserTjmsOverride] = useState([]);

  useEffect(() => {
    setUserTjmsOverride(project.user_tjms_override);
  }, [project]);

  const handleSave = async (userTjmsOverride) => {
    try {
      const { ok, data } = await api.put(`/project/${project._id}`, {
        user_tjms_override: userTjmsOverride,
      });
      if (!ok) return toast.error("Error saving TJM overrides");
      setUserTjmsOverride(data.user_tjms_override);
      toast.success("TJM overrides saved successfully");
    } catch (error) {
      toast.error("Error saving TJM overrides");
    }
  };

  const handleAddOverride = async (user) => {
    try {
      const existingOverride = project.user_tjms_override?.find((o) => o.userId === user._id);
      if (existingOverride) {
        toast.error("User already has a TJM override");
        return;
      }

      const newOverride = {
        userId: user._id,
        userName: user.name,
        userAvatar: user.avatar,
        tjms: user.tjms,
        defaultTjms: user.tjms,
      };

      const updatedOverrides = [...(project.user_tjms_override || []), newOverride];
      const { ok, data } = await api.put(`/project/${project._id}`, {
        user_tjms_override: updatedOverrides,
      });

      if (!ok) {
        toast.error("Error adding TJM override");
        return;
      }

      setUserTjmsOverride(data.user_tjms_override);
      toast.success("TJM override added successfully");
    } catch (error) {
      toast.error("Error adding TJM override");
    }
  };

  return (
    <div className="mb-4 p-4 border rounded bg-white">
      <div className="mb-4 p-2 bg-blue-50 border-l-4 border-blue-200 rounded text-sm text-blue-800">
        <p>
          TJM overrides allow you to set <b>custom daily rates</b> for specific users on this project, overriding their default rates.
          <br />
          These overrides will only apply to this project and will be applied retroactively to all existing time entries.
          <br />
          <br />
          Note: TJM overrides will only be applied for non-frozen budgets.
        </p>
      </div>

      <p className="text-sm text-gray-700 mb-2">Add a TJM override for a user</p>
      <SelectUser
        userRoles={["admin", "user"]}
        onChange={(e) => {
          if (!e) return;
          handleAddOverride(e);
        }}
      />
      {userTjmsOverride?.length > 0 && (
        <div className="mt-8 mb-4">
          <p className="text-sm text-gray-700 mb-2">Current TJM Overrides</p>
          <table className="min-w-full divide-y divide-gray-200 border border-gray-200 rounded">
            <thead>
              <tr>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">User</th>
                <th className="px-4 py-2 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Default Rate</th>
                <th className="px-4 py-2 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">New Rate</th>
                <th className="px-4 py-2 w-16"></th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {userTjmsOverride.map((override) => (
                <tr key={override.userId}>
                  <td className="px-4 py-2 whitespace-nowrap">
                    <div className="flex items-center gap-2">
                      {override.userAvatar && <img src={override.userAvatar} alt="" className="w-6 h-6 rounded-full" />}
                      <span>{override.userName || override.userId}</span>
                    </div>
                  </td>
                  <td className="px-4 py-2 whitespace-nowrap">
                    <div className="flex items-center justify-end gap-2">
                      <span>{override.defaultTjms?.toFixed(2)}€</span>
                    </div>
                  </td>
                  <td className="px-4 py-2 whitespace-nowrap">
                    <div className="flex items-center justify-end gap-2">
                      <input
                        type="number"
                        defaultValue={override.tjms}
                        onChange={(e) => {
                          e.persist();
                          setUserTjmsOverride((prev) => prev.map((o) => (o.userId === override.userId ? { ...o, tjms: Number(e.target.value) } : o)));
                        }}
                        onBlur={() => {
                          handleSave(userTjmsOverride);
                        }}
                        className="w-20 rounded border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                        min="0"
                      />
                      <span className="ml-1">€</span>
                    </div>
                  </td>
                  <td className="px-4 py-2 whitespace-nowrap text-right">
                    <button
                      onClick={() => {
                        if (!confirm("Are you sure you want to delete this TJM override?")) return;
                        handleSave(userTjmsOverride.filter((o) => o.userId !== override.userId));
                      }}
                      className="text-gray-400 hover:text-red-500 transition-colors">
                      <IoClose size={20} />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <button onClick={() => handleSave(userTjmsOverride)} className="btn btn-primary">
        Save Changes
      </button>
    </div>
  );
}
