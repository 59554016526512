import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { HiMagnifyingGlass } from "react-icons/hi2";
import { FaCheck } from "react-icons/fa6";
import { IoCloseSharp } from "react-icons/io5";

import Loader from "../../components/loader";
import Modal from "../../components/modal";
import LoadingButton from "../../components/loadingButton";
import SelectProject from "../../components/selectProject";

import api from "../../services/api";

export default () => {
  const navigate = useNavigate();
  const [audits, setAudits] = useState();
  const [filter, setFilter] = useState({ search: "", sort: { high_issues: -1, last_audit_date: -1 } });

  const [currentPage, setCurrentPage] = useState(1);
  const [median, setMedian] = useState();

  useEffect(() => {
    get();
    setCurrentPage(1);
  }, [filter]);

  useEffect(() => {
    get();
  }, [currentPage]);

  const get = async () => {
    try {
      const { data, ok, code } = await api.post("/audit/search", {
        search: filter.search,
        sort: filter.sort,
        limit: 1000,
      });
      if (!ok) return toast.error("Error!" || code);
      setAudits(data);

      const highIssues = data.map((audit) => audit.high_issues).filter(Boolean);
      const median = calculateMedian(highIssues);
      setMedian(median);
    } catch (error) {
      console.log(error);
      return toast.error("Error fetching audits!");
    }
  };

  const calculateMedian = (arr) => {
    const sortedArr = arr.sort((a, b) => a - b);
    const middle = Math.floor(sortedArr.length / 2);
    return sortedArr.length % 2 === 0 ? (sortedArr[middle - 1] + sortedArr[middle]) / 2 : sortedArr[middle];
  };

  if (!audits) return <Loader />;

  return (
    <div className="mx-auto p-4">
      <h2 className="text-xl mb-3 font-semibold text-gray-900">Audits</h2>

      <div className="flex items-center justify-between mb-5">
        <div className="relative w-96">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <HiMagnifyingGlass className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
          <input
            type="text"
            name="search"
            value={filter.search}
            onChange={(e) => setFilter({ ...filter, search: e.target.value })}
            className="block w-full rounded-lg border border-gray-300 bg-white py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
            placeholder="Search audits..."
          />
        </div>
        <Create />
      </div>

      <div className="flex gap-3 mb-5">
        <div className="bg-white rounded-lg p-4 border transition-shadow">
          <h3 className="text-gray-500 text-sm mb-1">Key Performance Indicator (KPI)</h3>
          <p className="text-base font-bold text-blue-600">Median of less than 5 high issues per project</p>
        </div>

        {audits?.length > 0 && (
          <>
            <div className="bg-white rounded-lg p-4 border transition-shadow">
              <h3 className="text-gray-500 text-sm mb-1">Current Median of High Issues</h3>
              <p className={`${median <= 5 ? "text-blue-600" : "text-red-700"} text-2xl font-bold `}>{median || 0}</p>
            </div>

            <div className="bg-white rounded-lg p-4 border transition-shadow">
              <h3 className="text-gray-500 text-sm mb-1">Projects Not Using Selego Stack</h3>
              <p className={`${
                audits.filter(a => !a.selego_stack).length === 0 ? "text-blue-600" : "text-red-700"
              } text-2xl font-bold`}>
                {audits.filter(a => !a.selego_stack).length}
              </p>
            </div>

            <div className="bg-white rounded-lg p-4 border transition-shadow">
              <h3 className="text-gray-500 text-sm mb-1">Outdated Audits (less than 6 months)</h3>
              <p className={`${
                audits.filter(a => !a.last_audit_date || new Date() - new Date(a.last_audit_date) > 180 * 24 * 60 * 60 * 1000).length === 0 
                  ? "text-blue-600" 
                  : "text-red-700"
              } text-2xl font-bold`}>
                {audits.filter(a => !a.last_audit_date || new Date() - new Date(a.last_audit_date) > 180 * 24 * 60 * 60 * 1000).length}
              </p>
            </div>

          </>
        )}

        <div className="bg-white rounded-lg p-4 border transition-shadow flex-1">
          <h3 className="text-gray-500 text-sm mb-1">Audit Guide</h3>
          <p className="text-sm text-gray-600 mb-3">
            Learn more about your audit results and what they mean for your project.
          </p>
          <a 
            href="https://docs.google.com/document/d/1-iQTCKmp8ze3-UiRfaEnBYiQxhazOmEIYdpSEAQic4U/edit?tab=t.0#heading=h.1vy8bkkhz95p" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="inline-flex items-center justify-center rounded-md bg-blue-600 px-4 py-2 text-sm font-medium text-white hover:bg-blue-700 shadow-md transition-transform hover:scale-105">
            View Guide
          </a>
        </div>
      </div>

      <div className="flow-root mb-5">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full align-middle">
            <div className="overflow-hidden border shadow-md rounded-lg bg-white">
              <table className="min-w-[900px] w-full divide-y divide-primary-black-50 rounded-lg overflow-hidden">
                <thead className="">
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      Project
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Team
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Status
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer"
                      onClick={() => setFilter({ ...filter, sort: { high_issues: filter?.sort?.high_issues === -1 ? 1 : -1 } })}>
                      High Issues {filter?.sort?.high_issues === -1 ? "↓" : "↑"}
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Selego Score
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Effort
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer"
                      onClick={() => setFilter({ ...filter, sort: { last_audit_date: filter?.sort?.last_audit_date === -1 ? 1 : -1 } })}>
                      Last Audit {filter?.sort?.last_audit_date === -1 ? "↓" : "↑"}
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Audit Doc
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Tracker
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Sentry
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Uptime Robot
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Selego Stack
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {audits.map((audit, i) => (
                    <React.Fragment key={i}>
                      <tr className="hover:bg-gray-100 transition-colors cursor-pointer border-t group" onClick={() => navigate(`/audit/${audit._id}`)}>
                        <td className="py-4 pl-4 pr-3 text-sm sm:pl-6">
                          <div className="font-medium text-gray-900">{audit?.project_name}</div>
                          {audit?.author_name && <p className="text-xs text-gray-500">Audited by {audit?.author_name}</p>}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {!audit?.team?.length ? (
                            "-"
                          ) : (
                            <div className="flex gap-1 flex-wrap">
                              {audit?.team?.map((person, index) => (
                                <img key={index} src={person.avatar} alt={person.name} className="w-7 h-7 rounded-full object-cover" />
                              ))}
                            </div>
                          )}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">{Status[audit?.status] || "-"}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm">
                          <span
                            className={`border p-1 px-3 rounded-md ${
                              audit?.high_issues > 5 ? "text-red-600 font-semibold bg-red-100 border-red-200 " : "text-yellow-700 font-semibold bg-yellow-100 border-yellow-300 "
                            }`}>
                            {audit?.high_issues === 0 ? "0" : audit?.high_issues || "-"}
                          </span>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{audit?.selego_test?.score || "-"}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{Effort[audit?.effort_to_audit] || "-"}</td>
                        <td className="px-3 py-4 text-sm text-gray-500">
                          <div className="max-w-xl flex flex-col gap-1">
                            {audit?.last_audit_date ? (
                              <>
                                <span className="inline-flex items-center rounded-md bg-purple-50 px-2 py-1 text-sm font-medium text-purple-700 border-purple-200 border">
                                  {Math.floor((new Date() - new Date(audit.last_audit_date)) / (1000 * 60 * 60 * 24 * 30))} months ago
                                </span>
                                <span className="text-xs text-gray-500">
                                  {new Date(audit.last_audit_date).toLocaleDateString()}
                                </span>
                              </>
                            ) : "-"}
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm">
                          {audit.audit_document ? (
                            <a
                              href={audit.audit_document}
                              target="_blank"
                              onClick={(e) => e.stopPropagation()}
                              className="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-sm font-medium text-blue-700 hover:bg-blue-100 border-blue-200 border">
                              Audit
                            </a>
                          ) : (
                            "-"
                          )}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm">
                          {audit.tracker ? (
                            <a
                              href={audit.tracker}
                              target="_blank"
                              onClick={(e) => e.stopPropagation()}
                              className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-sm font-medium text-green-700 hover:bg-green-100 border-green-200 border">
                              Tracker
                            </a>
                          ) : (
                            "-"
                          )}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">{Mark[audit?.sentry] || "-"}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">{Mark[audit?.timerobot] || "-"}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">{Mark[audit?.selego_stack] || "-"}</td>
                      </tr>
                      {audit.note && (
                        <tr>
                          <td colSpan="12" className="px-5 py-2 text-xs text-gray-600">
                            {audit.note}
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Create = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({});
  const [btnLoading, setBtnLoading] = useState(false);

  const onCreate = async () => {
    setBtnLoading(true);
    const { ok, code, data } = await api.post("/audit", values);

    if (!ok) return toast.error("Error creating audit." || code);

    toast.success("Audit created successfully!");
    setOpen(false);
    setValues({});
    setBtnLoading(false);
    navigate(`/audit/${data._id}`);
  };

  return (
    <div>
      <button className="bg-primary-50 border border-primary-700 text-primary-700 px-5 py-1.5 rounded-md text-sm" onClick={() => setOpen(true)}>
        Create Audit
      </button>

      <Modal isOpen={open} className="!w-[90vw] md:!w-[700px] p-6" onClose={() => setOpen(false)}>
        <h1 className="text-lg font-semibold mb-5 mt-3">Create Audit</h1>

        <div className="w-full mb-3">
          <div className="font-medium mb-2 text-sm">Select Project</div>
          <SelectProject className="w-full" value={values.project_id} onChange={(e) => setValues({ ...values, project_name: e.name, project_id: e._id, github_link: e.github_link })} />
        </div>

        <div className="flex justify-end items-center">
          <LoadingButton
            className="bg-primary-50 text-primary-900 border px-4 py-1.5 rounded border-primary-600 mt-4  disabled:cursor-not-allowed"
            loading={btnLoading}
            disabled={!values.project_id }
            onClick={() => onCreate()}>
            Create
          </LoadingButton>
        </div>
      </Modal>
    </div>
  );
};

const Effort = {
  S: <div className="border border-green-200 bg-green-50 text-green-800 rounded p-1 px-3 text-xs flex flex-row items-center space-x-2 w-fit  font-semibold">S</div>,
  M: <div className="border border-yellow-300 bg-yellow-50 text-yellow-600 rounded p-1 text-xs flex flex-row items-center px-2 space-x-2 w-fit  font-semibold">M</div>,
  L: <div className="border border-purple-300 bg-purple-50 text-purple-600 rounded p-1 text-xs flex flex-row items-center px-2 space-x-2 w-fit  font-semibold">L</div>,
  XL: <div className="border border-red-200 bg-red-50 text-red-600 rounded p-1 text-xs flex flex-row items-center px-2 space-x-2 w-fit  font-semibold">XL</div>,
};

const Status = {
  audited: <div className="border border-purple-200 bg-purple-50 text-purple-800 rounded p-1 px-3 text-xs flex flex-row items-center space-x-2 w-fit  font-semibold">Audited</div>,
  addressed: <div className="border border-green-200 bg-green-50 text-green-800 rounded p-1 px-3 text-xs flex flex-row items-center space-x-2 w-fit  font-semibold">Addressed</div>,
  todo: <div className="border border-yellow-300 bg-yellow-50 text-yellow-600 rounded p-1 text-xs flex flex-row items-center px-2 space-x-2 w-fit  font-semibold">Todo</div>,
};

const Mark = {
  true: (
    <div className="bg-green-700 text-white rounded p-1 text-xs w-fit font-semibold">
      <FaCheck />
    </div>
  ),
  false: (
    <div className="bg-red-700 text-white rounded p-1 text-xs w-fit font-semibold">
      <IoCloseSharp />
    </div>
  ),
};
