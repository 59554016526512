import React, { useState, useEffect, useRef } from "react";
import { FaExclamationTriangle, FaExclamation, FaInfoCircle, FaCode, FaBookOpen, FaRandom, FaShieldAlt, FaDownload } from "react-icons/fa";
import { ResponsivePie } from '@nivo/pie';
import api from "../../../services/api";
import toast from "react-hot-toast";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import SelegoLogo from "../../../assets/selego.png";

const Presentation = ({ audit }) => {
  const [filters, setFilters] = useState({
    priority: null,
    status: null,
    type: null,
  });

  const contentRef = useRef(null);

  const downloadAsPDF = async () => {
    try {
      const content = contentRef.current;
      const canvas = await html2canvas(content);
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
      
      const pageHeight = pdf.internal.pageSize.getHeight();
      
      if (pdfHeight > pageHeight) {
        const pageCount = Math.ceil(pdfHeight / pageHeight);
        
        for (let i = 0; i < pageCount; i++) {
          if (i > 0) {
            pdf.addPage();
          }
          
          const sourceY = i * canvas.height / pageCount;
          const sourceHeight = canvas.height / pageCount;
          
          pdf.addImage(
            imgData,
            'PNG',
            0,
            -sourceY * (pageHeight / sourceHeight),
            pdfWidth,
            pdfHeight
          );
        }
      } else {
        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      }

      pdf.save(`audit-report-${new Date().toISOString().split('T')[0]}.pdf`);
    } catch (error) {
      toast.error("Error generating PDF!");
    }
  };

  const issueData = [
    {
      id: 'Complexity',
      label: 'Complexity',
      value: audit.complexity_issues || 0,
      color: '#EF4444'
    },
    {
      id: 'Convention',
      label: 'Convention', 
      value: audit.convention_issues || 0,
      color: '#F59E0B'
    },
    {
      id: 'Inconsistency',
      label: 'Inconsistency',
      value: audit.inconsistency_issues || 0,
      color: '#10B981'
    },
    {
      id: 'red_flag',
      label: 'Legacy/Security/Logic Issues',
      value: audit.red_flag || 0,
      color: '#EF4444'
    }
  ];

  return (
    <div className="space-y-8 font-inter">
      <div className="flex justify-end mb-4">
        <button
          onClick={downloadAsPDF}
          className="flex items-center gap-2 px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-all shadow-md">
          <FaDownload className="w-4 h-4" />
          Download PDF
        </button>
      </div>

      <div ref={contentRef}>
        <div className="bg-white rounded-xl border border-gray-200 p-8 shadow-sm">
          <SelegoBranding />
          <div className="flex items-center justify-between mb-8">
            <div className="flex flex-col gap-6 w-full">
              <div className="flex items-center justify-between mb-4">
                <h3 className="text-2xl font-bold text-gray-900 flex items-center gap-2">
                  Executive Summary
                  <div className="group relative cursor-help">
                    <svg className="w-5 h-5 text-gray-400 hover:text-gray-600 transition-colors" fill="currentColor" viewBox="0 0 20 20">
                      <path
                        fillRule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <div className="invisible group-hover:visible absolute z-10 w-72 p-3 bg-gray-900 text-white text-sm rounded-lg -top-2 left-6 shadow-lg">
                      Overall assessment of the project's code quality and main findings from the latest code audit
                    </div>
                  </div>
                </h3>

                <div className="font-semibold text-gray-900 bg-blue-50 px-4 py-2 rounded-lg border border-blue-100 shadow-sm">
                  {new Date(audit.last_audit_date).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })}
                </div>
              </div>

              <div className="bg-gray-50 rounded-xl p-6 border border-gray-100 shadow-inner">
                <p className="text-gray-700 leading-relaxed whitespace-pre-wrap text-lg">{audit.summary || "No summary has been provided for this audit yet."}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="space-y-8 p-8">
          <div className="grid grid-cols-2 gap-8">
            <div onClick={() => setFilters({ priority: null })} className="bg-white rounded-xl p-8 border border-gray-200 text-center cursor-pointer shadow-sm hover:shadow-md transition-all">
              <h2 className="text-2xl font-bold text-gray-900 mb-4">Total Issues</h2>
              <div className="text-5xl font-black text-blue-600">
                {(audit.high_issues || 0) + (audit.medium_issues || 0) + (audit.low_issues || 0)}
              </div>
            </div>

            <div className="bg-white rounded-xl p-8 border border-gray-200 shadow-sm">
              <div style={{ height: '300px' }}>
                <ResponsivePie
                  data={issueData}
                  margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                  innerRadius={0.5}
                  padAngle={0.7}
                  cornerRadius={3}
                  activeOuterRadiusOffset={8}
                  borderWidth={1}
                  borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.2 ] ] }}
                  arcLinkLabelsSkipAngle={10}
                  arcLinkLabelsTextColor="#333333"
                  arcLinkLabelsThickness={2}
                  arcLinkLabelsColor={{ from: 'color' }}
                  arcLabelsSkipAngle={10}
                  arcLabelsTextColor="white"
                  legends={[
                    {
                      anchor: 'bottom',
                      direction: 'row',
                      justify: false,
                      translateX: 0,
                      translateY: 56,
                      itemsSpacing: 0,
                      itemWidth: 100,
                      itemHeight: 18,
                      itemTextColor: '#999',
                      itemDirection: 'left-to-right',
                      itemOpacity: 1,
                      symbolSize: 18,
                      symbolShape: 'circle'
                    }
                  ]}
                />
              </div>
            </div>
          </div>

          <div className="grid grid-cols-3 gap-8">
            <div
              onClick={() => setFilters({ priority: "high" })}
              className="bg-red-50 border border-red-200 rounded-xl p-8 transition-all hover:shadow-lg cursor-pointer">
              <div className="flex items-center justify-between mb-3">
                <div className="text-red-700 font-bold text-lg">High Priority</div>
                <FaExclamationTriangle className="w-6 h-6 text-red-500" />
              </div>
              <div className="text-4xl font-black text-red-600">{audit.high_issues || 0}</div>
              <div className="text-sm text-red-600 mt-2 font-medium">Critical security & performance issues</div>
            </div>

            <div
              onClick={() => setFilters({ priority: "medium" })}
              className="bg-yellow-50 border border-yellow-200 rounded-xl p-8 transition-all hover:shadow-lg cursor-pointer">
              <div className="flex items-center justify-between mb-3">
                <div className="text-yellow-700 font-bold text-lg">Medium Priority</div>
                <FaExclamation className="w-6 h-6 text-yellow-500" />
              </div>
              <div className="text-4xl font-black text-yellow-600">{audit.medium_issues || 0}</div>
              <div className="text-sm text-yellow-600 mt-2 font-medium">Code quality & maintainability</div>
            </div>

            <div
              onClick={() => setFilters({ priority: "low" })}
              className="bg-green-50 border border-green-200 rounded-xl p-8 transition-all hover:shadow-lg cursor-pointer">
              <div className="flex items-center justify-between mb-3">
                <div className="text-green-700 font-bold text-lg">Low Priority</div>
                <FaInfoCircle className="w-6 h-6 text-green-500" />
              </div>
              <div className="text-4xl font-black text-green-600">{audit.low_issues || 0}</div>
              <div className="text-sm text-green-600 mt-2 font-medium">Style & optimization suggestions</div>
            </div>
          </div>
        </div>
        <Issues audit={audit} filters={filters} />
      </div>
    </div>
  );
};

const Issues = ({ audit, filters }) => {
  const [issues, setIssues] = useState([]);

  useEffect(() => {
    getIssues();
  }, [filters]);

  const getIssues = async () => {
    const { data, ok } = await api.post(`/audit_issue/search`, {
      audit_id: audit._id,
      priority: filters.priority,
      status: filters.status,
      type: filters.type,
    });
    if (!ok) return toast.error("Error fetching issues!");
    setIssues(data);
  };

  const TagDescriptions = {
    complexity: "Difficult for new developers to understand and contribute. Over time, this increases maintenance costs and hinders scalability.",
    convention: "Not following SELEGO conventions can create barriers for future development, delays, and higher costs. Adhering to conventions ensures scalability and ease of enhancement.",
    inconsistency: "Makes the code harder to maintain and slows onboarding, increasing costs and limiting growth.",
    deadcode: "Confuses developers, adds complexity, and affects performance. Fixing it provides clarity and confidence for future development.",
    legacy: "Introduces security risks, performance issues, and compatibility problems.",
    red_flag: "Potential issue that needs immediate attention."
  };

  return (
    <div className="bg-white rounded-xl p-8 border border-gray-200 shadow-sm">
      <div className="print-only page-break"></div>
      <h3 className="text-2xl font-bold text-gray-900 mb-6">Issues</h3>
      <div className="space-y-6">
        {issues.map((issue) => (
          <div key={issue._id} className="flex items-start gap-6 p-6 bg-gray-50 rounded-xl border border-gray-100 hover:shadow-md transition-all">
            <div className="flex-shrink-0">
              {issue.type === "red_flag" && <FaExclamationTriangle className="w-6 h-6 text-red-500" />}
              {issue.type === "complexity" && <FaCode className="w-6 h-6 text-orange-500" />}
              {issue.type === "convention" && <FaBookOpen className="w-6 h-6 text-blue-500" />}
              {issue.type === "inconsistency" && <FaRandom className="w-6 h-6 text-purple-500" />}
            </div>
            <div className="flex-1">
              <div className="flex items-center gap-3 mb-3">
                <span className="text-gray-900 font-bold text-lg">{issue.description}</span>
                <div
                  className={`px-3 py-1 text-sm font-semibold rounded-full ${
                    issue.priority === "high" ? "bg-red-100 text-red-700" : issue.priority === "medium" ? "bg-yellow-100 text-yellow-700" : "bg-green-100 text-green-700"
                  }`}>
                  {issue.priority}
                </div>
                <div
                  className={`px-3 py-1 text-sm font-semibold rounded-full ${
                    issue.status === "done" ? "bg-green-100 text-green-700" : issue.status === "in_progress" ? "bg-blue-100 text-blue-700" : "bg-gray-100 text-gray-700"
                  }`}>
                  {issue.status.replace("_", " ")}
                </div>
                <div className={`px-3 py-1 text-sm font-semibold rounded-full ${
                  issue.type === "red_flag" ? "bg-red-100 text-red-700" :
                  issue.type === "complexity" ? "bg-orange-100 text-orange-700" :
                  issue.type === "convention" ? "bg-blue-100 text-blue-700" :
                  "bg-purple-100 text-purple-700"
                }`}>
                  {issue.type.replace("_", " ")}
                </div>
              </div>
              {issue.folder && <div className="text-sm text-gray-500 font-medium">Location: {issue.folder}</div>}
              <div className="mt-3 text-sm text-gray-600">
                <span className="font-bold text-gray-700">Business Impact: </span>
                {TagDescriptions[issue.type]}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const SelegoBranding = () => {
  return (
    <div className="flex items-center justify-between mb-10">
      <div className="flex items-center gap-4">
        <img 
          src={SelegoLogo} 
          alt="Selego Logo" 
          className="w-12 h-12 object-contain"
        />
        <div className="flex flex-col">
          <span className="text-2xl font-black text-gray-900">Selego</span>
          <span className="text-sm font-medium text-gray-600">Tech • Product • Data/IA • Growth</span>
        </div>
      </div>
      <div className="text-right">
        <h2 className="text-3xl font-black text-blue-600">Code Audit Report</h2>
        <p className="text-sm font-medium text-gray-600">Accelerating Startup Development</p>
      </div>
    </div>
  );
};

export default Presentation;
