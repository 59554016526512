import React, { useEffect, useState } from "react";
import { Routes, Route, useParams, NavLink, useNavigate, useLocation } from "react-router-dom";

import { useSelector } from "react-redux";
import { RiErrorWarningLine, RiTimeLine } from "react-icons/ri";

import Loader from "../../../components/loader";
import api from "../../../services/api";

import BudgetNormal from "./budgetNormal";
import Edit from "./edit";
import Billing from "./billing";
import Activities from "./activities";

import BudgetAdmin from "./budgetAdmin";
import Breakdown from "./breakdown";
import Invoice from "./invoice";
import Forecast from "./forecast";
import NewInvoice from "./newInvoice";
import Business from "./business";
import Document from "./document";
import Raw from "./raw";
import Perks from "./perks";
import Team from "./team";
import TeamStats from "./team-stats";
import Reports from "./reports";
import Backlog from "./backlog";
import Roadmap from "./roadmap";
import Meetings from "./meetings";
import Brief from "./brief";
import Quotes from "./quotes";
import Payments from "./payments";
import Tjms from "./tjms";

const tabItems = {
  client: [
    { href: "/backlog", title: "Backlog", roles: ["admin", "client", "user"] },
    { href: "/roadmap", title: "Roadmap", roles: ["admin", "client", "user"] },
    { href: "/budget-normal", title: "Budget", roles: ["admin", "client", "user"] },
    { href: "/activities", title: "Activities", roles: ["admin", "client", "user"] },
    { href: "/invoice", title: "Invoice", roles: ["admin", "client", "user"] },
    { href: "/document", title: "Document", roles: ["admin", "client", "user"] },
    { href: "/team", title: "Team", roles: ["admin", "client", "user"] },
    { href: "/perks", title: "Perks", roles: ["admin", "client", "user"] },
  ],
  admin: [
    { href: "/budget-admin", title: "Budget", roles: ["admin", "user"] },
    { href: "/payments", title: "Payments", roles: ["admin", "user"] },
    { href: "/meetings", title: "Meetings", roles: ["admin"] },
    { href: "/quotes", title: "Quotes", roles: ["admin"] },
    { href: "/breakdown", title: "Breakdown", roles: ["admin", "user"] },
    { href: "/tjms", title: "TJMs", roles: ["admin", "user"] },
    { href: "/business", title: "Business", roles: ["admin"] },
    { href: "/reports", title: "Reports", roles: ["admin", "client", "user"] },
    { href: "/brief", title: "Brief", roles: ["admin", "client", "user"] },
    { href: "/edit", title: "Edit", roles: ["admin", "user"] },
    { href: "/raw", title: "Raw", roles: ["admin", "user"] },
  ],
};

const Project = () => {
  const { id } = useParams();
  const [project, setProject] = useState(null);
  const [mode, setMode] = useState("client");
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    fetchData();
  }, [id]);

  async function fetchData() {
    const { data: u } = await api.get(`/project/${id}`);
    setProject(u);
  }

  useEffect(() => {
    const path = location.pathname;
    const currentPath = path.split("/").pop();
    const isAdminPath = tabItems.admin.some(({ href }) => href.replace("/", "") === currentPath);

    setMode(isAdminPath ? "admin" : "client");
  }, [location.pathname]);

  const user = useSelector((state) => state.Auth.user);

  if (!project) return <Loader />;

  return (
    <div className="p-4">
      <div className="flex mb-2">
        {project.website ? (
          <a href={project.website} target="_blank" rel="noopener noreferrer">
            <img
              src={project.logo || "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y"}
              alt="projectlogo"
              className="rounded-md w-7 h-7 bg-gray-50 object-contain mr-4"
            />
          </a>
        ) : (
          <img
            src={project.logo || "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y"}
            alt="projectlogo"
            className="rounded-md w-7 h-7 bg-gray-50 object-contain mr-4"
          />
        )}

        <h1 className="text-2xl font-bold mr-4">{project.name}</h1>
        <LateInvoices project={project} />
        <Inconsistencies project={project} />
        <div className="flex-1 space-y-2"></div>
      </div>
      <div className="mb-2 flex items-center justify-between gap-2 mt-2">
        <nav className="flex-1 bg-white rounded-md flex item-center gap-1 border border-neutral-300 p-1 overflow-x-auto">
          {tabItems[mode].map(({ href, title, roles }) => (
            <TabItem key={href} href={href} title={title} roles={roles} />
          ))}
        </nav>
        {user.role === "admin" && (
          <div className="bg-white rounded-md flex item-center gap-1 border border-neutral-300 p-1">
            <button
              className={`${mode === "client" ? "blue-btn" : "transparent-btn"}`}
              onClick={() => {
                setMode("client");
                navigate(`/project/${id}/backlog`);
              }}>
              Client
            </button>
            <button
              className={`${mode === "admin" ? "blue-btn" : "transparent-btn"}`}
              onClick={() => {
                setMode("admin");
                navigate(`/project/${id}/budget-admin`);
              }}>
              Admin
            </button>
          </div>
        )}
      </div>
      <div>
        <Routes>
          <Route path="/" element={<Backlog project={project} setProject={setProject} />} />
          <Route path="/backlog/:tab?" element={<Backlog project={project} setProject={setProject} />} />
          <Route path="/budget-normal" element={<BudgetNormal project={project} setProject={setProject} />} />
          <Route path="/edit" element={<Edit project={project} setProject={setProject} />} />
          <Route path="/budget-admin" element={<BudgetAdmin project={project} setProject={setProject} />} />
          <Route path="/breakdown" element={<Breakdown project={project} setProject={setProject} />} />
          <Route path="/activities" element={<Activities project={project} setProject={setProject} />} />
          <Route path="/roadmap/*" element={<Roadmap project={project} setProject={setProject} />} />
          <Route path="/invoice" element={<Invoice project={project} setProject={setProject} />} />
          <Route path="/billing" element={<Billing project={project} setProject={setProject} />} />
          <Route path="/forecast" element={<Forecast project={project} setProject={setProject} />} />
          <Route path="/new-invoice" element={<NewInvoice project={project} setProject={setProject} />} />
          <Route path="/business" element={<Business project={project} setProject={setProject} />} />
          <Route path="/document" element={<Document project={project} setProject={setProject} />} />
          <Route path="/reports" element={<Reports project={project} setProject={setProject} />} />
          <Route path="/perks" element={<Perks project={project} setProject={setProject} />} />
          <Route path="/raw" element={<Raw project={project} setProject={setProject} />} />
          <Route path="/team" element={<Team project={project} setProject={setProject} />} />
          <Route path="/team-stats" element={<TeamStats project={project} setProject={setProject} />} />
          <Route path="/meetings" element={<Meetings project={project} setProject={setProject} />} />
          <Route path="/brief" element={<Brief project={project} setProject={setProject} />} />
          <Route path="/quotes" element={<Quotes project={project} setProject={setProject} />} />
          <Route path="/payments" element={<Payments project={project} />} />
          <Route path="/tjms" element={<Tjms project={project} setProject={setProject} />} />
        </Routes>
      </div>
    </div>
  );
};

const TabItem = ({ href, title, roles }) => {
  const { id } = useParams();

  const { user } = useSelector((state) => state.Auth);
  if (!roles.includes(user.role)) return <div />;

  return (
    <NavLink to={`/project/${id}${href}`} className={({ isActive }) => (isActive ? "blue-btn" : "transparent-btn cursor-pointer relative rounded flex items-center")}>
      <div className="flex items-center gap-2 truncate">{title}</div>
    </NavLink>
  );
};

const LateInvoices = ({ project }) => {
  const [invoices, setInvoices] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const { data } = await api.post("/invoice/search", { projectId: project._id, late_invoice: true, received: "no" });
    setInvoices(data);
  };

  if (!invoices.length) return null;

  return (
    <div className="relative inline-block">
      <div className="relative cursor-pointer group">
        <RiTimeLine className="h-6 w-6 text-red-500" />
        <div className="absolute -top-2 -right-2 bg-red-500 text-white text-xs rounded-full h-4 w-4 flex items-center justify-center">{invoices.length}</div>

        {/* Tooltip */}
        <div className="absolute left-full ml-2 mt-20 -translate-y-1/2 w-96 p-2 bg-gray-900 text-white text-sm rounded-lg z-50 invisible group-hover:visible">
          <div className="flex flex-col gap-1">
            {invoices.map((invoice) => (
              <div key={invoice._id}>
                Invoice <strong>{invoice.name}</strong> of <strong>{invoice.total.toLocaleString("fr", { style: "currency", currency: "EUR" })}</strong> is{" "}
                <strong>{invoice.late}</strong> days late
              </div>
            ))}
            <button onClick={() => navigate(`/project/${project._id}/invoice`)} className="mt-2 w-full text-center px-2 py-1 bg-gray-700 hover:bg-gray-600 rounded">
              View invoices
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const Inconsistencies = ({ project }) => {
  const user = useSelector((state) => state.Auth.user);
  const navigate = useNavigate();
  if (user.role !== "admin") return null;
  const missingFields = [];

  if (!project.name) missingFields.push("Client name missing");
  if (!project.address) missingFields.push("Client's address missing");
  if (!project.clientsMails || !project.clientsMails.length) missingFields.push("Client's mail missing");
  if (!project.tax) missingFields.push("Client's VAT missing");
  if (!project.siren) missingFields.push("Client's SIREN missing");

  if (!missingFields.length) return null;

  return (
    <div className="relative inline-block ">
      <div className="relative cursor-pointer group">
        <RiErrorWarningLine className="h-6 w-6 text-red-500" />
        <div className="absolute -top-2 -right-2 bg-red-500 text-white text-xs rounded-full h-4 w-4 flex items-center justify-center">{missingFields.length}</div>

        {/* Tooltip */}
        <div className="absolute left-full ml-2 mt-20 -translate-y-1/2 w-64 p-2 bg-gray-900 text-white text-sm rounded-lg z-50 invisible group-hover:visible">
          <div className="flex flex-col gap-1">
            {missingFields.map((field, index) => (
              <div key={index}>{field}</div>
            ))}
            <button onClick={() => navigate(`/project/${project._id}/edit`)} className="mt-2 w-full text-center px-2 py-1 bg-gray-700 hover:bg-gray-600 rounded">
              Edit project
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Project;
