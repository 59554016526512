import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import FeedbackModal from "./feedback";
import { HiCheck, HiX, HiChevronUp, HiChevronDown } from "react-icons/hi";
import MultiAction from "../../../../../../components/MultiAction";
import api from "../../../../../../services/api";

export default ({ notion, setNotion }) => {
  const [loading, setLoading] = useState(false);
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const [expandedWhy, setExpandedWhy] = useState(null);

  const user = useSelector((state) => state.Auth.user);

  useEffect(() => {
    // loadGerard();
  }, [notion]);

  async function loadGerard() {
    if ((notion.suggestions || []).length > 0) return;
    if (loading) return;
    console.log("LOAD GERARD");
    try {
      setLoading(true);
      const { data } = await api.put(`/notion/${notion._id}/gerard`);
      console.log(data);
      setNotion(data);
    } catch (e) {
      console.log(`Error loading gerard:`, e);
      toast.error("Some Error!");
    }
    setLoading(false);
  }

  return (
    <div className="relative w-full max-w-2xl mx-auto bg-white px-4 py-2 border border-gray-200 rounded-md">
      <FeedbackModal isOpen={feedbackOpen} onClose={() => setFeedbackOpen(false)} notion={notion} setNotion={setNotion} />
      <div className="flex items-center justify-between gap-3">
        <h1 className="text-lg font-semibold">AI Suggestions</h1>
        <MultiAction
          actions={[
            {
              name: "Refresh",
              cb: async () => {
                setLoading(true);
                setNotion({ ...notion, suggestions: [] });
                const { data } = await api.put(`/notion/${notion._id}/gerard`);
                // setNotion(data);
                setLoading(false);
              },
            },
            { name: "Give feedback", cb: () => setFeedbackOpen(true) },
          ]}
        />
      </div>
      {loading && <p>Loading...</p>}
      <div>
        {(notion.suggestions || []).map((suggestion, index) => {
          if (suggestion.status === "ACCEPTED") return null;
          if (suggestion.status === "REJECTED") return null;

          console.log(suggestion);

          return (
            <div key={index} className="mt-2 border border-gray-200 rounded-lg p-3">
              <div className="flex flex-col gap-2">
                <div className="flex items-center justify-between">
                  {suggestion.from ? (
                    <h4 className="text-sm cursor-pointer" onClick={() => setExpandedWhy(expandedWhy === index ? null : index)}>
                      Set <strong>{suggestion.field}</strong> from <strong>{suggestion.from}</strong> to <strong>{suggestion.to}</strong>
                    </h4>
                  ) : (
                    <h4 className="text-sm cursor-pointer" onClick={() => setExpandedWhy(expandedWhy === index ? null : index)}>
                      Set <strong>{suggestion.field}</strong> to {suggestion.to}
                    </h4>
                  )}
                  <div className="flex gap-2">
                    <button
                      className="p-1 text-green-500 hover:bg-green-50 rounded"
                      onClick={async () => {
                        const suggestions = [...notion.suggestions];
                        suggestions[index].status = "ACCEPTED";
                        const { data } = await api.put(`/notion/${notion._id}`, { [suggestion.field]: suggestion.to, suggestions });
                        setNotion(data);
                        toast.success("Suggestion accepted!");
                      }}>
                      <HiCheck size={16} />
                    </button>
                    <button
                      className="p-1 text-red-500 hover:bg-red-50 rounded"
                      onClick={async () => {
                        const suggestions = [...notion.suggestions];
                        suggestions[index].status = "REJECTED";
                        const { data } = await api.put(`/notion/${notion._id}`, { suggestions });
                        setNotion(data);
                        toast.success("Suggestion rejected!");
                      }}>
                      <HiX size={16} />
                    </button>
                  </div>
                </div>

                {suggestion.why && (
                  <div className="cursor-pointer" onClick={() => setExpandedWhy(expandedWhy === index ? null : index)}>
                    {expandedWhy === index && <p className="text-gray-500 text-xs">{suggestion.why}</p>}
                  </div>
                )}
              </div>
            </div>
          );
        })}
        {(notion.suggestions || []).some((e) => e.status === "PENDING") && (
          <button className="cursor-pointer text-green-500 font-semibold text-sm mt-4 px-2 py-1 hover:bg-green-100 rounded" onClick={() => toast.success("TODO")}>
            Accept All Suggestions
          </button>
        )}
      </div>
    </div>
  );
};
