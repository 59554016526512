import React, { useState, useRef, useCallback, useEffect } from "react";
import api from "../../../../services/api";
import toast from "react-hot-toast";


export default ({ editor }) => {
  if (!editor) return null;

  const addLink = () => {
    const url = window.prompt("URL:");
    if (url) editor.chain().focus().setLink({ href: url }).run();
  };

  const addImage = () => {
    const url = window.prompt("Image URL:");
    if (url) editor.chain().focus().setImage({ src: url }).run();
  };


  const handleImageUpload = async (file) => {
    try {
      const rawBody = await readFileAsync(file);
      const { data } = await api.post("/file", { file: { rawBody, name: file.name }, folder: "/articles" });
      editor.chain().focus().setImage({ src: data }).run();
    } catch (error) {
      console.error("Upload failed:", error);
      toast.error("Failed to upload image");
    }
  };



  return (
    <div className="p-2 flex flex-wrap gap-2 bg-gray-50 sticky top-0 z-50 border-b">
      <div className="flex flex-wrap gap-2 w-full pb-2">
        <button
          onClick={() => editor.chain().focus().toggleBold().run()}
          className={`disabled:cursor-not-allowed disabled:bg-gray-200 hover:bg-gray-300 px-3 py-1 rounded bg-gray-200 text-gray-600 text-xs ${
            editor.isActive("bold") ? "!bg-gray-300 border" : ""
          }`}>
          Bold
        </button>
        <button
          onClick={() => editor.chain().focus().toggleItalic().run()}
          className={`disabled:cursor-not-allowed disabled:bg-gray-200 hover:bg-gray-300 px-3 py-1 rounded bg-gray-200 text-gray-600 text-xs ${
            editor.isActive("italic") ? "!bg-gray-300 border" : ""
          }`}>
          Italic
        </button>
        <button
          onClick={() => editor.chain().focus().toggleStrike().run()}
          className={`disabled:cursor-not-allowed disabled:bg-gray-200 hover:bg-gray-300 px-3 py-1 rounded bg-gray-200 text-gray-600 text-xs ${
            editor.isActive("strike") ? "!bg-gray-300 border" : ""
          }`}>
          Strike
        </button>
        <button
          onClick={() => editor.chain().focus().toggleCode().run()}
          className={`disabled:cursor-not-allowed disabled:bg-gray-200 hover:bg-gray-300 px-3 py-1 rounded bg-gray-200 text-gray-600 text-xs ${
            editor.isActive("code") ? "!bg-gray-300 border" : ""
          }`}>
          Code
        </button>
        <div
          className={`disabled:cursor-not-allowed disabled:bg-gray-200 flex items-center gap-1 hover:bg-gray-300 px-3 py-1 rounded bg-gray-200 text-gray-600 text-xs ${
            editor.isActive("strike") ? "!bg-gray-300 border" : ""
          }`}>
          <input type="color" onInput={(e) => editor.chain().focus().setColor(e.target.value).run()} className="text-gray-600 w-5 h-5" />
          <span className="text-xs text-gray-600">Text</span>
        </div>
        <div
          className={`disabled:cursor-not-allowed disabled:bg-gray-200 flex items-center gap-1 hover:bg-gray-300 px-3 py-1 rounded bg-gray-200 text-gray-600 text-xs ${
            editor.isActive("strike") ? "!bg-gray-300 border" : ""
          }`}>
          <input type="color" onInput={(e) => editor.chain().focus().setHighlight({ color: e.target.value }).run()} className="text-gray-600 w-5 h-5" />
          <span className="text-xs text-gray-600">Background</span>
        </div>
        <button
          onClick={() => editor.chain().focus().setParagraph().run()}
          className={`disabled:cursor-not-allowed disabled:bg-gray-200 hover:bg-gray-300 px-3 py-1 rounded bg-gray-200 text-gray-600 text-xs ${
            editor.isActive("paragraph") ? "!bg-gray-300 border" : ""
          }`}>
          Paragraph
        </button>
        {[1, 2, 3].map((level) => (
          <button
            key={level}
            onClick={() => editor.chain().focus().toggleHeading({ level }).run()}
            className={`disabled:cursor-not-allowed disabled:bg-gray-200 hover:bg-gray-300 px-3 py-1 rounded bg-gray-200 text-gray-600 text-xs ${
              editor.isActive("heading", { level }) ? "!bg-gray-300 border" : ""
            }`}>
            H{level}
          </button>
        ))}
        <button
          onClick={() => editor.chain().focus().toggleBlockquote().run()}
          className={`disabled:cursor-not-allowed disabled:bg-gray-200 hover:bg-gray-300 px-3 py-1 rounded bg-gray-200 text-gray-600 text-xs ${
            editor.isActive("blockquote") ? "!bg-gray-300 border" : ""
          }`}>
          Blockquote
        </button>
        <button
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={`disabled:cursor-not-allowed disabled:bg-gray-200 hover:bg-gray-300 px-3 py-1 rounded bg-gray-200 text-gray-600 text-xs ${
            editor.isActive("bulletList") ? "!bg-gray-300 border" : ""
          }`}>
          Bullet List
        </button>
        <button
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          className={`disabled:cursor-not-allowed disabled:bg-gray-200 hover:bg-gray-300 px-3 py-1 rounded bg-gray-200 text-gray-600 text-xs ${
            editor.isActive("orderedList") ? "!bg-gray-300 border" : ""
          }`}>
          Ordered List
        </button>
        <button
          onClick={() => editor.chain().focus().toggleCodeBlock().run()}
          className={`disabled:cursor-not-allowed disabled:bg-gray-200 hover:bg-gray-300 px-3 py-1 rounded bg-gray-200 text-gray-600 text-xs ${
            editor.isActive("codeBlock") ? "!bg-gray-300 border" : ""
          }`}>
          Code Block
        </button>
        <button
          onClick={() => editor.chain().focus().setHorizontalRule().run()}
          className="disabled:cursor-not-allowed disabled:bg-gray-200 hover:bg-gray-300 px-3 py-1 rounded bg-gray-200 text-gray-600 text-xs">
          Horizontal Rule
        </button>
        <button
          onClick={() => editor.chain().focus().setHardBreak().run()}
          className="disabled:cursor-not-allowed disabled:bg-gray-200 hover:bg-gray-300 px-3 py-1 rounded bg-gray-200 text-gray-600 text-xs">
          Hard Break
        </button>
        {/* <button
          onClick={addLink}
          className={`disabled:cursor-not-allowed disabled:bg-gray-200 hover:bg-gray-300 px-3 py-1 rounded bg-gray-200 text-gray-600 text-xs ${
            editor.isActive("link") ? "!bg-gray-300 border" : ""
          }`}>
          Link
        </button> */}
        {/* <input
          type="file"
          accept="image/*"
          className="hidden"
          ref={imageButtonRef}
          onChange={(e) => {
            const file = e.target.files?.[0];
            if (file) handleImageUpload(file);
          }}
        /> */}
        {/* <div className="relative">
          <button
            onClick={() => imageButtonRef.current?.click()}
            className="disabled:cursor-not-allowed disabled:bg-gray-200 hover:bg-gray-300 px-3 py-1 rounded bg-gray-200 text-gray-600 text-xs">
            Image
          </button>
          <div className="absolute top-full mt-1 bg-white border rounded-lg shadow-lg p-2 hidden group-hover:block">
            <button onClick={() => imageButtonRef.current?.click()} className="block w-full text-left px-2 py-1 hover:bg-gray-100 rounded text-sm">
              Upload from computer
            </button>
            <button onClick={addImage} className="block w-full text-left px-2 py-1 hover:bg-gray-100 rounded text-sm">
              Add image URL
            </button>
          </div>
        </div> */}
        {/* <button
          onClick={addComment}
          disabled={editor?.state.selection.empty}
          className="disabled:cursor-not-allowed disabled:bg-gray-200 hover:bg-gray-300 px-3 py-1 rounded bg-gray-200 text-gray-600 text-xs">
          Add Comment
        </button> */}
      </div>
    </div>
  );
};
