import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Loader from "../../../components/loader";

import api from "../../../services/api";

import Info from "./info";
import Raw from "./raw";

const TABS = {
  INFO: "Info",
  RAW: "Raw",
};

export default () => {
  const [tab, setTab] = useState(TABS.INFO);
  const [alert, setAlert] = useState(null);

  const { id } = useParams();

  useEffect(() => {
    get();
  }, [id]);

  async function get() {
    try {
      const { data } = await api.get(`/alert/${id}`);
      console.log("data", data);
      setAlert(data);
    } catch (e) {
      console.log("Error fetching alert:", e);
    }
  }

  if (!alert) return <Loader />;

  return (
    <div>
      <div className="m-2">
        <nav className="border-b border-gray-200">
          <ul className="flex gap-4 px-2">
            {Object.values(TABS).map((tabName) => (
              <li key={tabName}>
                <button
                  onClick={() => setTab(tabName)}
                  className={`py-2 border-b-2 font-medium transition-colors ${
                    tab === tabName ? "border-blue-500 text-blue-600" : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
                  }`}>
                  {tabName}
                </button>
              </li>
            ))}
          </ul>
        </nav>
        <div className="p-4">
          {tab === TABS.INFO && <Info alert={alert} setAlert={setAlert} />}
          {tab === TABS.RAW && <Raw alert={alert} />}
        </div>
      </div>
    </div>
  );
};
