import React, { useEffect, useState } from "react";
import api from "../../services/api";
import Table from "../../components/Table";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import Modal from "../../components/modal";

export default () => {
  const [alerts, setAlerts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    fetchAlerts();
  }, []);

  async function fetchAlerts() {
    try {
      setIsLoading(true);
      const { ok, data } = await api.post("/alert/search");
      if (!ok) return toast.error("Error fetching alerts");
      setAlerts(data);
    } catch (error) {
      console.error(error);
      toast.error("Failed to load alerts");
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div className="py-8 px-10">
      <div className="flex flex-col gap-8">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-4">
            <h1 className="text-2xl font-bold text-gray-900">Alerts</h1>
            <span className="bg-blue-100 text-blue-800 text-xs font-medium px-2.5 py-0.5 rounded-full">{alerts.length} Total</span>
          </div>
          <Create />
        </div>

        {isLoading ? (
          <div className="flex justify-center items-center h-64">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
          </div>
        ) : (
          <div className="bg-white overflow-hidden">
            <Table
              total={alerts.length}
              header={[
                { title: "Name", key: "name" },
                { title: "Description", key: "description" },
                { title: "Type", key: "type" },
                { title: "Created at", key: "created_at" },
                { title: "User", key: "user_name" },
              ]}>
              {alerts.map((alert) => (
                <tr key={alert._id} onClick={() => navigate(`/alert/${alert._id}`)} className="hover:bg-gray-50 cursor-pointer transition-colors duration-150">
                  <td className="px-4 py-3 font-medium text-gray-900">{alert.name}</td>
                  <td className="px-4 py-3 text-gray-600 truncate max-w-xs">{alert.description}</td>
                  <td className="px-4 py-3">
                    <span className="bg-gray-100 text-gray-800 text-xs font-medium px-2.5 py-0.5 rounded">{alert.type}</span>
                  </td>
                  <td className="px-4 py-3 text-gray-600">
                    {new Date(alert.created_at).toLocaleString("fr-FR", {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </td>
                  <td className="px-4 py-3 text-gray-600">
                    <div className="flex items-center gap-2">
                      <img src={alert.user_avatar || "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y"} alt="" className="w-6 h-6 rounded-full" />
                      {alert.user_name}
                    </div>
                  </td>
                </tr>
              ))}
            </Table>
          </div>
        )}
      </div>
    </div>
  );
};

const Create = () => {
  const [values, setValues] = useState({ name: "" });
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async () => {
    try {
      const res = await api.post("/alert", values);
      if (!res.ok) throw res;
      toast.success("Created!");
      setOpen(false);
      navigate(`/alert/${res.data._id}`);
    } catch (e) {
      console.log(e);
      toast.error("Some Error!", e.code);
    }
  };

  return (
    <>
      <button className="btn blue-btn" onClick={() => setOpen(true)}>
        Create a new alert
      </button>
      <Modal isOpen={open} className="max-w-3xl w-full p-6" onClose={() => setOpen(false)}>
        <div className="p-8">
          <h2 className="text-2xl font-semibold mb-4">Create alert</h2>
          <div className="grid grid-cols-2 gap-4 ">
            <div className="w-full col-span-2">
              <div className="text-sm font-medium mb-2">Name</div>
              <input className="projectsInput block" name="name" value={values.name} onChange={(e) => setValues({ ...values, name: e.target.value })} />
            </div>
          </div>
          <div className="flex justify-end mt-6">
            <button className="btn blue-btn w-32" onClick={handleSubmit}>
              Create
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};
