import React, { useEffect, useState } from "react";
import api from "../../../services/api";

export default ({ data }) => {
  const [documents, setDocuments] = useState(null);

  useEffect(() => {
    loadDocuments();
  }, []);

  const loadDocuments = async () => {
    try {
      const { data: docs } = await api.post(`/rfp_document/search`, { workspace_id: data._id });
      setDocuments(docs);
    } catch (e) {
      console.log(e);
    }
  };

  if (!documents) return <div>Loading documents...</div>;

  return (
    <div className="mt-2">
      {documents.length === 0 ? (
        <div className="text-gray-500">No documents found</div>
      ) : (
        <div className="space-y-2">
          {documents.map((doc) => (
            <div key={doc._id} className="p-4 bg-white rounded-lg shadow">
              <div className="font-medium">{doc.name}</div>
              <div className="text-sm text-gray-500">{doc.description}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
