import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import { IoMdClose, IoMdCheckmarkCircle, IoMdSync } from "react-icons/io";
import { ResponsiveRadar } from "@nivo/radar";
import { appURL } from "../../../config";
import { BsThreeDotsVertical } from "react-icons/bs";
import { HiUserAdd, HiLink } from "react-icons/hi";
import { HiUsers } from "react-icons/hi2";

import Loader from "../../../components/loader";
import api from "../../../services/api";
import Modal from "../../../components/modal";
import SelectUser from "../../../components/selectUser";
import { copyToClipboard } from "../../../utils";

export default function EditProject() {
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showCreateModal, setShowCreateModal] = useState(false);

  const { id } = useParams();

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    try {
      setLoading(true);
      const { data, ok } = await api.get(`/project/${id}`);
      setProject(data);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }

  if (!project)
    return (
      <div>
        <Loader />
      </div>
    );

  return (
    <div className="relative overflow-x-auto">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-3 mb-3">
        <div className="flex flex-col justify-between p-3 bg-gray-100 rounded-md">
          <div className="flex items-center gap-2 mb-1">
            <HiUserAdd className="w-5 h-5 text-blue-500" />
            <h3 className="text-sm font-medium">Add new team member</h3>
          </div>
          <button onClick={() => setShowCreateModal(true)} className="w-full px-2 py-1.5 text-xs bg-white border border-gray-300 rounded hover:bg-gray-50">
            Create User
          </button>
        </div>

        <div className="flex flex-col justify-between p-3 bg-gray-100 rounded-md">
          <div className="flex items-center gap-2 mb-1">
            <HiUsers className="w-5 h-5 text-green-500" />
            <h3 className="text-sm font-medium">Add existing user</h3>
          </div>
          <SelectUser
            userRoles={["admin", "user", "client"]}
            placeholder="Select user"
            className="w-full px-2 py-1.5 text-xs bg-white border border-gray-300 rounded hover:bg-gray-50"
            onChange={async (e) => {
              const team = project.team || [];
              team.push({
                userId: e._id,
                userName: e.name,
                userAvatar: e.avatar,
                userEmail: e.email,
                userJobTitle: e.job_title,
                userRole: e.role,
              });
              await api.put(`/project/${project._id}`, { team });
              toast.success("User assigned");
              fetchData();
            }}
          />
        </div>

        <div className="flex flex-col justify-between p-3 bg-gray-100 rounded-md">
          <div className="flex items-center gap-2 mb-1">
            <HiLink className="w-5 h-5 text-purple-500" />
            <h3 className="text-sm font-medium">Share invite link</h3>
          </div>
          <button
            onClick={() => {
              copyToClipboard(`${appURL}/auth/invite?redirect=${project._id}`);
              toast.success("Link copied!");
            }}
            className="w-full px-2 py-1.5 text-xs bg-white border border-gray-300 rounded hover:bg-gray-50">
            Copy Invite Link
          </button>
        </div>
      </div>

      <Modal isOpen={showCreateModal} onClose={() => setShowCreateModal(false)}>
        <CreateUserForm
          onClose={() => setShowCreateModal(false)}
          onSubmit={async (userData) => {
            try {
              const { data: newUser } = await api.post("/user", { ...userData, redirect: `${appURL}/project/${project._id}` });
              const team = project.team || [];
              team.push({
                userId: newUser._id,
                userName: newUser.name,
                userAvatar: newUser.avatar,
                userEmail: newUser.email,
                userJobTitle: newUser.job_title,
                userRole: newUser.role,
              });
              await api.put(`/project/${project._id}`, { team });
              toast.success("User created and added to team");
              fetchData();
              setShowCreateModal(false);
            } catch (error) {
              if (error.code === "EMAIL_ALREADY_EXIST") toast.error("User already exists\nTry adding them directly to the team.");
              else toast.error("Failed to create user");
              console.error(error);
            }
          }}
        />
      </Modal>

      <table className="min-w-full bg-white border border-gray-300 rounded-md">
        <thead>
          <tr className="bg-gray-50">
            <th className="p-2">Name</th>
            <th className="p-2">Email</th>
            <th className="p-2">Job Title</th>
            <th className="p-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {project.team.map((member) => (
            <UserCard key={member._id} member={member} project={project} setProject={setProject} />
          ))}
        </tbody>
        {project.team.length === 0 && (
          <tr>
            <td colSpan={3}>
              <div className="flex flex-col items-center justify-center p-8 text-gray-500">
                <HiUserGroup className="w-12 h-12 mb-2" />

                <p className="text-lg font-medium">No team members yet</p>
                <p className="text-sm">Use the form above to add members to your team</p>
              </div>
            </td>
          </tr>
        )}
      </table>
    </div>
  );
}

const UserCard = ({ member, project, setProject }) => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const [showMenu, setShowMenu] = useState(false);

  const removeMember = async () => {
    try {
      if (!confirm(`Are you sure you want to remove ${member.userName} from the team?`)) return;
      const team = project.team.filter((m) => m.userId.toString() !== member.userId.toString());
      console.log("✌️  team", team);
      const { data } = await api.put(`/project/${project._id}`, { team });
      setProject(data);
      toast.success("User removed");
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <tr className="border-t border-gray-200">
      <td className="p-2">
        <div className="flex items-center gap-2">
          <img src={member?.userAvatar} alt={member?.userName} className="w-8 h-8 rounded-full object-cover" />
          <p className="">{member?.userName}</p>
        </div>
      </td>
      <td className="p-4">
        <div className="flex items-center gap-2">{member?.userEmail}</div>
      </td>
      <td className="p-4">
        <p className="">{member?.userJobTitle}</p>
      </td>
      <td className="p-4">
        <div className="relative">
          <button onClick={() => setShowMenu(!showMenu)} className="p-1 bg-white rounded-full border border-gray-300 hover:bg-gray-100">
            <BsThreeDotsVertical className="w-3 h-3 text-gray-600" />
          </button>
          {showMenu && (
            <div className="absolute right-0 top-full mt-2 w-48 bg-white rounded-md shadow-lg z-50">
              <div className="py-1">
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    query.set("user_modal_id", member.userId);
                    query.set("user_modal_tab", "skills");
                    navigate({ search: query.toString() });
                  }}
                  className="w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100">
                  View Profile
                </button>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    removeMember();
                    setShowMenu(false);
                  }}
                  className="w-full px-4 py-2 text-left text-sm text-red-600 hover:bg-gray-100">
                  Remove from team
                </button>
              </div>
            </div>
          )}
        </div>
      </td>
    </tr>
  );
};

const CreateUserForm = ({ onSubmit, onClose }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    job_title: "",
    role: "client",
  });

  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      onSubmit(formData);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6 p-6 max-w-md mx-auto">
      <div className="text-center">
        <h2 className="text-2xl font-bold text-gray-900">Create New User</h2>
        <p className="mt-2 text-sm text-gray-600">Add a new member to your team</p>
      </div>

      <div className="space-y-5">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Full Name</label>
          <input
            type="text"
            required
            placeholder="John Doe"
            className="mt-1 block w-full rounded-md border border-gray-300 px-4 py-2.5 shadow-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
            value={formData.name}
            onChange={(e) => setFormData((prev) => ({ ...prev, name: e.target.value }))}
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Email Address</label>
          <input
            type="email"
            required
            placeholder="john@example.com"
            className="mt-1 block w-full rounded-md border border-gray-300 px-4 py-2.5 shadow-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
            value={formData.email}
            onChange={(e) => setFormData((prev) => ({ ...prev, email: e.target.value }))}
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Job Title</label>
          <input
            type="text"
            required
            placeholder="Software Engineer"
            className="mt-1 block w-full rounded-md border border-gray-300 px-4 py-2.5 shadow-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
            value={formData.job_title}
            onChange={(e) => setFormData((prev) => ({ ...prev, job_title: e.target.value }))}
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Role</label>
          <select
            className="mt-1 block w-full rounded-md border border-gray-300 px-4 py-2.5 shadow-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500 bg-white"
            value={formData.role}
            onChange={(e) => setFormData((prev) => ({ ...prev, role: e.target.value }))}>
            <option value="client">Client</option>
            <option value="user">User</option>
            <option value="admin">Admin</option>
          </select>
        </div>
      </div>

      <div className="flex gap-3 pt-2">
        <button
          type="button"
          onClick={onClose}
          className="flex-1 px-4 py-2.5 bg-white text-gray-700 rounded-md border border-gray-300 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
          Cancel
        </button>
        <button
          disabled={loading}
          type="submit"
          className="flex-1 px-4 py-2.5 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
          Invite user
        </button>
      </div>
    </form>
  );
};
