import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Loader from "../../../components/loader";
import TabItem from "../../../components/TabItem";
import Edit from "./edit";
import Presentation from "./presentation";
import RawData from "./raw";
import History from "./history";
import Test from "./test";
import Tracking from "./tracking";

import api from "../../../services/api";

const PRESENTATION = "PRESENTATION";
const EDIT = "EDIT";
const RAW_DATA = "RAW_DATA";
const HISTORY = "HISTORY";
const TEST = "TEST";
const TRACKING = "TRACKING";

export default () => {
  const { id } = useParams();
  const [audit, setAudit] = useState();
  const [tab, setTab] = useState(PRESENTATION);

  useEffect(() => {
    fetchAudit();
  }, [id]);

  const fetchAudit = async () => {
    try {
      const { data, ok } = await api.get(`/audit/${id}`);
      if (!ok) return toast.error("Error fetching audit data.");
      setAudit(data);
    } catch (e) {
      toast.error("Error fetching audit data.");
    }
  };

  if (!audit) return <Loader />;

  return (
    <div className="p-5">
      <div className="w-full gap-x-4 gap-y-2 ">
        <nav className="bg-white rounded-md flex flex-row w-full border-[1px] border-gray-200 overflow-hidden my-2">
          <TabItem title="Presentation" tab="PRESENTATION" onClick={() => setTab("PRESENTATION")} active={tab === "PRESENTATION"} />
          <TabItem title="Edit" tab="EDIT" onClick={() => setTab("EDIT")} active={tab === "EDIT"} />
          <TabItem title="Tracking" tab="TRACKING" onClick={() => setTab("TRACKING")} active={tab === "TRACKING"} />
          <TabItem title="Selego Test" tab="TEST" onClick={() => setTab("TEST")} active={tab === "TEST"} />
          <TabItem title="Audit History" tab="HISTORY" onClick={() => setTab("HISTORY")} active={tab === "HISTORY"} />
          <TabItem title="Raw Data" tab="RAW_DATA" onClick={() => setTab("RAW_DATA")} active={tab === "RAW_DATA"} />
        </nav>
        <div className="bg-white rounded-md p-6 border border-primary-black-50 w-full">
          {tab === PRESENTATION && <Presentation audit={audit} />}
          {tab === EDIT && <Edit audit={audit} setAudit={setAudit} />}
          {tab === TRACKING && <Tracking audit={audit} />}
          {tab === RAW_DATA && <RawData audit={audit} />}
          {tab === HISTORY && <History history={audit?.history} />}
          {tab === TEST && <Test audit={audit} />}
        </div>
      </div>
    </div>
  );
};


