import React, { useEffect, useState } from "react";
import Contacts from "./contacts";
import Companies from "./companies";

export default () => {
  const [tab, setTab] = useState("contacts");

  return (
    <div className="bg-white h-full">
      <h1 className="">Intraprenariat</h1>
      <div className="flex  items-center">
        <button
          key="contacts"
          className={`px-3 py-1 rounded text-sm ${tab === "contacts" ? "bg-gray-100 text-blue-600" : "text-gray-600 hover:bg-gray-50"}`}
          onClick={() => setTab("contacts")}>
          Contacts
        </button>
        <button
          key="companies"
          className={`px-3 py-1 rounded text-sm ${tab === "companies" ? "bg-gray-100 text-blue-600" : "text-gray-600 hover:bg-gray-50"}`}
          onClick={() => setTab("companies")}>
          Companies
        </button>
        <button
          key="stats"
          className={`px-3 py-1 rounded text-sm ${tab === "stats" ? "bg-gray-100 text-blue-600" : "text-gray-600 hover:bg-gray-50"}`}
          onClick={() => setTab("stats")}>
          Stats
        </button>
      </div>
      {tab === "contacts" && <Contacts />}
      {tab === "companies" && <Companies />}
    </div>
  );
};
