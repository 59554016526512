import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import api from "../services/api";
import Loader from "../components/loader";

export default function BudgetSignature() {
  const [budget, setBudget] = useState(null);
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchParams] = useSearchParams();

  const [signed, setSigned] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [signingLoading, setSigningLoading] = useState(false);

  const [activeSigningToken, setActiveSigningToken] = useState(null);

  const token = searchParams.get("token");

  const get = async () => {
    try {
      const responseBudget = await api.get(`/budget/token/${token}`);
      setBudget(responseBudget.data);
      setActiveSigningToken(responseBudget.data.signing_tokens.find((t) => t.token === token));
      const responseProject = await api.get(`/project/${responseBudget.data.projectId}`);
      setProject(responseProject.data);
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    get();
  }, [token]);

  if (loading)
    return (
      <div className="bg-[#ededeb] min-h-full p-4 sm:p-8 w-full flex justify-center">
        <div className="w-[210mm] min-h-[297mm] bg-white rounded-lg shadow-lg mx-auto">
          <div className="p-[1.5cm]">
            <p className="text-center mt-2 italic">Loading budget details...</p>
            <Loader />
          </div>
        </div>
      </div>
    );

  if (!budget || !activeSigningToken)
    return (
      <div className="bg-[#ededeb] h-full p-8 w-full">
        <div className="w-3/4 mx-auto bg-white rounded-lg p-8 shadow-lg">
          <div className="text-center">
            <div className="text-red-600 flex items-center justify-center text-xl">
              <svg className="w-8 h-8 mr-2" fill="currentColor" viewBox="0 0 20 20">
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                  clipRule="evenodd"
                />
              </svg>
              Seems that nothing is here
            </div>
            <p className="mt-4 text-gray-600">
              If you have any questions, please contact{" "}
              <a href="mailto:office@selego.co" className="text-sky-600 hover:underline">
                office@selego.co
              </a>
            </p>
          </div>
        </div>
        <div className="w-3/4 mx-auto mt-4 text-center text-sm text-gray-500">
          Powered by <span className="font-bold">Selego</span>
        </div>
      </div>
    );

  const handleConfirmSign = async () => {
    setSigningLoading(true);
    try {
      const { ok, data } = await api.put(`/budget/${budget._id}/sign`, {
        token,
      });
      if (ok) {
        setSigned(true);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setSigningLoading(false);
    }
    setShowConfirmModal(false);
  };

  if (signed)
    return (
      <div className="bg-[#ededeb] h-full p-4 sm:p-8 w-full">
        <div className="w-full md:w-3/4 mx-auto bg-white rounded-lg p-4 sm:p-8 shadow-lg">
          <div className="text-center">
            <div className="text-green-600 flex items-center justify-center text-lg sm:text-xl">
              <svg className="w-6 h-6 sm:w-8 sm:h-8 mr-2" fill="currentColor" viewBox="0 0 20 20">
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clipRule="evenodd"
                />
              </svg>
              Budget successfully signed
            </div>
            <p className="mt-4 text-sm sm:text-base text-gray-600">
              If you have any questions, please contact{" "}
              <a href="mailto:office@selego.co" className="text-sky-600 hover:underline">
                office@selego.co
              </a>
            </p>
          </div>
        </div>
      </div>
    );

  return (
    <div className="bg-[#ededeb] min-h-full p-4 sm:p-8 w-full flex justify-center">
      {showConfirmModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-xl max-w-md w-full">
            <h3 className="text-xl font-semibold mb-4">Confirm Signature</h3>
            <p className="text-gray-600 mb-6">By signing, you agree to the terms and conditions of the budget.</p>
            <div className="flex justify-end space-x-4">
              <button onClick={() => setShowConfirmModal(false)} className="px-4 py-2 text-gray-600 hover:text-gray-800">
                Cancel
              </button>
              <button
                disabled={signingLoading}
                onClick={handleConfirmSign}
                className="bg-sky-500 text-white px-4 py-2 rounded-lg hover:bg-sky-600 transition-colors disabled:opacity-50">
                {signingLoading ? "Confirming..." : "Confirm sign"}
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="w-[210mm] min-h-[297mm] bg-white rounded-lg shadow-lg mx-auto">
        <div className="p-[1.5cm]">
          <div className="border-b pb-4 mb-6 flex flex-col sm:flex-row items-start sm:items-center sm:justify-between gap-4">
            <div className="">
              <div className="flex items-center mb-2">
                <div>
                  <img src={project.logo} alt={project.name} className="w-6 h-6 mr-2" />
                  <span className="text-base sm:text-lg text-gray-600">{project.name}</span>
                </div>
              </div>
              <h1 className="text-2xl sm:text-3xl font-bold text-gray-800">{budget.name}</h1>
            </div>
            <div className="bg-sky-50 p-4 rounded-lg w-full sm:w-auto">
              <p className="text-sky-800">Total budget amount</p>
              <h2 className="text-xl sm:text-2xl font-bold text-sky-800 mb-2">{budget.amount.toLocaleString()}€</h2>
              <p className="text-sky-800">or</p>
              <div className="font-semibold text-sky-800">
                {(
                  budget.amount /
                  ((new Date(budget.endAt).getFullYear() - new Date(budget.startAt).getFullYear()) * 12 +
                    (new Date(budget.endAt).getMonth() - new Date(budget.startAt).getMonth()) +
                    1)
                ).toLocaleString("fr-FR", {
                  style: "currency",
                  currency: "EUR",
                  maximumFractionDigits: 2,
                })}
                <span className="text-sm font-normal text-sky-800">/month</span>
              </div>
            </div>
          </div>

          <div className="grid gap-6">
            <div className="grid gap-4">
              <div>
                <h3 className="font-semibold text-gray-700 mb-2">Budget status</h3>
                <div className="bg-gray-50 p-4 rounded-lg">
                  {!budget.signed_at ? (
                    <div className="space-y-4 flex flex-col items-center">
                      <p className="text-gray-600 text-center">Dear {activeSigningToken.name}, this budget requires your signature to be approved.</p>
                      <button onClick={() => setShowConfirmModal(true)} className="bg-sky-500 text-white px-6 py-2 rounded-lg hover:bg-sky-600 transition-colors">
                        Sign budget
                      </button>
                    </div>
                  ) : (
                    <div className="flex items-center">
                      <svg className="w-10 h-10 mr-2 text-green-600" fill="currentColor" viewBox="0 0 20 20">
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <div>
                        <div>
                          Signed on{" "}
                          {new Date(budget.signed_at).toLocaleDateString("en-US", {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          })}
                          , {new Date(budget.signed_at).toLocaleTimeString()}
                        </div>
                        <div>by {budget.signed_by_name}</div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="border-b pb-4">
                <h3 className="font-semibold text-gray-700 mb-2">Budget details</h3>
                <div className="space-y-2 text-gray-600">
                  <p>
                    This budget {budget.recurring ? "is" : "is not"} recurring and runs on a {budget.period || "not specified"} basis.
                  </p>
                  <p>
                    {budget.facturation_type === "regie" && "This budget is of type régie (time and materials)"}
                    {budget.facturation_type === "forfait" && "This budget is of type forfait (fixed price)"}
                  </p>
                  <p>
                    Starting from{" "}
                    {budget.startAt
                      ? new Date(budget.startAt).toLocaleString("en-US", {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        })
                      : "an unspecified date"}{" "}
                    until{" "}
                    {budget.endAt
                      ? new Date(budget.endAt).toLocaleString("en-US", {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        })
                      : "an unspecified date"}{" "}
                    ({Math.ceil((new Date(budget.endAt) - new Date(budget.startAt)) / (1000 * 60 * 60 * 24))} days).
                  </p>
                </div>
              </div>

              <div className="border-b pb-4">
                <h3 className="font-semibold text-gray-700 mb-2">Description</h3>
                <p className="text-gray-600 whitespace-pre-wrap">{budget.description || "No description provided"}</p>
              </div>
              <div className="border-b pb-4">
                <h3 className="font-semibold text-gray-700 mb-2">Terms and conditions</h3>
                <p className="text-gray-600 whitespace-pre-wrap">
                  In the event of a contract between the parties, the terms of that contract shall prevail in accordance with Article 1103 of the French Civil Code. In the absence
                  of such a contract, the applicable provisions of the French Civil Code and/or Commercial Code shall apply.
                </p>
              </div>
              <p className="text-gray-600">
                If you have any questions, please contact{" "}
                <a href="mailto:office@selego.co" className="text-sky-600 hover:underline">
                  office@selego.co
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
