import { useState } from "react";
import API from "../../../../services/api";
import toast from "react-hot-toast";

const Social = ({ quote }) => {
  const [values, setValues] = useState(quote);

  const handleUpdate = async (field, value) => {
    try {
      const { data, ok } = await API.put(`/quote/${quote._id}`, { [field]: value });
      if (!ok) return toast.error("Failed to update action");
      setValues(data);
      toast.success("Action updated");
    } catch (error) {
      console.error(error);
      toast.error("An error occurred while updating");
    }
  };

  return (
    <div className="space-y-2 p-4">
      <div className="space-y-2">
        <label className="text-sm font-semibold" htmlFor="action_to_review">
          To review
        </label>
        <select id="action_to_review" value={values.action_to_review} onChange={(e) => handleUpdate("action_to_review", e.target.value)} className="input">
          <option value="">(empty)</option>
          <option value="TO DO">TO DO</option>
          <option value="DONE">DONE</option>
        </select>
      </div>
      <div className="space-y-2">
        <label className="text-sm font-semibold" htmlFor="action_blog">
          Blog
        </label>
        <select id="action_blog" value={values.action_blog} onChange={(e) => handleUpdate("action_blog", e.target.value)} className="input">
          <option value="">(empty)</option>
          <option value="TO DO">TO DO</option>
          <option value="DONE">DONE</option>
        </select>
      </div>
      <div className="space-y-2">
        <label className="text-sm font-semibold" htmlFor="action_sortlist">
          Sortlist
        </label>
        <select id="action_sortlist" value={values.action_sortlist} onChange={(e) => handleUpdate("action_sortlist", e.target.value)} className="input">
          <option value="">(empty)</option>
          <option value="TO DO">TO DO</option>
          <option value="DONE">DONE</option>
        </select>
      </div>
      <div className="space-y-2">
        <label className="text-sm font-semibold" htmlFor="action_trustpilot">
          Trustpilot
        </label>
        <select id="action_trustpilot" value={values.action_trustpilot} onChange={(e) => handleUpdate("action_trustpilot", e.target.value)} className="input">
          <option value="">(empty)</option>
          <option value="TO DO">TO DO</option>
          <option value="DONE">DONE</option>
        </select>
      </div>
      <div className="space-y-2">
        <label className="text-sm font-semibold" htmlFor="action_clutch">
          Clutch
        </label>
        <select id="action_clutch" value={values.action_clutch} onChange={(e) => handleUpdate("action_clutch", e.target.value)} className="input">
          <option value="">(empty)</option>
          <option value="TO DO">TO DO</option>
          <option value="DONE">DONE</option>
        </select>
      </div>

      <div className="space-y-2">
        <label className="text-sm font-semibold" htmlFor="action_goodfirms">
          Good Firms
        </label>
        <select id="action_goodfirms" value={values.action_goodfirms} onChange={(e) => handleUpdate("action_goodfirms", e.target.value)} className="input">
          <option value="">(empty)</option>
          <option value="TO DO">TO DO</option>
          <option value="DONE">DONE</option>
        </select>
      </div>
      <div className="space-y-2">
        <label className="text-sm font-semibold" htmlFor="action_codeur">
          Codeur
        </label>
        <select id="action_codeur" value={values.action_codeur} onChange={(e) => handleUpdate("action_codeur", e.target.value)} className="input">
          <option value="">(empty)</option>
          <option value="TO DO">TO DO</option>
          <option value="DONE">DONE</option>
        </select>
      </div>
    </div>
  );
};

export default Social;
