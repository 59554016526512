import React, { useEffect, useState } from "react";
import { FaUser, FaLinkedin, FaEnvelope, FaCheck, FaTimes, FaRegClock } from "react-icons/fa";
import toast from "react-hot-toast";

import CreateContact from "../createContact";
import api from "../../../../services/api";

export default ({ company, setSelectedUser }) => {
  const [users, setUsers] = useState([]); // Initialize with one empty user
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchUsers();
  }, [company]);

  if (!company) return <div />;

  async function fetchUsers() {
    console.log("fetchUsers", company);
    try {
      if (!company) return;
      setLoading(true);
  
      const res = await api.post(`/s_contact/search`, { company_id: company._id, limit: 20 });
      if (!res.ok) throw res;
      setUsers(res.data);
      setLoading(false);
    } catch (e) {
      console.error(e);
      toast.error("Some Error!", e.code);
    }
  }

  if (loading) return <div>Loading...</div>;

  return (
    <div className="">
      {users.map((user, index) => (
        <Preview key={index} user={user} onClick={() => setSelectedUser(user)} />
      ))}
      <CreateContact defaultValues={{ company_id: company._id }} onDone={fetchUsers} />
    </div>
  );
};

const Preview = ({ user, onClick }) => {
  return (
    <div className={`user-form bg-white shadow-md cursor-pointer mb-2 p-4`} onClick={onClick}>
      <div className="flex items-center justify-between mb-2">
        <div className="flex items-center">
          <div className="w-12 h-12 bg-gray-200 rounded-full flex items-center justify-center mr-4 overflow-hidden">
            {user?.picture ? (
              <img src={user.picture} alt="avatar" className="w-full h-full object-cover rounded-full" style={{ aspectRatio: "1/1" }} />
            ) : (
              user.first_name?.[0]?.toUpperCase() || "U"
            )}
          </div>
          <div>
            <h3 className="font-medium text-sm">{`${user.first_name || ""} ${user.last_name || ""}`}</h3>
            <p className="text-xs text-gray-500">{user.job_title}</p>
            <div className="flex space-x-3">
              <span className="text-base flex gap-1">
                {user.linkedin && <FaLinkedin className="text-gray-600 text-base" />}
                {user.status_linkedin_visit_profile ? <FaCheck className="text-green-500" /> : <FaTimes className="text-red-500" />}
                {user.status_linkedin_invite_sent ? <FaCheck className="text-green-500" /> : <FaTimes className="text-red-500" />}
                {user.status_linkedin_invite_accepted ? <FaCheck className="text-green-500" /> : <FaTimes className="text-red-500" />}
                {user.status_linkedin_message_sent ? <FaCheck className="text-green-500" /> : <FaTimes className="text-red-500" />}
                {user.email && <FaEnvelope className="text-gray-600 text-base" />}
              </span>
            </div>
          </div>
        </div>
        <div className="flex space-x-2">
          {/* <button
            className="btn px-3 py-1 rounded bg-blue-500 text-white hover:bg-blue-600"
            onClick={(e) => {
              e.stopPropagation();
              setIsEditing(true);
            }}>
            Edit
          </button> */}
        </div>
      </div>
      <p className="text-sm text-gray-600 line-clamp-2">{user.description || user?.linkedin_raw?.description || ""}</p>
    </div>
  );
};
