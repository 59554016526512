import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import Budget from "../../../components/budget";
import Loader from "../../../components/loader";
import SelectType from "../../../components/selectType";
import SelectUser from "../../../components/selectUser";
import DebounceInput from "../../../components/DebounceInput";
import Modal from "../../../components/modal";
import Pagination from "../../../components/Paginator";
import MultiSelect from "../../../components/MultiSelect";
import SelectOrganisation from "../../../components/selectOrganisation";

import api from "../../../services/api";
import CreateProject from "./createProject";

export default () => {
  const [filters, setFilters] = useState({ search: "", lead_id: "", lead_product_id: "", responsible_id: "", page: 1, per_page: 10 });
  const [projects, setProjects] = useState([]);
  const [total, setTotal] = useState();

  const user = useSelector((state) => state.Auth.user);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const query = { status: "active" };
        if (filters.search) query.search = filters.search;
        if (filters.lead_id) query.lead_id = filters.lead_id;
        if (filters.lead_product_id) query.lead_product_id = filters.lead_product_id;
        if (filters.responsible_id) query.responsible_id = filters.responsible_id;
        if (filters.types) query.types = filters.types;
        if (user.role === "client") query.client_id = user._id;

        query.page = filters.page;
        query.per_page = filters.per_page;

        const { ok, data } = await api.post("/project/search", query);
        if (!ok) throw new Error("Failed to fetch projects");
        setProjects(data.projects);
        setTotal(data.total);
      } catch (error) {
        console.error(error);
        toast.error("Failed to fetch projects");
      }
    };
    fetchData();
  }, [filters]);

  return (
    <div className="py-8 px-10">
      <div className="flex items-center justify-between mb-4">
        <h1 className="text-2xl font-semibold">{`${total === undefined ? `Loading...` : `${total} projects`} `}</h1>
        <CreateProject />
      </div>
      <section>
        <div className="flex items-center justify-between gap-4 mb-4">
          <div className="flex-1 space-y-1">
            <DebounceInput debounce={300} value={filters.search} onChange={(e) => setFilters({ ...filters, search: e.target.value })} className="input" placeholder="Search" />
          </div>
          <div className="w-1/5 space-y-1">
            <SelectUser
              placeholder="Lead dev"
              value={{ _id: filters.lead_id, name: filters.lead_name, avatar: filters.lead_avatar }}
              onChange={(user) => setFilters({ ...filters, lead_id: user?._id, lead_name: user?.name, lead_avatar: user?.avatar })}
            />
          </div>
          <div className="w-1/5 space-y-1">
            <SelectUser
              placeholder="Lead product"
              value={{ _id: filters.lead_product_id, name: filters.lead_product_name, avatar: filters.lead_product_avatar }}
              onChange={(user) => setFilters({ ...filters, lead_product_id: user?._id, lead_product_name: user?.name, lead_product_avatar: user?.avatar })}
            />
          </div>
          <div className="w-1/5 space-y-1">
            <div className="w-full">
              <MultiSelect
                id="select-type"
                options={["prospection", "startup-project", "startup-invest", "admin"].map((type) => ({ value: type, label: type }))}
                onSelectedChange={(e) => setFilters((f) => ({ ...f, types: e.map((e) => e.value) }))}
                placeholder="type"
              />
            </div>
          </div>
        </div>
        <div className="space-y-6">
          {(projects || []).map((project) => (
            <Project project={project} key={project._id} />
          ))}
          <div className="flex justify-center mx-[25%]">
            <Pagination page={filters.page} setPage={(page) => setFilters({ ...filters, page })} last={Math.ceil(total / filters.per_page)} />
          </div>
        </div>
      </section>
    </div>
  );
};

const Project = ({ project }) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.Auth.user);
  return (
    <div
      key={project._id}
      onClick={() => navigate(`/project/${project._id}/backlog`)}
      className="grid grid-cols-12 items-center justify-between bg-white rounded-lg cursor-pointer border border-gray-200 hover:border-gray-300">
      <div className="col-span-8 flex items-center gap-x-6 p-4">
        <img className="h-12 w-12 flex-none rounded-md bg-gray-50 object-contain" src={project.logo} alt="project logo" />
        <div className="min-w-0">
          <div className="flex items-start gap-x-3 flex-wrap">
            <p className="text-base font-semibold leading-6 text-gray-900">{project.name}</p>
          </div>

          <div className="flex flex-row items-center mt-2">
            {project.lead_avatar && (
              <div className="flex items-center gap-x-2 mr-2">
                <p className="text-sm font-semibold text-gray-700">{"Lead dev : "}</p>
                <img src={project.lead_avatar} alt="lead avatar" className="h-6 w-6 rounded-full" />
              </div>
            )}
            {project.lead_product_avatar && (
              <div className="flex items-center gap-x-2 mr-2 ml-4">
                <p className="text-sm font-semibold text-gray-700">{"Lead product : "}</p>
                <img src={project.lead_product_avatar} alt="lead avatar" className="h-6 w-6 rounded-full" />
              </div>
            )}
          </div>
          <div className="hidden sm:block mt-1 leading-5">
            {(project.description ? project.description : "").split("\n").map((line, i) => (
              <p key={i} className="text-sm font-medium text-gray-700">
                {line}
              </p>
            ))}
          </div>
        </div>
      </div>

      <div className="col-span-4 w-full p-4">
        <Budget project={project} />
      </div>
    </div>
  );
};
