import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { HiMagnifyingGlass } from "react-icons/hi2";
import { MdUpload } from "react-icons/md";

import Loader from "../../components/loader";
import Modal from "../../components/modal";
import api from "../../services/api";
import { readFileAsync } from "../../utils";

export default () => {
  const [assets, setAssets] = useState();
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [filter, setFilter] = useState({ search: "" });

  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    get();
    setCurrentPage(1);
  }, [filter]);

  useEffect(() => {
    get();
  }, [currentPage]);

  const get = async () => {
    try {
      const { data, ok, code } = await api.post("/asset/search", { search: filter.search, limit: 1000 });
      if (!ok) return toast.error("Error!" || code);
      setAssets(data);
    } catch (error) {
      console.log(error);
      return toast.error("Error fetching audits!");
    }
  };

  if (!assets) return <Loader />;

  return (
    <div className="mx-auto p-4">
      <div className="flex items-center justify-between mb-5">
        <div className="relative w-96">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <HiMagnifyingGlass className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
          <input
            type="text"
            name="search"
            value={filter.search}
            onChange={(e) => setFilter({ ...filter, search: e.target.value })}
            className="block w-full rounded-lg border border-gray-300 bg-white py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
            placeholder="Search ..."
          />
        </div>
        <div>
          <div className={`relative rounded-xl overflow-hidden border border-gray-200 `}>
            <label htmlFor={`openFile`} className="px-3 py-2 w-full h-full hover:backdrop-blur-sm cursor-pointer flex flex-col items-center justify-center">
              <MdUpload />
              <span className="text-sm">Upload</span>
            </label>
            <input
              id={`openFile`}
              type="file"
              onChange={async (e) => {
                e.persist();
                const file = e.target.files[0];
                const rawBody = await readFileAsync(file);
                const { data: url } = await api.post(`/file`, { file: { rawBody, name: file.name }, folder: "/assets" });
                const { data } = await api.post("/asset", { url, name: file.name, size: file.size });
                toast.success("File uploaded successfully");
                await get();
                setSelectedAsset(data);
              }}
            />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-8 gap-4 max-w-7xl mx-auto">
        {assets.map((asset) => {
          return (
            <div 
              key={asset._id} 
              className="relative group border border-gray-300 rounded-lg p-4 cursor-pointer transition-all duration-200 hover:shadow-lg hover:border-blue-500"
              onClick={() => setSelectedAsset(asset)}
            >
              <div className="aspect-square overflow-hidden rounded-md">
                <img 
                  src={asset.url} 
                  alt={asset.name} 
                  className="w-full h-full object-cover transition-transform duration-200 group-hover:scale-105" 
                />
              </div>
              <div className="mt-3 space-y-1">
                <p className="font-medium text-gray-900 truncate">{asset.name}</p>
              </div>
              <div className="absolute inset-0 bg-black/50 opacity-0 group-hover:opacity-100 transition-opacity duration-200 rounded-lg flex items-center justify-center">
                <div className="text-white text-center">
                  <p className="font-medium">{asset.name}</p>
                  <p className="text-sm">{formatFileSize(asset.size)}</p>
                  <p className="text-sm">{new Date(asset.created_at).toLocaleDateString()}</p>
                  {asset.description && (
                    <p className="text-sm mt-2 italic">{asset.description}</p>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <ViewModal
        asset={selectedAsset}
        setAsset={(e) => {
          setSelectedAsset(e);
          get();
        }}
      />
    </div>
  );
};

const formatFileSize = (bytes) => {
  if (!bytes) return "0 Bytes";
  const k = 1024;
  const sizes = ["Bytes", "KB", "MB", "GB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(2))} ${sizes[i]}`;
};

const ViewModal = ({ asset, setAsset }) => {
  const [values, setValues] = useState(null);

  useEffect(() => {
    setValues(asset);
  }, [asset]);

  if (!values) return null;

  const handleCopyUrl = () => {
    navigator.clipboard.writeText(asset.url);
    toast.success("URL copied to clipboard!");
  };

  const handleDelete = async () => {
    if (!window.confirm("Are you sure you want to delete this asset?")) return;

    const { ok, code } = await api.remove(`/asset/${asset._id}`);
    if (!ok) return toast.error("Error deleting asset" || code);

    toast.success("Asset deleted successfully!");
    setAsset(null);
  };

  const handleSave = async () => {
    const { ok, code } = await api.put(`/asset/${asset._id}`, values);
    if (!ok) return toast.error("Error updating asset" || code);
    toast.success("Asset updated successfully!");
  };

  return (
    <Modal isOpen={true} className="!w-[90vw] md:!w-[700px] p-6" onClose={() => setAsset(null)}>
      <div className="space-y-4">
        <img src={values.url} alt={values.name} className="max-h-[50vh]" onClick={handleCopyUrl} />
        <div className="text-sm text-gray-500 cursor-pointer hover:text-gray-700" onClick={handleCopyUrl}>
          {values.url}
        </div>
        <div className="space-y-2">
          <input
            type="text"
            value={values.name}
            onChange={(e) => setValues({ ...values, name: e.target.value })}
            className="block w-full rounded-lg border border-gray-300 px-3 py-2"
            placeholder="Asset name"
          />
          <textarea
            value={values.description}
            onChange={(e) => setValues({ ...values, description: e.target.value })}
            className="block w-full rounded-lg border border-gray-300 px-3 py-2"
            placeholder="Asset description"
            rows={3}
          />
        </div>
        <div className="flex justify-between">
          <button onClick={handleDelete} className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600">
            Delete
          </button>
          <button onClick={handleSave} className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">
            Save Changes
          </button>
        </div>
      </div>
    </Modal>
  );
};
