import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import { HiMagnifyingGlass } from "react-icons/hi2";
import { GrFormClose } from "react-icons/gr";
import DebounceInput from "react-debounce-input";
import { IoCopyOutline } from "react-icons/io5";
import { IoIosArrowBack } from "react-icons/io";
import { useDispatch } from "react-redux";

import Loader from "../../../components/loader";
import { copyToClipboard } from "../../../utils";
import { RiThumbUpLine, RiThumbUpFill } from "react-icons/ri";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

import api from "../../../services/api";

const MAX_PEOPLE_TO_SHOW = 15;

const Available = () => {
  const navigate = useNavigate();
  const [availableUsers, setAvailableUsers] = useState(null);
  const [total, setTotal] = useState(null);
  const [loading, setLoading] = useState(false);
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const [filters, setFilters] = useState();
  const [minimized, setMinimized] = useState(true);

  const getUsers = async () => {
    setLoading(true);
    const { data, ok } = await api.get(`/user/available?search=${filters.search}`);

    if (!ok) return toast.error("An error occured while fetching users");
    setLoading(false);
    setAvailableUsers(data.users);
    setTotal(data.total);
  };

  useEffect(() => {
    if (!filters) return;
    getUsers();
    if (filters.search) query.set("search", filters.search);
    else query.delete("search");
    navigate({ pathname: location.pathname, search: query.toString() });
  }, [filters]);

  React.useEffect(() => {
    (async () => {
      const _filters = { search: "" };
      if (query.get("search")) _filters.search = query.get("search");
      setFilters(_filters);
    })();
  }, []);

  if (!availableUsers) return <Loader />;
  return (
    <div className="bg-white border-[1px] border-gray-200 rounded-md overflow-hidden">
      {/* TODO */}
      {/* <UserPreview applicant={selected} setApplicant={setSelected} onClose={() => setSelected(null)} /> */}
      <div className="p-3 md:flex-auto">
        <h1 className="text-base font-semibold leading-6 text-gray-900">{total} people available</h1>
        <p className="mt-2 text-sm text-gray-700">A list of all the users currently available, do not hesitate to contact them on slack !</p>
      </div>
      <div className="px-3 flex gap-1 items-center">
        <div className="relative rounded-md shadow-sm w-full">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <HiMagnifyingGlass className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
          <DebounceInput
            debounceTimeout={300}
            name="search"
            id="email"
            className="block w-full rounded-md border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 md:text-sm"
            placeholder="Search (skills, job title, name)"
            value={filters.search}
            onChange={(e) => {
              e.persist();
              setFilters((f) => ({ ...f, search: e.target.value }));
            }}
          />
        </div>
        {loading ? <Loader size="small" /> : null}
      </div>
      <div className="mt-2 flow-root">
        <div className="overflow-x-auto">
          <div className="bg-white inline-block min-w-full align-middle">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th scope="col" className="py-2 px-3 text-left text-sm font-semibold text-gray-900">
                    Name
                  </th>
                  <th scope="col" className="py-2 px-3 text-left text-sm font-semibold text-gray-900">
                    Contract
                  </th>
                  <th scope="col" className="py-2 px-3 text-left text-sm font-semibold text-gray-900">
                    Job Title
                  </th>
                  <th scope="col" className="py-2 px-3 text-left text-sm font-semibold text-gray-900">
                    Available since
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {availableUsers.slice(0, minimized ? MAX_PEOPLE_TO_SHOW : undefined).map((availableUser) => (
                  <tr key={availableUser.email} className="even:bg-gray-100">
                    <td
                      className="whitespace-nowrap py-1 px-3 text-sm font-semibold text-gray-900 cursor-pointer group"
                      onClick={() => {
                        query.set("user_modal_id", availableUser._id);
                        query.set("user_modal_tab", "overview");
                        navigate({ search: query.toString() });
                      }}>
                      <div className="flex items-center gap-1">
                        <img src={availableUser.avatar} alt="userlogo" className="rounded-full w-7 h-7" />
                        <div>
                          <div className="group-hover:underline">{availableUser.name}</div>
                          <div className="flex items-center gap-1 font-normal text-xs text-gray-700">
                            {availableUser.email}
                            <button
                              className="hover:bg-gray-100 cursor-pointer rounded-lg px-2 py-1"
                              onClick={(e) => {
                                e.stopPropagation();
                                copyToClipboard(availableUser.email);
                                toast.success("mail copied to clipboard");
                              }}>
                              <IoCopyOutline />
                            </button>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="whitespace-nowrap py-1 px-3 text-xs font-normal text-gray-900">
                      <div className="group-hover:underline">{availableUser.contract}</div>
                      {availableUser.contract === "FREELANCE" && <div className="flex items-center gap-1 font-normal text-xs text-gray-700">{availableUser.payment_type}</div>}
                    </td>
                    <td className="whitespace-nowrap py-1 px-3 text-sm font-normal text-gray-900">{availableUser.job_title || <Empty />}</td>

                    <td className="whitespace-nowrap py-1 px-3 text-sm font-normal text-gray-900">
                      {new Date(availableUser.available_since).toLocaleDateString()}, {new Date(availableUser.available_since).toLocaleTimeString()}
                    </td>
                  </tr>
                ))}
                {availableUsers?.length > MAX_PEOPLE_TO_SHOW ? (
                  <tr className="">
                    <td colSpan="3">
                      <div
                        onClick={() => setMinimized((e) => !e)}
                        className="flex w-full gap-1 justify-center items-center py-2 px-3 text-sm text-gray-700 md:pl-3 pl-0 cursor-pointer hover:underline">
                        {minimized ? (
                          <>
                            See more ({availableUsers.length - MAX_PEOPLE_TO_SHOW})
                            <IoIosArrowDown />
                          </>
                        ) : (
                          <>
                            See less
                            <IoIosArrowUp />
                          </>
                        )}
                      </div>
                    </td>
                  </tr>
                ) : null}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

const Skills = ({ skills }) => {
  const [displayFullList, setDisplayFullList] = useState(false);
  if (!skills || skills.length === 0) return <Empty />;

  const Skill = ({ skill }) => (
    <span className="bg-orange-50 text-orange-800 rounded-sm px-2 py-1 border-[1px] border-orange-200 text-xs flex items-center gap-2">
      {skill.name}
      {skill.recommandations?.length ? (
        <div className="flex items-center gap-1">
          <RiThumbUpFill className="text-orange-800" />
          <span className="text-xs text-orange-800">{skill.recommandations?.length}</span>
        </div>
      ) : null}
    </span>
  );

  return (
    <div className="flex items-center gap-1 flex-wrap">
      {displayFullList ? (
        <>
          {skills
            .sort((a, b) => b.recommandations?.length - a.recommandations?.length)
            .map((skill) => (
              <Skill skill={skill} />
            ))}
          <button
            onClick={() => setDisplayFullList(false)}
            className="bg-orange-50 text-orange-800 rounded-sm px-2 py-1 border-[1px] border-orange-200 text-xs flex items-center gap-2">
            <IoIosArrowBack />
          </button>
        </>
      ) : (
        <>
          {skills.slice(0, 3).map((skill, i) => (
            <Skill key={i} skill={skill} />
          ))}
          {skills.sort((a, b) => b.recommandations?.length - a.recommandations?.length).slice(3, skills.length)?.length ? (
            <button
              onClick={() => setDisplayFullList(true)}
              className="bg-orange-50 text-orange-800 rounded-sm px-2 py-1 border-[1px] border-orange-200 text-xs flex items-center gap-2">
              +{skills.slice(3, skills.length)?.length}
            </button>
          ) : null}
        </>
      )}
    </div>
  );
};

const Empty = () => <span className="px-3 py-1 bg-gray-50 text-gray-400 rounded-lg border-[1px] border-gray-100 text-xs">Empty</span>;

export default Available;
