import React, { useEffect, useState } from "react";
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import MultiAction from "../../components/MultiAction";
import Contacts from "./contacts";
import Companies from "./companies";
import Sources from "./sources";
import Queue from "./queue";
import Activities from "./activities";
import Pipe from "./pipe";
import Intrapreneurship from "./intrapreneurship";
import Stats from "./stats";
import Actions from "./action";
import BusinessIntro from "./business_intro";
import Corporate from "./corporate";
import Metabase from "./metabase";
import Incubator from "./incubator";
import Startupearly from "./startupearly";
import StartupsAdvanced from "./startupadvanced";
import Government from "./government";

export default () => {
  const navigate = useNavigate();

  console.log("COUCOU acquiz");

  return (
    <div>
      <div className="m-2">
        <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700 flex justify-between p-2">
          <ul className="flex flex-wrap -mb-px list-none space-x-2">
            <li onClick={(e) => navigate(`/acquiz/startupearly`)} className={`inline-block p-2 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 cursor-pointer dark:hover:text-gray-300 ${false && "border-primary"}`}>Startupearly</li>
            <li onClick={(e) => navigate(`/acquiz/startups_advanced`)} className={`inline-block p-2 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 cursor-pointer dark:hover:text-gray-300 ${false && "border-primary"}`}>Startups advanced</li>
            <li onClick={(e) => navigate(`/acquiz/business_intro`)} className={`inline-block p-2 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 cursor-pointer dark:hover:text-gray-300 ${false && "border-primary"}`}>Business intro</li>
            <li onClick={(e) => navigate(`/acquiz/incubator`)} className={`inline-block p-2 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 cursor-pointer dark:hover:text-gray-300 ${false && "border-primary"}`}>Incubator</li>
            <li onClick={(e) => navigate(`/acquiz/corporate`)} className={`inline-block p-2 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 cursor-pointer dark:hover:text-gray-300 ${false && "border-primary"}`}>Corporate</li>
            <li onClick={(e) => navigate(`/acquiz/metabase`)} className={`inline-block p-2 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 cursor-pointer dark:hover:text-gray-300 ${false && "border-primary"}`}>Metabase</li>
            <li onClick={(e) => navigate(`/acquiz/government`)} className={`inline-block p-2 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 cursor-pointer dark:hover:text-gray-300 ${false && "border-primary"}`}>Government</li>
            <li onClick={(e) => navigate(`/acquiz/intrapreneurship`)} className={`inline-block p-2 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 cursor-pointer dark:hover:text-gray-300 ${false && "border-primary"}`}>Intrapreneurship</li>
          </ul>
          <MultiAction
            actions={[
              { name: "Pipe", cb: () => navigate("/acquiz/pipe") },
              { name: "Contacts", cb: () => navigate("/acquiz/contacts") },
              { name: "Companies", cb: () => navigate("/acquiz/companies") },
              { name: "Sources", cb: () => navigate("/acquiz/sources") },
              { name: "Stats", cb: () => navigate("/acquiz/stats") },
              { name: "Activities", cb: () => navigate("/acquiz/activities") },
              { name: "Queue", cb: () => navigate("/acquiz/queue") }, 
              { name: "Actions", cb: () => navigate("/acquiz/actions") },
            ]}
          />
        </div>
        <Routes>
          <Route path="/" element={<Navigate to="/acquiz/contacts" replace />} />
          <Route path="/contacts/*" element={<Contacts />} />
          <Route path="/business_intro/*" element={<BusinessIntro />} />
          <Route path="/companies/*" element={<Companies />} />
          <Route path="/pipe/*" element={<Pipe />} />
          <Route path="/stats/*" element={<Stats />} />
          <Route path="/incubator/*" element={<Incubator />} />
          <Route path="/government/*" element={<Government />} />
          <Route path="/startupearly/*" element={<Startupearly />} />
          <Route path="/startups_advanced/*" element={<StartupsAdvanced />} />
          <Route path="/corporate/*" element={<Corporate />} />
          <Route path="/metabase/*" element={<Metabase />} />
          <Route path="/intrapreneurship/*" element={<Intrapreneurship />} />
          <Route path="/sources/*" element={<Sources />} />
          <Route path="/activities/*" element={<Activities />} />
          <Route path="/queue/*" element={<Queue />} />
          <Route path="/actions/*" element={<Actions />} />
        </Routes>
      </div>
    </div>
  );
};
