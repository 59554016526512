import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";

import Modal from "../../../../components/modal";
import api from "../../../../services/api";

import Company from "./company";
import Contacts from "./contacts";
import Contact from "./contact";
import Messages from "./messages";

export default ({ company, setCompany, contact, setContact, onClose }) => {
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);

  useEffect(() => {
    load();
  }, [contact, company]);

  async function load() {
    setSelectedCompany(null);
    setSelectedUser(null);

    if (contact) {
      setSelectedUser(contact);
      if (!company && contact.companies.length) {
        const res = await api.get(`/s_company/${contact.companies[0]?.company_id}`);
        if (!res.ok) return toast.error("Error fetching company");
        setSelectedCompany(res.data);
      }
    }
    if (company) {
      setSelectedCompany(company);
    }
  }

  if (!company && !contact) return <div />;

  console.log("selectedCompany", selectedCompany);

  return (
    <Modal
      isOpen={true}
      onClose={() => {
        onClose();
        if (setCompany) setCompany(null);
        if (setContact) setContact(null);
        setSelectedUser(null);
      }}>
      <div className="w-full h-screen m-4bg-[#FBFBF9] overflow-y-auto p-4">
        <div className="grid grid-cols-4 gap-4">
          <Company company={selectedCompany} setCompany={setSelectedCompany} onClick={() => setSelectedUser(null)} />
          {!selectedUser && <Contacts company={selectedCompany} selectedUser={selectedUser} setSelectedUser={setSelectedUser} />}
          {selectedUser && <Contact selectedUser={selectedUser} setSelectedUser={setSelectedUser} />}
          {selectedUser && (
            <div className="col-span-2">
              <Messages company={company} user={selectedUser} />
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};
