import React, { useState } from "react";
import TabItem from "../../components/TabItem";
import BusinessOpportunities from "./business-opportunities";
import ByPerson from "./by-person";
import ByProject from "./by-project";
import Stats from "./stats";
import UnpaidActivities from "./unpaid-activities";
import PaidActivities from "./paid-activities";


const BUSINESS_OPPORTUNITIES = "BUSINESS_OPPORTUNITIES";
const BY_PERSON = "BY_PERSON";
const BY_PROJECT = "BY_PROJECT"
const STATS = "STATS"
const UNPAID_ACTIVITIES = "UNPAID_ACTIVITIES"
const PAID_ACTIVITIES = "PAID_ACTIVITIES"

export default () => {
  const [activeTab, setActiveTab] = useState(BY_PROJECT);

  return (
    <div className="bg-white h-full">
      <div className="border-b border-gray-200">
        <TabItem title="By Person" tab={BY_PERSON} onClick={() => setActiveTab(BY_PERSON)} active={activeTab === BY_PERSON} />
        <TabItem title="By Project" tab={BY_PROJECT} onClick={() => setActiveTab(BY_PROJECT)} active={activeTab === BY_PROJECT} />
        <TabItem title="Stats" tab={STATS} onClick={() => setActiveTab(STATS)} active={activeTab === STATS} />
        <TabItem title="Unpaid Activities" tab={UNPAID_ACTIVITIES} onClick={() => setActiveTab(UNPAID_ACTIVITIES)} active={activeTab === UNPAID_ACTIVITIES} />
        <TabItem title="Paid Activities" tab={PAID_ACTIVITIES} onClick={() => setActiveTab(PAID_ACTIVITIES)} active={activeTab === PAID_ACTIVITIES} />
        <TabItem title="Business Opportunities" tab={BUSINESS_OPPORTUNITIES} onClick={() => setActiveTab(BUSINESS_OPPORTUNITIES)} active={activeTab === BUSINESS_OPPORTUNITIES} />

      </div>
      <div className="p-4">
        {activeTab === BY_PERSON && <ByPerson />}
        {activeTab === BY_PROJECT && <ByProject />}
        {activeTab === BUSINESS_OPPORTUNITIES && <BusinessOpportunities />}
        {activeTab === STATS && <Stats />}
        {activeTab === UNPAID_ACTIVITIES && <UnpaidActivities />}
        {activeTab === PAID_ACTIVITIES && <PaidActivities />}
      </div>
    </div>
  );
};
