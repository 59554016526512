import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

import Loader from "../../../components/loader";
import Comments from "../../../components/Comments";

import api from "../../../services/api";

export default ({ alert, setAlert }) => {
  const { id } = useParams();
  const user = useSelector((state) => state.Auth.user);
  const navigate = useNavigate();

  useEffect(() => {
    get();
  }, [id]);

  async function get() {
    const { data } = await api.get(`/alert/${id}`);
    setAlert(data);
  }

  async function handleSubmit() {
    try {
      const { data } = await api.put(`/alert/${id}`, alert);
      setAlert(data);
      toast.success("Alert updated!");
    } catch (e) {
      toast.error("An error occurred");
      console.log(e);
    }
  }

  async function onDelete() {
    if (!window.confirm("Are you sure you want to delete this alert?")) return;
    await api.remove(`/alert/${alert._id}`);
    toast.success("Alert deleted!");
    navigate("/alert");
  }

  if (!alert) return <Loader />;

  return (
    <div className="flex flex-col gap-8 p-4">
      <div className="flex-1 flex flex-col gap-6">
        <div className="w-full space-y-6">
          <div>
            <label htmlFor="name" className="block text-sm font-medium text-gray-700">
              Name
            </label>
            <input
              id="name"
              type="text"
              value={alert.name || ""}
              onChange={(e) => setAlert({ ...alert, name: e.target.value })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
              placeholder="Enter alert name"
            />
          </div>
          <div>
            <label htmlFor="description" className="block text-sm font-medium text-gray-700">
              Description
            </label>
            <textarea
              id="description"
              value={alert.description || ""}
              onChange={(e) => setAlert({ ...alert, description: e.target.value })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
              rows={4}
              placeholder="Enter alert description"
            />
          </div>
          <div>
            <label htmlFor="type" className="block text-sm font-medium text-gray-700">
              Type
            </label>
            <input
              id="name"
              type="text"
              value={alert.type || ""}
              onChange={(e) => setAlert({ ...alert, type: e.target.value })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
              placeholder="Enter alert name"
            />
          </div>
        </div>
        <div className="flex gap-3">
          <button className="btn blue-btn flex items-center gap-2" onClick={handleSubmit}>
            <span>Update Alert</span>
          </button>
          <button className="btn red-btn flex items-center gap-2" onClick={onDelete}>
            <span>Delete Alert</span>
          </button>
        </div>
      </div>
    </div>
  );
};
