import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import api from "../../../services/api";
import { useDispatch, useSelector } from "react-redux";
import { IoClose } from "react-icons/io5";

import { BarChart, Bar, CartesianGrid, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, ComposedChart } from "recharts";
import { useNavigate } from "react-router-dom";

export default function BudgetNormal({ project }) {
  const [selectedBudget, setSelectedBudget] = useState(null);
  const [filters, setFilters] = useState({ status: "active" });
  const [loading, setLoading] = useState(false);
  const [budgets, setBudgets] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const query = { projectId: project._id };
        if (filters.status) query.status = filters.status;
        const res = await api.post(`/budget/search`, query);
        if (!res.ok) throw new Error("Failed to fetch budgets");
        setBudgets(res.data);
      } catch (error) {
        console.error(error);
        toast.error("Failed to fetch data");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [project, filters]);

  return (
    <div className="space-y-6">
      <div className="flex gap-3">
        <div className="w-1/3 rounded-md bg-white border flex flex-col gap-4 p-2">
          <div className="space-y-4">
            <div className="flex items-center gap-2">
              <div className="flex rounded-lg overflow-hidden border">
                <button
                  onClick={() => setFilters({ ...filters, status: "active" })}
                  className={`px-4 py-2 text-sm transition-colors ${filters.status === "active" ? "blue-btn" : "bg-white text-gray-600 hover:bg-gray-50"}`}>
                  Active
                </button>
                <button
                  onClick={() => setFilters({ ...filters, status: "inactive" })}
                  className={`px-4 py-2 text-sm transition-colors ${filters.status === "inactive" ? "blue-btn" : "bg-white text-gray-600 hover:bg-gray-50"}`}>
                  Inactive
                </button>
                <button
                  onClick={() => setFilters({ ...filters, status: "" })}
                  className={`px-4 py-2 text-sm transition-colors ${filters.status === "" ? "blue-btn" : "bg-white text-gray-600 hover:bg-gray-50"}`}>
                  All
                </button>
              </div>
            </div>
            <div className="divide-y">
              {loading ? (
                <div className="text-center py-8 text-gray-500">Loading...</div>
              ) : budgets.length === 0 ? (
                <div className="text-center py-8 text-gray-500">No budgets found</div>
              ) : (
                budgets.map((item, index) => <BudgetRow key={item._id} item={item} isSelected={item._id === selectedBudget?._id} setSelectedBudget={setSelectedBudget} />)
              )}
            </div>
          </div>
        </div>
        <div className="w-2/3 rounded-md bg-white border flex flex-col gap-4 p-2">
          {selectedBudget ? (
            <Budget selectedBudget={selectedBudget} setSelectedBudget={setSelectedBudget} budgets={budgets} project={project} />
          ) : (
            <Overview budgets={budgets} project={project} />
          )}
        </div>
      </div>
    </div>
  );
}

const BudgetRow = ({ item, setSelectedBudget, isSelected }) => {
  const daysRemaining = Math.ceil((new Date(item.endAt) - new Date()) / (1000 * 60 * 60 * 24));
  const percentageUsed = (item.amountUsed / item.amount) * 100;

  return (
    <div
      className={`${
        isSelected ? "bg-blue-50 border-l-4 border-l-blue-500" : item.status === "inactive" ? "bg-gray-50" : "bg-white"
      } cursor-pointer hover:bg-blue-50/50 transition-all duration-200`}
      onClick={() => setSelectedBudget(isSelected ? null : item)}>
      <div className="p-4 space-y-3">
        <div className="flex justify-between items-start">
          <div>
            <h4 className="font-medium text-gray-900">{item.name}</h4>
            {item.status === "inactive" && <span className="inline-block px-2 py-1 text-xs font-medium text-gray-500 bg-gray-100 rounded-full mt-1">Inactive</span>}
            {item.signed_at ? (
              <span className="inline-block px-2 py-1 text-xs font-medium text-gray-500 bg-gray-100 rounded-full mt-1">Signed</span>
            ) : (
              <span className="inline-block px-2 py-1 text-xs font-medium text-gray-500 bg-gray-100 rounded-full mt-1">Not signed</span>
            )}
          </div>
          <div className={`text-sm font-medium ${daysRemaining < 30 ? "text-red-600" : "text-gray-600"}`}>{daysRemaining} days remaining</div>
        </div>

        <div className="space-y-2">
          <ProgressBar value={percentageUsed} />
          <div className="flex justify-between text-sm">
            <div className="space-x-4">
              <span className="font-medium">
                {(item.amountUsed || 0).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })} /{" "}
                {(item.amount || 0).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}
              </span>
            </div>
            <div className="text-gray-500 space-x-2">
              <span>{new Date(item.startAt).toLocaleDateString()}</span>
              <span>→</span>
              <span>{new Date(item.endAt).toLocaleDateString()}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ProgressBar = ({ value }) => {
  const getBarColor = (percentage) => {
    if (percentage >= 100) return "bg-red-600";
    if (percentage >= 80) return "bg-orange-500";
    if (percentage >= 60) return "bg-yellow-500";
    return "bg-green-500";
  };

  return (
    <div className="w-full flex items-center gap-2">
      <div className="w-full h-2.5 bg-gray-200 rounded-full overflow-hidden">
        <div className={`h-full rounded-full transition-all duration-300 ${getBarColor(value)}`} style={{ width: `${Math.min(value, 100)}%` }} />
      </div>
      <span className="text-sm font-medium min-w-[3rem]">{Math.round(value)}%</span>
    </div>
  );
};

const Budget = ({ selectedBudget, setSelectedBudget, project }) => {
  const [loading, setLoading] = useState(false);
  const [activities, setActivities] = useState([]);
  const [banks, setBanks] = useState([]);
  const [showSendSigningLinks, setShowSendSigningLinks] = useState(false);
  const [selectedRecipients, setSelectedRecipients] = useState([]);
  const [sendLinkLoading, setSendLinkLoading] = useState(false);
  const user = useSelector((state) => state.Auth.user);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      if (!selectedBudget) return;
      setLoading(true);
      try {
        const [activitiesRes, banksRes] = await Promise.all([
          api.post(`/activity/search`, { projectId: selectedBudget.projectId, budgetId: selectedBudget._id }),
          api.post(`/bank/search`, { projectId: selectedBudget.projectId, budgetId: selectedBudget._id }),
        ]);
        if (activitiesRes.ok) setActivities(activitiesRes.data);
        let banks2 = banksRes.data;
        banks2 = banks2.banks.filter((bank) => bank.category !== "INCOME");
        console.log(banks2);
        if (banksRes.ok) setBanks(banks2);
      } catch (error) {
        console.error(error);
        toast.error("Failed to fetch budget details");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [selectedBudget]);

  const handleSendSigningLinks = async () => {
    try {
      setSendLinkLoading(true);

      if (selectedRecipients.length === 0) {
        toast.error("Please select at least one recipient");
        return;
      }
      const response = await api.post(`/budget/${selectedBudget._id}/send-signing-links`, {
        recipients: selectedRecipients,
      });

      if (response.ok) {
        toast.success("Signing links sent successfully");
        setShowSendSigningLinks(false);
        setSelectedRecipients([]);
      } else {
        throw new Error("Failed to send signing links");
      }
    } catch (error) {
      console.error(error);
      toast.error("Failed to send signing links");
    } finally {
      setSendLinkLoading(false);
    }
  };

  if (loading) return <div className="text-center py-8 text-gray-500">Loading...</div>;
  if (!selectedBudget) return <div />;

  const totalAmountActivities = activities?.map((activity) => Math.abs(activity.value)).reduce((acc, curr) => acc + curr, 0) || 0;
  const totalAmountBanks = banks?.banks?.reduce((acc, { amount }) => acc + Math.abs(amount), 0) || 0;
  const totalAmount = selectedBudget?.amount || 0;

  const finalRemainingAmount = Math.max(totalAmount - totalAmountActivities - totalAmountBanks, 0);

  const activitiesByMonth = activities.reduce((acc, activity) => {
    const month = new Date(activity.date).toLocaleString("default", { month: "long", year: "numeric" });
    if (!acc[month]) acc[month] = [];
    acc[month].push(activity);
    return acc;
  }, {});

  const banksByMonth =
    banks?.banks?.reduce((acc, bank) => {
      const month = new Date(bank.date).toLocaleString("default", { month: "long", year: "numeric" });
      if (!acc[month]) acc[month] = [];
      acc[month].push(bank);
      return acc;
    }, {}) || {};

  const allMonths = (() => {
    const monthsSet = new Set();
    const start = new Date(selectedBudget.startAt);
    const end = new Date(selectedBudget.endAt);

    // Add all months between start and end dates
    for (let date = new Date(start); date <= end; date.setMonth(date.getMonth() + 1)) {
      monthsSet.add(new Date(date).toLocaleString("default", { month: "long", year: "numeric" }));
    }

    // Add months from activities
    activities.forEach((activity) => {
      const activityMonth = new Date(activity.date).toLocaleString("default", { month: "long", year: "numeric" });
      monthsSet.add(activityMonth);
    });

    // Add months from banks
    banks.forEach((bank) => {
      const bankMonth = new Date(bank.date).toLocaleString("default", { month: "long", year: "numeric" });
      monthsSet.add(bankMonth);
    });

    // Convert to array and sort chronologically
    return Array.from(monthsSet).sort((a, b) => {
      const dateA = new Date(a.split(" ").reverse().join(" "));
      const dateB = new Date(b.split(" ").reverse().join(" "));
      return dateA - dateB;
    });
  })();

  const monthlyData = allMonths.reduce((acc, month, index) => {
    const currentDate = new Date();
    const monthDate = new Date(month.split(" ").reverse().join(" "));
    const isInFuture = monthDate > currentDate;

    // Only calculate actuals if the month is not in the future
    const activitiesTotal = isInFuture ? 0 : (activitiesByMonth[month] || []).reduce((sum, activity) => sum + Math.abs(activity.value), 0);
    const banksTotal = isInFuture ? 0 : (banksByMonth[month] || []).reduce((sum, bank) => sum + Math.abs(bank.amount), 0);

    // Calculate expected spending only for months within budget date range
    const startDate = new Date(selectedBudget.startAt);
    startDate.setDate(1); // Set to first day of month
    startDate.setHours(0, 0, 0, 0);

    const endDate = new Date(selectedBudget.endAt);
    endDate.setDate(endDate.getDate()); // Keep the end date as is
    endDate.setHours(23, 59, 59, 999);

    const monthStartDate = new Date(monthDate);
    monthStartDate.setDate(1);
    monthStartDate.setHours(0, 0, 0, 0);

    const isWithinBudgetRange = monthStartDate >= startDate && monthStartDate <= endDate;

    const totalMonths = (endDate.getFullYear() - startDate.getFullYear()) * 12 + (endDate.getMonth() - startDate.getMonth()) + 1;
    const expectedMonthlySpending = isWithinBudgetRange ? totalAmount / totalMonths : 0;

    const previousCumulative = index > 0 ? acc[index - 1].cumulativeActual : 0;
    const previousExpectedCumulative = index > 0 ? acc[index - 1].cumulativeExpected : 0;

    return [
      ...acc,
      {
        month,
        actual: activitiesTotal + banksTotal,
        expected: expectedMonthlySpending,
        cumulativeActual: isInFuture ? 0 : previousCumulative + activitiesTotal + banksTotal,
        cumulativeExpected: previousExpectedCumulative + expectedMonthlySpending,
      },
    ];
  }, []);

  const currentMonth = new Date().toLocaleString("default", { month: "long", year: "numeric" });

  let activitiesByUser = [];

  for (const activity of activities) {
    const userName = activity.userName || "Unknown User";
    const find = activitiesByUser.find((a) => a.userName === userName);
    if (!find) {
      activitiesByUser.push({ userName, userAvatar: activity.userAvatar, totalCost: Math.abs(activity.value), totalDays: activity.total / 8, userJobTitle: activity.userJobTitle });
      continue;
    }
    find.totalCost += Math.abs(activity.value);
    find.totalDays += activity.total / 8;
  }

  activitiesByUser = activitiesByUser.sort((a, b) => b.totalCost - a.totalCost);

  // Group banks by name and calculate total amount
  let banksByName = [];
  const groupedBanks = (banks || []).reduce((acc, bank) => {
    const name = bank.name || "Unknown";
    if (!acc[name]) {
      acc[name] = { name, totalAmount: 0, count: 0 };
    }
    acc[name].totalAmount += Math.abs(bank.amount);
    acc[name].count += 1;
    return acc;
  }, {});
  banksByName = Object.values(groupedBanks).sort((a, b) => b.totalAmount - a.totalAmount);

  const signingLinksModal = showSendSigningLinks && (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-xl max-w-md w-full">
        <h3 className="text-xl font-semibold mb-4">Send signing links</h3>

        <div className="space-y-4 mb-6">
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">Select recipients from the team</label>
            <div className="max-h-48 overflow-y-auto border rounded-md divide-y">
              {project?.team
                ?.filter((member) => member.userRole === "client")
                ?.map((member) => (
                  <div key={member.userEmail} className="flex items-center p-2 hover:bg-gray-50">
                    <label className="flex items-center space-x-3 w-full cursor-pointer">
                      <input
                        type="checkbox"
                        checked={selectedRecipients.some((recipient) => recipient.email === member.userEmail)}
                        onChange={(e) => {
                          setSelectedRecipients(
                            e.target.checked
                              ? [...selectedRecipients, { email: member.userEmail, name: member.userName }]
                              : selectedRecipients.filter((recipient) => recipient.email !== member.userEmail),
                          );
                        }}
                        className="rounded border-gray-300"
                      />
                      <div className="flex items-center space-x-2">
                        <div>
                          <div className="text-sm font-medium">{member.userName}</div>
                          <div className="text-xs text-gray-500">{member.userEmail}</div>
                        </div>
                      </div>
                    </label>
                  </div>
                ))}
            </div>
            <a className="btn gray-btn" href={`/project/${project._id}/team`}>
              add a client
            </a>
          </div>
        </div>

        <div className="flex justify-end space-x-4">
          <button
            onClick={() => {
              setShowSendSigningLinks(false);
              setSelectedRecipients([]);
            }}
            className="px-4 py-2 text-gray-600 hover:text-gray-800">
            Cancel
          </button>
          <button
            disabled={sendLinkLoading}
            onClick={handleSendSigningLinks}
            className="bg-sky-500 text-white px-4 py-2 rounded-lg hover:bg-sky-600 transition-colors disabled:opacity-50">
            Send
          </button>
        </div>
      </div>
    </div>
  );

  return (
    <div className="flex flex-col w-full">
      {signingLinksModal}
      {!selectedBudget.signed_at && (
        <div className="col-span-2">
          <a
            href={`/project/${selectedBudget.projectId}/budget-signature?token=${selectedBudget.token}`}
            target="_blank"
            className="w-full btn btn-primary hover:bg-blue-600 transition-colors">
            Sign budget
          </a>
        </div>
      )}
      <div className="flex justify-between items-center w-full pt-4 px-4">
        <h2 className="text-2xl font-semibold">{selectedBudget?.name}</h2>
        <div className="flex items-center space-x-2">
          {user.role === "admin" && (
            <button className="gray-btn" onClick={() => navigate(`/project/${selectedBudget.projectId}/budget-admin`)}>
              edit
            </button>
          )}
          <button className="btn-icon text-gray-400 hover:text-gray-600" onClick={() => setSelectedBudget(null)}>
            <IoClose className="h-5 w-5" />
          </button>
        </div>
      </div>
      <div className="grid grid-cols-4 gap-8 w-full p-4">
        <div className="flex flex-col col-span-1">
          <div className="text-3xl font-bold mb-2">{totalAmount.toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</div>
          <div className="text-xl font-semibold">
            {(
              totalAmount /
              ((new Date(selectedBudget.endAt).getFullYear() - new Date(selectedBudget.startAt).getFullYear()) * 12 +
                (new Date(selectedBudget.endAt).getMonth() - new Date(selectedBudget.startAt).getMonth()) +
                1)
            ).toLocaleString("fr-FR", {
              style: "currency",
              currency: "EUR",
              maximumFractionDigits: 2,
            })}
            <span className="text-sm font-normal text-gray-500">/month</span>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-6 col-span-3">
          <div className="bg-gray-50 p-4 rounded-lg">
            <h3 className="font-semibold text-gray-800 mb-3">Timeline</h3>
            <div className="space-y-2">
              <InfoRow label="Start date" value={new Date(selectedBudget.startAt).toLocaleDateString()} />
              <InfoRow label="End date" value={new Date(selectedBudget.endAt).toLocaleDateString()} />
              <InfoRow label="Signed date" value={selectedBudget.signed_at ? new Date(selectedBudget.signed_at).toLocaleDateString() : "Not signed"} />
            </div>
          </div>

          <div className="bg-gray-50 p-4 rounded-lg">
            <h3 className="font-semibold text-gray-800 mb-3">Status</h3>
            <div className="space-y-2">
              <InfoRow label="Facturation type" value={selectedBudget.facturation_type || "Not set"} />
              <InfoRow label="Days left" value={Math.ceil((new Date(selectedBudget.endAt) - new Date()) / (1000 * 60 * 60 * 24))} />
              <InfoRow
                label="Remaining amount"
                value={finalRemainingAmount.toLocaleString("fr-FR", {
                  style: "currency",
                  currency: "EUR",
                })}
              />
            </div>
          </div>

          {user.role === "admin" && !selectedBudget.signed_at && (
            <div className="col-span-2">
              <button onClick={() => setShowSendSigningLinks(true)} className="w-full btn btn-primary hover:bg-blue-600 transition-colors">
                Send signing links
              </button>
            </div>
          )}
        </div>
      </div>

      <div className="">
        <h3 className="text-lg font-semibold mb-4">Cumulative expenses over time</h3>
        <div className="h-64 w-full">
          <ResponsiveContainer width="100%" height="100%">
            <ComposedChart data={monthlyData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis tickFormatter={(value) => new Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR", notation: "compact" }).format(value)} />
              <Tooltip
                formatter={(value, name) => {
                  const formatter = new Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" });
                  return [formatter.format(value), name];
                }}
                labelFormatter={(label, payload) => {
                  const monthData = monthlyData.find((d) => d.month === label);
                  const actualValue = monthData?.actual || 0;
                  const formatter = new Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" });
                  return `${label} ${label === currentMonth ? "(Current Month)" : ""}\nMonthly Actual: ${formatter.format(actualValue)}`;
                }}
              />
              <Legend />
              <Bar dataKey="cumulativeActual" name="Cumulative Actual Expenses" fill="#3B82F6" />
              <Bar dataKey="cumulativeExpected" name="Cumulative Expected Expenses" fill="#9CA3AF" />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </div>

      <div className="mt-8">
        <h3 className="text-lg font-semibold mb-4">Detailed expenses</h3>

        <div className="grid grid-cols-2 gap-8">
          <div>
            <h4 className="text-md font-medium mb-2">Activities by user</h4>
            <div className="space-y-2">
              {activitiesByUser.map((user) => (
                <div key={user.userName} className="flex items-center justify-between p-2 bg-white rounded-md border">
                  <div className="flex items-center space-x-2">
                    <img src={user?.userAvatar || "/default-avatar.png"} alt={user.userName} className="w-8 h-8 rounded-full" />
                    <div className="text-sm">
                      <div>{user.userName}</div>
                      <div className="text-gray-400 text-xs">{user?.userJobTitle || "No position"}</div>
                    </div>
                  </div>
                  <div className="text-sm text-right">
                    <div>
                      {user.totalCost.toLocaleString("fr-FR", {
                        style: "currency",
                        currency: "EUR",
                      })}
                    </div>
                    <div className="text-gray-400 text-xs">{user.totalDays.toFixed(2)} days</div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div>
            <h4 className="text-md font-medium mb-2">Bank expenses</h4>
            <div className="space-y-2">
              {banksByName.map((bank) => (
                <div key={bank.name} className="flex items-center justify-between p-2 bg-white rounded-md border">
                  <div className="flex items-center space-x-2">
                    <div className="text-sm">
                      <div>{bank.name}</div>
                      <div className="text-gray-400 text-xs">
                        {bank.count} transaction{bank.count > 1 ? "s" : ""}
                      </div>
                    </div>
                  </div>
                  <div className="text-sm">
                    {bank.totalAmount.toLocaleString("fr-FR", {
                      style: "currency",
                      currency: "EUR",
                    })}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const InfoRow = ({ label, value }) => (
  <div className="flex justify-between items-center">
    <span className="text-gray-600">{label}:</span>
    <span className="font-medium text-gray-800">{value}</span>
  </div>
);

const Overview = ({ budgets, project }) => {
  const [activities, setActivities] = useState([]);
  const [banks, setBanks] = useState([]);
  const [loading, setLoading] = useState(false);

  // Fetch all activities and banks for active budgets only
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const activeBudgetIds = budgets.filter((budget) => budget.status === "active").map((budget) => budget._id);

        const [activitiesRes, banksRes] = await Promise.all([
          api.post(`/activity/search`, {
            projectId: project._id,
            budgetId: { $in: activeBudgetIds },
          }),
          api.post(`/bank/search`, {
            projectId: project._id,
            budgetId: { $in: activeBudgetIds },
          }),
        ]);

        if (activitiesRes.ok) setActivities(activitiesRes.data);
        if (banksRes.ok) {
          const filteredBanks = banksRes.data.banks.filter((bank) => bank.category !== "INCOME");
          setBanks(filteredBanks);
        }
      } catch (error) {
        console.error(error);
        toast.error("Failed to fetch overview data");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [project._id, budgets]);

  // Calculate monthly data for all active budgets
  const calculateMonthlyData = () => {
    const allMonths = new Set();
    const currentDate = new Date();

    // Collect months from budgets
    budgets.forEach((budget) => {
      if (budget.status !== "active") return;
      const start = new Date(budget.startAt);
      const end = new Date(budget.endAt);
      for (let date = new Date(start); date <= end; date.setMonth(date.getMonth() + 1)) {
        allMonths.add(date.toLocaleString("default", { month: "long", year: "numeric" }));
      }
    });

    // Add months from activities and banks
    [...activities, ...banks].forEach((item) => {
      const date = new Date(item.date);
      allMonths.add(date.toLocaleString("default", { month: "long", year: "numeric" }));
    });

    // Sort months chronologically
    const sortedMonths = Array.from(allMonths).sort((a, b) => {
      const dateA = new Date(a.split(" ").reverse().join(" "));
      const dateB = new Date(b.split(" ").reverse().join(" "));
      return dateA - dateB;
    });

    let cumulativeExpected = 0;
    let cumulativeActual = 0;

    return sortedMonths.map((month) => {
      const monthDate = new Date(month.split(" ").reverse().join(" "));
      const isInFuture = monthDate > currentDate;

      // Calculate expected amount for this month
      let expectedTotal = 0;
      budgets.forEach((budget) => {
        if (budget.status !== "active") return;
        const startDate = new Date(budget.startAt);
        startDate.setDate(1); // Set to first day of month
        startDate.setHours(0, 0, 0, 0);

        const endDate = new Date(budget.endAt);
        endDate.setDate(endDate.getDate()); // Keep the actual end date
        endDate.setHours(23, 59, 59, 999);

        const monthStartDate = new Date(monthDate);
        monthStartDate.setDate(1);
        monthStartDate.setHours(0, 0, 0, 0);

        const isWithinBudgetRange = monthStartDate >= startDate && monthStartDate <= endDate;
        const totalMonths = (endDate.getFullYear() - startDate.getFullYear()) * 12 + (endDate.getMonth() - startDate.getMonth()) + 1;

        if (isWithinBudgetRange) {
          expectedTotal += budget.amount / totalMonths;
        }
      });

      // Calculate actual amount for this month
      const monthActivities = activities.filter((activity) => {
        const activityDate = new Date(activity.date);
        return activityDate.getMonth() === monthDate.getMonth() && activityDate.getFullYear() === monthDate.getFullYear();
      });

      const monthBanks = banks.filter((bank) => {
        const bankDate = new Date(bank.date);
        return bankDate.getMonth() === monthDate.getMonth() && bankDate.getFullYear() === monthDate.getFullYear();
      });

      const actualTotal = isInFuture
        ? 0
        : monthActivities.reduce((sum, activity) => sum + Math.abs(activity.value), 0) + monthBanks.reduce((sum, bank) => sum + Math.abs(bank.amount), 0);

      // Update cumulative totals
      cumulativeExpected += expectedTotal;
      cumulativeActual += actualTotal;

      return {
        month,
        cumulativeExpected,
        cumulativeActual,
        isInFuture,
      };
    });
  };

  const calculateSummary = () => {
    const activeBudgets = budgets.filter((budget) => budget.status === "active");

    // Total budget amount
    const totalBudget = activeBudgets.reduce((sum, budget) => sum + budget.amount, 0);

    // Total spent (activities + banks)
    const totalSpent = activities.reduce((sum, activity) => sum + Math.abs(activity.value), 0) + banks.reduce((sum, bank) => sum + Math.abs(bank.amount), 0);

    // Calculate monthly budget
    const monthlyBudget = activeBudgets.reduce((sum, budget) => {
      const months =
        (new Date(budget.endAt).getFullYear() - new Date(budget.startAt).getFullYear()) * 12 + (new Date(budget.endAt).getMonth() - new Date(budget.startAt).getMonth()) + 1;
      return sum + budget.amount / months;
    }, 0);

    return {
      totalBudget,
      totalSpent,
      monthlyBudget,
      remaining: Math.max(totalBudget - totalSpent, 0),
    };
  };

  const summary = calculateSummary();
  const monthlyData = calculateMonthlyData();

  if (loading) return <div className="text-center py-8 text-gray-500">Loading...</div>;

  return (
    <div className="p-4">
      <h3 className="text-lg font-semibold mb-4">Budgets overview</h3>
      <div className="grid grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
        <div className="bg-gray-50 p-4 rounded-lg ">
          <div className="text-sm text-gray-500 mb-1">Total budget</div>
          <div className="text-xl font-semibold">
            {summary.totalBudget.toLocaleString("fr-FR", {
              style: "currency",
              currency: "EUR",
              maximumFractionDigits: 0,
            })}
          </div>
        </div>

        <div className="bg-gray-50 p-4 rounded-lg ">
          <div className="text-sm text-gray-500 mb-1">Monthly budget</div>
          <div className="text-xl font-semibold">
            {summary.monthlyBudget.toLocaleString("fr-FR", {
              style: "currency",
              currency: "EUR",
              maximumFractionDigits: 0,
            })}
          </div>
        </div>

        <div className="bg-gray-50 p-4 rounded-lg">
          <div className="text-sm text-gray-500 mb-1">Total spent</div>
          <div className="text-xl font-semibold">
            {summary.totalSpent.toLocaleString("fr-FR", {
              style: "currency",
              currency: "EUR",
              maximumFractionDigits: 0,
            })}
          </div>
        </div>

        <div className="bg-gray-50 p-4 rounded-lg ">
          <div className="text-sm text-gray-500 mb-1">Remaining</div>
          <div className="text-xl font-semibold">
            {summary.remaining.toLocaleString("fr-FR", {
              style: "currency",
              currency: "EUR",
              maximumFractionDigits: 0,
            })}
          </div>
        </div>
      </div>
      <div className="h-64 w-full">
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart data={monthlyData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" />
            <YAxis
              tickFormatter={(value) =>
                new Intl.NumberFormat("fr-FR", {
                  style: "currency",
                  currency: "EUR",
                  notation: "compact",
                }).format(value)
              }
            />
            <Tooltip
              formatter={(value, name) => {
                return [
                  new Intl.NumberFormat("fr-FR", {
                    style: "currency",
                    currency: "EUR",
                  }).format(value),
                  name,
                ];
              }}
              labelFormatter={(label, payload) => {
                const monthData = monthlyData.find((d) => d.month === label);
                const actualValue = monthData?.actual || 0;
                const expectedValue = monthData?.expected || 0;
                const formatter = new Intl.NumberFormat("fr-FR", {
                  style: "currency",
                  currency: "EUR",
                });
                return `${label}\nMonthly Actual: ${formatter.format(actualValue)}\nMonthly Expected: ${formatter.format(expectedValue)}`;
              }}
            />
            <Legend />
            <Bar dataKey="cumulativeActual" name="Cumulative Actual Expenses" fill="#3B82F6" />
            <Bar dataKey="cumulativeExpected" name="Cumulative Expected Budget" fill="#9CA3AF" />
          </ComposedChart>
        </ResponsiveContainer>
      </div>

      <div className="mt-8">
        <h3 className="text-lg font-semibold mb-4">Detailed expenses</h3>

        <div className="grid grid-cols-2 gap-8">
          <div>
            <h4 className="text-md font-medium mb-2">Activities by user</h4>
            <div className="space-y-2">
              {(() => {
                let activitiesByUser = [];

                for (const activity of activities) {
                  const userName = activity.userName || "Unknown User";
                  const find = activitiesByUser.find((a) => a.userName === userName);
                  if (!find) {
                    activitiesByUser.push({
                      userName,
                      userAvatar: activity.userAvatar,
                      totalCost: Math.abs(activity.value),
                      totalDays: activity.total / 8,
                      userJobTitle: activity.userJobTitle,
                    });
                    continue;
                  }
                  find.totalCost += Math.abs(activity.value);
                  find.totalDays += activity.total / 8;
                }

                return activitiesByUser
                  .sort((a, b) => b.totalCost - a.totalCost)
                  .map((user) => (
                    <div key={user.userName} className="flex items-center justify-between p-2 bg-white rounded-md border">
                      <div className="flex items-center space-x-2">
                        <img src={user?.userAvatar || "/default-avatar.png"} alt={user.userName} className="w-8 h-8 rounded-full" />
                        <div className="text-sm">
                          <div>{user.userName}</div>
                          <div className="text-gray-400 text-xs">{user?.userJobTitle || "No position"}</div>
                        </div>
                      </div>
                      <div className="text-sm text-right">
                        <div>
                          {user.totalCost.toLocaleString("fr-FR", {
                            style: "currency",
                            currency: "EUR",
                          })}
                        </div>
                        <div className="text-gray-400 text-xs">{user.totalDays.toFixed(2)} days</div>
                      </div>
                    </div>
                  ));
              })()}
            </div>
          </div>

          <div>
            <h4 className="text-md font-medium mb-2">Bank expenses</h4>
            <div className="space-y-2">
              {(() => {
                const groupedBanks = banks.reduce((acc, bank) => {
                  const name = bank.name || "Unknown";
                  if (!acc[name]) {
                    acc[name] = { name, totalAmount: 0, count: 0 };
                  }
                  acc[name].totalAmount += Math.abs(bank.amount);
                  acc[name].count += 1;
                  return acc;
                }, {});

                return Object.values(groupedBanks)
                  .sort((a, b) => b.totalAmount - a.totalAmount)
                  .map((bank) => (
                    <div key={bank.name} className="flex items-center justify-between p-2 bg-white rounded-md border">
                      <div className="flex items-center space-x-2">
                        <div className="text-sm">
                          <div>{bank.name}</div>
                          <div className="text-gray-400 text-xs">
                            {bank.count} transaction{bank.count > 1 ? "s" : ""}
                          </div>
                        </div>
                      </div>
                      <div className="text-sm">
                        {bank.totalAmount.toLocaleString("fr-FR", {
                          style: "currency",
                          currency: "EUR",
                        })}
                      </div>
                    </div>
                  ));
              })()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
