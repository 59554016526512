import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";

import DebounceInput from "../../../../components/DebounceInput";
import MultiAction from "../../../../components/MultiAction";
import MultiSelect from "../../../../components/MultiSelect";
import SelectUser from "../../../../components/selectUser";

import api from "../../../../services/api";

import Kanban from "./components/kanban";
import List from "./components/list";

import Budget from "./components/Budget";
import NotionModal from "./components/notionModal";

import BulkActionModal from "./components/BulkActionsModal";

import NotionStats from "./components/Stats";
import History from "./components/history";

// TODO: rename all the 'notion' to 'task'

export default ({ project, setProject }) => {
  const [notions, setNotions] = useState([]);

  const [filters, setFilters] = useState({ page: 1, sort: { created_at: -1 }, statuses: ["TODO", "READY_TO_DEV", "IN_PROGRESS", "TESTING", "DONE"] });
  const [loading, setLoading] = useState(false);
  const [view, setView] = useState();
  const [budgets, setBudgets] = useState([]);
  const [openModalMultiSelect, setOpenModalMultiSelect] = useState(false);
  const [selectedNotions, setSelectedNotions] = useState({});
  const { search } = useLocation();
  const navigate = useNavigate();

  const { id, tab } = useParams();

  useEffect(() => {
    if (tab === "kanban") return setView("KANBAN");
    if (tab === "list") return setView("LIST");
    if (tab === "stats") return setView("STATS");
    if (tab === "history") return setView("HISTORY");
    setView("KANBAN");
  }, [tab]);

  const user = useSelector((state) => state.Auth.user);

  useEffect(() => {
    fetch();
  }, [filters]);

  useEffect(() => {
    getBudgets();
  }, []);

  const getBudgets = async () => {
    try {
      const { data } = await api.post("/budget/search", { projectId: project._id, status: "active" });
      setBudgets(data);
    } catch (e) {
      console.log("e", e);
    }
  };

  async function fetch() {
    setLoading(true);
    const newFilters = { ...filters, project_id: project._id };
    const { data, ok } = await api.post("/notion/search", newFilters);
    if (!ok) return toast.error("Error fetching users");

    setNotions(data);
    setLoading(false);
    setSelectedNotions(data.reduce((acc, notion) => ({ ...acc, [notion._id]: false }), {}));
  }

  async function onExport() {
    const newFilters = { ...filters, project_id: project._id };
    const { data: notions, ok } = await api.post("/notion/search", newFilters);
    if (!ok) return toast.error("Error fetching users");

    if (!notions.length) return;

    let arr = notions.map((notion) => {
      console.log("notion", notion);
      // Format comments array into readable text with HTML stripped
      const commentsText =
        notion.comments
          ?.filter((comment) => comment.text) // Only include comments with text
          ?.map((comment) => {
            const date = new Date(comment.created_at).toLocaleDateString();
            // Strip HTML tags and decode HTML entities
            const cleanText = comment.text
              ?.replace(/<[^>]*>/g, "") // Remove HTML tags
              ?.replace(/&nbsp;/g, " ") // Replace &nbsp; with space
              ?.replace(/&amp;/g, "&") // Replace &amp; with &
              ?.replace(/&lt;/g, "<") // Replace &lt; with <
              ?.replace(/&gt;/g, ">") // Replace &gt; with >
              ?.replace(/&quot;/g, '"') // Replace &quot; with "
              ?.trim();

            return `${comment.user_name || "Unknown"} (${date}):\n${cleanText}\n----------------------------------------`; // Separator line
          })
          .join("\n\n") || ""; // Double newline between comments

      return {
        name: notion.name,
        priority: (notion.priority || "").toUpperCase(),
        category: notion.category,
        status: notion.status,
        budget: budgets.find((b) => b._id === notion.budget_id)?.name,
        time: notion.estimated_hours,
        price: notion.estimated_price,
        link: `https://accounting.selego.co/project/662e7b0607f5bf070d91916e/backlog?task_modal_id=${notion._id}`,
        created_at: notion.created_at,
        comments: commentsText,
      };
    });

    const date = new Date();
    const filename = `${project.name} - ${date.toLocaleDateString("fr")}.csv`;
    const header = Object.keys(arr[0]);

    // Format CSV with proper escaping for comments
    const csv = [
      header.join(";"),
      ...arr.map((row) =>
        header
          .map((field) => {
            const value = row[field] || "";
            // Double escape quotes and wrap field in quotes
            return `"${value.toString().replace(/"/g, '""')}"`;
          })
          .join(";"),
      ),
    ].join("\n");

    const blob = new Blob(["\ufeff" + csv], { type: "text/csv;charset=utf-8" }); // Add BOM for Excel UTF-8 support
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveBlob(blob, filename);
    } else {
      const encodedUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUrl);
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(encodedUrl);
    }
  }

  return (
    <div className="bg-white border border-gray-300 rounded-md overflow-hidden px-4 py-2">
      <div className="bg-white h-full">
        <Budget project={project} setFilters={setFilters} />
        <div className="flex justify-between items-center gap-2 mb-2">
          <div className="flex-1 flex items-center gap-2 mb-2 flex-wrap">
            <DebounceInput
              debounce={300}
              className="input w-[200px]"
              placeholder="Search..."
              value={filters.search}
              onChange={(e) => setFilters((prev) => ({ ...prev, search: e.target.value, page: 1 }))}
            />
            <SelectTags project={project} value={filters.tags} onChange={(e) => setFilters({ ...filters, tags: e })} />

            <SelectModule project={project} value={filters.module} onChange={(e) => setFilters((f) => ({ ...f, module: e }))} />
            <SelectEpic project={project} value={filters.epic_ids} onChange={(e) => setFilters((f) => ({ ...f, epic_ids: e }))} />
            <MultiSelect
              id="select-status"
              options={["TODO", "READY_TO_DEV", "IN_PROGRESS", "TESTING", "DONE", "ARCHIVED"].map((status) => ({ value: status, label: status }))}
              onSelectedChange={(e) => setFilters((f) => ({ ...f, statuses: e.map((e) => e.value) }))}
              values={filters.statuses.map((status) => ({ value: status, label: status }))}
              placeholder="Status"
            />
            <MultiSelect
              id="select-category"
              options={["BUG", "FEATURE"].map((category) => ({ value: category, label: category }))}
              onSelectedChange={(e) => setFilters((f) => ({ ...f, categories: e.map((e) => e.value) }))}
              placeholder="Category"
            />
            <MultiSelect
              id="select-priority"
              options={["P0", "P1", "P2", "P3"].map((priority) => ({ value: priority, label: priority }))}
              onSelectedChange={(e) => setFilters((f) => ({ ...f, priorities: e.map((e) => e.value) }))}
              placeholder="Priority"
            />
            <div className="flex flex-row items-center gap-4">
              <SelectUser
                name="people"
                className="projectsInput"
                placeholder="User"
                onChange={(e) => setFilters({ ...filters, user_id: e._id, user_name: e.name, user_avatar: e.avatar })}
                value={{ _id: filters.user_id, name: filters.user_name, avatar: filters.user_avatar }}
              />
            </div>
          </div>
          <MultiAction
            actions={[
              { name: "Edit", cb: () => setOpenModalMultiSelect(true) },
              {
                name: "New ticket",
                cb: async () => {
                  const nf = { project_id: project._id };
                  const { data } = await api.post("/notion", nf);
                  const query = new URLSearchParams(search);
                  query.set("task_modal_id", data[0]._id);
                  navigate({ search: query.toString() });
                  setNotions([data[0], ...notions]);
                },
              },
              { name: "Export", cb: onExport },
            ]}
          />
        </div>

        <div className="flex justify-between">
          <div className="flex items-end ml-2 ">
            <div onClick={() => navigate(`/project/${id}/backlog/kanban`)} className="flex items-center cursor-pointer">
              <div className={`${view === "KANBAN" ? "gray-btn" : "transparent-btn"} h-8 text-center transition duration-300 rounded-b-none`}>Kanban</div>
            </div>
            <div onClick={() => navigate(`/project/${id}/backlog/list`)} className="flex items-center cursor-pointer">
              <div className={`${view === "LIST" ? "gray-btn" : "transparent-btn"} h-8 text-center transition duration-300 rounded-b-none`}>List</div>
            </div>
            <div onClick={() => navigate(`/project/${id}/backlog/stats`)} className="flex items-center cursor-pointer">
              <div className={`${view === "STATS" ? "gray-btn" : "transparent-btn"} h-8 text-center transition duration-300 rounded-b-none`}>Stats</div>
            </div>
            <div onClick={() => navigate(`/project/${id}/backlog/history`)} className="flex items-center cursor-pointer">
              <div className={`${view === "HISTORY" ? "gray-btn" : "transparent-btn"} h-8 text-center transition duration-300 rounded-b-none`}>History</div>
            </div>
          </div>
        </div>
        {view === "KANBAN" && <Kanban notions={notions} setNotions={setNotions} projectId={project._id} getNotions={fetch} />}
        {view === "LIST" && <List notions={notions} setNotions={setNotions} projectId={project._id} getNotions={fetch} />}
        {view === "STATS" && <NotionStats data={notions} />}
        {view === "HISTORY" && <History data={notions} />}
      </div>
      <NotionModal
        project={project}
        onDelete={(notion) => setNotions(notions.filter((n) => n._id !== notion._id))}
        onUpdate={(notion) => {
          setNotions(notions.map((n) => (n._id === notion._id ? { ...n, ...notion } : n)));
        }}
      />
      <BulkActionModal selectedNotions={selectedNotions} isOpen={openModalMultiSelect} onClose={() => setOpenModalMultiSelect(false)} projectId={project._id} />
    </div>
  );
};

const SelectTags = ({ value, onChange, project }) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    get();
  }, []);

  async function get() {
    const { data } = await api.post("/notion/aggregate", { project_id: project._id, key: "tags" });
    const arr = data.filter((e) => e._id !== null).map((e) => ({ label: e._id, value: e._id, count: e.count }));
    setOptions(arr);
  }

  return <MultiSelect id="select-status" options={options} value={value} onSelectedChange={(e) => onChange(e.map((e) => e.value))} placeholder="Tags" />;
};

const SelectModule = ({ value, onChange, project }) => {
  const [options, setOptions] = useState([]);
  const [lastFetch, setLastFetch] = useState(0);

  async function fetchModules() {
    const { data } = await api.post("/notion/aggregate", { project_id: project._id, key: "module" });
    const arr = data.filter((e) => e._id !== null).map((e) => ({ label: e._id, value: e._id, count: e.count }));
    setOptions(arr);
    setLastFetch(Date.now());
  }

  useEffect(() => {
    // Initial fetch when component mounts
    fetchModules();
  }, [project._id]);

  // Refetch when component gains focus if more than 5 seconds have passed
  useEffect(() => {
    function handleFocus() {
      if (Date.now() - lastFetch > 5000) {
        fetchModules();
      }
    }
    window.addEventListener("focus", handleFocus);
    return () => window.removeEventListener("focus", handleFocus);
  }, [lastFetch]);

  return (
    <MultiSelect
      id="select-modules"
      options={options}
      values={value?.map((tag) => ({ value: tag, label: tag }))}
      onSelectedChange={(e) => onChange(e.map((e) => e.value))}
      placeholder="Module"
    />
  );
};

const SelectEpic = ({ value, onChange, project }) => {
  const [options, setOptions] = useState([]);
  const [lastFetch, setLastFetch] = useState(0);

  async function fetchEpics() {
    const { data } = await api.post("/epic/search", { project_id: project._id, sort: "title" });
    const arr = data.filter((e) => e._id !== null).map((e) => ({ label: e.title, value: e._id }));
    setOptions(arr);
    setLastFetch(Date.now());
  }

  useEffect(() => {
    // Initial fetch when component mounts
    fetchEpics();
  }, [project._id]);

  // Refetch when component gains focus if more than 5 seconds have passed
  useEffect(() => {
    function handleFocus() {
      if (Date.now() - lastFetch > 5000) {
        fetchEpics();
      }
    }
    window.addEventListener("focus", handleFocus);
    return () => window.removeEventListener("focus", handleFocus);
  }, [lastFetch]);

  return (
    <MultiSelect
      id="select-epics"
      options={options}
      values={value?.map((tag) => ({ value: tag, label: tag }))}
      onSelectedChange={(e) => onChange(e.map((e) => e.value))}
      placeholder="Epic"
    />
  );
};
