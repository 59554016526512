import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import api from "../../../../services/api";
import { classNames } from "../../../../utils";
import DataRoomTab from "./dataroom";

const ViewAnswer = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [answer, setAnswer] = useState({
    title: "",
    description: "", 
    status: "pending",
    date: "",
    montant: 0,
    entreprise: "",
    files: [],
  });
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState("edit");

  useEffect(() => {
    loadAnswer();
  }, []);

  async function loadAnswer() {
    try {
      setLoading(true);
      const { ok, data } = await api.get(`/mission_ao_answer/${id}`);
      if (!ok) throw new Error("Failed to fetch answer");
      setAnswer(data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      toast.error("Error loading answer");
    }
  }

  async function updateAnswer() {
    try {
      const { ok } = await api.put(`/mission_ao_answer/${id}`, answer);
      if (!ok) throw new Error("Failed to update answer");
      toast.success("Answer updated successfully!");
    } catch (error) {
      console.error(error);
      toast.error("Error updating answer");
    }
  }

  if (loading) return (
    <div className="min-h-screen flex items-center justify-center">
      <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
    </div>
  );

  return (
    <div className="mx-auto ">
      <div className="bg-white rounded-xl shadow-lg overflow-hidden">
        <nav className="border-b border-gray-200">
          <div className="flex gap-4 p-2">
            <TabItem title="Edit" tab="edit" setTab={setTab} active={tab === "edit"} />
            <TabItem title="Data Room" tab="data-room" setTab={setTab} active={tab === "data-room"} />
            <TabItem title="Raw" tab="raw" setTab={setTab} active={tab === "raw"} />
          </div>
        </nav>

        <div className="p-6">
          {tab === "edit" && (
            <div className="space-y-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label className="block text-sm font-semibold text-gray-700 mb-2">Title</label>
                  <input 
                    className="input w-full focus:ring-2 focus:ring-blue-500 transition-all" 
                    value={answer.title} 
                    onChange={(e) => setAnswer({ ...answer, title: e.target.value })} 
                  />
                </div>

                <div>
                  <label className="block text-sm font-semibold text-gray-700 mb-2">Status</label>
                  <select 
                    className="input w-full focus:ring-2 focus:ring-blue-500 transition-all"
                    value={answer.status} 
                    onChange={(e) => setAnswer({ ...answer, status: e.target.value })}
                  >
                    <option value="pending">Pending</option>
                    <option value="approved">Approved</option>
                    <option value="rejected">Rejected</option>
                  </select>
                </div>

                <div>
                  <label className="block text-sm font-semibold text-gray-700 mb-2">Date</label>
                  <input 
                    type="date" 
                    className="input w-full focus:ring-2 focus:ring-blue-500 transition-all"
                    value={answer.date} 
                    onChange={(e) => setAnswer({ ...answer, date: e.target.value })} 
                  />
                </div>

                <div>
                  <label className="block text-sm font-semibold text-gray-700 mb-2">Montant</label>
                  <div className="relative">
                    <input 
                      type="number" 
                      className="input w-full pl-8 focus:ring-2 focus:ring-blue-500 transition-all"
                      value={answer.montant} 
                      onChange={(e) => setAnswer({ ...answer, montant: e.target.value })} 
                    />
                    <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500">€</span>
                  </div>
                </div>

                <div>
                  <label className="block text-sm font-semibold text-gray-700 mb-2">Entreprise</label>
                  <input 
                    className="input w-full focus:ring-2 focus:ring-blue-500 transition-all"
                    value={answer.entreprise} 
                    onChange={(e) => setAnswer({ ...answer, entreprise: e.target.value })} 
                  />
                </div>
              </div>

              <div>
                <label className="block text-sm font-semibold text-gray-700 mb-2">Description</label>
                <textarea 
                  className="input w-full h-32 focus:ring-2 focus:ring-blue-500 transition-all" 
                  value={answer.description} 
                  onChange={(e) => setAnswer({ ...answer, description: e.target.value })} 
                />
              </div>

              <div className="flex justify-end">
                <button 
                  className="btn bg-blue-600 hover:bg-blue-700 text-white px-6 py-2 rounded-lg transition-colors"
                  onClick={updateAnswer}
                >
                  Save Changes
                </button>
              </div>
            </div>
          )}

          {tab === "data-room" && <DataRoomTab answer={answer} setAnswer={setAnswer} />}

          {tab === "raw" && (
            <div className="bg-gray-50 rounded-lg p-6">
              <pre className="text-sm overflow-auto">{JSON.stringify(answer, null, 2)}</pre>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const TabItem = ({ tab, title, setTab, active }) => (
  <button
    onClick={() => setTab(tab)}
    className={classNames(
      "px-4 py-2 text-sm font-medium rounded-lg transition-all",
      active 
        ? "text-blue-600 bg-white shadow-sm" 
        : "text-gray-600 hover:text-blue-600 hover:bg-white/60"
    )}>
    {title}
  </button>
);

export default ViewAnswer;
